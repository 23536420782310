import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CustomerProfileTypeEnum } from '@/api/customerApi'
import { UseQueryGetPofileData } from '@/api/onboardingApi'
import { AuthedStatusEnum } from '@/config'
import { EKYC_REF_NO } from '@/constants/sessionKeys'
import { selectAuthStatus } from '@/stores/auth.selectors'
import { sessionSetEkyc } from '@/stores/ekyc'
import { selectEpfToken } from '@/stores/epfToken.selectors'
import { getSessionStorage } from '@/utils'

export const useProfileType = () => {
  const authStatus = useSelector(selectAuthStatus)
  const epfToken = useSelector(selectEpfToken)

  const [profileType, setProfileType] = useState<
    UseQueryGetPofileData | undefined
  >()

  const dispatch = useDispatch()
  useEffect(() => {
    //check if is ekyc --
    if (authStatus === AuthedStatusEnum.RequireEKYC) {
      const ekycRefNo = getSessionStorage(EKYC_REF_NO)
      dispatch(sessionSetEkyc({ refNo: ekycRefNo || undefined }))
      setProfileType({
        type: CustomerProfileTypeEnum.EKYC,
        key: ekycRefNo || undefined,
      })
      return
    }

    //check if is epf --
    if (!!epfToken) {
      setProfileType({ type: CustomerProfileTypeEnum.EPF, key: epfToken })
      return
    }

    setProfileType({ type: CustomerProfileTypeEnum.EXISTING })
  }, [])

  return profileType
}

export default useProfileType
