import { InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { forwardRef, memo } from 'react'
import { ReactComponent as ChevronRightIcon } from './assets/chevron-right.svg'

export interface FundInputProps
  extends Omit<TextFieldProps, 'type' | 'InputProps'> {
  className?: string
  InputProps?: Omit<TextFieldProps['InputProps'], 'endAdornment'>
}

const FundInput = memo(
  forwardRef<HTMLDivElement, FundInputProps>(
    ({ className = '', InputProps, disabled, ...rest }, ref) => {
      return (
        <TextField
          className={`[&>div]:cursor-pointer [&>div>input]:cursor-pointer [&>div>input]:text-left [&>div>input]:py-3 ${className}`}
          type='button'
          ref={ref}
          disabled={disabled}
          InputProps={{
            ...InputProps,
            endAdornment: !disabled && (
              <InputAdornment position='end'>
                <ChevronRightIcon className='w-6 h-6' />
              </InputAdornment>
            ),
          }}
          {...rest}
        />
      )
    }
  )
)

export default FundInput
