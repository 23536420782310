import StickyBottomContainer from '@/components/StickyBottomContainer'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

export interface RedemptionFooterProps {
  onCashOut: () => void
  disabled?: boolean
}

const RedemptionFooter = ({
  onCashOut,
  disabled = false,
}: RedemptionFooterProps) => {
  const { t } = useTranslation(['investedFundsPage', 'common'])

  return (
    <StickyBottomContainer className='max-w-screen-xl mx-auto xl:!px-0'>
      <Button
        variant='contained'
        fullWidth
        disabled={disabled}
        onClick={() => onCashOut()}
        className='mb-6 disabled:!bg-gradient-to-r disabled:!from-pi-gray-4 disabled:!to-pi-gray-3 disabled:!shadow-none disabled:!text-white'
      >
        {t('cash-out', { ns: 'common' })}
      </Button>
    </StickyBottomContainer>
  )
}

export default RedemptionFooter
