import { Button, Chip } from '@mui/material'
import { FundInformation } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FundInfoLink from '../FundInfoLink'

export interface FundInfoCardProps
  extends Pick<
    FundInformation,
    | 'name'
    | 'isNewFund'
    | 'isSyariahFund'
    | 'details'
    | 'isEPFFund'
    | 'fundInfoUrl'
  > {
  className?: string
  onSelectFund?: (selectCallback: (state: boolean) => void) => void
  onDeselectFund?: () => void
  hasSelected?: boolean
  isEPFFund?: boolean
  showSelectButton?: boolean
  onCardClick?: () => void
  disabled?: boolean
}

const FundInfoCard = ({
  className = '',
  name,
  isNewFund,
  isSyariahFund,
  isEPFFund = false,
  details,
  fundInfoUrl = '',
  onSelectFund,
  onDeselectFund,
  hasSelected = false,
  showSelectButton = true,
  onCardClick,
  disabled = false,
}: FundInfoCardProps) => {
  const { t } = useTranslation(['investmentPage', 'common'])
  const [selected, setSelected] = useState(hasSelected)

  const onSelectDeselectCallback = (state: boolean) => {
    setSelected(state)
  }

  const onSelectDeselect = () => {
    if (selected) {
      onDeselectFund && onDeselectFund()
      setSelected(false)
    } else {
      onSelectFund && onSelectFund(onSelectDeselectCallback)
    }
  }
  return (
    <div
      onClick={!disabled ? onCardClick : undefined}
      aria-disabled={disabled}
      className={`flex flex-col justify-between bg-pi-sky-blue-1 rounded-lg border-solid border border-pi-sky-blue-3 ${
        !!onCardClick ? 'cursor-pointer' : ''
      } ${
        !!disabled ? '!bg-pi-gray-6 !border-pi-gray-3 !text-pi-gray-2' : ''
      } ${className}`}
    >
      <div
        className='flex flex-col rounded-t-lg p-4'
        onClick={!disabled ? onSelectDeselect : undefined}
      >
        <h1 className='mb-2 md:mb-4'>{name}</h1>
        <div className='flex flex-row flex-wrap gap-2'>
          {isNewFund && (
            <Chip
              className='text-xs !leading-6 h-min font-bold'
              label={t('New', { ns: 'common' })}
              color={!!disabled ? 'default' : 'error'}
            />
          )}
          {isSyariahFund && (
            <Chip
              className='text-xs !leading-6 h-min font-bold'
              label={t('shariah-compliant')}
              color={!!disabled ? 'default' : 'success'}
            />
          )}
        </div>
      </div>
      {!!details?.length && (
        <ul className='mt-0 mb-4 ps-10 pe-4'>
          {details.map(({ index, detail }) => (
            <li key={index}>{detail}</li>
          ))}
        </ul>
      )}
      <div
        className={`flex flex-row bg-white rounded-b-lg justify-between items-center p-4 border-solid border-0 border-t border-pi-sky-blue-3 ${
          !!disabled ? '!bg-pi-gray-6 !border-pi-gray-3' : ''
        }`}
      >
        <FundInfoLink url={fundInfoUrl} disabled={disabled} />
        {showSelectButton && (
          <Button
            variant={selected ? 'outlined' : 'contained'}
            className={`tracking-wide ${isEPFFund ? 'go-epf-button' : ''}`}
            onClick={onSelectDeselect}
          >
            {t(isEPFFund ? 'visit-i-invest' : selected ? 'selected' : 'select')}
          </Button>
        )}
      </div>
    </div>
  )
}

export default FundInfoCard
