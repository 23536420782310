import { useQueryFundList } from '@/api/commonApi'
import { FundCategory, FundListByCategory } from '@/Routes/pages/FundList'
import { FundInformation } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useMemo, useState } from 'react'
import useRiskProfileTabs from '../useRiskProfileTabs'
import { useRiskProfileStatus } from '../useRiskProfileStatus'

export const useInitFundListData = () => {
  const riskProfileTabs = useRiskProfileTabs()
  const [isLoadingFilter, setLoadingFilter] = useState(false)
  const {
    riskLevelList,
    metaData,
    riskLevel,
    riskProfile,
    riskProfileName,
    totalAssetsAmt,
    profitLoss,
    portfolioData,
    isLoading: isLoadingPage,
    isError: isErrorPage,
  } = useRiskProfileStatus()

  const {
    data: fundList,
    isLoading: isLoadingFundListQuery,
    isError: isFundListQueryError,
  } = useQueryFundList({ enabled: true })

  const fundListByCategory: FundListByCategory = useMemo(() => {
    setLoadingFilter(true)
    const riskLevel = riskLevelList?.levels[riskProfileTabs.value]
    const EpfFunds: FundInformation[] = []
    const CashFunds: FundInformation[] = []
    fundList?.forEach((fund) => {
      if (fund.riskLevel === riskLevel?.level && fund.isActive) {
        if (fund.isEPFFund) {
          EpfFunds.push(fund)
        }
        CashFunds.push({ ...fund, isEPFFund: false })
      }
    })
    setLoadingFilter(false)
    return {
      [FundCategory.EPF]: EpfFunds,
      [FundCategory.Cash]: CashFunds,
    }
  }, [riskProfileTabs.value, riskLevelList, fundList])

  const isLoadingFundList = useMemo(
    () => isLoadingFundListQuery || isLoadingFilter,
    [isLoadingFundListQuery, isLoadingFilter]
  )

  return {
    isLoadingFundList,
    isLoadingPage,
    isFundListQueryError,
    isErrorPage,
    fundListByCategory,
    riskLevelList,
    riskProfileTabs,
    metaData,
    riskLevel,
    riskProfile,
    riskProfileName,
    totalAssetsAmt,
    profitLoss,
    portfolioData,
  }
}

export default useInitFundListData
