import {
  AgentInformation as AgentCode,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import {
  ButtonWithLoading,
  ResponseError,
  useResponseError,
  formattedAmount,
  useModal,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect, useMemo, useRef } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  useMutationPurchasingInstruction,
  UseMutationPurchasingInstruction,
} from '@/api/orderApi'
import Divider from '@/components/Divider'
import HelmetSiteTitle from '@/components/HelmetSiteTitle'
import Loading from '@/components/Loading'
import TwoSideText from '@/components/TwoSideText'
import {
  AML_FAILED,
  AML_EXPIRED,
  RISK_PROFILE_NOT_PASSED,
  RISK_PROFILE_NOT_EXPIRED,
  UNKNOWN_ERROR,
} from '@/constants/errorCodes'
import useBeforeUnload from '@/hooks/useBeforeUnload'
import { InvestSessionState, SelectedFund } from '@/hooks/useInvestmentReducer'
import useSetUserNotIdle from '@/hooks/useSetUserNotIdle'
import PostLogonMainBody from '@/layout/PostLogonMainBody'
import PostLogonContainer from '@/layout/PostLogonContainer'
import {
  scrollTop,
  getErrorResponseCode,
  calTotalAmount,
  getFundCodesString,
  isValidAccountCheck,
} from '@/utils'
import AgentCodeComp, { AgentCodeRef } from './components/AgentCodeComp'
import CampaignCodeComp, {
  CampaignCodeRef,
} from './components/CampaignCodeComp'
import FundsComp, { FundsCompItemErr } from './components/FundsComp'
import HasUnappliedCodeModal from './components/HasUnappliedCodeModal'
import useInitData from './hooks/useInitData'
import SubaccountInvalidModal, {
  ActionTypeEnum, TransactionActionEnum
} from '@/components/SubaccountInvalidModal'
import ReferralCodeComp from './components/ReferralCodeComp'

export interface CashInAmountFormProps extends InvestSessionState {
  byPassHighRisk: boolean
  ignoreUnappliedCode: boolean
}
export const CashInAmount = ({
  handleSetRequestToken,
  errorMessage,
  handleErrorMessage,
  setInvestments,
  resetInvestments,
}: {
  handleSetRequestToken: (requestToken: string) => void
  errorMessage?: string
  handleErrorMessage: (error?: string) => void
  setInvestments: (data: InvestSessionState) => void
  resetInvestments: () => void
}) => {
  const { t } = useTranslation(['cashInPage', 'common'])
  const campaignCodeRef = useRef<CampaignCodeRef>(null)
  const agentCodeRef = useRef<AgentCodeRef>(null)
  const setUserNotIdle = useSetUserNotIdle()

  const unappliedCodeModal = useModal()
  const accountInvalidModal = useModal()

  const { control, handleSubmit, watch, setValue, getValues } =
    useForm<CashInAmountFormProps>({
      mode: 'onChange',
      defaultValues: {
        funds: [],
        campaignCode: undefined,
        agentCode: undefined,
        referralCode: undefined,
        totalAmount: 0,
        byPassHighRisk: false,
        ignoreUnappliedCode: false,
      },
    })

  const {
    isEpf,
    cmData,
    piData,
    isValidUserStatus,
    handleCheckUserStatus,
    isLoading: initDataIsLoading,
  } = useInitData({
    handelSetFunds: (funds: SelectedFund[]) => {
      setValue('funds', funds)
      setValue('totalAmount', calTotalAmount(funds))
    },
    handelSetAgentCode: (agentCode: AgentCode) =>
      setValue('agentCode', agentCode),
    resetInvestments,
  })

  useEffect(() => {
    const _totalAmount = calTotalAmount(watch('funds'))
    if (watch('totalAmount') !== _totalAmount)
      setValue('totalAmount', _totalAmount)
  }, [watch('funds')])

  const handlerSelectedDiffPromoCode = (
    isDiffPromoCode: boolean | undefined
  ) => {
    if (isDiffPromoCode) {
      handleErrorMessage('')
    }
  }

  const fundCodes = useMemo(
    () => getFundCodesString(watch('funds')),
    [watch('funds')]
  )

  const isAccountValid = useMemo(() => {
    if (!!piData && !!cmData) {
      const headAccountSts = cmData?.headAccount?.sts
      const subAccountSts = piData?.subaccountStatus
      return isValidAccountCheck(
        headAccountSts,
        subAccountSts,
        TransactionActionEnum.CASH_IN
      )
    }
    return false
  }, [piData, cmData])

  const showAgentCode = useMemo(() => {
    return !piData?.agentCodeDisabled
  }, [piData])

  const showReferralCode = useMemo(() => {
    return !piData?.referralCodeDisabled
  }, [piData])

  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationPurchasingInstruction()

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()
  const onSubmit = async (data: CashInAmountFormProps) => {
    handleErrorMessage(undefined)

    //check user ecdd & isaf status --
    handleCheckUserStatus({})
    if (!isValidUserStatus) return

    if (!isEpf) {
      //check user risklevel --
      if (
        // isafStatus !== IsafStatusEnum.Passed ||
        !cmData ||
        !cmData?.riskProfile
      ) {
        handleCheckUserStatus({ forceOpenModal: 'isaf' })
        return
      }
    }

    /* Headaccount and Subaccount invalid check */
    if (!isAccountValid) {
      accountInvalidModal.modalOpen()
      return
    }

    // max cash in amount check
    if (!!piData?.maxAmount && data.totalAmount > piData?.maxAmount) {
      handleErrorMessage('max-cash-in-amount-30k')
      return
    }

    //check unapplied Code --
    if (
      (!!campaignCodeRef.current?.hasUnappliedCode &&
        !!campaignCodeRef.current?.hasUnappliedCode()) ||
      (!!agentCodeRef.current?.hasUnappliedCode &&
        !!agentCodeRef.current?.hasUnappliedCode())
    ) {
      unappliedCodeModal.modalOpen()
      return
    }

    //check campaignCode minAmount --
    const minSingleDeposit =
      !!data.campaignCode &&
      !!data.campaignCode?.minimumSingleDeposit &&
      Number(data.campaignCode?.minimumSingleDeposit)

    const instrumentId =
      !!data.campaignCode &&
      !!data.campaignCode?.instrumentId &&
      data.campaignCode?.instrumentId

    const hasMinDepositAmount =
      !!minSingleDeposit &&
      !!instrumentId &&
      data.funds.some(
        (fund) => fund.code === instrumentId && fund.amount < minSingleDeposit
      )
    if (hasMinDepositAmount) {
      handleErrorMessage('campaign-min-signle-deposit')
      return
    }

    //passed checking and proceed --
    if (!piData || !piData?.wrapperType) {
      handleErrorMessage(UNKNOWN_ERROR)
      return
    }
    setResponseErrorAttrs(undefined)
    const mutatingData = {
      wrapperType: piData.wrapperType,
      funds: data.funds.map(({ code, amount, currency, salesFeePerc }) => ({
        fundCode: code,
        amount,
        ccy: currency,
        feePercentage: salesFeePerc,
      })),
      amount: data.totalAmount,
      agentCode: data?.agentCode?.agentCode,
      campaignCode: data.campaignCode?.campaignCode,
      referralCode: data.referralCode?.referralCode,
    } as unknown as UseMutationPurchasingInstruction
    mutate(mutatingData)
    setUserNotIdle()
  }

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      if ([AML_FAILED, AML_EXPIRED].includes(errMsg)) {
        handleCheckUserStatus({ forceOpenModal: 'ecdd' })
        return
      }

      if (
        [RISK_PROFILE_NOT_PASSED, RISK_PROFILE_NOT_EXPIRED].includes(errMsg)
      ) {
        handleCheckUserStatus({ forceOpenModal: 'isaf' })
        return
      }

      handleErrorMessage(errMsg)
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      console.log(mutatedData)
      setInvestments(getValues())
      handleSetRequestToken(mutatedData.requestToken)
    }
  }, [mutatedData, mutateStatus, mutatedError])

  useEffect(() => {
    if (!!errorMessage) {
      setResponseErrorAttrs({ i18nKey: errorMessage })
      scrollTop()
      return
    }
    setResponseErrorAttrs(undefined)
  }, [errorMessage])

  useBeforeUnload({ enabled: !!isEpf })

  return !!initDataIsLoading ? (
    <Loading isLoadingPage={true} />
  ) : (
    <PostLogonMainBody useTransBg={false}>
      <HelmetSiteTitle pageTitle={t('cash-in', { ns: 'common' })} />
      <PostLogonContainer className='overflow-visible md:overflow-hidden'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h1 className='font-bold'>{t('cash-in', { ns: 'common' })}</h1>
          <h2 className='font-bold text-xl'>{t('how-much-invest')}</h2>
          {!!responseErrorAttrs && (
            <ResponseError>
              {t(responseErrorAttrs.i18nKey, {
                ns: responseErrorAttrs.ns,
              })}
            </ResponseError>
          )}
          <Controller
            name='funds'
            control={control}
            rules={{
              validate: (value) => {
                const _err = value.reduce(
                  (acc, { code, amount, minSubsAmt = 0 }) => {
                    const message =
                      amount <= 0
                        ? 'Required'
                        : amount < minSubsAmt
                        ? 'smaller-than-min-sub-amount'
                        : undefined
                    return !!message ? [...acc, { code, message }] : acc
                  },
                  [] as FundsCompItemErr[]
                )
                return !_err.length || JSON.stringify(_err)
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FundsComp
                {...field}
                error={error}
                disabled={mutateIsLoading}
                disableAdjustAmount={isEpf}
              />
            )}
          />
          <Divider className='-mx-4 sm:hidden' />
          <Controller
            name='campaignCode'
            control={control}
            render={({ field, fieldState: { error } }) => (
              <CampaignCodeComp
                {...field}
                error={error}
                disabled={mutateIsLoading}
                ref={campaignCodeRef}
                fundCodes={fundCodes}
                subaccountNo={piData?.subaccountNo || ''}
                handlerSelectedDiffPromoCode={handlerSelectedDiffPromoCode}
                handleErrorMessage={handleErrorMessage}
              />
            )}
          />
          {showReferralCode && (
            <>
              <Divider className='-mx-4 sm:hidden' />
              <Controller
                name='referralCode'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <ReferralCodeComp
                    {...field}
                    error={error}
                    disabled={mutateIsLoading}
                    ref={agentCodeRef}
                  />
                )}
              />
            </>
          )}
          {showAgentCode && (
            <>
              <Divider className='-mx-4 sm:hidden' />
              <Controller
                name='agentCode'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <AgentCodeComp
                    {...field}
                    error={error}
                    disabled={mutateIsLoading}
                    ref={agentCodeRef}
                  />
                )}
              />
            </>
          )}
          <Divider className='-mx-4 sm:hidden' />
          <div className='md:p-6 md:-m-6 md:mt-4 md:pt-3 md:shadow-[0_-2px_6px_0_rgba(0,0,0,0.1)]'>
            <TwoSideText
              className='text-sm font-bold items-end mb-4'
              left={t('total-cash-in')}
              right={
                <span className='text-[28px] leading-[36px]'>
                  {formattedAmount({ amount: watch('totalAmount') })}
                </span>
              }
            />
            <ButtonWithLoading
              type='submit'
              fullWidth
              variant='contained'
              size='large'
              className='mb-10 sm:mb-2'
              disabled={mutateIsLoading}
              isLoading={mutateIsLoading}
            >
              {t('Continue', { ns: 'common' })}
            </ButtonWithLoading>
          </div>
        </form>
      </PostLogonContainer>
      <HasUnappliedCodeModal
        {...unappliedCodeModal}
        handleHardProceed={() => {
          setValue('ignoreUnappliedCode', true)
          onSubmit(getValues())
        }}
      />
      <SubaccountInvalidModal
        {...accountInvalidModal}
        action={ActionTypeEnum.CASH_IN}
      />
    </PostLogonMainBody>
  )
}

export default CashInAmount
