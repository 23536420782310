import { useModal, ActionModal } from '@pimy-b2cweb/frontend-lib'
import { memo, useEffect, useState } from 'react'
import useCountDown from 'react-countdown-hook'
import { useTranslation, Trans } from 'react-i18next'
import { INTERVAL } from '@/constants'
import useGoExternalEpf from '@/hooks/useGoExternalEpf'
const REDIRECT_COUNTDOWN = 12000 //12 sec

export const EpfTimeoutModal = memo(
  ({ epfTimeLeft }: { epfTimeLeft: number }) => {
    const { t } = useTranslation('epfCountdownTimer')
    const { modalOpen, open } = useModal()
    const goExternalEpf = useGoExternalEpf()
    const [redirectTimeLeft, { start }] = useCountDown(
      REDIRECT_COUNTDOWN,
      INTERVAL
    )
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
      if (epfTimeLeft <= 0) {
        start()
        setInitialized(true)
        modalOpen()
      }
    }, [epfTimeLeft])

    useEffect(() => {
      if (!!initialized && redirectTimeLeft <= 0) goExternalEpf()
    }, [redirectTimeLeft, initialized])

    return (
      <ActionModal
        title={t('epf-time-expired-title')}
        actions={[
          {
            label: t('back-to-epf'),
            onClick: goExternalEpf,
            variant: 'contained',
          },
        ]}
        open={open}
        actionsStyle='full'
      >
        <Trans
          i18nKey='epf-time-expired-text'
          t={t}
          values={{ sec: Math.round(redirectTimeLeft / 1000) }}
          components={{ P: <p />, B: <strong /> }}
        />
      </ActionModal>
    )
  }
)

export default EpfTimeoutModal
