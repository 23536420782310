import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SwitchingFeeModal from '../../components/SwitchingFeeModal'
import {
  useModal,
  formattedAmount,
  ButtonWithLoading,
  useResponseError,
  ResponseError,
  ResponseErrorProps,
} from '@pimy-b2cweb/frontend-lib'
import { Divider } from '@mui/material'
import TermsAndConditionsComp, {
  TncNoticeVariantEnum,
} from '@/components/TermsAndConditionsComp'
import { SwitchingData } from '@/hooks/useSwitchingDataReducer'
import { calTotalAmount, getErrorResponseCode } from '@/utils'
import { useQueryFundNameMapping } from '@/hooks/useQueryFundNameMapping'
import Loading from '@/components/Loading'
import { useEffect, useMemo } from 'react'
import { RoutePathEnum } from '@/constants/routePath'
import { useNavigate } from 'react-router-dom'
import { SwitchingOrder } from '../SwitchAmount/components/SwitchingOrders'
import { useDispatch } from 'react-redux'
import { sessionVerifyOtp } from '@/stores/auth'
import { useMutationSubmitSwitchingOrderInstruction } from '@/api/orderApi'
import TwoSideText from '@/components/TwoSideText'
import {
  INPUT_INVALID,
  REQUEST_INVALID,
  SUBACCOUNT_INVALID,
} from '@/constants/errorCodes'

interface SwitchSummaryFormProps {
  agreeTnc: string[]
}
export interface SwitchSummaryProps extends SwitchingData {
  goNext: () => void
  goToOrderForm: () => void
  setOrderFormErrMsg: (err: ResponseErrorProps) => void
  openTechnicalErrorModal: () => void
}

const SwitchSummary = ({
  goNext,
  goToOrderForm,
  setOrderFormErrMsg,
  openTechnicalErrorModal,
  ...switchingData
}: SwitchSummaryProps) => {
  const { t } = useTranslation(['switchingPage', 'common'])
  const switchingFeeModal = useModal()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const { funds, agentCode } = switchingData

  const {
    fundNamesMapping,
    status: queryStatus,
    isLoading: isLoadingFL,
    isError: isErrorFL,
  } = useQueryFundNameMapping()

  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationSubmitSwitchingOrderInstruction()

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      if (errMsg === SUBACCOUNT_INVALID) {
        setResponseErrorAttrs({ i18nKey: errMsg })
      } else if ([INPUT_INVALID, REQUEST_INVALID].includes(errMsg)) {
        setOrderFormErrMsg({
          i18nKey: 'invalid_request',
          ns: 'errorResponse',
        })
        goToOrderForm()
      } else {
        openTechnicalErrorModal()
      }
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      console.log('mutatedData: ', mutatedData)
      dispatch(sessionVerifyOtp({ ...mutatedData }))
      goNext()
    }
  }, [mutateStatus, mutatedData, mutatedError])

  const _funds = funds.map((fund) => fund.from)

  const totalAmount = calTotalAmount(_funds)

  useEffect(() => {
    if (isErrorFL) {
      navigate(RoutePathEnum.ERROR)
    }
  }, [isErrorFL])

  const orders: SwitchingOrder[] = useMemo(() => {
    if (queryStatus === 'success' && !!fundNamesMapping) {
      const _orders = funds.map(({ from, to }) => {
        return {
          from: {
            ...from,
            name: Object.prototype.hasOwnProperty.call(
              fundNamesMapping,
              from.fundCode
            )
              ? fundNamesMapping[from.fundCode]
              : '',
          },
          to: {
            ...to,
            name: Object.prototype.hasOwnProperty.call(
              fundNamesMapping,
              to.fundCode
            )
              ? fundNamesMapping[to.fundCode]
              : '',
          },
        }
      })
      return _orders
    }
    return []
  }, [queryStatus, fundNamesMapping])

  const { control, handleSubmit, watch } = useForm<SwitchSummaryFormProps>({
    mode: 'onChange',
    defaultValues: {
      agreeTnc: [],
    },
  })

  const onSubmit = (data: SwitchSummaryFormProps) => {
    console.log(data)
    mutate({ ...switchingData, agentCode: switchingData.agentCode?.agentCode })
  }

  return isLoadingFL ? (
    <Loading isLoadingPage />
  ) : (
    <>
      <h1 className='font-bold mt-4'>{t('your-switch-summary')}</h1>

      {!!responseErrorAttrs && (
        <ResponseError>
          {t(responseErrorAttrs.i18nKey, {
            ns: responseErrorAttrs.ns,
          })}
        </ResponseError>
      )}

      <div className='flex flex-col gap-1 mb-4'>
        {orders.map((order) => {
          return (
            <div
              className='flex flex-col justify-between bg-pi-sky-blue-1 col-span-full text-sm -mx-4 p-4 sm:-mx-6 sm:p-6'
              key={order.from.fundCode}
            >
              <div>
                <div className='col-span-full'>{t('switch-from')}:</div>
                <span className='font-bold'>{order.from.name}</span>
              </div>
              <Divider className='my-4' />
              <div className='mb-4'>
                <div className='col-span-full'>{t('switch-to')}:</div>
                <span className='font-bold'>{order.to.name}</span>
              </div>
              <TwoSideText
                left={t('sales-charge-inclusive')}
                right={`${order.to.feePercentage || 0}%`}
              />
              <TwoSideText
                left={`${t('switch-amount')}:`}
                right={
                  <span className='font-bold'>
                    {formattedAmount({ amount: order.from.amount })}
                  </span>
                }
              />
            </div>
          )
        })}
      </div>

      <div className='mb-6'>
        <div className='flex flex-col gap-2'>
          {!!agentCode?.agentName && (
            <TwoSideText
              className='text-xs'
              left={
                <span className='font-bold'>
                  {`${t('agent', { ns: 'common' })}:`}
                </span>
              }
              right={agentCode.agentName}
            />
          )}
          <TwoSideText
            className='text-xs font-bold'
            left={t('total-switch')}
            right={
              <span className='text-[28px] leading-[36px]'>
                {formattedAmount({ amount: totalAmount })}
              </span>
            }
          />
        </div>
        <p className='mt-2 text-xs'>
          {t('switching-fee-info')}
          <span
            className='clickable-text font-normal pl-1'
            onClick={() => switchingFeeModal.modalOpen()}
          >
            {t('more-info', { ns: 'common' })}
          </span>
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='agreeTnc'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TermsAndConditionsComp
              id='agreeTnc'
              variant={TncNoticeVariantEnum.Transition}
              {...field}
              error={error}
              disabled={mutateIsLoading}
            />
          )}
        />

        <ButtonWithLoading
          type='submit'
          fullWidth
          variant='contained'
          size='large'
          className='mb-10 sm:mb-2'
          disabled={!watch('agreeTnc').length || mutateIsLoading}
          isLoading={mutateIsLoading}
        >
          {t('Continue', { ns: 'common' })}
        </ButtonWithLoading>
      </form>
      <SwitchingFeeModal {...switchingFeeModal} />
    </>
  )
}

export default SwitchSummary
