import { useEffect, useRef, useState } from 'react'

export const useShowHeaderStatus = (): boolean => {
  const [showHeader, setShowHeader] = useState(false)
  const prevScrollPos = useRef(window.scrollY)

  useEffect(() => {
    const checkScrollPos = () => {
      const currentScrollPos = window.scrollY
      if (prevScrollPos.current > currentScrollPos) {
        setShowHeader(true)
      } else {
        setShowHeader(false)
      }
      prevScrollPos.current = currentScrollPos
    }

    window.addEventListener('scroll', checkScrollPos)

    return () => window.removeEventListener('scroll', checkScrollPos)
  }, [])
  return showHeader
}
