import { TextField } from '@mui/material'
import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  ButtonWithLoading,
  FormLabel,
  ResponseError,
  useResponseError,
  CSLink,
} from '@pimy-b2cweb/frontend-lib'
import {
  useMutationSignUpEmail,
  UseMutationSignUpEmail as EmailFormProps,
} from '@/api/onboardingApi'
import BottomAction from '@/components/BottomAction'
import { sessionSignUpPendingOtp } from '@/stores/auth'
import { getErrorResponseCode } from '@/utils'

export const Email = () => {
  const { t } = useTranslation(['signUpPage', 'common'])

  const { control, handleSubmit } = useForm<EmailFormProps>({
    mode: 'onChange',
    defaultValues: { email: '' },
  })

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const dispatch = useDispatch()
  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationSignUpEmail()

  const onSubmit = async (data: EmailFormProps) => {
    console.log(data)
    setResponseErrorAttrs(undefined)
    mutate({
      ...data,
    })
  }

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      console.log('mutatedData: ', mutatedData)
      dispatch(sessionSignUpPendingOtp(mutatedData))
    }
  }, [mutatedData, mutateStatus, mutatedError])

  return (
    <>
      <h1>{t('enter-email-address')}</h1>
      <p>{t('please-enter-your-email-address')}</p>
      {!!responseErrorAttrs && (
        <ResponseError>
          <Trans
            {...responseErrorAttrs}
            t={t}
            components={{ CS: <CSLink /> }}
          />
        </ResponseError>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='email'
          control={control}
          defaultValue=''
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='email'
              label={t('Email', { ns: 'common' })}
              isError={!!error}
              className='!mb-10'
            >
              <TextField
                type='email'
                fullWidth
                {...field}
                error={!!error}
                helperText={!!error ? (error?.message as string) : ''}
                disabled={mutateIsLoading}
                autoComplete='off'
              />
            </FormLabel>
          )}
        />
        <ButtonWithLoading
          type='submit'
          fullWidth
          variant='contained'
          size='large'
          disabled={mutateIsLoading}
          isLoading={mutateIsLoading}
        >
          {t('Continue', { ns: 'common' })}
        </ButtonWithLoading>
      </form>
      <BottomAction type='signin' />
    </>
  )
}

export default Email
