import { useQuerySearchTransactionHistory } from '@/api/orderApi'
import { useQueryFundNameMapping } from '@/hooks/useQueryFundNameMapping'
import { useRiskProfileStatus } from '@/hooks/useRiskProfileStatus'
import {
  convertTransactions,
  getOrderStatusForTransactionType,
} from '@/utils/transactionHistory'
import { useMemo } from 'react'
import { useTransactionFilterForm } from '../useTransactionFilterForm'

export const useInitData = () => {
  const { formMethods, filterData, filters, onSubmitForm, onClearFilter } =
    useTransactionFilterForm()

  const {
    isLoading: isLoadingPS,
    isError: isErrorPS,
    riskProfileName,
    profitLoss,
    totalAssetsAmt,
  } = useRiskProfileStatus()

  const {
    data: transactionHistory,
    isLoading: isLoadingTH,
    isError: isErrorTH,
    ...restUseInfiniteQuery
  } = useQuerySearchTransactionHistory(filterData)

  const {
    fundNamesMapping,
    isLoading: isLoadingFL,
    isError: isErrorFL,
  } = useQueryFundNameMapping()

  const isLoading = useMemo(
    () => isLoadingTH || isLoadingFL || isLoadingPS,
    [isLoadingTH, isLoadingFL, isLoadingPS]
  )

  const isError = useMemo(
    () => isErrorTH || isErrorFL || isErrorPS,
    [isErrorTH, isErrorFL, isErrorPS]
  )

  const transactionDetails = useMemo(() => {
    const transactions = transactionHistory?.pages
      .flatMap((info) => info.body)
      .filter((trnx) =>
        /* Filter transaction status based on given transaction type */
        getOrderStatusForTransactionType(trnx.type).includes(trnx.status)
      )

    const _transactions = convertTransactions(transactions || [])
    return _transactions
  }, [transactionHistory])

  return {
    isLoading,
    isError,
    transactionDetails,
    fundNamesMapping,
    formMethods,
    filters,
    onSubmitForm,
    onClearFilter,
    riskProfileName,
    profitLoss,
    totalAssetsAmt,
    ...restUseInfiniteQuery,
  }
}

export default useInitData
