import { useMutationSubmitRedemptionOrderInstruction } from '@/api/orderApi'
import {
  INPUT_INVALID,
  REQUEST_INVALID,
  SUBACCOUNT_INVALID,
} from '@/constants/errorCodes'
import { sessionVerifyOtp } from '@/stores/auth'
import { getErrorResponseCode } from '@/utils'
import {
  OtpInitResDto,
  RedemptionOrderReqDto,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { ResponseErrorProps, useResponseError } from '@pimy-b2cweb/frontend-lib'
import { UseMutateFunction } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

export interface UseConfirmRedemptionOrderMutation {
  goVerifyOtp: () => void
  openTechnicalErrorModal: () => void
  goToOrderForm: () => void
  setOrderFormErrMsg: (err: ResponseErrorProps) => void
}

export interface UseSubmitRedemptionOrderMutationResponse {
  mutate: UseMutateFunction<
    OtpInitResDto,
    unknown,
    RedemptionOrderReqDto,
    unknown
  >
  responseErrorAttrs: ResponseErrorProps | undefined
  mutateIsLoading: boolean
}

export const useSubmitRedemptionOrderMutation = ({
  goVerifyOtp,
  openTechnicalErrorModal,
  goToOrderForm,
  setOrderFormErrMsg,
}: UseConfirmRedemptionOrderMutation): UseSubmitRedemptionOrderMutationResponse => {
  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()
  const dispatch = useDispatch()
  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationSubmitRedemptionOrderInstruction()

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      if (errMsg === SUBACCOUNT_INVALID) {
        setResponseErrorAttrs({ i18nKey: errMsg })
      } else if ([INPUT_INVALID, REQUEST_INVALID].includes(errMsg)) {
        setOrderFormErrMsg({
          i18nKey: 'invalid_request',
          ns: 'errorResponse',
        })
        goToOrderForm()
      } else {
        openTechnicalErrorModal()
      }
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      console.log('mutatedData: ', mutatedData)
      dispatch(sessionVerifyOtp({ ...mutatedData }))
      goVerifyOtp()
    }
  }, [mutateStatus, mutatedData, mutatedError])

  return {
    mutate,
    responseErrorAttrs,
    mutateIsLoading,
  }
}
