import EditEmploymentInfoForm from '@/components/EditEmploymentInfoForm'
import { LovSessionStateLang } from '@/stores/lov'
import {
  ButtonWithLoading,
  ResponseError,
  ResponseErrorProps,
} from '@pimy-b2cweb/frontend-lib'
import { FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ProfileInfoFormProps } from '../../hooks/useProfileForm'

export const EmploymentInfoForm = ({
  onNavBack,
  onSubmitForm,
  isSubmitting = false,
  responseErrorAttrs,
  lovs,
}: {
  onNavBack: () => void
  onSubmitForm: (data: ProfileInfoFormProps) => void
  isSubmitting?: boolean
  responseErrorAttrs?: ResponseErrorProps
  lovs: LovSessionStateLang
}) => {
  const { t } = useTranslation(['profileInfoPage', 'common'])

  const onSubmit = (data: FieldValues) => {
    onSubmitForm(data as ProfileInfoFormProps)
  }

  return (
    <>
      <h1 className='flex items-center'>{t('profile-info')}</h1>

      <p className='text-sm font-bold mb-1'>
        {t('step-currStep-of-totalSteps', { currStep: 2, totalSteps: 2 })}
      </p>
      <h2>{t('employment-information')}</h2>

      {!!responseErrorAttrs && (
        <ResponseError>
          {t(responseErrorAttrs.i18nKey, {
            ns: responseErrorAttrs.ns,
          })}
        </ResponseError>
      )}
      <EditEmploymentInfoForm
        onSubmitForm={onSubmit}
        isSubmitting={isSubmitting}
        lovs={lovs}
        submitButtonLabel={t('Continue', { ns: 'common' })}
      />

      <ButtonWithLoading
        type='button'
        fullWidth
        variant='outlined'
        size='large'
        disabled={isSubmitting}
        onClick={onNavBack}
      >
        {t('back', { ns: 'common' })}
      </ButtonWithLoading>
    </>
  )
}

export default EmploymentInfoForm
