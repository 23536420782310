import { CircularProgress } from '@mui/material'

export const Loading = ({
  className = '',
  isLoadingPage = false,
}: {
  className?: string
  isLoadingPage?: boolean
}) => (
  <div
    className={`loading col-span-full w-full min-h-[calc(100svh-248px)] flex justify-center items-center ${
      isLoadingPage ? 'min-h-[calc(100vh-202px)]' : ''
    } ${className}`}
  >
    <CircularProgress className='text-pi-principal-blue' />
  </div>
)

export default Loading
