import { TextField, TextFieldProps } from '@mui/material'
import {
  FullScreenModal,
  FullScreenModalProps,
  ScrollArea,
  ButtonWithLoading,
  formattedAmount,
} from '@pimy-b2cweb/frontend-lib'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useQueryGetCampaignCode,
  useMutationVerifyCampaignCode,
} from '@/api/commonApi'
import { ReactComponent as SelectedIco } from '@/assets/tick.svg'
import ErrorMessageComp from '@/components/ErrorMessageComp'
import Loading from '@/components/Loading'
import ResponseContainer from '@/components/ResponseContainer'
import { NO_REFERRER } from '@/constants'
import { NO_DUPLICATE_INPUT_SELECTION } from '@/constants/errorCodes'
import useSetUserNotIdle from '@/hooks/useSetUserNotIdle'
import { getErrorResponseCode } from '@/utils'
import { CampaignCode } from '@/hooks/useInvestmentReducer'

export interface CampaignPromoModalProps
  extends Omit<FullScreenModalProps, 'children'> {
  appliedCode?: CampaignCode['campaignCode']
  handleSetSelectedCode: (data: CampaignCode) => void
  fundCodes: string
  subaccountNo: string
  handlerSelectedDiffPromoCode: (isDiffPromoCode: boolean | undefined) => void
}

export const CampaignPromoModal = ({
  appliedCode,
  modalClose,
  handleSetSelectedCode,
  fundCodes,
  subaccountNo,
  handlerSelectedDiffPromoCode,
  ...rest
}: CampaignPromoModalProps) => {
  const setUserNotIdle = useSetUserNotIdle()
  const { t } = useTranslation(['cashInPage', 'common'])

  const {
    isLoading,
    isError,
    data: promoList,
  } = useQueryGetCampaignCode({
    subaccountNo,
    fundCodes,
  })

  const [selectedPromoCodeName, setSelectedPromoCodeName] = useState<
    string | undefined
  >('')
  const [userInputCode, setUserInputCode] = useState<string>('')
  const [inputCodeError, setInputCodeError] = useState<string>('')
  const [duplicateCodeError, setDuplicateCodeError] = useState<boolean>(false)
  const [selectedPromo, setSelectedPromo] = useState<CampaignCode | undefined>(
    undefined
  )

  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationVerifyCampaignCode()
  useEffect(() => {
    if (mutateStatus === 'error') {
      setInputCodeError(getErrorResponseCode(mutatedError))
      return
    }
    setInputCodeError('')

    if (mutateStatus === 'success' && !!mutatedData) {
      handleSetSelectedCode(mutatedData)
      handleResetAndModalClose()
    }
  }, [mutatedData, mutateStatus, mutatedError])

  const handleChange: TextFieldProps['onChange'] = (e) => {
    if (!!e.target.value && !/^[a-zA-Z0-9]+$/.test(e.target.value)) {
      return
    }
    setUserInputCode(e.target.value)
    setInputCodeError('')
  }

  useEffect(() => {
    setDuplicateCodeError(!!userInputCode && !!selectedPromo ? true : false)
  }, [userInputCode, selectedPromo])

  const handleClickApply = () => {
    const isDiffPromoCode =
      selectedPromoCodeName !== selectedPromo?.name ? true : false
    handlerSelectedDiffPromoCode(isDiffPromoCode)
    setSelectedPromoCodeName(selectedPromo?.name)

    if (
      (!!userInputCode && !!selectedPromo) ||
      (!userInputCode && !selectedPromo)
    )
      return
    if (!!userInputCode) {
      //search by user text input --
      mutate({
        campaignCode: userInputCode,
        fundCodes,
        subaccountNo,
      })
      setUserNotIdle()
      return
    }
    if (!!selectedPromo) {
      //apply code by uer select card --
      handleSetSelectedCode(selectedPromo)
      handleResetAndModalClose()
    }
  }

  const handleResetAndModalClose = () => {
    setUserInputCode('')
    setInputCodeError('')
    setDuplicateCodeError(false)
    setSelectedPromo(undefined)
    modalClose()
  }

  return (
    <FullScreenModal
      modalClose={handleResetAndModalClose}
      {...rest}
      className='campaign-promo-modal'
    >
      <div className='flex flex-col grow pb-8 max-h-[calc(100vh-136px)]'>
        <ResponseContainer>
          <h3 className='font-bold text-xl'>{t('campaign-promo')}</h3>
          <TextField
            type='text'
            fullWidth
            onChange={handleChange}
            value={userInputCode}
            placeholder={t('enter-promo-or-referral-code')}
            autoComplete='off'
            className='mb-4 [&>input]:bg-white'
            error={!!inputCodeError || duplicateCodeError}
            helperText={t(
              duplicateCodeError
                ? NO_DUPLICATE_INPUT_SELECTION
                : inputCodeError || ''
            )}
            disabled={mutateIsLoading}
          />
        </ResponseContainer>
        <ScrollArea className='grow'>
          {isLoading ? (
            <Loading isLoadingPage={true} />
          ) : isError ? (
            <div className='col-span-full min-h-[100%] flex items-center justify-center'>
              <ErrorMessageComp
                type={t('ops-something-went-wrong', { ns: 'common' })}
              />
            </div>
          ) : (
            <ResponseContainer>
              {!promoList?.length
                ? t('no-promo-available')
                : promoList.map((promo) => {
                    const {
                      campaignCode,
                      name,
                      minimumSingleDeposit,
                      termsAndConditionUrl,
                    } = promo
                    const isSelected = selectedPromo === promo
                    const isDisabled = appliedCode === campaignCode

                    return (
                      <div
                        key={campaignCode}
                        className={`mb-4 flex flex-col py-3 px-4  shadow-[2px_6px_16px_-10px_var(--color-pi-gray-4)] rounded ${
                          isDisabled || mutateIsLoading
                            ? 'cursor-default bg-pi-gray-5/50 !border border-solid border-pi-gray-5'
                            : 'cursor-pointer bg-white'
                        }`}
                        onClick={() => {
                          if (!isDisabled && !mutateIsLoading)
                            setSelectedPromo(isSelected ? undefined : promo)
                        }}
                      >
                        <div className='flex items-center gap-2 mb-1'>
                          <span className='whitespace-normal text-pi-utility-green text-base font-black grow'>
                            {name}
                          </span>
                          {isSelected && <SelectedIco />}
                          {isDisabled && (
                            <SelectedIco className='[&>path]:stroke-pi-gray-3' />
                          )}
                        </div>
                        <span className='text-xs mb-1'>
                          {t('minimum-cash-in')}{' '}
                          {formattedAmount({
                            amount: Number(minimumSingleDeposit || 0),
                          })}
                        </span>
                        <div>
                          <span
                            onClick={(e) => {
                              e.stopPropagation()
                              window.open(
                                termsAndConditionUrl,
                                '_blank',
                                NO_REFERRER
                              )
                            }}
                            className='clickable-text text-xs mb-1 !font-normal'
                          >
                            {t('Term & Conditions', { ns: 'common' })}
                          </span>
                        </div>
                      </div>
                    )
                  })}
            </ResponseContainer>
          )}
        </ScrollArea>
        <ResponseContainer className='pt-8'>
          <ButtonWithLoading
            variant='contained'
            fullWidth
            onClick={handleClickApply}
            disabled={
              (!selectedPromo && !userInputCode) ||
              mutateIsLoading ||
              duplicateCodeError
            }
            isLoading={mutateIsLoading}
          >
            {t('Apply', { ns: 'common' })}
          </ButtonWithLoading>
        </ResponseContainer>
      </div>
    </FullScreenModal>
  )
}

export default CampaignPromoModal
