import {
  CustomerOnboardSubmitResDto,
  CustomerAMLStatusEnum,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { CustomerProfileTypeEnum } from '@/api/customerApi'
import { AuthedStatusEnum } from '@/config'
import { UNKNOWN_ERROR } from '@/constants/errorCodes'
import { verifyAMLStatus, verifyAMLStatusEnum } from '@/utils'
import { EpfActionEnum } from '@/stores/epfToken'

export enum ProfileSetupSwitchEnum {
  EcddPending = AuthedStatusEnum.EcddPending,
  EcddSubmitted = AuthedStatusEnum.EcddSubmitted,
  EcddRejected = AuthedStatusEnum.EcddRejected,
  AmlUnknown = 'amlUnknown',
  EpfPurchaseFlow = 'epf-purchase-flow',
  EpfRedemptionFlow = 'epf-redemption-flow',
  EpfSwitchingFlow = 'epf-switching-flow',
  IsafNotPassed = AuthedStatusEnum.IsafNotPassed,
  UnknownError = UNKNOWN_ERROR,
}

export interface ProfileSetupSwitchLogic
  extends Partial<CustomerOnboardSubmitResDto> {
  type?: CustomerProfileTypeEnum
  epfAction?: EpfActionEnum
}
export const profileSetupSwitchLogic = ({
  cifid,
  amlStatus,
  type,
  epfAction,
}: ProfileSetupSwitchLogic): ProfileSetupSwitchEnum => {
  const _amlStatus = verifyAMLStatus(amlStatus || CustomerAMLStatusEnum.unknown)

  //AC1) amlUnknown
  if (_amlStatus === verifyAMLStatusEnum.AmlUnknown) {
    return ProfileSetupSwitchEnum.AmlUnknown
  }

  if (_amlStatus !== verifyAMLStatusEnum.AmlPassed) {
    //AC2a) aml = EcddPending but missing cifid
    if (_amlStatus === verifyAMLStatusEnum.EcddPending && !cifid) {
      return ProfileSetupSwitchEnum.UnknownError
    }

    //AC2b) AC2c) AC2d) aml not passed: EcddPending, EcddSubmitted, EcddRejected
    return _amlStatus as unknown as ProfileSetupSwitchEnum
  }

  if (!!cifid) {
    //AC3) aml passed with epf token--
    //AC4) aml passed, no epf--
    if (type === 'epf') {
      switch (epfAction) {
        case EpfActionEnum.PURCHASE:
          return ProfileSetupSwitchEnum.EpfPurchaseFlow
        case EpfActionEnum.REDEEM:
          return ProfileSetupSwitchEnum.EpfRedemptionFlow
        case EpfActionEnum.SWITCH:
          return ProfileSetupSwitchEnum.EpfSwitchingFlow

        default:
          return ProfileSetupSwitchEnum.UnknownError
      }
    }
    return ProfileSetupSwitchEnum.IsafNotPassed
  }

  return ProfileSetupSwitchEnum.UnknownError
}
