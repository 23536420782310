import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as InfoIco } from '@/assets/info.svg'
import { RoutePathEnum } from '@/constants/routePath'
import { useShowHeaderStatus } from '@/hooks/useShowHeaderStatus'
import { IsafStatusEnum } from '@/enums/isafEnums'

const ISAFAlertBar = ({ isafStatus }: { isafStatus?: IsafStatusEnum }) => {
  const { t } = useTranslation('isafPage')
  const showHeader = useShowHeaderStatus()
  const navigate = useNavigate()

  return !!isafStatus
    ? isafStatus !== 'passed' && (
        <div
          className={`w-full h-16 xs:h-12 sticky z-40 transition-top duration-300 ${
            showHeader ? 'top-20 lg:top-16' : '!-top-20'
          } px-4 bg-pi-true-blue`}
        >
          <div className='max-w-screen-xl h-16 xs:h-12 py-2 mx-auto flex gap-3 items-center text-sm text-white font-medium'>
            <div className='w-5 h-5'>
              <InfoIco className='[&>*>*]:stroke-white' />
            </div>
            <div className='grow'>{t(`isaf-${isafStatus}`)}</div>
            {isafStatus !== IsafStatusEnum.Unknown && (
              <div
                className='cursor-pointer'
                onClick={() => navigate(RoutePathEnum.ISAF)}
              >
                {t('complete-isaf')}
              </div>
            )}
          </div>
        </div>
      )
    : null
}

export default ISAFAlertBar
