import {
  useQueryCustomerMetadata,
  useQueryCustomerPortfolio,
} from '@/api/customerApi'
import { useQueryRiskLevelList } from '@/api/riskProfileApi'
import { AuthedStatusEnum } from '@/config'
import { DEFAULT_RISK_PROFILE, EMPTY_VALUE } from '@/constants'
import { selectAuthStatus } from '@/stores/auth.selectors'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useRiskProfileStatus = () => {
  const authStatus = useSelector(selectAuthStatus)

  const {
    data: riskLevelList,
    isLoading: isLoadingRiskLevelListQuery,
    isError: isRiskLevelListQueryError,
  } = useQueryRiskLevelList()
  const {
    data: metaData,
    isLoading: isLoadingMetaDataQuery,
    isError: isMetadataQueryError,
  } = useQueryCustomerMetadata({})

  const {
    data: portfolioData,
    isLoading: isLoadingCP,
    isError: isErrorCP,
  } = useQueryCustomerPortfolio()

  const isLoading = useMemo(
    () => isLoadingRiskLevelListQuery || isLoadingMetaDataQuery || isLoadingCP,
    [isLoadingRiskLevelListQuery, isLoadingMetaDataQuery, isLoadingCP]
  )
  const isError = useMemo(
    () => isRiskLevelListQueryError || isMetadataQueryError || isErrorCP,
    [isRiskLevelListQueryError, isMetadataQueryError, isErrorCP]
  )

  // checking if user has passed isaf status or not
  const showRiskProfile = !(
    [
      AuthedStatusEnum.IsafExpired,
      AuthedStatusEnum.IsafNotPassed,
      AuthedStatusEnum.IsafUnknown,
    ] as string[]
  ).includes(authStatus)

  const riskLevel = useMemo(
    () => (!!showRiskProfile ? metaData?.riskProfile?.level : undefined),
    [metaData]
  )

  const riskProfile = useMemo(() => {
    const riskLvl =
      riskLevelList?.levels?.findIndex(
        (lvl) => lvl.level === metaData?.riskProfile?.level
      ) || DEFAULT_RISK_PROFILE
    return riskLvl > -1 ? riskLvl : DEFAULT_RISK_PROFILE
  }, [metaData, riskLevelList, showRiskProfile])

  const riskProfileName = useMemo(() => {
    return !!showRiskProfile
      ? riskLevelList?.levels?.find(
          (lvl) => lvl.level === metaData?.riskProfile?.level
        )?.name || EMPTY_VALUE
      : EMPTY_VALUE
  }, [metaData, riskLevelList, showRiskProfile])

  const totalAssets = useMemo(() => {
    return {
      totalAssetsAmt: portfolioData?.totalValue,
      profitLoss: portfolioData?.totalGain,
    }
  }, [portfolioData])

  return {
    isLoading,
    isError,
    riskLevelList,
    metaData,
    portfolioData,
    riskProfile,
    riskProfileName,
    riskLevel,
    ...totalAssets,
  }
}
