import { InvestmentCategoryEnum } from '@/enums/InvestmentCategoryEnum'
import useFeeds from '@/hooks/useFeeds'
import useInitInvestmentData from '@/hooks/useInitInvestmentData'
import useInvestmentCategoryTabs from '@/hooks/useInvestmentCategoryTabs'
import useFundSelection from '@/Routes/pages/FundList/hooks/useFundSelection'
import { ProductIdEnum } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useEffect } from 'react'

export const useInitData = ({ productId }: { productId?: ProductIdEnum }) => {
  const { portfolioData, fundList, isLoading, isError } =
    useInitInvestmentData()

  const fundSelectionUtils = useFundSelection()

  const investmentCategories = [
    InvestmentCategoryEnum.KWSP_I_INVEST,
    InvestmentCategoryEnum.CASH,
  ]

  const useTabsReturns = useInvestmentCategoryTabs(investmentCategories)
  useEffect(() => {
    if (
      !!productId &&
      [ProductIdEnum.EPF, ProductIdEnum.EPFCash].includes(productId)
    ) {
      useTabsReturns.handleSetValue(
        investmentCategories.indexOf(InvestmentCategoryEnum.KWSP_I_INVEST)
      )
    } else {
      useTabsReturns.handleSetValue(
        investmentCategories.indexOf(InvestmentCategoryEnum.CASH)
      )
    }
  }, [productId])

  const useFeedsReturns = useFeeds({
    portfolioData,
    fundList,
    tabType: useTabsReturns.tabType,
  })

  return {
    isLoading,
    isError,
    feeds: useFeedsReturns,
    tabs: { ...useTabsReturns, disabled: !!productId },
    fundSelectionUtils,
  }
}
