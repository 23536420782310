import {
  RiskLevelListResDto,
  RiskProfileApi,
  RiskProfileQuestionnaireContextDto,
  RiskProfileQuestionnaireSubmitReqDto,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { BaseApiConfig, apiClient } from './api-config'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  GET_CURRENT_QUESTIONNAIRE,
  QUERY_RISK_LEVEL_LIST,
} from '@/constants/apiKeys'
import { apiLangCode } from '@/utils'
import i18n from '@/i18n'

const riskProfileApi = new RiskProfileApi(BaseApiConfig, apiClient)

export const MOCK_QUESTIONNAIRE: RiskProfileQuestionnaireContextDto = {
  questionGroup: '2',
  questions: [
    {
      code: '0',
      text: 'My age is…',
      options: [
        {
          code: 'A',
          text: '60 and above',
        },
        {
          code: 'B',
          text: '55 to 60',
        },
        {
          code: 'C',
          text: '46 to 55',
        },
        {
          code: 'D',
          text: '36 to 45',
        },
        {
          code: 'E',
          text: '35 and below',
        },
      ],
    },
    {
      code: '1',
      text: 'I would describe my knowledge in investment as:',
      options: [
        {
          code: 'A',
          text: 'No Knowledge',
        },
        {
          code: 'B',
          text: 'Very Little Knowledge',
        },
        {
          code: 'C',
          text: 'Some Knowledge',
        },
        {
          code: 'D',
          text: 'Good Knowledge',
        },
        {
          code: 'E',
          text: 'Very Extensive Knowledge',
        },
      ],
    },
    {
      code: '2',
      text: 'When I invest my money, I am:',
      options: [
        {
          code: 'A',
          text: 'Most concerned about my investment losing value',
        },
        {
          code: 'B',
          text: 'Equally concerned about my investment losing value or gaining value',
        },
        {
          code: 'C',
          text: 'Most concerned about my investment gaining value',
        },
      ],
    },
    {
      code: '3',
      text: 'When you think of the word "risk" in a financial context, which of the following word comes to mind first?',
      options: [
        {
          code: 'A',
          text: 'Uncertainty',
        },
        {
          code: 'B',
          text: 'Volatility',
        },
        {
          code: 'C',
          text: 'Opportunity',
        },
        {
          code: 'D',
          text: 'Thrill',
        },
      ],
    },
    {
      code: '4',
      text: 'What is your expected investment period?',
      options: [
        {
          code: 'A',
          text: '≤ 1 year',
        },
        {
          code: 'B',
          text: '> 1  year and  < 3 years',
        },
        {
          code: 'C',
          text: '> 3  years and  < 5 years',
        },
        {
          code: 'D',
          text: '> 5  years and ≤ 10 years',
        },
        {
          code: 'E',
          text: '> 10 years',
        },
      ],
    },
  ],
}

export const useQueryGetCurrentQuestionnaire = () => {
  const langCode = apiLangCode(i18n.language)
  return useQuery({
    queryFn: async () => {
      const res = await riskProfileApi.getCurrentQuestionnaire({
        langCode,
      })
      return res.data
    },
    queryKey: [GET_CURRENT_QUESTIONNAIRE, langCode],
  })
}

export const useQueryRiskLevelList = () => {
  const langCode = apiLangCode(i18n.language)
  return useQuery<RiskLevelListResDto>({
    queryFn: async () => {
      const res = await riskProfileApi.queryRiskLevelList({
        langCode,
      })
      return res.data
    },
    queryKey: [QUERY_RISK_LEVEL_LIST, langCode],
  })
}

export const useMutationSubmitQuestionnaire = () => {
  return useMutation({
    mutationFn: async (data: RiskProfileQuestionnaireSubmitReqDto) => {
      const res = await riskProfileApi.submitQuestionnaire({
        riskProfileQuestionnaireSubmitReqDto: {
          ...data,
        },
      })
      return res.data
    },
  })
}
