import { IconButton } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import dayjs from 'dayjs'
import { useSnackbar, VariantType, SnackbarMessage } from 'notistack'
import { INTERNAL_SERVER_ERROR } from '@/constants/errorCodes'
import { useTranslation } from 'react-i18next'

export const useNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { t } = useTranslation('errorResponse')

  const addNotice = ({
    message,
    key = dayjs().toISOString(),
    variant = 'info',
    persist = false,
  }: {
    key?: string
    message: SnackbarMessage
    variant?: VariantType
    persist?: boolean
  }) => {
    enqueueSnackbar(message, {
      key,
      variant,
      persist,
      action: (
        <IconButton onClick={() => closeSnackbar(key)}>
          <HighlightOffIcon className='text-white' />
        </IconButton>
      ),
    })
  }

  // const addValidateErrorNotice = () => {
  //   addNotice({
  //     message: 'Validation Error, please review your inputs.',
  //     variant: 'error',
  //   })
  // }

  // const addSubmitSucceedNotice = () => {
  //   addNotice({
  //     message: 'Submisison succeed.',
  //     variant: 'success',
  //   })
  // }

  const addNetworkErrorNotice = () => {
    addNotice({
      message: t(INTERNAL_SERVER_ERROR),
      variant: 'error',
    })
  }

  const dismissNotice = (key: string) => closeSnackbar(key)
  const dismissAllNotice = () => closeSnackbar()

  return {
    addNotice,
    addNetworkErrorNotice,
    // addValidateErrorNotice,
    // addSubmitSucceedNotice,
    dismissNotice,
    dismissAllNotice,
  }
}

export default useNotifications
