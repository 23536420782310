const SITE_KEY = process.env.REACT_APP_SITE_KEY || ''

export const useRecaptcha = () => {
  const handleExecute = (action: string) => {
    return new Promise<string>((resolve, reject) => {
      if (!window.grecaptcha) reject(new Error('grecaptcha-not-ready'))

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(SITE_KEY, { action })
          .then((token: string) => resolve(token))
      })
    })
  }

  return { handleExecute }
}

export default useRecaptcha
