import { RoutePathEnum } from '@/constants/routePath'
import { ActionModal, ActionModalProps } from '@pimy-b2cweb/frontend-lib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const ProfileUpdatedModal = ({
  modalClose,
  ...rest
}: Omit<
  ActionModalProps,
  'title' | 'actions' | 'actionsStyle' | 'closeBtn' | 'onClose' | 'children'
> & {
  modalClose: () => void
}) => {
  const { t } = useTranslation(['profileInfoPage', 'common'])
  const navigate = useNavigate()
  const [triggerNavOnClose, setTriggerNavOnClose] = useState(false)
  useEffect(() => {
    if (!!triggerNavOnClose && !rest.open) navigate(RoutePathEnum.PROFILE_EDIT)
  }, [rest.open, triggerNavOnClose])

  return (
    <ActionModal
      {...rest}
      title={t('profile-info-updated')}
      actions={[
        {
          label: t('OK', { ns: 'common' }),
          onClick: () => {
            setTriggerNavOnClose(true)
            modalClose()
          },
          variant: 'contained',
        },
      ]}
      actionsStyle='full'
      closeBtn={true}
      onClose={modalClose}
    >
      <p className='mb-0'>{t('you-have-updated-profile')}</p>
    </ActionModal>
  )
}

export default ProfileUpdatedModal
