import { BasicModal, BasicModalProps } from '@pimy-b2cweb/frontend-lib'
import { PortfolioFund } from '@/utils/portfolioFund'
import FundDetailsComp, { FundDetailsCompProps } from '../FundDetailsComp'
import { useEffect, useState } from 'react'

export interface FundDetailsModalProps
  extends Pick<BasicModalProps, 'open' | 'onClose'>,
    Pick<FundDetailsCompProps, 'onCashIn'> {
  fundDetails?: PortfolioFund
  modalClose: () => void
  isValidUserStatus?: boolean
  className?: string
}

const FundDetailsModal = ({
  open,
  onCashIn,
  onClose,
  fundDetails,
  modalClose,
  isValidUserStatus = true,
  className = '',
}: FundDetailsModalProps) => {
  const [triggerNavOnClose, setTriggerNavOnClose] = useState(false)
  useEffect(() => {
    if (!!triggerNavOnClose && !open) onCashIn()
  }, [open, triggerNavOnClose])
  return (
    <BasicModal closeBtn open={open} onClose={onClose} className={className}>
      {!!fundDetails ? (
        <FundDetailsComp
          fundDetails={fundDetails}
          onCashIn={() => {
            /**
             * if !!isValidUserStatus, close modal before proceed to cash-in
             * 
             * if !isValidUserStatus, do not close the modal, proceed cash-in function, 
             * which supposed to be blocked and open alert message modal on top
             */
            if (isValidUserStatus) {
              setTriggerNavOnClose(true)
              modalClose()
            } else {
              onCashIn()
            }
          }}
        />
      ) : (
        <></>
      )}
    </BasicModal>
  )
}

export default FundDetailsModal
