import { ResponseErrorProps } from '@pimy-b2cweb/frontend-lib'
import { useTranslation } from 'react-i18next'
import ErrorMessageComp from '@/components/ErrorMessageComp'
import ErrorRedirectBtn from '@/components/ErrorRedirectBtn'
import PostLogonMainBody from '@/layout/PostLogonMainBody'

export const ErrorPage = ({
  responseErrorAttrs,
}: {
  responseErrorAttrs: ResponseErrorProps
}) => {
  const { t } = useTranslation('redemptionPage')

  return (
    <PostLogonMainBody layout='8-6-4'>
      <ErrorMessageComp
        className='[&>p]:text-center'
        type={t(responseErrorAttrs.i18nKey, { ns: responseErrorAttrs.ns })}
      />
      <ErrorRedirectBtn />
    </PostLogonMainBody>
  )
}

export default ErrorPage
