import { useQueryFundList } from '@/api/commonApi'
import { useQueryCustomerPortfolio } from '@/api/customerApi'
import { useMemo } from 'react'

const useInitInvestmentData = () => {
  const {
    data: portfolioData,
    isLoading: isLoadingCP,
    isError: isErrorCP,
  } = useQueryCustomerPortfolio()

  const {
    data: fundList,
    isLoading: isLoadingFL,
    isError: isErrorFL,
  } = useQueryFundList({ enabled: true })

  return {
    isLoading: useMemo(
      () => !!isLoadingCP || !!isLoadingFL,
      [isLoadingCP, isLoadingFL]
    ),
    isError: useMemo(() => !!isErrorCP || !!isErrorFL, [isErrorCP, isErrorFL]),
    portfolioData,
    fundList,
  }
}

export default useInitInvestmentData
