import {
  FundInformation,
  FundPortfolioDto,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'

export interface PortfolioFund extends FundInformation, FundPortfolioDto {}

export const convertPortfolioFund = ({
  pFunds,
  fundList,
}: {
  pFunds?: FundPortfolioDto[]
  fundList?: FundInformation[]
}): PortfolioFund[] => {
  if (!pFunds || !pFunds.length || !fundList || !fundList.length)
    return [] as PortfolioFund[]

  return pFunds.reduce((acc, item) => {
    const fund = fundList.find(({ code }) => code === item.fundCode)
    return !!fund ? [...acc, { ...fund, ...item }] : acc
  }, [] as PortfolioFund[])
}
