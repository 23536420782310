import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AuthedStatusEnum } from '@/config'
import { RoutePathEnum } from '@/constants/routePath'
import { IsafResultEnum } from '@/enums/isafEnums'
import { sessionAuthedStatus } from '@/stores/auth'
import { selectIsafResult } from '@/stores/isaf.selectors'
import bannerAggressive from './assets/banner-aggressive.png'
import bannerConservative from './assets/banner-conservative.png'
import bannerModerate from './assets/banner-moderate.png'

export const Result = () => {
  const { t } = useTranslation(['isafPage', 'common'])
  const result = useSelector(selectIsafResult)
  const risk = IsafResultEnum[result as number]
  const navigate = useNavigate()
  const dispatch = useDispatch()

  return (
    <>
      <Banner result={result} />
      <p className='mb-2'>{t('your-risk-profile')}</p>
      <h1 className='leading-7'>{t(`result-${risk}-title`)}</h1>
      <p>{t(`result-${risk}-description`)}</p>
      <p className='mb-10'>
        <strong>{t('lets-begin-exploring')}</strong>
      </p>
      <Button
        variant='contained'
        fullWidth
        onClick={() => {
          dispatch(sessionAuthedStatus({ status: AuthedStatusEnum.LoggedIn }))
          navigate(RoutePathEnum.DASHBOARD)
        }}
      >
        {t('go-to-dashboard')}
      </Button>
    </>
  )
}

const Banner = ({ result }: { result?: IsafResultEnum }) => {
  if (!result) return null
  let bannerSrc: string = bannerConservative
  switch (result) {
    case IsafResultEnum.Conservative:
    case IsafResultEnum.ModeratelyConservative:
      bannerSrc = bannerConservative
      break
    case IsafResultEnum.Moderate:
      bannerSrc = bannerModerate
      break
    case IsafResultEnum.ModeratelyAggressive:
    case IsafResultEnum.Aggressive:
      bannerSrc = bannerAggressive
      break
    default:
      break
  }

  return (
    <img
      src={bannerSrc}
      className='mb-4 -mx-4 w-[calc(100%+2rem)] sm:mx-0 sm:w-full'
      alt={IsafResultEnum[result]}
    />
  )
}
export default Result
