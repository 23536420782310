import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'

export const PasswordSucceed = () => {
  const { t } = useTranslation(['resetPasswordPage', 'common'])
  const navigate = useNavigate()

  return (
    <>
      <h1>{t('password-successfully-reset')}</h1>
      <p className='mb-10'>{t('can-log-in-new-password')}</p>
      <Button
        fullWidth
        variant='contained'
        size='large'
        onClick={() => navigate(RoutePathEnum.SIGN_IN)}
      >
        {t('Login Screen', { ns: 'common' })}
      </Button>
    </>
  )
}

export default PasswordSucceed
