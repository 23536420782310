import { useOktaAuth } from '@okta/okta-react'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Loading from '@/components/Loading'
import { AUTHED_STATUS, AuthedStatusEnum } from '@/config'
import { RoutePathEnum } from '@/constants/routePath'
import { INVEST_SESSION, ORIGION_SESSION } from '@/constants/sessionKeys'
import DirectToEKYC from '@/Routes/pages/DirectToEKYC'
import {
  sessionAuthedStatus,
  sessionReferralCode,
  sessionRenewAuthToken,
  sessionReset,
  sessionSetPendingReloadCI,
} from '@/stores/auth'
import { selectAuthSessionState } from '@/stores/auth.selectors'
import {
  checkEpfTokenExpiration,
  getSessionStorage,
  removeSessionStorage,
  verifyAMLStatusEnum,
} from '@/utils'
import useCheckEpfJourney from './hooks/useCheckEpfJourney'
import useCustomerIndicator from './hooks/useCustomerIndicator'
import { selectEpfTokenSessionState } from '@/stores/epfToken.selectors'
import { EpfActionEnum } from '@/stores/epfToken'

export const RequireAuth = ({ children }: PropsWithChildren) => {
  const [isLoading, setIsLoading] = useState(true)

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { authState, oktaAuth } = useOktaAuth()
  const { status: authStatus, pendingReloadCI } = useSelector(
    selectAuthSessionState
  )
  const {
    token: epfToken,
    expAt: epfExpAt,
    decoded: decodedEpfToken,
  } = useSelector(selectEpfTokenSessionState)
  const dispatch = useDispatch()

  const {
    mutate: ciMutate,
    handleGetCiStatus,
    status: ciStatus,
    data: ciData,
    error: ciError,
  } = useCustomerIndicator()

  /**
   * When user land on root page or Authed pages (defined in '@/Routes/index.tsx'):
   */
  useEffect(() => {
    /**
     * if no Okta Auth, go to A1)
     */
    if (!authState?.isAuthenticated) {
      dispatch(sessionReset())
      removeSessionStorage(INVEST_SESSION)
      handleRouteSwitch('notAuthed')
      return
    }

    /**
     * else if okta authed but no auth store data (e.g. user reload or new login),
     *   - call CustomerIndicator api,
     *   - wait for CustomerIndicator api call in B1)
     */
    if (
      authStatus === AuthedStatusEnum.SigningIn ||
      authStatus === AuthedStatusEnum.PostSignUpCheck ||
      !AUTHED_STATUS.includes(authStatus)
    ) {
      dispatch(sessionRenewAuthToken({ oktaTokens: authState }))
      ciMutate({})
      return
    }

    /**
     * else  (all normal cases or user signing out),
     *   - go to route switch A3)
     */
    handleRouteSwitch(authStatus as unknown as AuthedStatusEnum)
  }, [])

  /**
   * B1) when CustomerIndicator api returns results:
   */
  useEffect(() => {
    /**
     * if api return errrs, goto A2)
     */
    if (ciStatus === 'error') {
      console.error('ciError: ', ciError)
      handleRouteSwitch('ci-error')
      return
    }
    /**
     * if api do not retrun errors:
     */
    if (ciStatus === 'success' && !!ciData) {
      /**
       * get CI status from the logic, then:
       */
      const status = handleGetCiStatus()

      /**
       * if no CI status or CI status returns 'ecddUnknown' returns, goto A2)
       */
      if (!status || status == verifyAMLStatusEnum.AmlUnknown) {
        handleRouteSwitch('ci-error')
        return
      }

      /**
       * else:
       *  - set auth status as CI status
       *  - in case of epf to B2C: check auth Status and also maintain epf/isaf status
       *      e.g. if EPF user has ecdd expired and tried to Buy/Sell/Switch fund: status = AuthedStatusEnum.ecddExpired, authStatus = AuthedStatusEnum.EpfPurchasing/EpfRedemption/epfSwitching
       *  - goto A3)
       */
      const _hasEpfToken =
        !!epfToken && !!epfExpAt && checkEpfTokenExpiration(epfExpAt)
      // Store referral code coming from indicator API
      const referralCode = ciData.referralCode
      dispatch(sessionReferralCode({ referralCode }))
      if (
        _hasEpfToken &&
        decodedEpfToken?.action &&
        ciData.customerProfileCompleted
      ) {
        const authStatus = getEpfActionStatus(decodedEpfToken.action)
        dispatch(sessionAuthedStatus({ status, authStatus }))
        handleRouteSwitch(authStatus || status)
      } else {
        dispatch(sessionAuthedStatus({ status }))
        handleRouteSwitch(status)
      }
    }
  }, [ciStatus, ciData, ciError])

  /**
   * watching authStatus,
   * when authStatus become "SigningOut" (e.g. set form the useHandleSignOut hook):
   *   - set isLoading = true so to set the page content become <Loading />
   *   - reset auth store
   *   - remove INVEST_SESSION from session storage
   *   - trigger Okta sign out and wait
   */
  useEffect(() => {
    if (authStatus === AuthedStatusEnum.SigningOut) {
      setIsLoading(true)
      dispatch(sessionReset())
      removeSessionStorage(INVEST_SESSION)
      oktaAuth.signOut()
    }
  }, [authStatus])

  /**
   * When pendingReloadCI become true:
   *   - set isLoading = true so to set the page content become <Loading />
   *   - reload customer indicator and re run checking
   *   - reset pendingReloadCI to false
   */
  useEffect(() => {
    if (!!pendingReloadCI) {
      setIsLoading(true)
      ciMutate({
        forceUpdate: true,
      })
      dispatch(sessionSetPendingReloadCI({ pendingReloadCI: false }))
    }
  }, [pendingReloadCI])

  useCheckEpfJourney({ pathname, authStatus: authStatus as AuthedStatusEnum })

  const handleRouteSwitch = (
    authStatus: 'notAuthed' | 'ci-error' | AuthedStatusEnum
  ) => {
    /**
     * if Auth Status = SigningOut, do nothing to stay on <Loading /> and waitn for Okta sign-out
     */
    if (authStatus === AuthedStatusEnum.SigningOut) return

    /**
     *  A1) if no okta auth, route to non-auth pages
     */
    if (authStatus === 'notAuthed') {
      /**
       * for details, see #S1 from '@/Routes/hoc/NotRequireAuth'
       *   - restore ORIGION_SESSION from session storge (e.g. './sign-up')
       *   - if ORIGION_SESSION === './sign-up', go './sign-up'
       *   - else, go './sign-in'
       */
      const origion = getSessionStorage(ORIGION_SESSION)
      navigate(
        origion === RoutePathEnum.SIGN_UP ? origion : RoutePathEnum.SIGN_IN
      )
      return
    }

    /**
     * A2) CustomerIndicator api returns errors:
     *   - set AuthStatus to CustomerIndicatorError
     *   - goto Error page
     * in result:
     *   - show the error page until user click "Go to Login" and to sign-out.
     */
    if (authStatus === 'ci-error') {
      dispatch(
        sessionAuthedStatus({ status: AuthedStatusEnum.CustomerIndicatorError })
      )
      navigate(RoutePathEnum.ERROR)
      return
    }

    /**
     * A3) any other cases:
     */
    /**
     * A3.1) if Auth status = RequireCustomerProfile:
     *   - force route to PROFILE_SETUP logics
     */
    if (authStatus === AuthedStatusEnum.RequireCustomerProfile) {
      if (pathname !== RoutePathEnum.PROFILE_SETUP)
        navigate(RoutePathEnum.PROFILE_SETUP)
      setIsLoading(false)
      return
    }

    /**
     * A3.2) if Auth status = DirectToEKYC:
     *   - keeps loading screen and go to EKYC
     */
    if (authStatus === AuthedStatusEnum.DirectToEKYC) return
    /**
     * A3.3) if Auth status = RequireEKYC:
     *   - force route to EKYC_REQUIRED logics
     */
    if (authStatus === AuthedStatusEnum.RequireEKYC) {
      if (
        !(
          [RoutePathEnum.EKYC_REQUIRED, RoutePathEnum.PROFILE_SETUP] as string[]
        ).includes(pathname)
      )
        navigate(RoutePathEnum.EKYC_REQUIRED)
      setIsLoading(false)
      return
    }

    /**
     * A3.4) if Auth status = EcddRejected:
     *   - force route to ACCOUNT_DEACTIVATED logics
     */
    // if (authStatus === AuthedStatusEnum.EcddRejected) {
    //   if (pathname !== RoutePathEnum.ACCOUNT_DEACTIVATED)
    //     navigate(RoutePathEnum.ACCOUNT_DEACTIVATED)
    //   setIsLoading(false)
    //   return
    // }

    /**
     * A3.5) if Auth status = EcddSubmitted:
     *   - force route to ACCOUNT_DEACTIVATED logics
     */
    // if (authStatus === AuthedStatusEnum.EcddSubmitted) {
    //   if (pathname !== RoutePathEnum.PROFILE_VERIFICATION)
    //     navigate(RoutePathEnum.PROFILE_VERIFICATION)
    //   setIsLoading(false)
    //   return
    // }

    /**
     * A3.6) if Auth status = RequiredEcdd:
     *   - force route to profile Ecdd logics
     */
    if (authStatus === AuthedStatusEnum.EcddPending) {
      if (pathname !== RoutePathEnum.PROFILE_ECDD)
        navigate(RoutePathEnum.PROFILE_ECDD)
      setIsLoading(false)
      return
    }

    // normal case but user land on special pages, go to dashboard

    /**
     * A3.7)
     *   if Auth status != Auth status of EKYC_REQUIRED or ACCOUNT_DEACTIVATED,
     *   but user land on pages of  EKYC_REQUIRED or ACCOUNT_DEACTIVATED:
     *     - force route to DASHBOARD logics
     */
    if (
      (
        [
          RoutePathEnum.EKYC_REQUIRED,
          RoutePathEnum.ACCOUNT_DEACTIVATED,
        ] as string[]
      ).includes(pathname)
    ) {
      navigate(RoutePathEnum.DASHBOARD)
      setIsLoading(false)
      return
    }

    /**
     * A3.8)
     *   if Auth status != Auth status of EKYC_REQUIRED or ACCOUNT_DEACTIVATED,
     *   but user land Root):
     *     if is EpfPurchasing / EpfRedemption / EpfSwitch, goto cahs-in
     *     else, force route to DASHBOARD logics
     */
    if (pathname === RoutePathEnum.ROOT) {
      navigate(
        authStatus === AuthedStatusEnum.EpfPurchasing
          ? RoutePathEnum.ORDER_PURCHASE_FORM
          : authStatus === AuthedStatusEnum.EpfRedemption
          ? RoutePathEnum.ORDER_REDEMPTION_FORM
          : authStatus === AuthedStatusEnum.EpfSwitching
          ? RoutePathEnum.ORDER_SWITCHING_FORM
          : RoutePathEnum.DASHBOARD
      )
      setIsLoading(false)
      return
    }

    /**
     * A3.9) all good normal case, set isLoading = false to display the page
     */
    setIsLoading(false)
  }

  if (!isLoading) return children
  return (
    <>
      <Loading isLoadingPage={true} />
      {authStatus === AuthedStatusEnum.DirectToEKYC ? <DirectToEKYC /> : null}
    </>
  )
}

const getEpfActionStatus = (action: EpfActionEnum) => {
  switch (action) {
    case EpfActionEnum.PURCHASE:
      return AuthedStatusEnum.EpfPurchasing
    case EpfActionEnum.REDEEM:
      return AuthedStatusEnum.EpfRedemption
    case EpfActionEnum.SWITCH:
      return AuthedStatusEnum.EpfSwitching
    default:
      return
  }
}

export default RequireAuth
