import { useResponseError } from '@pimy-b2cweb/frontend-lib'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMutationResendOtp } from '@/api/commonApi'
import { sessionRenewChallengeId } from '@/stores/auth'
import { selectChallengeId } from '@/stores/auth.selectors'
import { getErrorResponseCode } from '@/utils'
import useCountDownTimer from '../useCountDownTimer'

const COUNTDOWN_TIME_IN_MS = 60 * 1000

export const useResendOtp = ({
  initCanResend,
  handleResetPageError,
}: {
  initCanResend: boolean
  handleResetPageError: () => void
}) => {
  const { timeLeft, start } = useCountDownTimer(COUNTDOWN_TIME_IN_MS)

  const lastChallengeId = useSelector(selectChallengeId)

  const [resending, setResending] = useState<boolean>(false)
  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const dispatch = useDispatch()

  const {
    mutate,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationResendOtp()

  const startCountDown = useCallback(() => {
    start()
  }, [])

  useEffect(() => {
    if (!!initCanResend) startCountDown()
  }, [])

  const onResendOtp = () => {
    if (!resending) {
      if (!!lastChallengeId) {
        handleResetPageError()
        setResponseErrorAttrs(undefined)
        setResending(true)
        mutate()
        return
      }
      console.error('missing lastChallengeId')
    } else {
      console.log('resending')
    }
  }

  useEffect(() => {
    let _lockResend = false
    if (!initCanResend) _lockResend = true

    if (mutateStatus === 'error') {
      console.log('mutatedError: ', mutatedError)
      const errMsg = getErrorResponseCode(mutatedError)
      setResponseErrorAttrs({ i18nKey: errMsg })
      setResending(false)
      return
    }

    if (mutateStatus === 'success' && !!mutatedData) {
      dispatch(sessionRenewChallengeId(mutatedData))
      if (mutatedData.canResend === false) {
        _lockResend = true
      } else {
        startCountDown()
      }
    }
    setResponseErrorAttrs(
      !!_lockResend ? { i18nKey: 'can-not-resent-otp-error' } : undefined
    )

    if (mutateStatus !== 'loading') {
      setResending(false)
    }
  }, [mutateStatus, mutatedData, mutatedError, initCanResend])

  return {
    timeLeft,
    resendErrorAttrs: responseErrorAttrs,
    resending,
    onResendOtp,
    onResetResendError: () => {
      setResponseErrorAttrs(undefined)
    },
  }
}
