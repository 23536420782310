import ErrorMessageComp from '@/components/ErrorMessageComp'
import FundCard from '@/components/FundCard'
import HelmetSiteTitle from '@/components/HelmetSiteTitle'
import InvestmentCategoryTabs from '@/components/InvestmentCategoryTabs'
import Loading from '@/components/Loading'
import { RoutePathEnum } from '@/constants/routePath'
import useInvestmentPageContent from '@/hooks/useInvestmentPageContent'
import { PortfolioFund } from '@/utils/portfolioFund'
import { MainBody } from '@pimy-b2cweb/frontend-lib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import RedemptionFooter from './components/RedemptionFooter'

const Redemption = () => {
  const { t } = useTranslation(['redemptionPage', 'investmentPage', 'common'])

  const { isLoading, feeds, tabs, isError } = useInvestmentPageContent()
  const [selectedFund, setSelectedFund] = useState<PortfolioFund | undefined>(
    undefined
  )
  const navigate = useNavigate()

  useEffect(() => {
    if (isError) navigate(RoutePathEnum.ERROR)
  }, [isError])

  const onCashOut = () => {
    navigate(RoutePathEnum.ORDER_REDEMPTION_FORM, {
      state: {
        subaccountNo: selectedFund?.accountNo,
        productId: selectedFund?.productId,
        funds: [
          {
            fundCode: selectedFund?.fundCode,
            amount: 1, //TODO: to be removed
          },
        ],
      },
    })
  }
  const onFundCardClick = (fund: PortfolioFund) => {
    if (selectedFund?.code === fund.code) {
      setSelectedFund(undefined)
      return
    }
    setSelectedFund({ ...fund })
  }

  return (
    <>
      <HelmetSiteTitle pageTitle={t('redemption')} />
      <MainBody>
        <h3 className='col-span-full text-xl font-bold leading-7 mb-0'>
          {t('please-select-fund-to-cash-out')}
        </h3>

        <InvestmentCategoryTabs {...tabs} />

        {isLoading ? (
          <Loading isLoadingPage />
        ) : (
          <>
            <div className='col-span-full'>
              <div className='flex flex-col gap-4'>
                {!!feeds.displayFeeds.length ? (
                  feeds.displayFeeds.map((fund) => {
                    return (
                      <FundCard
                        key={fund.code}
                        {...fund}
                        className='h-full'
                        onCardClick={() => onFundCardClick(fund)}
                        isSelected={selectedFund?.code === fund.code}
                      />
                    )
                  })
                ) : (
                  <ErrorMessageComp
                    className='col-span-full min-h-[calc(100vh-450px)] sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 lg:col-start-5 lg:col-span-4 flex justify-center items-center gap-2 [&>h1]:!mb-0 [&>h1]:!text-xl [&>h1]:!font-normal [&>h1]:!text-pi-gray-1'
                    type={t('oops-no-funds-available', {
                      ns: 'investmentPage',
                    })}
                    message={t('please-check-back-later', {
                      ns: 'investmentPage',
                    })}
                    displayIcon={false}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </MainBody>
      <RedemptionFooter onCashOut={onCashOut} disabled={!selectedFund} />
    </>
  )
}

export default Redemption
