import { Helmet } from 'react-helmet-async'
import { SITE_TITLE } from '@/config'

export const HelmetSiteTitle = ({ pageTitle }: { pageTitle?: string }) => (
  <Helmet
    children={
      <title>
        {!!pageTitle ? `${pageTitle} | ` : ''}
        {SITE_TITLE}
      </title>
    }
  />
)

export default HelmetSiteTitle
