import { MouseEventHandler } from 'react'
import TwoSideText from '@/components/TwoSideText'

type ButtonType = {
  label: string
  onClick?: MouseEventHandler<HTMLSpanElement>
}

export const TwoSideLinks = ({
  left,
  right,
  className = '',
  disabled = false,
}: {
  left: ButtonType
  right: ButtonType
  className?: string
  disabled?: boolean
}) => (
  <TwoSideText
    className={`two-side-link ${className}`}
    left={
      <span
        className={`clickable-text ${disabled ? 'disabled' : ''}`}
        onClick={(e) => !disabled && !!left.onClick && left.onClick(e)}
      >
        {left.label}
      </span>
    }
    right={
      <span
        className={`clickable-text ${disabled ? 'disabled' : ''}`}
        onClick={(e) => !disabled && !!right.onClick && right.onClick(e)}
      >
        {right.label}
      </span>
    }
  />
)
export default TwoSideLinks
