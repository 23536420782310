import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useMutationOktaAuth } from '@/api/oktaAuth'
import { sessionSignIn } from '@/stores/auth'
import { AuthedStatusEnum } from '@/config'
import { RoutePathEnum } from '@/constants/routePath'

export const useSignin = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const oktaAuth = useMutationOktaAuth()

  const handleSignIn = () => {
    navigate(RoutePathEnum.SIGN_IN)
  }

  const handleDispatchSignIn = ({ status }: { status?: AuthedStatusEnum }) => {
    if (!!oktaAuth.data?.tokens) {
      dispatch(sessionSignIn({ status, oktaTokens: oktaAuth.data?.tokens }))
      navigate(RoutePathEnum.ROOT)
    }
  }

  return {
    handleSignIn,
    handleDispatchSignIn,
    ...oktaAuth,
  }
}

export default useSignin
