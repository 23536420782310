import {
  AuthApi,
  AuthRecoverUsernameReqDto,
  AuthSecureContextReqDto,
  AuthResetPasswordInitReqDto,
  AuthResetPasswordSubmitReqDto,
  AuthChangePasswordSubmitReqDto,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import {
  CHANGE_PASSWORD,
  RECOVER_USERNAME,
  SIGN_IN_USERNAME,
  RESET_PW_USERNAME,
  RESET_PW_SETUP_PASSWORD,
} from '@/constants/apiKeys'
import { INVALID_USERID } from '@/constants/errorCodes'
import {
  LOGIN,
  RESET_PASSWORD,
  RECOVER_USERNAME as RECOVER_USERNAME_ACTION,
} from '@/constants/RecaptchaActions'
import useRecaptcha from '@/hooks/useRecaptcha'
import i18n from '@/i18n'
import { selectEpfDecoded } from '@/stores/epfToken.selectors'
import { selectSessionToken } from '@/stores/auth.selectors'
import { apiLangCode } from '@/utils'
import { BaseApiConfig, apiClient } from './api-config'

const authApiClient = new AuthApi(BaseApiConfig, apiClient)

export interface UseMutationRecoverUsername
  extends Omit<AuthRecoverUsernameReqDto, 'langCode'> {}
export const useMutationRecoverUsername = () => {
  const { handleExecute } = useRecaptcha()
  const langCode = apiLangCode(i18n.language)

  return useMutation({
    mutationFn: async (data: UseMutationRecoverUsername) => {
      const recaptchaToken = await handleExecute(RECOVER_USERNAME_ACTION)

      const res = await authApiClient.submitRecoverUsername({
        xRecaptchaAction: RECOVER_USERNAME_ACTION,
        xRecaptchaToken: recaptchaToken,
        authRecoverUsernameReqDto: {
          ...data,
          langCode,
        },
      })

      return res.data
    },
    mutationKey: [RECOVER_USERNAME, langCode],
  })
}

export interface UseMutationSignInUsername
  extends Omit<AuthSecureContextReqDto, 'langCode'> {}

export const useMutationSignInUsername = () => {
  const { handleExecute } = useRecaptcha()
  const epfdecoded = useSelector(selectEpfDecoded)

  return useMutation({
    mutationFn: async (data: UseMutationSignInUsername) => {
      const recaptchaToken = await handleExecute(LOGIN)
      const res = await authApiClient.findSecureContext({
        xRecaptchaAction: LOGIN,
        xRecaptchaToken: recaptchaToken,
        authSecureContextReqDto: { ...data },
      })

      if (!!epfdecoded) {
        if (!!epfdecoded?.cifid && epfdecoded?.cifid !== res.data?.cifid) {
          throw new Error(INVALID_USERID)
        }
        if (
          !!epfdecoded.externalCifid &&
          epfdecoded?.externalCifid !== res.data?.externalCifid
        ) {
          throw new Error(INVALID_USERID)
        }
        if (!epfdecoded?.cifid && !epfdecoded.externalCifid) {
          throw new Error(INVALID_USERID)
        }
      }

      return {
        ...data,
        ...res.data,
        recaptchaToken,
      }
    },
    mutationKey: [SIGN_IN_USERNAME],
  })
}

export interface UseMutationResetPwUsername
  extends Omit<AuthResetPasswordInitReqDto, 'langCode'> {}

export const useMutationResetPwUsername = () => {
  const { handleExecute } = useRecaptcha()
  const langCode = apiLangCode(i18n.language)

  return useMutation({
    mutationFn: async (data: UseMutationResetPwUsername) => {
      const recaptchaToken = await handleExecute(RESET_PASSWORD)

      const res = await authApiClient.initResetPassword({
        xRecaptchaAction: RESET_PASSWORD,
        xRecaptchaToken: recaptchaToken,
        authResetPasswordInitReqDto: {
          ...data,
          langCode,
        },
      })
      return res.data
    },
    mutationKey: [RESET_PW_USERNAME, langCode],
  })
}

export const useMutationResetPwSetPw = () => {
  const sessionToken = useSelector(selectSessionToken)

  return useMutation({
    mutationFn: async (data: AuthResetPasswordSubmitReqDto) => {
      const res = await authApiClient.submitResetPassword({
        xSessionToken: sessionToken as string,
        authResetPasswordSubmitReqDto: data,
      })
      return res.data
    },
    mutationKey: [RESET_PW_SETUP_PASSWORD],
  })
}

export const useMutationChangePw = () => {
  const { handleExecute } = useRecaptcha()

  return useMutation({
    mutationFn: async (data: AuthChangePasswordSubmitReqDto) => {
      const recaptchaToken = await handleExecute(RESET_PASSWORD)

      const res = await authApiClient.submitChangePassword({
        authChangePasswordSubmitReqDto: {
          ...data,
          recaptchaToken: recaptchaToken,
        },
      })
      return res.data
    },
    mutationKey: [CHANGE_PASSWORD],
  })
}
