import { useOktaAuth } from '@okta/okta-react'
import { useEffect, PropsWithChildren } from 'react'
import Loading from '@/components/Loading'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AUTHED_STATUS } from '@/config'
import { selectAuthStatus } from '@/stores/auth.selectors'
import { sessionReset } from '@/stores/auth'
import { ORIGION_SESSION } from '@/constants/sessionKeys'
import useHandleSignOut from '@/hooks/useHandleSignOut'
import { setSessionStorage, removeSessionStorage } from '@/utils'

export const NotRequireAuth = ({ children }: PropsWithChildren) => {
  const { authState } = useOktaAuth()
  const { pathname } = useLocation()
  const handleSignOut = useHandleSignOut()

  const authStatus = useSelector(selectAuthStatus)
  const dispatch = useDispatch()

  /**
   * When user land on Unauthed pages (defined in '@/Routes/index.tsx'):
   */
  useEffect(() => {
    /**
     * If has Okta Auth:
     *  - set current path name to ORIGION_SESSION (see #S1)
     *  - signout user
     */
    if (!!authState?.isAuthenticated) {
      setSessionStorage(ORIGION_SESSION, pathname)
      handleSignOut()
      return
    }

    /**
     * else:
     *  - clear ORIGION_SESSION from session storage
     *  - reset Auth store
     */
    if (AUTHED_STATUS.includes(authStatus)) {
      removeSessionStorage(ORIGION_SESSION)
      dispatch(sessionReset())
    }
  }, [authState])

  if (!!authState?.isAuthenticated) return <Loading isLoadingPage={true} />
  return children
}

export default NotRequireAuth

/**
 * #S1: Special handeling for nedge case of EPF journey:
 *   when the user is on EPF purchasing journey, which:
 *     - user require a sign-up
 *     - user is Okta authed in the browser
 *   Then:
 *     - save current path (e.g. './sign-up') as ORIGION_SESSION to session storge
 *     - run signout producture
 *   Then:
 *     - user will land on Root page without Okta Auth
 *     - goto A1) in '@/Routes/hoc/RequireAuth'
 *   Then, in @/Routes/hoc/RequireAuth:
 *     - restore ORIGION_SESSION from session storge (e.g. './sign-up')
 *     - if ORIGION_SESSION === './sign-up', go './sign-up'
 *     - else, go './sign-in'
 */
