import ErrorNotFound from '@/Routes/pages/ErrorNotFound'
import Loading from '@/components/Loading'
import { RoutePathEnum } from '@/constants/routePath'
import {
  AccountStatusEnum,
  FundInformation,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useEffect, useState } from 'react'
import { useModal } from '@pimy-b2cweb/frontend-lib'
import { useNavigate, useParams } from 'react-router-dom'
import FundListByCat from './subpages/FundListByCat'
import FundListRoot from './subpages/FundListRoot'
import useFundSelection from './hooks/useFundSelection'
import useInitFundListData from '@/hooks/useInitFundListData'
import { SelectedFund } from '@/hooks/useInvestmentReducer'
import HighRiskAlertModal from '@/components/HighRiskAlertModal'
import useHandleUserStatus from '@/hooks/useHandleUserStatus'
import SubaccountInvalidModal, {
  ActionTypeEnum,
} from '@/components/SubaccountInvalidModal'

export enum FundCategory {
  EPF = 'epf',
  Cash = 'cash',
}

export type FundListByCategory = {
  [key in FundCategory]: FundInformation[]
}

const FundList = () => {
  const params = useParams()
  const navigate = useNavigate()
  const highRiskAlertModal = useModal()

  const [selectedFund, setSelectedFund] =
    useState<Omit<SelectedFund, 'amount'>>()

  const [selectCallback, setSelectCallback] =
    useState<(state: boolean) => void>()

  const category = !params.category
    ? 'root'
    : Object.values(FundCategory).find((item) => item === params?.category)

  const {
    isLoadingFundList,
    isLoadingPage,
    isFundListQueryError,
    isErrorPage,
    fundListByCategory,
    riskLevelList,
    riskProfileTabs,
    riskProfile,
    riskProfileName,
    riskLevel,
    totalAssetsAmt,
    profitLoss,
    portfolioData,
  } = useInitFundListData()

  const fundSelection = useFundSelection()
  const subaccountInvalidModal = useModal()
  const { isValidUserStatus, handleCheckUserStatus } = useHandleUserStatus()

  useEffect(() => {
    if (isErrorPage) {
      navigate(RoutePathEnum.ERROR)
    }
  }, [isErrorPage])

  useEffect(() => {
    console.log('riskProfile: ', riskProfile)

    riskProfileTabs.handleSetValue(riskProfile)
  }, [riskProfile])

  const openHighRiskModal = (
    fund: Omit<SelectedFund, 'amount'>,
    selectCallback: (state: boolean) => void
  ) => {
    const userRiskLevel = riskLevel || 0
    if (!userRiskLevel || userRiskLevel < fund.riskLevel) {
      highRiskAlertModal.modalOpen()
      setSelectCallback(() => selectCallback)
      setSelectedFund(fund)
    } else {
      fundSelection.onSelectFund(fund)
      selectCallback(true)
    }
  }

  const handleHardProceed = () => {
    if (selectedFund) {
      fundSelection.onSelectFund(selectedFund)
      selectCallback && selectCallback(true)
      setSelectedFund(undefined)
      setSelectCallback(() => {})
    }
  }

  const onCheckOut = () => {
    /* ISAF/ECDD Check */
    handleCheckUserStatus({})
    if (!isValidUserStatus) return

    /* Subaccount status check */
    const subaccountStatus =
      portfolioData?.cashGroup.funds?.at(0)?.subaccountStatus
    if (!!subaccountStatus && subaccountStatus !== AccountStatusEnum.ACTIVE) {
      subaccountInvalidModal.modalOpen()
      return
    }

    fundSelection.onCheckOut()
  }

  return (
    <>
      {isLoadingPage ? (
        <Loading isLoadingPage={true} />
      ) : category === 'root' ? (
        <FundListRoot
          {...riskProfileTabs}
          {...fundSelection}
          onCheckOut={onCheckOut}
          riskLevels={riskLevelList?.levels}
          riskProfileName={riskProfileName}
          totalAssetsAmt={totalAssetsAmt}
          profitLoss={profitLoss}
          isLoading={isLoadingFundList}
          isError={isFundListQueryError}
          fundListByCategory={fundListByCategory}
          openHighRiskModal={openHighRiskModal}
        />
      ) : !category ? (
        <ErrorNotFound />
      ) : (
        <FundListByCat
          {...riskProfileTabs}
          {...fundSelection}
          onCheckOut={onCheckOut}
          category={category}
          fundList={
            category === FundCategory.EPF
              ? fundListByCategory[FundCategory.EPF]
              : fundListByCategory[FundCategory.Cash]
          }
          riskLevels={riskLevelList?.levels}
          isLoading={isLoadingFundList}
          isError={isFundListQueryError}
          openHighRiskModal={openHighRiskModal}
        />
      )}

      <HighRiskAlertModal
        {...highRiskAlertModal}
        handleHardProceed={handleHardProceed}
      />
      <SubaccountInvalidModal
        {...subaccountInvalidModal}
        action={ActionTypeEnum.CASH_IN}
      />
    </>
  )
}

export default FundList
