import { useEffect, useState } from 'react'
import VerifyOtp from '../../components/VerifyOtp'
import EmploymentInfoForm from './subpages/EmploymentInfoForm'
import ProfileUpdatedModal from '../../components/ProfileUpdatedModal'
import { useModal } from '@pimy-b2cweb/frontend-lib'
import { useQueryClient } from '@tanstack/react-query'
import { CUSTOMER_PROFILE_FETCH } from '@/constants/apiKeys'

export enum EmploymentInfoPageEnum {
  EMPLOYMENT_INFO = 'employment_info',
  VERIFY_OTP = 'verify_otp',
}

const EmploymentInfo = ({
  openModalOnStart = false,
}: {
  openModalOnStart: boolean
}) => {
  const profileUpdatedModal = useModal()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (openModalOnStart) {
      profileUpdatedModal.modalOpen()
      queryClient.invalidateQueries({
        queryKey: [CUSTOMER_PROFILE_FETCH],
        exact: true,
      })
    }
  }, [])

  const [currentPage, setCurrentPage] = useState(
    EmploymentInfoPageEnum.EMPLOYMENT_INFO
  )

  const goToVerifyOtp = () => setCurrentPage(EmploymentInfoPageEnum.VERIFY_OTP)

  return currentPage === EmploymentInfoPageEnum.EMPLOYMENT_INFO ? (
    <>
      <EmploymentInfoForm goToVerifyOtp={goToVerifyOtp} />
      <ProfileUpdatedModal {...profileUpdatedModal} />
    </>
  ) : (
    <VerifyOtp />
  )
}

export default EmploymentInfo
