import { ActionModal, ActionModalProps } from '@pimy-b2cweb/frontend-lib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export enum TrxTypeEnum {
  CASH_OUT = 'cash-out',
  SWITCHING = 'switching',
}

export interface InsufficientBalanceModalProps
  extends Omit<
    ActionModalProps,
    'title' | 'actions' | 'actionsStyle' | 'closeBtn' | 'onClose' | 'children'
  > {
  modalClose: () => void
  onContinue: () => void
  trxType: TrxTypeEnum
}

const InsufficientBalanceModal = ({
  modalClose,
  onContinue,
  trxType,
  ...rest
}: InsufficientBalanceModalProps) => {
  const { t } = useTranslation(['insufficientBalanceModal', 'common'])

  const [triggerNavOnClose, setTriggerNavOnClose] = useState(false)
  useEffect(() => {
    if (!!triggerNavOnClose && !rest.open) onContinue()
  }, [rest.open, triggerNavOnClose])

  return (
    <ActionModal
      {...rest}
      title={t('ops-insufficient-balance', { transaction_type: t(trxType) })}
      actions={[
        {
          label: t('done', { ns: 'common' }),
          onClick: () => {
            setTriggerNavOnClose(true)
            modalClose()
          },
          variant: 'contained',
        },
      ]}
      actionsStyle='full'
      closeBtn={false}
      onClose={modalClose}
    >
      <>
        {t('financial-goal-has-insufficient-balance', {
          transaction_type: t(trxType),
        })}
      </>
    </ActionModal>
  )
}

export default InsufficientBalanceModal
