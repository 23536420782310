import { IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useShowHeaderStatus } from '@/hooks/useShowHeaderStatus'
import { PortfolioFund } from '@/utils/portfolioFund'
import FundDetailsComp, { FundDetailsCompProps } from '../FundDetailsComp'
import { ReactComponent as ArrowLeft } from './assets/arrow-left.svg'
import { ReactComponent as CloseIco } from './assets/close-ico.svg'

export interface FundDetailsCardProps
  extends Pick<FundDetailsCompProps, 'onCashIn'> {
  onCloseFund: () => void
  fundDetails?: PortfolioFund
  className?: string
}

const FundDetailsCard = ({
  onCloseFund,
  onCashIn,
  fundDetails,
  className = '',
}: FundDetailsCardProps) => {
  const { t } = useTranslation('portfolioPage')
  const showHeader = useShowHeaderStatus()

  return (
    <div
      className={`flex flex-col bg-white rounded max-h-[476px] h-full p-4 gap-2 transition-top duration-300 sticky ${
        !showHeader ? 'top-[72px]' : 'top-[152px] lg:top-[136px]'
      } ${className}`}
    >
      {!!fundDetails ? (
        <>
          <div className='ml-auto'>
            <IconButton
              aria-label='close'
              onClick={onCloseFund}
              className='-m-2'
            >
              <CloseIco className='w-6 h-6' />
            </IconButton>
          </div>
          <FundDetailsComp fundDetails={fundDetails} onCashIn={onCashIn} />
        </>
      ) : (
        <div className='flex flex-row items-start gap-2'>
          <ArrowLeft className='w-6 h-6 block' />
          <div className='text-xl font-black max-w-[calc(100%-32px)]'>
            {t('please-select-fund')}
          </div>
        </div>
      )}
    </div>
  )
}

export default FundDetailsCard
