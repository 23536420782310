import HelmetSiteTitle from '@/components/HelmetSiteTitle'
import PostLogonContainer from '@/layout/PostLogonContainer'
import PostLogonMainBody from '@/layout/PostLogonMainBody'
import NewPassword from './subpages/NewPassword'
import PasswordSucceed from './subpages/PasswordSucceed'
import { RoutePathEnum } from '@/constants/routePath'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

export enum ChangePasswordStatusEnum {
  ChangePwPendingPassword = 'changePwPendingPassword',
  ChangePwSucceed = 'changePwSucceed',
}

export const ResetPassword = () => {
  const { t } = useTranslation('profileInfoPage')
  return (
    <>
      <HelmetSiteTitle pageTitle={t('reset-password')} />
      <PostLogonMainBody useTransBg={false}>
        <PostLogonContainer>
          <h1 className='font-bold'>{t('profile')}</h1>
          <StatusSwitch />
        </PostLogonContainer>
      </PostLogonMainBody>
    </>
  )
}

const StatusSwitch = () => {
  const navigate = useNavigate()

  const backToProfile = () => {
    navigate(RoutePathEnum.PROFILE_EDIT)
    setPageState(ChangePasswordStatusEnum.ChangePwPendingPassword)
  }

  const goToSuccessPage = () =>
    setPageState(ChangePasswordStatusEnum.ChangePwSucceed)

  const [pageState, setPageState] = useState(
    ChangePasswordStatusEnum.ChangePwPendingPassword
  )

  switch (pageState) {
    case ChangePasswordStatusEnum.ChangePwPendingPassword:
      return <NewPassword goToSuccess={goToSuccessPage} />
    case ChangePasswordStatusEnum.ChangePwSucceed:
      return <PasswordSucceed goBackToProfile={backToProfile} />
    default:
      return <NewPassword goToSuccess={goToSuccessPage} />
  }
}

export default ResetPassword
