import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { ActionModal } from '@pimy-b2cweb/frontend-lib'
import useManageSession from './useManageSession'

export const SessionModal = memo(() => {
  const { t } = useTranslation(['sessionModal', 'common'])
  const {
    formattedTimeleft,
    mutateIsLoading,
    handleSignOut,
    onExtSession,
    ...rest
  } = useManageSession()

  return (
    <ActionModal
      title={t('login-about-expire-title')}
      actions={[
        {
          label: t('Cancel', { ns: 'common' }),
          onClick: handleSignOut,
          disabled: mutateIsLoading,
        },
        {
          label: t('OK', { ns: 'common' }),
          onClick: onExtSession,
          disabled: mutateIsLoading,
        },
      ]}
      {...rest}
    >
      <p>
        {!!formattedTimeleft &&
          t('login-about-expire-body', { time: formattedTimeleft })}
      </p>
    </ActionModal>
  )
})
export default SessionModal
