import Loading from '@/components/Loading'
import TermsAndConditionsComp, {
  TncNoticeVariantEnum,
} from '@/components/TermsAndConditionsComp'
import TwoSideText from '@/components/TwoSideText'
import { RoutePathEnum } from '@/constants/routePath'
import { useQueryFundNameMapping } from '@/hooks/useQueryFundNameMapping'
import { Button } from '@mui/material'
import { OrderFundDto } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { formattedAmount } from '@pimy-b2cweb/frontend-lib'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface CashOutSummaryFormProps {
  agreeTnc: string[]
}

interface CashOutSummaryProps {
  funds: OrderFundDto[]
  amount: number
  goNext: () => void
}

const CashOutSummary = ({ funds, amount, goNext }: CashOutSummaryProps) => {
  const { t } = useTranslation(['redemptionPage', 'common'])
  const navigate = useNavigate()

  const {
    fundNamesMapping,
    isLoading: isLoadingFL,
    isError: isErrorFL,
  } = useQueryFundNameMapping()

  useEffect(() => {
    if (isErrorFL) {
      navigate(RoutePathEnum.ERROR)
    }
  }, [isErrorFL])

  const { control, handleSubmit, watch } = useForm<CashOutSummaryFormProps>({
    mode: 'onChange',
    defaultValues: {
      agreeTnc: [],
    },
  })

  const onSubmit = (data: CashOutSummaryFormProps) => {
    console.log('orderFunds: ', data)
    goNext()
  }

  return isLoadingFL ? (
    <Loading isLoadingPage />
  ) : (
    <>
      <h1 className='font-bold'>{t('your-cashout-summary')}</h1>
      <div className='flex flex-col gap-1'>
        {funds.map(({ fundCode, amount, feeAmount = 0 }) => {
          const name = Object.prototype.hasOwnProperty.call(
            fundNamesMapping,
            fundCode
          )
            ? fundNamesMapping[fundCode]
            : ''
          return (
            <div
              className='-mx-4 sm:-mx-6 p-4 sm:px-6 bg-pi-sky-blue-1 flex flex-col gap-4'
              key={fundCode}
            >
              <div className='flex flex-col gap-2 text-sm'>
                <div className='font-bold'>{name}</div>
                <div className='flex flex-col gap-1'>
                  <TwoSideText
                    className=' items-start'
                    left={`${t('cash-out-amount')}:`}
                    right={
                      <span className='font-bold'>
                        {formattedAmount({ amount })}
                      </span>
                    }
                  />
                  <TwoSideText
                    className=' items-start'
                    left={`${t('cash-out-fee')}:`}
                    right={
                      <span className='font-bold'>
                        {formattedAmount({ amount: feeAmount })}
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className='pt-4 flex flex-col gap-6'>
        <TwoSideText
          className='text-xs font-bold items-center'
          left={t('total-cash-out')}
          right={
            <span className='text-[28px] leading-[36px]'>
              {formattedAmount({
                amount: amount,
              })}
            </span>
          }
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='agreeTnc'
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Required',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <TermsAndConditionsComp
                id='agreeTnc'
                variant={TncNoticeVariantEnum.Transition}
                {...field}
                error={error}
                disabled={false}
              />
            )}
          />

          <Button
            type='submit'
            fullWidth
            variant='contained'
            size='large'
            className='mb-10 sm:mb-2'
            disabled={!watch('agreeTnc').length}
          >
            {t('Continue', { ns: 'common' })}
          </Button>
        </form>
      </div>
    </>
  )
}

export default CashOutSummary
