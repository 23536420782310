import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { RoutePathEnum } from '@/constants/routePath'
import Loading from '@/components/Loading'
import SwitchOut from './subpages/SwitchOut'
import SwitchIn from './subpages/SwitchIn'
import {
  FundInformation,
  SwitchingOrderInstructionQueryResDto,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import HelmetSiteTitle from '@/components/HelmetSiteTitle'
import { useTranslation } from 'react-i18next'
import { useSwitchingDataReducer } from '@/hooks/useSwitchingDataReducer'
import { useInitSwitchingData } from '@/hooks/useInitSwitchingData'

export enum SwitchActionEnum {
  SWITCH_IN = 'switch_in',
  SWITCH_OUT = 'switch_out',
}

const Switching = () => {
  const { t } = useTranslation('SwitchingPage')
  const navigate = useNavigate()
  const { state } = useLocation()
  const {
    productId,
    subaccountNo,
    funds = [],
    changeIndex = 0,
    agentCode,
    subaccountStatus,
    action = SwitchActionEnum.SWITCH_OUT,
  } = state || {}

  const [switchAction, setSwitchAction] = useState<SwitchActionEnum>(action)

  const { switchingData, setSwitchingData, setSwitchingFunds } =
    useSwitchingDataReducer({
      initData: {
        productId,
        subaccountNo,
        funds,
        changeIndex,
        agentCode,
        subaccountStatus,
      },
    })

  const { isLoading, isError } = useInitSwitchingData()

  const goToSwitchIn = (
    instructionData: SwitchingOrderInstructionQueryResDto
  ) => {
    const switchingFunds = instructionData.funds.map((fund) => {
      return {
        from: {
          ...fund,
        },
        to: {
          fundCode: '',
          amount: 0,
          availableAmount: 0,
          maxAmount: 0,
          minAmount: 0,
          feePercentage: 0,
        },
      }
    })
    setSwitchingData({
      ...instructionData,
      agentCode: instructionData.lastAgent,
      funds: switchingFunds,
    })
    setSwitchAction(SwitchActionEnum.SWITCH_IN)
  }

  const onSubmitSwitching = (selectedFund: FundInformation) => {
    const switchingFunds = [...switchingData.funds]
    if (switchingFunds.length > 0) {
      switchingFunds[changeIndex].to.fundCode = selectedFund.code
      switchingFunds[changeIndex].from.amount = 0
      switchingFunds[changeIndex].to.amount = 0
      switchingFunds[changeIndex].to.minAmount = selectedFund.minSubsAmt || 0
      switchingFunds[changeIndex].to.feePercentage =
        selectedFund.salesFeePerc || 0
      setSwitchingFunds({ funds: [...switchingFunds] })
    }
  }

  useEffect(() => {
    if (!!isError) {
      navigate(RoutePathEnum.ERROR)
    }
  }, [isError])

  return (
    <>
      <HelmetSiteTitle pageTitle={t('switching')} />
      {isLoading ? (
        <Loading isLoadingPage />
      ) : switchAction === SwitchActionEnum.SWITCH_OUT ? (
        <SwitchOut {...switchingData} goToSwitchIn={goToSwitchIn} />
      ) : (
        <SwitchIn {...switchingData} onSubmitSwitching={onSubmitSwitching} />
      )}
    </>
  )
}

export default Switching
