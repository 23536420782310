import { ReactComponent as ChevronUpIco } from '@/assets/chevron-up.svg'
import { ReactComponent as CrossIco } from '@/assets/cross-ico.svg'
import { ReactComponent as TickIco } from '@/assets/tick-ico.svg'
import { NAV_DECIMAL } from '@/constants'
import {
  ADJUSTED_CASH_IN,
  ADJUSTED_CASH_OUT,
  ADJUSTED_SWITCH_IN,
  ADJUSTED_SWITCH_OUT,
  COMPLETED_ORDER_TYPES,
  DISTRIBUTION_TRANSACTION_TYPES,
  FAILED_FINAL_STATUS,
  FAILED_ORDER_STATUS_LIST,
  PENDING_PAYMENT,
  PENDING_REFUND,
  REFUND_COMPLETED,
  REVERSE_CASH_IN,
  REVERSE_CASH_OUT,
  REVERSE_COMPLETED,
  REVERSE_SWITCH_IN,
  REVERSE_SWITCH_OUT,
  SWITCHING_FINAL_STATUS,
  SWITCHING_TRANSACTION_TYPES,
  TimeLineStatusMapping,
  TransactionTypeEnum,
  TRANSFER_IN_TRANSACTION_TYPES,
  TRANSFER_OUT_TRANSACTION_TYPES,
  UNIT_PRICE_ADJUSTMENT,
} from '@/utils/transactionHistory'
import {
  OrderStatusEnum,
  OrderTypeEnum,
  TransactionHistoryItem,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { CSLink, formattedAmount } from '@pimy-b2cweb/frontend-lib'
import dayjs from 'dayjs'
import { PropsWithChildren, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

export interface TransactionHistoryItemCardProps
  extends Pick<TransactionHistoryItem, 'type'> {
  transactionType: TransactionTypeEnum
  name: string
  transferredFundName: string
  amount: number
  status: OrderStatusEnum
  submittedStatus?: Pick<
    TransactionHistoryItem,
    'trxType' | 'amount' | 'feeAmount' | 'salesChargePercentage'
  > & {
    date?: string
  }
  processingStatus?: {
    date?: string
  }
  resultStatus?: Pick<
    TransactionHistoryItem,
    | 'amount'
    | 'feeAmount'
    | 'salesChargeAmount'
    | 'salesChargePercentage'
    | 'completedAmount'
    | 'unit'
    | 'nav'
    | 'refundCompleted'
  > & {
    isFullCashOut?: boolean
    date?: string
  }
  correctionStatus?: Pick<
    TransactionHistoryItem,
    | 'amount'
    | 'completedAmount'
    | 'navDate'
    | 'salesChargePercentage'
    | 'feeAmount'
    | 'unit'
  > & {
    date?: string
    unitPrice?: number
    reqAmount?: number
  }
  reversalStatus?: {
    date?: string
  }
  unitPriceAdjustment?: Pick<
    TransactionHistoryItem,
    'adjustmentUnit' | 'adjustmentNav' | 'adjustmentAmount' | 'amount'
  > & {
    date?: string
  }
  refundStatus?: Pick<TransactionHistoryItem, 'refundUnit' | 'refundNav'> & {
    date?: string
  }
}

const checkIfPendingPayment = (
  transactionType: TransactionTypeEnum,
  status: OrderStatusEnum
) =>
  transactionType === TransactionTypeEnum.CashOut &&
  status === OrderStatusEnum.PAYOUT_REJECTED

const checkIfReverseCompleted = (
  reversalStatus: TransactionHistoryItemCardProps['reversalStatus'],
  correctionStatus: TransactionHistoryItemCardProps['correctionStatus']
) => {
  return (
    reversalStatus?.date !== undefined && !getCorrectionStatus(correctionStatus)
  )
}

const getCorrectionStatus = (
  correctionStatus: TransactionHistoryItemCardProps['correctionStatus']
) => {
  return (
    correctionStatus?.amount !== undefined ||
    correctionStatus?.completedAmount !== undefined ||
    correctionStatus?.salesChargePercentage !== undefined ||
    correctionStatus?.feeAmount !== undefined ||
    correctionStatus?.unit !== undefined ||
    correctionStatus?.unitPrice !== undefined
  )
}

const getRefundStatus = (
  resultStatus: TransactionHistoryItemCardProps['resultStatus']
) => (!!resultStatus?.refundCompleted ? REFUND_COMPLETED : PENDING_REFUND)

export const TransactionHistoryItemCard = (
  props: TransactionHistoryItemCardProps
) => {
  const { transactionType, name, status, resultStatus } = props
  const { t } = useTranslation('transactionHistoryPage')
  const [isOpen, setIsOpen] = useState(false)
  const transactionStatus = checkIfReverseCompleted(
    props.reversalStatus,
    props.correctionStatus
  )
    ? REVERSE_COMPLETED
    : checkIfPendingPayment(transactionType, status)
    ? PENDING_PAYMENT
    : status

  return (
    <div
      className='bg-white p-4 cursor-pointer'
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className='grid grid-cols-[auto_auto_24px] gap-4 w-full'>
        <h4 className='font-bold text-base m-0'>{t(transactionType)}</h4>
        <Amount {...props} />
        <div>
          <ChevronUpIco
            className={`w-6 h-6 transform transition-transform duration-300 ${
              isOpen ? '' : 'rotate-180'
            }`}
          />
        </div>
        <div className='text-xs mb-0 text-pi-gray-3'>{name}</div>
        <div className='mb-0'>
          <div className='flex flex-col text-xs text-pi-gray-3 text-right'>
            <div
              className={`${
                !FAILED_ORDER_STATUS_LIST.includes(status)
                  ? 'text-pi-gray-3'
                  : 'text-pi-utility-red'
              }`}
            >
              {t(TimeLineStatusMapping[transactionStatus] || '')}
            </div>
            {SWITCHING_TRANSACTION_TYPES.includes(transactionType) &&
              FAILED_ORDER_STATUS_LIST.includes(status) && (
                <div className='text-xs text-right'>
                  {t(TimeLineStatusMapping[getRefundStatus(resultStatus)])}
                </div>
              )}
          </div>
        </div>
      </div>
      <div
        className={`overflow-hidden h-auto transition-max-height duration-300 ${
          isOpen ? 'max-h-[100vh]' : 'max-h-0'
        }`}
      >
        <TimeLine {...props} />
      </div>
    </div>
  )
}

const Amount = ({
  status,
  transactionType,
  amount,
}: {
  status: OrderStatusEnum
  transactionType: TransactionTypeEnum
  amount: number
}) => {
  const textColor = TRANSFER_IN_TRANSACTION_TYPES.includes(transactionType)
    ? 'text-pi-utility-green'
    : DISTRIBUTION_TRANSACTION_TYPES.includes(transactionType)
    ? 'text-pi-principal-blue'
    : ''

  const amountSign = TRANSFER_OUT_TRANSACTION_TYPES.includes(transactionType)
    ? '-'
    : '+'

  const hideAmount =
    transactionType == TransactionTypeEnum.SwitchIn &&
    !SWITCHING_FINAL_STATUS.includes(status)

  return (
    <div className={`${textColor} font-bold text-right`}>
      {hideAmount ? '-' : `${amountSign} ${formattedAmount({ amount })}`}
    </div>
  )
}

const TimeLine = ({
  name,
  type,
  status,
  transactionType,
  submittedStatus,
  processingStatus,
  resultStatus,
  unitPriceAdjustment,
  transferredFundName,
  refundStatus,
  reversalStatus,
  correctionStatus,
}: Omit<TransactionHistoryItemCardProps, 'amount'>) => {
  const { t } = useTranslation('transactionHistoryPage')

  const isCorrectioStatusData =
    correctionStatus?.amount !== undefined ||
    correctionStatus?.completedAmount !== undefined ||
    correctionStatus?.salesChargePercentage !== undefined ||
    correctionStatus?.feeAmount !== undefined ||
    correctionStatus?.unit !== undefined ||
    correctionStatus?.unitPrice !== undefined

  const finalStatus = checkIfPendingPayment(transactionType, status)
    ? PENDING_PAYMENT
    : FAILED_ORDER_STATUS_LIST.includes(status)
    ? OrderStatusEnum.FAILED
    : OrderStatusEnum.COMPLETED

  const retryTxt =
    finalStatus === OrderStatusEnum.FAILED ? (
      <p className='mb-2 text-sm text-pi-gray-2'>
        {t('please-retry-or-reach-cs')}
      </p>
    ) : finalStatus === PENDING_PAYMENT ? (
      <p className='mb-2 text-sm text-pi-gray-2'>
        <Trans
          t={t}
          i18nKey={'invalid-acc-number-retry'}
          components={{ CS: <CSLink /> }}
        />
      </p>
    ) : null

  switch (transactionType) {
    case TransactionTypeEnum.CashIn:
      return (
        <>
          <TimeLineItem
            statusItem={OrderStatusEnum.PENDING_PAYMENT}
            {...submittedStatus}
          >
            <div className='text-sm text-pi-gray-2'>
              {`${t('transaction-method')}: ${
                !!submittedStatus?.trxType ? t(submittedStatus.trxType) : ''
              }`}
            </div>
          </TimeLineItem>
          <TimeLineItem
            statusItem={OrderStatusEnum.SETTLED}
            {...processingStatus}
          >
            {null}
          </TimeLineItem>
          <TimeLineItem
            statusItem={finalStatus}
            {...resultStatus}
            displayTime={false}
          >
            {!!resultStatus && (
              <>
                {retryTxt}
                {status === OrderStatusEnum.COMPLETED && (
                  <div className='grid grid-cols-2 gap-2 text-sm text-pi-gray-2'>
                    {!!resultStatus.amount && (
                      <>
                        <div>{t('total-cash-in')}:</div>
                        <div className='text-right'>
                          {formattedAmount({
                            amount: resultStatus.amount,
                          })}
                        </div>
                      </>
                    )}
                    {resultStatus.salesChargeAmount !== undefined && (
                      <>
                        <div>
                          {t('sales-charge', {
                            sales_charge_percentage:
                              resultStatus.salesChargePercentage || 0,
                          })}
                          :
                        </div>
                        <div className='text-right'>
                          {formattedAmount({
                            amount: resultStatus.salesChargeAmount,
                          })}
                        </div>
                      </>
                    )}
                    {resultStatus.completedAmount !== undefined && (
                      <>
                        <div className=' font-bold'>{t('net-amount')}:</div>
                        <div className='text-right font-bold'>
                          {formattedAmount({
                            amount: resultStatus.completedAmount,
                          })}
                        </div>
                      </>
                    )}
                    {resultStatus.unit !== undefined &&
                      resultStatus.nav !== undefined && (
                        <>
                          <div>
                            {`${t('unit')}: ${formattedAmount({
                              amount: resultStatus.unit,
                              currency: '',
                            })}`}
                          </div>
                          <div className='text-right'>
                            {`${t('unit-price')}: ${formattedAmount({
                              amount: resultStatus.nav,
                              decimal: NAV_DECIMAL,
                            })}`}
                          </div>
                        </>
                      )}
                  </div>
                )}
              </>
            )}
          </TimeLineItem>

          {!!unitPriceAdjustment && (
            <TimeLineItem
              statusItem={UNIT_PRICE_ADJUSTMENT}
              {...unitPriceAdjustment}
              displayTime={false}
            >
              <div className='grid grid-cols-2 gap-2 text-sm text-pi-gray-2'>
                {unitPriceAdjustment.adjustmentUnit !== undefined &&
                  unitPriceAdjustment.adjustmentNav !== undefined && (
                    <>
                      <div>
                        {`${t('unit')}: ${formattedAmount({
                          amount: unitPriceAdjustment.adjustmentUnit,
                          currency: '',
                        })}`}
                      </div>
                      <div className='text-right'>
                        {`${t('unit-price')}: ${formattedAmount({
                          amount: unitPriceAdjustment.adjustmentNav,
                          decimal: NAV_DECIMAL,
                        })}`}
                      </div>
                    </>
                  )}
              </div>
            </TimeLineItem>
          )}
          {reversalStatus && reversalStatus.date !== undefined && (
            <TimeLineItem
              statusItem={REVERSE_CASH_IN}
              {...reversalStatus}
              displayTime={false}
            >
              {null}
            </TimeLineItem>
          )}

          {isCorrectioStatusData && (
            <TimeLineItem
              statusItem={ADJUSTED_CASH_IN}
              {...correctionStatus}
              displayTime={false}
            >
              {isCorrectioStatusData && (
                <>
                  <div className='grid grid-cols-2 gap-2 text-sm text-pi-gray-2'>
                    {correctionStatus.reqAmount !== undefined && (
                      <>
                        <div>{t('request-cash-in')}:</div>
                        <div className='text-right'>
                          {formattedAmount({
                            amount: correctionStatus.reqAmount,
                          })}
                        </div>
                      </>
                    )}
                    {correctionStatus.completedAmount !== undefined && (
                      <>
                        <div>{t('adjusted-cash-in')}:</div>
                        <div className='text-right'>
                          {formattedAmount({
                            amount: correctionStatus.completedAmount,
                          })}
                        </div>
                      </>
                    )}
                    {!!correctionStatus.salesChargePercentage &&
                      !!correctionStatus?.feeAmount && (
                        <>
                          <div>
                            {t('sales-charge', {
                              sales_charge_percentage:
                                correctionStatus.salesChargePercentage,
                            })}
                            :
                          </div>
                          <div className='text-right'>
                            {formattedAmount({
                              amount: correctionStatus.feeAmount,
                            })}
                          </div>
                        </>
                      )}
                    {correctionStatus.completedAmount !== undefined && (
                      <>
                        <div className=' font-bold'>{t('net-amount')}:</div>
                        <div className='text-right font-bold'>
                          {formattedAmount({
                            amount: correctionStatus.completedAmount,
                          })}
                        </div>
                      </>
                    )}
                    {correctionStatus.unit !== undefined &&
                      correctionStatus.unitPrice !== undefined && (
                        <>
                          <div>
                            {`${t('unit')}: ${formattedAmount({
                              amount: correctionStatus.unit,
                              currency: '',
                            })}`}
                          </div>
                          <div className='text-right'>
                            {`${t('unit-price')}: ${formattedAmount({
                              amount: correctionStatus.unitPrice,
                              decimal: NAV_DECIMAL,
                            })}`}
                          </div>
                        </>
                      )}
                  </div>
                </>
              )}
            </TimeLineItem>
          )}
        </>
      )
    case TransactionTypeEnum.CashOut:
      return (
        <>
          <TimeLineItem
            statusItem={OrderStatusEnum.PENDING_PAYMENT}
            {...submittedStatus}
            displayTime={type !== OrderTypeEnum.ManSell}
          >
            <div className='text-sm text-pi-gray-2'>
              {`${t('transaction-method')}: ${
                !!submittedStatus?.trxType ? t(submittedStatus.trxType) : ''
              }`}
            </div>
            {!!submittedStatus?.amount && (
              <div className='text-sm text-pi-gray-2'>
                {`${t('cash-out-amount')}: ${formattedAmount({
                  amount: submittedStatus.amount,
                })}`}
              </div>
            )}
            {!!submittedStatus?.feeAmount && (
              <div className='text-sm text-pi-gray-2'>
                {`${t('administration-fee')}: ${formattedAmount({
                  amount: submittedStatus.feeAmount,
                })}`}
              </div>
            )}
          </TimeLineItem>
          <TimeLineItem
            statusItem={OrderStatusEnum.SETTLED}
            {...processingStatus}
            displayTime={type !== OrderTypeEnum.ManSell}
          >
            {null}
          </TimeLineItem>
          <TimeLineItem
            statusItem={finalStatus}
            {...resultStatus}
            displayTime={false}
          >
            {!!resultStatus && (
              <>
                {retryTxt}
                {!retryTxt && !!resultStatus.isFullCashOut && (
                  <p className='mb-2 text-pi-gray-2'>
                    {t('full-cash-out-text')}
                  </p>
                )}
                {status === OrderStatusEnum.WITHDRAWAL_COMPLETED && (
                  <div className='grid grid-cols-2 gap-2 text-sm text-pi-gray-2'>
                    {resultStatus.unit !== undefined &&
                      resultStatus.nav !== undefined && (
                        <>
                          <div>
                            {`${t('unit')}: ${formattedAmount({
                              amount: resultStatus.unit,
                              currency: '',
                            })}`}
                          </div>
                          <div className='text-right'>
                            {`${t('unit-price')}: ${formattedAmount({
                              amount: resultStatus.nav,
                              decimal: NAV_DECIMAL,
                            })}`}
                          </div>
                        </>
                      )}
                  </div>
                )}
              </>
            )}
          </TimeLineItem>
          {!!unitPriceAdjustment && (
            <TimeLineItem
              statusItem={UNIT_PRICE_ADJUSTMENT}
              {...unitPriceAdjustment}
              displayTime={false}
            >
              <div className='grid grid-cols-2 gap-2 text-sm text-pi-gray-2'>
                {unitPriceAdjustment.adjustmentUnit !== undefined &&
                  unitPriceAdjustment.adjustmentNav !== undefined && (
                    <>
                      <div>
                        {`${t('unit')}: ${formattedAmount({
                          amount: unitPriceAdjustment.adjustmentUnit,
                          currency: '',
                        })}`}
                      </div>
                      <div className='text-right'>
                        {`${t('unit-price')}: ${formattedAmount({
                          amount: unitPriceAdjustment.adjustmentNav,
                          decimal: NAV_DECIMAL,
                        })}`}
                      </div>
                    </>
                  )}
                {unitPriceAdjustment?.amount !== undefined && (
                  <>
                    <div>{t('request-cash-out')}:</div>
                    <div className='text-right'>
                      {formattedAmount({
                        amount: unitPriceAdjustment?.amount,
                      })}
                    </div>
                  </>
                )}
                {unitPriceAdjustment?.adjustmentAmount !== undefined && (
                  <>
                    <div>{t('adjusted-cash-out')}:</div>
                    <div className='text-right'>
                      {formattedAmount({
                        amount: unitPriceAdjustment?.adjustmentAmount,
                      })}
                    </div>
                  </>
                )}
              </div>
            </TimeLineItem>
          )}
          {reversalStatus && reversalStatus.date !== undefined && (
            <TimeLineItem
              statusItem={REVERSE_CASH_OUT}
              {...reversalStatus}
              displayTime={false}
            >
              {null}
            </TimeLineItem>
          )}

          {isCorrectioStatusData && (
            <TimeLineItem
              statusItem={ADJUSTED_CASH_OUT}
              {...correctionStatus}
              displayTime={false}
            >
              {isCorrectioStatusData && (
                <>
                  <div className='grid grid-cols-2 gap-2 text-sm text-pi-gray-2'>
                    {correctionStatus.reqAmount !== undefined && (
                      <>
                        <div>{t('request-cash-out')}:</div>
                        <div className='text-right'>
                          {formattedAmount({
                            amount: correctionStatus.reqAmount,
                          })}
                        </div>
                      </>
                    )}
                    {correctionStatus.completedAmount !== undefined && (
                      <>
                        <div>{t('adjusted-cash-out')}:</div>
                        <div className='text-right'>
                          {formattedAmount({
                            amount: correctionStatus.completedAmount,
                          })}
                        </div>
                      </>
                    )}
                    {!!correctionStatus.salesChargePercentage &&
                      !!correctionStatus?.feeAmount && (
                        <>
                          <div>
                            {t('sales-charge', {
                              sales_charge_percentage:
                                correctionStatus.salesChargePercentage,
                            })}
                            :
                          </div>
                          <div className='text-right'>
                            {formattedAmount({
                              amount: correctionStatus.feeAmount,
                            })}
                          </div>
                        </>
                      )}
                    {correctionStatus.unit !== undefined &&
                      correctionStatus.unitPrice !== undefined && (
                        <>
                          <div>
                            {`${t('unit')}: ${formattedAmount({
                              amount: correctionStatus.unit,
                              currency: '',
                            })}`}
                          </div>
                          <div className='text-right'>
                            {`${t('unit-price')}: ${formattedAmount({
                              amount: correctionStatus.unitPrice,
                              decimal: NAV_DECIMAL,
                            })}`}
                          </div>
                        </>
                      )}
                  </div>
                </>
              )}
            </TimeLineItem>
          )}
        </>
      )
    case TransactionTypeEnum.IncomeDistribution:
    case TransactionTypeEnum.BonusDistribution:
      return (
        <TimeLineItem
          statusItem={
            status === OrderStatusEnum.FAILED
              ? status
              : OrderStatusEnum.COMPLETED
          }
          {...resultStatus}
          displayTime={false}
        >
          {status === OrderStatusEnum.COMPLETED && (
            <div className='grid grid-cols-2 gap-2 text-sm text-pi-gray-2'>
              {resultStatus?.unit !== undefined &&
                resultStatus?.nav !== undefined && (
                  <>
                    <div>
                      {t('unit')}:{' '}
                      {formattedAmount({
                        amount: resultStatus.unit,
                        currency: '',
                      })}
                    </div>
                    <div className='text-right'>
                      {t('unit-price')}:{' '}
                      {formattedAmount({
                        amount: resultStatus.nav,
                        decimal: NAV_DECIMAL,
                      })}
                    </div>
                  </>
                )}
            </div>
          )}
        </TimeLineItem>
      )
    case TransactionTypeEnum.CoolingOff:
      return (
        <>
          <TimeLineItem
            statusItem={OrderStatusEnum.PENDING_PAYMENT}
            {...submittedStatus}
            displayTime={false}
          >
            <div className='text-sm text-pi-gray-2'>
              {`${t('transaction-method')}: ${
                !!submittedStatus?.trxType ? t(submittedStatus?.trxType) : ''
              }`}
            </div>
            {!!submittedStatus?.amount && (
              <div className='text-sm text-pi-gray-2'>
                {`${t('cooling-off-amount')}: ${formattedAmount({
                  amount: submittedStatus.amount,
                })}`}
              </div>
            )}
          </TimeLineItem>
          <TimeLineItem
            statusItem={OrderStatusEnum.SETTLED}
            {...processingStatus}
            displayTime={false}
          >
            {null}
          </TimeLineItem>
          <TimeLineItem
            statusItem={finalStatus}
            {...resultStatus}
            displayTime={false}
          >
            {!!resultStatus && (
              <>
                {retryTxt}
                {status === OrderStatusEnum.WITHDRAWAL_COMPLETED && (
                  <div className='grid grid-cols-2 gap-2 text-sm text-pi-gray-2'>
                    {resultStatus.unit !== undefined &&
                      resultStatus.nav !== undefined && (
                        <>
                          <div>
                            {t('unit')}:{' '}
                            {formattedAmount({
                              amount: resultStatus.unit,
                              currency: '',
                            })}
                          </div>
                          <div className='text-right'>
                            {t('unit-price')}:{' '}
                            {formattedAmount({
                              amount: resultStatus.nav,
                              decimal: NAV_DECIMAL,
                            })}
                          </div>
                        </>
                      )}
                  </div>
                )}
              </>
            )}
          </TimeLineItem>
          {/* TODO: pending for confirmation
          {!!unitPriceAdjustment && (
            <TimeLineItem
              status={TransactionHistoryStatusEnum.UnitPriceAdjustment}
              {...unitPriceAdjustment}
              displayTime={false}
            >
              <div className='grid grid-cols-2 gap-2 text-sm text-pi-gray-2'>
                {unitPriceAdjustment.unit !== undefined &&
                  unitPriceAdjustment.unitPrice !== undefined && (
                    <>
                      <div>
                        {t('unit')}:{' '}
                        {formattedAmount({
                          amount: unitPriceAdjustment.unit,
                          currency: '',
                        })}
                      </div>
                      <div className='text-right'>
                        {t('unit-price')}:{' '}
                        {formattedAmount({
                          amount: unitPriceAdjustment.unitPrice,
                          decimal: NAV_DECIMAL,
                        })}
                      </div>
                    </>
                  )}
              </div>
            </TimeLineItem>
          )} */}
        </>
      )
    case TransactionTypeEnum.SwitchOut:
      return (
        <>
          <TimeLineItem
            statusItem={OrderStatusEnum.PENDING_PAYMENT}
            {...submittedStatus}
          />
          <TimeLineItem
            statusItem={OrderStatusEnum.SETTLED}
            {...processingStatus}
          >
            {!!processingStatus?.date && (
              <div className='text-sm text-pi-gray-2'>
                {t('your-switch-out-processed')}
              </div>
            )}
          </TimeLineItem>
          <TimeLineItem
            statusItem={finalStatus}
            {...resultStatus}
            displayTime={false}
          >
            {!!resultStatus && (
              <>
                {finalStatus === OrderStatusEnum.FAILED && (
                  <div className='text-sm text-pi-gray-2'>
                    {t('switch-out-failed')}
                  </div>
                )}

                {COMPLETED_ORDER_TYPES.includes(status) && (
                  <>
                    <div className='text-sm text-pi-gray-2'>
                      {t('your-switch-out-successful', {
                        switch_in_fund: transferredFundName,
                      })}
                    </div>
                    <div className='grid grid-cols-2 gap-2 text-sm text-pi-gray-2'>
                      {resultStatus.unit !== undefined &&
                        resultStatus.nav !== undefined && (
                          <>
                            <div>
                              {t('unit')}:{' '}
                              {formattedAmount({
                                amount: resultStatus.unit,
                                currency: '',
                              })}
                            </div>
                            <div className='text-right'>
                              {t('unit-price')}:{' '}
                              {formattedAmount({
                                amount: resultStatus.nav,
                                decimal: NAV_DECIMAL,
                              })}
                            </div>
                          </>
                        )}
                    </div>
                  </>
                )}
              </>
            )}
          </TimeLineItem>

          {finalStatus === OrderStatusEnum.FAILED && (
            <TimeLineItem
              statusItem={getRefundStatus(resultStatus)}
              {...refundStatus}
              displayTime={false}
            >
              {!resultStatus?.refundCompleted ? (
                <div className='text-sm text-pi-gray-2'>
                  {t('pending-refund-to', {
                    switch_out_fund: name,
                  })}
                </div>
              ) : (
                <>
                  <div className='text-sm text-pi-gray-2'>
                    {t('switch-out-amount-refunded', {
                      switch_out_fund: name,
                    })}
                  </div>
                </>
              )}
            </TimeLineItem>
          )}
          {reversalStatus && reversalStatus.date !== undefined && (
            <TimeLineItem
              statusItem={REVERSE_SWITCH_OUT}
              {...reversalStatus}
              displayTime={false}
            >
              {null}
            </TimeLineItem>
          )}

          {isCorrectioStatusData && (
            <TimeLineItem
              statusItem={ADJUSTED_SWITCH_OUT}
              {...correctionStatus}
              displayTime={false}
            >
              {isCorrectioStatusData && (
                <>
                  <div className='grid grid-cols-2 gap-2 text-sm text-pi-gray-2'>
                    {correctionStatus.reqAmount !== undefined && (
                      <>
                        <div>{t('request-switch-out')}:</div>
                        <div className='text-right'>
                          {formattedAmount({
                            amount: correctionStatus.reqAmount,
                          })}
                        </div>
                      </>
                    )}
                    {correctionStatus.completedAmount !== undefined && (
                      <>
                        <div>{t('adjusted-switch-out')}:</div>
                        <div className='text-right'>
                          {formattedAmount({
                            amount: correctionStatus.completedAmount,
                          })}
                        </div>
                      </>
                    )}
                    {correctionStatus.unit !== undefined &&
                      correctionStatus.unitPrice !== undefined && (
                        <>
                          <div>
                            {`${t('unit')}: ${formattedAmount({
                              amount: correctionStatus.unit,
                              currency: '',
                            })}`}
                          </div>
                          <div className='text-right'>
                            {`${t('unit-price')}: ${formattedAmount({
                              amount: correctionStatus.unitPrice,
                              decimal: NAV_DECIMAL,
                            })}`}
                          </div>
                        </>
                      )}
                  </div>
                </>
              )}
            </TimeLineItem>
          )}
        </>
      )
    case TransactionTypeEnum.SwitchIn:
      return (
        <>
          <TimeLineItem
            statusItem={OrderStatusEnum.PENDING_PAYMENT}
            {...submittedStatus}
          />
          <TimeLineItem
            statusItem={OrderStatusEnum.SETTLED}
            {...processingStatus}
          >
            {!!processingStatus?.date && (
              <div className='text-sm text-pi-gray-2'>
                {t('your-switch-in-processed')}
              </div>
            )}
          </TimeLineItem>
          <TimeLineItem
            statusItem={finalStatus}
            {...resultStatus}
            displayTime={false}
          >
            {!!resultStatus && (
              <>
                {finalStatus === OrderStatusEnum.FAILED && (
                  <div className='text-sm text-pi-gray-2'>
                    {t('switch-in-failed')}
                  </div>
                )}

                {COMPLETED_ORDER_TYPES.includes(status) && (
                  <div className='grid grid-cols-2 gap-2 text-sm text-pi-gray-2'>
                    {resultStatus.amount !== undefined && (
                      <>
                        <div>{t('initial-switch-in')}:</div>
                        <div className='text-right'>
                          {formattedAmount({
                            amount: resultStatus.amount,
                          })}
                        </div>
                      </>
                    )}
                    {resultStatus.salesChargeAmount !== undefined && (
                      <>
                        <div>
                          {t('sales-charge', {
                            sales_charge_percentage:
                              resultStatus.salesChargePercentage || 0,
                          })}
                          :
                        </div>
                        <div className='text-right'>
                          {formattedAmount({
                            amount: resultStatus.salesChargeAmount,
                          })}
                        </div>
                      </>
                    )}
                    {resultStatus.completedAmount !== undefined && (
                      <>
                        <div className=' font-bold'>{t('net-switch-in')}:</div>
                        <div className='text-right font-bold'>
                          {formattedAmount({
                            amount: resultStatus.completedAmount,
                          })}
                        </div>
                      </>
                    )}
                    {resultStatus.unit !== undefined &&
                      resultStatus.nav !== undefined && (
                        <>
                          <div>
                            {`${t('unit')}: ${formattedAmount({
                              amount: resultStatus.unit,
                              currency: '',
                            })}`}
                          </div>
                          <div className='text-right'>
                            {`${t('unit-price')}: ${formattedAmount({
                              amount: resultStatus.nav,
                              decimal: NAV_DECIMAL,
                            })}`}
                          </div>
                        </>
                      )}
                  </div>
                )}
              </>
            )}
          </TimeLineItem>

          {finalStatus === OrderStatusEnum.FAILED && (
            <TimeLineItem
              statusItem={getRefundStatus(resultStatus)}
              {...refundStatus}
              displayTime={false}
            >
              {!resultStatus?.refundCompleted ? (
                <div className='text-sm text-pi-gray-2'>
                  {t('pending-refund-to', {
                    switch_out_fund: transferredFundName,
                  })}
                </div>
              ) : (
                <div className='text-sm text-pi-gray-2'>
                  {t('switch-in-amount-refunded', {
                    switch_out_fund: transferredFundName,
                  })}
                </div>
              )}
            </TimeLineItem>
          )}
          {reversalStatus && reversalStatus.date !== undefined && (
            <TimeLineItem
              statusItem={REVERSE_SWITCH_IN}
              {...reversalStatus}
              displayTime={false}
            >
              {null}
            </TimeLineItem>
          )}

          {isCorrectioStatusData && (
            <TimeLineItem
              statusItem={ADJUSTED_SWITCH_IN}
              {...correctionStatus}
              displayTime={false}
            >
              {isCorrectioStatusData && (
                <>
                  <div className='grid grid-cols-2 gap-2 text-sm text-pi-gray-2'>
                    {correctionStatus.reqAmount !== undefined && (
                      <>
                        <div>{t('request-switch-in')}:</div>
                        <div className='text-right'>
                          {formattedAmount({
                            amount: correctionStatus.reqAmount,
                          })}
                        </div>
                      </>
                    )}
                    {correctionStatus.completedAmount !== undefined && (
                      <>
                        <div>{t('adjusted-switch-in')}:</div>
                        <div className='text-right'>
                          {formattedAmount({
                            amount: correctionStatus.completedAmount,
                          })}
                        </div>
                      </>
                    )}
                    {!!correctionStatus.salesChargePercentage &&
                      !!correctionStatus?.feeAmount && (
                        <>
                          <div>
                            {t('sales-charge', {
                              sales_charge_percentage:
                                correctionStatus.salesChargePercentage,
                            })}
                            :
                          </div>
                          <div className='text-right'>
                            {formattedAmount({
                              amount: correctionStatus.feeAmount,
                            })}
                          </div>
                        </>
                      )}
                    {correctionStatus.completedAmount !== undefined && (
                      <>
                        <div className=' font-bold'>{t('net-amount')}:</div>
                        <div className='text-right font-bold'>
                          {formattedAmount({
                            amount: correctionStatus.completedAmount,
                          })}
                        </div>
                      </>
                    )}
                    {correctionStatus.unit !== undefined &&
                      correctionStatus.unitPrice !== undefined && (
                        <>
                          <div>
                            {`${t('unit')}: ${formattedAmount({
                              amount: correctionStatus.unit,
                              currency: '',
                            })}`}
                          </div>
                          <div className='text-right'>
                            {`${t('unit-price')}: ${formattedAmount({
                              amount: correctionStatus.unitPrice,
                              decimal: NAV_DECIMAL,
                            })}`}
                          </div>
                        </>
                      )}
                  </div>
                </>
              )}
            </TimeLineItem>
          )}
        </>
      )
    default:
      return null
  }
}

const TimeLineItem = ({
  statusItem: status,
  date,
  children,
  displayTime = true,
}: PropsWithChildren<{
  statusItem: keyof typeof TimeLineStatusMapping
  date?: string
  displayTime?: boolean
}>) => {
  const { t } = useTranslation('transactionHistoryPage')
  const isGrayColor = !date && status !== OrderStatusEnum.FAILED
  return (
    <div
      className={`grid grid-cols-[24px_auto] gap-4 w-full relative first:mt-6 after:content-[""] after:block after:absolute after:w-[2px] after:h-[calc(100%-24px)] after:left-[11px] after:top-6 last:after:content-none pb-2 ${
        isGrayColor ? 'after:bg-pi-gray-3' : 'after:bg-pi-principal-blue'
      }`}
    >
      <div>
        <div
          className={`w-6 h-6 rounded-full flex items-center justify-center ${
            status === PENDING_PAYMENT
              ? 'bg-pi-utility-red'
              : isGrayColor
              ? 'bg-pi-gray-3'
              : status === OrderStatusEnum.FAILED
              ? 'bg-pi-utility-red'
              : 'bg-pi-principal-blue'
          }`}
        >
          {!FAILED_FINAL_STATUS.includes(status) ? (
            <TickIco />
          ) : (
            <CrossIco className='-ml-[1px]' />
          )}
        </div>
      </div>
      <div>
        <h5
          className={`font-bold text-sm m-0 ${
            isGrayColor ? 'text-pi-gray-3' : ''
          }`}
        >
          {t(TimeLineStatusMapping[status] || '')}
        </h5>
        {children}
        {!!date && (
          <p className='mt-2 mb-0 text-sm text-pi-gray-3'>
            {dayjs(date).format(
              displayTime ? 'D MMM YYYY, h:mm A' : 'D MMM YYYY'
            )}
          </p>
        )}
      </div>
    </div>
  )
}

export default TransactionHistoryItemCard
