import { ActionModal, ActionModalProps } from '@pimy-b2cweb/frontend-lib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'
import { IsafStatusEnum } from '@/enums/isafEnums'

const ISAFModal = ({
  isafStatus,
  modalClose,
  ...rest
}: Omit<
  ActionModalProps,
  'title' | 'actions' | 'actionsStyle' | 'closeBtn' | 'onClose' | 'children'
> & {
  isafStatus?: IsafStatusEnum
  modalClose: () => void
}) => {
  const { t } = useTranslation('isafPage')
  const navigate = useNavigate()
  const [triggerNavOnClose, setTriggerNavOnClose] = useState(false)
  useEffect(() => {
    if (!!triggerNavOnClose && !rest.open) navigate(RoutePathEnum.ISAF)
  }, [rest.open, triggerNavOnClose])

  return !!isafStatus && isafStatus !== IsafStatusEnum.Passed ? (
    <ActionModal
      {...rest}
      title={t(`isaf-${isafStatus}`)}
      actions={
        isafStatus === IsafStatusEnum.Unknown
          ? []
          : [
              {
                label: t('proceed-to-isaf-renewal'),
                onClick: () => {
                  setTriggerNavOnClose(true)
                  modalClose()
                },
                variant: 'contained',
              },
            ]
      }
      actionsStyle='full'
      closeBtn={true}
      onClose={modalClose}
    >
      <p className='mb-0'>{t(`isaf-${isafStatus}-description`)}</p>
    </ActionModal>
  ) : null
}

export default ISAFModal
