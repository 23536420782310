import { useEffect, useMemo } from 'react'
import { useModal, useResponseError } from '@pimy-b2cweb/frontend-lib'
import { useMutationVerifyOtp } from '@/api/commonApi'
import VerifyOtpUi, { VerifyOtpFormProps } from '@/components/VerifyOtpUi'
import { useResendOtp } from '@/hooks/useResendOtp'
import { getErrorResponseCode } from '@/utils'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuthSessionState } from '@/stores/auth.selectors'
import { sessionResetVerifyOtp } from '@/stores/auth'
import { useMutationConfirmRedemptionOrderInstruction } from '@/api/orderApi'
import { OrderCreateResultResDto } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import TechnicalErrorModal from '@/components/TechnicalErrorModal'

export interface VerifyOtpProps {
  goNext: () => void
  goToOrderForm: () => void
  setTrxDetails: (trxDetails: OrderCreateResultResDto) => void
}
export const VerifyOtp = ({
  goNext,
  goToOrderForm,
  setTrxDetails,
}: VerifyOtpProps) => {
  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()
  const dispatch = useDispatch()

  const { notificationRecipient, canResend } = useSelector(
    selectAuthSessionState
  )

  const technicalErrorModal = useModal()

  const {
    mutate: mutateVO,
    isLoading: mutateIsLoadingVO,
    status: mutateStatusVO,
    data: mutatedDataVO,
    error: mutatedErrorVO,
  } = useMutationVerifyOtp()

  const {
    mutate: mutateRO,
    isLoading: mutateIsLoadingRO,
    status: mutateStatusRO,
    data: mutatedDataRO,
    error: mutatedErrorRO,
  } = useMutationConfirmRedemptionOrderInstruction()

  const { onResetResendError, ...useResendOtpRest } = useResendOtp({
    initCanResend: canResend !== false,
    handleResetPageError: () => setResponseErrorAttrs(undefined),
  })

  const onSubmit = async (data: VerifyOtpFormProps) => {
    console.log(data)
    setResponseErrorAttrs(undefined)
    onResetResendError()
    mutateVO(data)
  }

  useEffect(() => {
    if (mutateStatusVO === 'error') {
      const errMsg = getErrorResponseCode(mutatedErrorVO)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)

    if (mutateStatusVO === 'success' && !!mutatedDataVO) {
      console.log('mutatedDataVO: ', mutatedDataVO)
      mutateRO({ requestToken: mutatedDataVO.sessionToken })
    }
  }, [mutateStatusVO, mutatedDataVO, mutatedErrorVO])

  useEffect(() => {
    if (mutateStatusRO === 'error') {
      console.log('mutatedErrorRO: ', mutatedErrorRO)
      technicalErrorModal.modalOpen()
      return
    }

    setResponseErrorAttrs(undefined)

    if (mutateStatusRO === 'success' && !!mutatedDataRO) {
      console.log('mutatedDataRO: ', mutatedDataRO)
      dispatch(sessionResetVerifyOtp())
      setTrxDetails({ ...mutatedDataRO })
      goNext()
    }
  }, [mutateStatusRO, mutatedDataRO, mutatedErrorRO])

  const isLoading = useMemo(
    () => mutateIsLoadingVO || mutateIsLoadingRO,
    [mutateIsLoadingVO, mutateIsLoadingRO]
  )

  return (
    <>
      <VerifyOtpUi
        notificationRecipient={notificationRecipient}
        responseErrorAttrs={responseErrorAttrs}
        onSubmit={onSubmit}
        isLoading={isLoading}
        canResend={canResend}
        {...useResendOtpRest}
        operation='continue'
      />
      <TechnicalErrorModal
        {...technicalErrorModal}
        onContinue={goToOrderForm}
      />
    </>
  )
}

export default VerifyOtp
