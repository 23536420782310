import {
  useMutationEditCustomerProfileContactInfo,
  useQueryCustomerProfile,
} from '@/api/customerApi'
import Loading from '@/components/Loading'
import { RoutePathEnum } from '@/constants/routePath'
import PostLogonContainer from '@/layout/PostLogonContainer'
import PostLogonMainBody from '@/layout/PostLogonMainBody'
import { formatMobileNo, getErrorResponseCode } from '@/utils'
import { TextField } from '@mui/material'
import {
  CustomerProfileEditContactInfoReqDto,
  CustomerProfileResDto,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import {
  ButtonWithLoading,
  FormLabel,
  ResponseError,
  TelTextField,
  useResponseError,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { sessionVerifyOtp } from '@/stores/auth'
import { useDispatch } from 'react-redux'

export interface ContactInfoFormProps
  extends CustomerProfileEditContactInfoReqDto,
    Pick<CustomerProfileResDto, 'mobilePhoneNo'> {}

const ContactInfoForm = ({ goToVerifyOtp }: { goToVerifyOtp: () => void }) => {
  const { t } = useTranslation(['profileInfoPage', 'common'])

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    isLoading: queryIsLoading,
    status: queryStatus,
    data: queryData,
    error: queryError,
  } = useQueryCustomerProfile()

  const {
    mutate,
    isLoading: isSubmitting,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationEditCustomerProfileContactInfo()

  const { control, handleSubmit, setValue } = useForm<ContactInfoFormProps>({
    defaultValues: {
      mobilePhoneNo: '',
      email: '',
    },
  })

  useEffect(() => {
    if (queryStatus === 'error') {
      console.log('error:', queryError)
      navigate(RoutePathEnum.ERROR)
      return
    }

    if (queryStatus === 'success' && !!queryData) {
      setValue('mobilePhoneNo', formatMobileNo(queryData.mobilePhoneNo) || '')
      setValue('email', queryData.email || '')
    }
  }, [queryStatus, queryData, queryError])

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      console.log('mutatedData: ', mutatedData)
      dispatch(sessionVerifyOtp({ ...mutatedData }))
      goToVerifyOtp()
    }
  }, [mutatedData, mutateStatus, mutatedError])

  const onSubmit = (data: ContactInfoFormProps) => {
    console.log(data)
    mutate(data)
  }

  return queryIsLoading ? (
    <Loading isLoadingPage />
  ) : (
    <PostLogonMainBody useTransBg={false}>
      <PostLogonContainer>
        <h1 className='font-bold'>{t('contact-info')}</h1>

        {!!responseErrorAttrs && (
          <ResponseError>
            {t(responseErrorAttrs.i18nKey, {
              ns: responseErrorAttrs.ns,
            })}
          </ResponseError>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='mobilePhoneNo'
            control={control}
            defaultValue=''
            render={({ field, fieldState: { error } }) => (
              <FormLabel
                id='mobilePhoneNo'
                label={t('contact-number')}
                isError={!!error}
              >
                <TelTextField
                  fullWidth
                  {...field}
                  error={!!error}
                  helperText={!!error ? (error?.message as string) : ''}
                  disabled
                />
              </FormLabel>
            )}
          />
          <Controller
            name='email'
            control={control}
            defaultValue=''
            rules={{
              required: {
                value: true,
                message: 'Required',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormLabel
                id='email'
                label={t('email')}
                isError={!!error}
                className='!mb-10'
              >
                <TextField
                  type='email'
                  fullWidth
                  {...field}
                  error={!!error}
                  helperText={!!error ? (error?.message as string) : ''}
                  disabled={isSubmitting}
                  autoComplete='off'
                />
              </FormLabel>
            )}
          />

          <ButtonWithLoading
            type='submit'
            fullWidth
            variant='contained'
            size='large'
            className='mb-6'
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            {t('update-profile')}
          </ButtonWithLoading>

          <p className='text-sm text-pi-gray-2s'>
            {t('for-non-editable-info-contact-customer-service', {
              ns: 'common',
            })}
          </p>
        </form>
      </PostLogonContainer>
    </PostLogonMainBody>
  )
}

export default ContactInfoForm
