import { ReactNode } from 'react'

export const TwoSideText = ({
  left,
  right,
  className = '',
}: {
  left: ReactNode
  right: ReactNode
  className?: string
}) => (
  <div className={`two-side-text flex gap-2 ${className}`}>
    <div className='grow'>{left}</div>
    <div className='grow text-right'>{right}</div>
  </div>
)
export default TwoSideText
