import { PropsWithChildren } from 'react'

export const ResponseContainer = ({
  children,
  className = '',
}: PropsWithChildren<{
  className?: string
}>) => (
  <div className={`grid grid-cols-12 gap-6 ${className}`}>
    <div className='response-container !my-0 !pt-0'>{children}</div>
  </div>
)

export default ResponseContainer
