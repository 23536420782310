import ErrorMessageComp from '@/components/ErrorMessageComp'
import FundInfoCard from '@/components/FundInfoCard'
import InvestmentDisclaimer from '@/components/InvestmentDisclaimer/Index'
import Loading from '@/components/Loading'
import RiskProfileStatus, {
  RiskProfileStatusProps,
} from '@/components/RiskProfileStatus'
import Slider from '@/components/Slider'
import { SelectedFund } from '@/hooks/useInvestmentReducer'
import { UseFundSelection } from '../../hooks/useFundSelection'
import { MainBody } from '@pimy-b2cweb/frontend-lib'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { FundListByCategory, FundCategory } from '../..'
import CheckOutInvestmentFooter from '../../components/CheckOutInvestmentFooter'
import RiskProfileTabs, {
  RiskProfileTabsProps,
} from '@/components/RiskProfileTabs'
import TechnicalErrorComp from '@/components/TechnicalErrorComp'

interface FundListRootProps
  extends RiskProfileTabsProps,
    Omit<UseFundSelection, 'selectedFunds'>,
    RiskProfileStatusProps {
  isLoading: boolean
  isError: boolean
  fundListByCategory: FundListByCategory
  openHighRiskModal: (
    fund: Omit<SelectedFund, 'amount'>,
    selectCallback: (state: boolean) => void
  ) => void
}

const FundListRoot = ({
  isLoading,
  isError,
  fundListByCategory,
  selectedFundCodes,
  selectedFundCount,
  riskProfileName,
  totalAssetsAmt,
  profitLoss,
  onDeselectFund,
  goExternalEpf,
  onCheckOut,
  openHighRiskModal,
  ...propsRest
}: FundListRootProps) => {
  const { t } = useTranslation(['investmentPage', 'riskProfiles', 'common'])

  const checkHighRiskFund = (
    fund: Omit<SelectedFund, 'amount'>,
    selectCallback: (state: boolean) => void
  ) => {
    openHighRiskModal(fund, selectCallback)
  }

  return (
    <>
      <RiskProfileStatus
        riskProfileName={riskProfileName}
        totalAssetsAmt={totalAssetsAmt}
        profitLoss={profitLoss}
      />
      <MainBody>
        <RiskProfileTabs {...propsRest} />
        {isLoading ? (
          <Loading className='min-h-[calc(100vh-450px)]' />
        ) : isError ? (
          <TechnicalErrorComp className='!min-h-[calc(100vh-450px)]' />
        ) : fundListByCategory[FundCategory.EPF].length ||
          fundListByCategory[FundCategory.Cash].length ? (
          <>
            {Object.entries(fundListByCategory)?.map(
              ([category, funds]) =>
                !!funds.length && (
                  <Fragment key={category}>
                    <div className='col-span-full flex flex-row justify-between items-center'>
                      <div className='font-bold leading-6'>
                        {t(`category-${category}`)} ({funds.length})
                      </div>
                      <Link to={category}>{t('view-all')}</Link>
                    </div>
                    <Slider>
                      {funds.map(({ code, name, fundInfoUrl, ...rest }) => (
                        <FundInfoCard
                          className='flex-none w-[80vw] xs:w-[67vw] md:w-[38vw] lg:w-[288px] max-w-[310px] md:max-w-[288px] h-full'
                          key={code}
                          name={name}
                          fundInfoUrl={fundInfoUrl}
                          {...rest}
                          onSelectFund={(
                            selectCallback: (state: boolean) => void
                          ) =>
                            rest.isEPFFund
                              ? goExternalEpf()
                              : checkHighRiskFund(
                                  {
                                    code,
                                    name,
                                    ...rest,
                                  },
                                  selectCallback
                                )
                          }
                          onDeselectFund={() =>
                            rest.isEPFFund
                              ? goExternalEpf()
                              : onDeselectFund(code)
                          }
                          hasSelected={selectedFundCodes.includes(code)}
                        />
                      ))}
                    </Slider>
                  </Fragment>
                )
            )}
            <InvestmentDisclaimer className='col-span-full' />
          </>
        ) : (
          <ErrorMessageComp
            className='col-span-full min-h-[calc(100vh-450px)] sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 lg:col-start-5 lg:col-span-4 flex justify-center items-center gap-2 [&>h1]:!mb-0 [&>h1]:!text-xl [&>h1]:!font-normal [&>h1]:!text-pi-gray-1'
            type={t('oops-no-funds-available')}
            message={t('please-check-back-later')}
            displayIcon={false}
          />
        )}
      </MainBody>
      <CheckOutInvestmentFooter
        selectedFundCount={selectedFundCount}
        onCheckOut={onCheckOut}
      />
    </>
  )
}

export default FundListRoot
