import { useTranslation, Trans } from 'react-i18next'
import { LEGAL_LINKS } from '@/config'
import ExternalLink from '@/components/ExternalLink'
import { ReactComponent as RcsmIco } from './assets/rcsm.svg'

export const Footer = () => {
  const { t } = useTranslation('footer')

  return (
    <footer className='footer bg-pi-dark-prussian-blue text-white min-h-[202px] px-4 py-6 lg:py-10'>
      <div className='max-w-screen-xl mx-auto'>
        <div className='flex flex-col flex-wrap gap-y-4 mb-4 sm:flex-row sm:gap-x-10 sm:mb-10'>
          {Object.values(LEGAL_LINKS).map(({ href, labelKey }) => (
            <ExternalLink
              className='clickable-text white-text text-sm'
              href={href}
              key={labelKey}
              children={t(labelKey)}
            />
          ))}
        </div>
        <div className='text-pi-chartreuse text-xs flex flex-row items-center gap-2 mb-2'>
          <RcsmIco />
          {t('regulated-by-scm')}
        </div>
        <p className='text-xs mb-0 text-pi-gray-4 pl-[1.5px] font-bold'>
          <Trans
            i18nKey='protected-by-recaptcha'
            t={t}
            components={{
              A1: (
                <ExternalLink
                  href='https://policies.google.com/privacy'
                  className='text-pi-gray-4 hover:text-white underline'
                />
              ),
              A2: (
                <ExternalLink
                  href='https://policies.google.com/terms'
                  className='text-pi-gray-4 hover:text-white underline'
                />
              ),
            }}
          />
        </p>
      </div>
    </footer>
  )
}

export default Footer
