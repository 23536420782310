import { IconButton } from '@mui/material'
import { forwardRef, memo, useEffect, useMemo, useState } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import {
  FormLabel,
  FormErrorMessage,
  CurrencyTextField,
  formattedAmount,
} from '@pimy-b2cweb/frontend-lib'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIco } from '@/assets/close-ico.svg'
import TwoSideText from '@/components/TwoSideText'
import { SelectedFund } from '@/hooks/useInvestmentReducer'

export interface FundsCompProps {
  error?: FieldError
  value: SelectedFund[]
  disabled?: boolean
  disableAdjustAmount?: boolean
  onChange: ControllerRenderProps['onChange']
}
export interface FundsCompItemErr {
  code: string
  message: string
}
export const FundsComp = memo(
  forwardRef<HTMLDivElement, FundsCompProps>(
    (
      { value, error, disabled, disableAdjustAmount = false, onChange },
      ref
    ) => {
      const { t } = useTranslation(['cashInPage', 'common'])

      const [localVal, setLocalVal] = useState<SelectedFund[]>([])
      useEffect(() => {
        if (JSON.stringify(value) !== JSON.stringify(localVal))
          setLocalVal(value)
      }, [value])
      useEffect(() => {
        const timer = setTimeout(() => {
          if (JSON.stringify(value) !== JSON.stringify(localVal))
            onChange(localVal)
        }, 100)
        return () => clearTimeout(timer)
      }, [localVal])

      const handleChange = ({ id, value }: { id: string; value: number }) => {
        const _localVal = localVal.map((item) =>
          item.code === id
            ? {
                ...item,
                amount: value || 0,
              }
            : item
        )
        setLocalVal(_localVal)
      }

      const handleRemove = (code: string) => {
        if (localVal.length <= 1 || !!disabled) return
        const _localVal = localVal.filter((item) => item.code !== code)
        setLocalVal(_localVal)
      }

      const errMessages = useMemo(
        () =>
          (!!error &&
            !!error.message &&
            (JSON.parse(error.message) as FundsCompItemErr[])) ||
          undefined,
        [error]
      )

      return (
        <div ref={ref}>
          {localVal.map(
            ({ name, code, amount, minSubsAmt, salesFeePerc = 0 }) => {
              const _disabled = localVal.length <= 1 || !!disabled
              const errMsg =
                (!!errMessages &&
                  errMessages.find((item) => item.code === code)) ||
                undefined

              return (
                <FormLabel
                  id={code}
                  key={code}
                  className='[&>label]:overflow-visible'
                  label={
                    <TwoSideText
                      className='!gap-4 [&>:first-child]:truncate'
                      left={name}
                      right={
                        <IconButton
                          onClick={() => handleRemove(code)}
                          disabled={_disabled}
                          className={`-m-2 ${_disabled ? '' : 'cusor-pointer'}`}
                        >
                          <CloseIco
                            className={
                              _disabled ? '[&>path]:stroke-pi-gray-3' : ''
                            }
                          />
                        </IconButton>
                      }
                    />
                  }
                  isError={!!errMsg}
                >
                  <CurrencyTextField
                    id={code}
                    fullWidth
                    disabled={disabled || disableAdjustAmount}
                    value={amount}
                    onChange={(value: number) =>
                      handleChange({ id: code, value })
                    }
                    className='mb-2'
                    error={!!errMsg}
                    inputProps={{ maxLength: 15 }}
                  />
                  <TwoSideText
                    className={`text-sm px-[1.5px] ${
                      !!errMsg ? 'text-pi-utility-red' : ''
                    }`}
                    left={
                      !!minSubsAmt
                        ? t('minimum-cash-in-inclusive-sales-charge', {
                            min_cash_in: formattedAmount({
                              amount: minSubsAmt,
                            }),
                            sales_charge: salesFeePerc,
                          })
                        : t('inclusive-of-sales-charge')
                    }
                    right={!!minSubsAmt ? '' : `${salesFeePerc}%`}
                  />
                  <FormErrorMessage
                    error={
                      !!error && !!errMsg
                        ? { type: error.type, message: errMsg.message }
                        : undefined
                    }
                  />
                </FormLabel>
              )
            }
          )}
        </div>
      )
    }
  )
)
export default FundsComp
