import { useState, Dispatch, SetStateAction } from 'react'
import HelmetSiteTitle from '@/components/HelmetSiteTitle'
import PreLogonLayout from '@/layout/PreLogonLayout'
import Landing from './subpages/Landing'
import Questionnaire from './subpages/Questionnaire'
import Result from './subpages/Result'
import {
  useQueryGetCurrentQuestionnaire,
  //useQueryGetCurrentQuestionnaire,
  useQueryRiskLevelList,
} from '@/api/riskProfileApi'
import Loading from '@/components/Loading'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'
import { RiskProfileQuestionnaireContextDto } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useTranslation } from 'react-i18next'

export enum IsafStatusEnum {
  Landing = 'landing',
  Questionnaire = 'questionnaire',
  Result = 'result',
}

export const ISAF = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('isafPage')

  const [status, setStatus] = useState<IsafStatusEnum>(IsafStatusEnum.Landing)
  const {
    isLoading: isLoadingGetQuestionnaireQuery,
    isError: isGetQuestionnaireQueryError,
    data: questionnaire,
  } = useQueryGetCurrentQuestionnaire()

  const {
    isLoading: isLoadingRiskLevelListQuery,
    isError: isRiskLevelListQueryError,
  } = useQueryRiskLevelList()

  const flipColor = ![
    IsafStatusEnum.Questionnaire,
    IsafStatusEnum.Result,
  ].includes(status)

  if (isLoadingRiskLevelListQuery || isLoadingGetQuestionnaireQuery) {
    return <Loading />
  }

  if (isRiskLevelListQueryError || isGetQuestionnaireQueryError) {
    navigate(RoutePathEnum.ERROR)
  }

  return (
    <PreLogonLayout flipColor={flipColor}>
      <HelmetSiteTitle pageTitle={t('isaf')} />
      <StatusSwitch
        status={status}
        setStatus={setStatus}
        questionnaire={questionnaire}
      />
    </PreLogonLayout>
  )
}

const StatusSwitch = ({
  status,
  setStatus,
  questionnaire,
}: {
  status: IsafStatusEnum
  setStatus: Dispatch<SetStateAction<IsafStatusEnum>>
  questionnaire?: RiskProfileQuestionnaireContextDto
}) => {
  const LandingPage = (
    <Landing goNext={() => setStatus(IsafStatusEnum.Questionnaire)} />
  )
  switch (status) {
    case IsafStatusEnum.Landing:
      return LandingPage
    case IsafStatusEnum.Questionnaire:
      return (
        <Questionnaire
          goNext={() => setStatus(IsafStatusEnum.Result)}
          questionnaire={questionnaire}
        />
      )
    case IsafStatusEnum.Result:
      return <Result />
    default:
      return LandingPage
  }
}

export default ISAF
