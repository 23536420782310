import { EMPTY_VALUE } from '@/constants'
import { getSignedNumber } from '@/utils'
import { formattedAmount } from '@pimy-b2cweb/frontend-lib'
import { useTranslation } from 'react-i18next'

export interface RiskProfileStatusProps {
  riskProfileName?: string
  totalAssetsAmt?: number
  profitLoss?: number
}

export const RiskProfileStatus = ({
  riskProfileName,
  totalAssetsAmt,
  profitLoss,
}: RiskProfileStatusProps) => {
  const { t } = useTranslation(['riskProfileStatus', 'riskProfiles'])

  return (
    <div className='bg-gradient-to-r to-pi-navy-blue from-pi-principal-blue text-white px-4 py-3'>
      <div className='max-w-screen-xl mx-auto'>
        <table className='table-auto text-sm border-spacing-x-4'>
          <tbody>
            <tr>
              <td>{t('risk-profile')}</td>
              <td className='font-bold'>{riskProfileName}</td>
            </tr>
            <tr>
              <td>{t('total-assets')}</td>
              <td className='font-bold'>
                {totalAssetsAmt !== undefined
                  ? formattedAmount({ amount: totalAssetsAmt })
                  : EMPTY_VALUE}{' '}
                <span className='text-pi-chartreuse'>
                  (
                  {profitLoss !== undefined
                    ? getSignedNumber({ num: profitLoss })
                    : EMPTY_VALUE}
                  )
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default RiskProfileStatus
