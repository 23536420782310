import { FundInformation } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useEffect, useMemo, useState } from 'react'
import { UseQueryCustomerPortfolioRes } from '@/api/customerApi'
import { InvestmentCategoryEnum } from '@/enums/InvestmentCategoryEnum'
import { PortfolioFund, convertPortfolioFund } from '@/utils/portfolioFund'

export const useFeeds = ({
  portfolioData,
  fundList = [],
  tabType,
}: {
  portfolioData?: UseQueryCustomerPortfolioRes
  fundList?: FundInformation[]
  tabType: InvestmentCategoryEnum
}) => {
  const feedPerBatch = 10
  const [currBatch, setCurrBatch] = useState(1)

  const handleResetBatch = () => setCurrBatch(1)
  useEffect(() => handleResetBatch(), [tabType])

  const displayFeeds: PortfolioFund[] = useMemo(() => {
    //TODO: pending for how to sort feeds?

    const epfFunds = portfolioData?.epfGroup.funds || []
    const cashFunds = portfolioData?.cashGroup.funds || []
    const _feeds =
      tabType === InvestmentCategoryEnum.ALL
        ? [...epfFunds, ...cashFunds]
        : tabType === InvestmentCategoryEnum.KWSP_I_INVEST
        ? epfFunds
        : cashFunds

    const _feedsNum = currBatch * feedPerBatch

    return convertPortfolioFund({
      pFunds: _feeds.slice(0, _feedsNum),
      fundList,
    })
  }, [tabType, portfolioData, fundList, currBatch])

  return {
    feedPerBatch,
    currBatch,
    handleShowMore: () => setCurrBatch(currBatch + 1),
    handleResetBatch,
    displayFeeds,
  }
}

export default useFeeds
