import { useMutationConfirmPurchasingInstruction } from '@/api/orderApi'
import { postHiddenForm } from '@/utils'
import { OrderInstructionConfirmReqDto } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useEffect, useState } from 'react'
import useClearEpfStatus from '@/hooks/useClearEpfStatus'

export const useConfirmPurchasingInstruction = () => {
  const [stateIsLoading, setStateIsLoading] = useState(false)
  const { clearEpfToken } = useClearEpfStatus()
  const [proceedToConfirm, setProceedToConfirm] = useState(false)

  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationConfirmPurchasingInstruction()

  const handleMutate = (data: OrderInstructionConfirmReqDto) => {
    setStateIsLoading(true)
    mutate(data)
  }

  useEffect(() => {
    if (mutateStatus === 'error') {
      setStateIsLoading(false)
    }

    if (mutateStatus === 'success' && !!mutatedData) {
      console.log(mutatedData)
      const { paymentUrl, paymentDetail } = mutatedData
      setProceedToConfirm(true)
      clearEpfToken()
      postHiddenForm(paymentUrl, paymentDetail)
    }
  }, [mutatedData, mutateStatus])

  return {
    handleMutate,
    mutateStatus,
    mutatedData,
    mutatedError,
    isLoading: stateIsLoading || mutateIsLoading,
    proceedToConfirm,
  }
}

export default useConfirmPurchasingInstruction
