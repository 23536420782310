import { useModal } from '@pimy-b2cweb/frontend-lib'
import { EcddStatusEnum } from '@/enums/ecddEnums'

export const useEcddModal = (ecddStatus?: EcddStatusEnum) => {
  const { modalOpen, ...useModalRest } = useModal()

  return {
    modalOpen: () => {
      if (!ecddStatus || ecddStatus === EcddStatusEnum.Passed) return
      modalOpen()
    },
    ecddStatus,
    ...useModalRest,
  }
}

export default useEcddModal
