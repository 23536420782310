import { getSignedNumber } from '@/utils'
import { Card, CardContent } from '@mui/material'
import { GroupedPortfolioSummaryDto } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { formattedAmount } from '@pimy-b2cweb/frontend-lib'
import { useTranslation } from 'react-i18next'

export enum AccountTypeEnum {
  CASH = 'cash',
  EPF = 'epf',
}

export interface AccountBalanceCardProps
  extends Partial<
    Pick<GroupedPortfolioSummaryDto, 'totalValue' | 'totalGain'>
  > {
  className?: string
  category: AccountTypeEnum
  numOfFunds?: number
  onClickFunds?: () => void
}

const AccountBalanceCard = ({
  className = '',
  category,
  totalValue = 0,
  totalGain = 0,
  numOfFunds = 0,
  onClickFunds,
}: AccountBalanceCardProps) => {
  const { t } = useTranslation('dashboardPage')
  return (
    <Card
      className={`rounded-xl bg-gradient-to-br ${
        category === AccountTypeEnum.CASH
          ? 'from-[#006BB8] via-[#007AD2] to-[#07B5FF]'
          : 'from-[#003772] via-[#0350A3] to-[#0386FF]'
      } ${className}`}
    >
      <CardContent className='text-base font-normal text-white !py-4 md:px-5'>
        <div className='mb-1 font-bold'>{t(category)}</div>
        {!!numOfFunds ? (
          <>
            <div className='font-black'>
              {formattedAmount({ amount: totalValue })}
            </div>
            <div
              className={`mb-4 ${
                totalGain >= 0 ? 'text-pi-chartreuse' : 'text-pi-gray-4'
              }`}
            >
              ({getSignedNumber({ num: totalGain })})
            </div>
            <div
              onClick={onClickFunds}
              className='text-white font-normal text-sm underline cursor-pointer'
            >
              {t('fundCount', { count: numOfFunds })}
            </div>
          </>
        ) : (
          <div className='font-black mb-9'>{t('no-investment-available')}</div>
        )}
      </CardContent>
    </Card>
  )
}

export default AccountBalanceCard
