/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CustomerProfileIdentiy,
  OnboardingProfileQueryResDto,
  CustomerEPF,
  CustomerProfileAddressContact,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useResponseError } from '@pimy-b2cweb/frontend-lib'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  CustomerProfileTypeEnum,
  useMutationCustomerProfile,
  UseMutationCustomerProfile,
} from '@/api/customerApi'
import useSetUserNotIdle from '@/hooks/useSetUserNotIdle'
import useLov from '@/hooks/useLov'
import { getErrorResponseCode, formatAddress, scrollTop } from '@/utils'
import usePostSubmitSwitch from './usePostSubmitSwitch'
import { ScreenTypeEnum } from '@/api/commonApi'
import { useSelector } from 'react-redux'
import { selectEpfDecoded } from '@/stores/epfToken.selectors'
import { BooleanFormValues } from '@/enums/formValueEnum'
import {
  COMMON_PASSWORD_CHECK_FAILED,
  SUBMIT_FAILED,
} from '@/constants/errorCodes'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'
import {
  CustomerAddress,
  getCustomerProfileAddress,
} from '@/utils/CustomerProfileAddress'

export enum ProfileInfoPageEnum {
  CONTACT_INFO = 'contact_info',
  EMPLOYMENT_INFO = 'employment_info',
}

export interface ProfileInfoFormProps
  extends Omit<
      UseMutationCustomerProfile,
      'pepFlag' | 'primaryContact' | 'correspondentContact'
    >,
    Partial<
      Pick<OnboardingProfileQueryResDto, 'fullName' | 'email' | 'nationality'>
    > {
  idNo: CustomerProfileIdentiy['idNo']
  epfNumber: CustomerEPF['epfNumber']
  //epfiStatus: true = Islamic, require effective date; otherwise false = Conventional
  epfAccountType: 'Islamic' | 'Conventional' | ''
  epfiStatusEffectiveDate: CustomerEPF['epfiStatusEffectiveDate']
  pepFlag: BooleanFormValues
  taxResidentOtherThanMalaysia: BooleanFormValues
  isPrimaryContactCurrent: boolean
  agreeTnc: string[]
  primaryContact: CustomerAddress
  correspondentContact: CustomerAddress
}

export const useProfileForm = (type?: CustomerProfileTypeEnum) => {
  const { lovsCurrLang, validated: lovValidated } = useLov({
    screen: ScreenTypeEnum.PROFILE_FORM,
  })

  const decodedEpfToken = useSelector(selectEpfDecoded)

  const formMethods = useForm<ProfileInfoFormProps>({
    mode: 'onChange',
    defaultValues: {
      fullName: '',
      idNo: '',
      epfNumber: '',
      epfAccountType: '',
      epfiStatusEffectiveDate: '',
      mobilePhoneNo: '',
      email: '',
      race: '',
      gender: '',
      nationality: '',
      primaryContact: {
        addressLines: {
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          addressLine4: '',
          addressLine5: '',
        },
        postalInfo: { postalCode: '', cityName: '', state: '', country: '' },
      },
      occupation: '',
      natureOfBusiness: '',
      yearlyIncomeLevel: '',
      employerName: '',
      sourceOfFund: '',
      investmentObjectives: '',
      pepFlag: undefined,
      taxResidentOtherThanMalaysia: BooleanFormValues.FALSE,
      crsCountries: [
        {
          tin: '',
          country: '',
        },
      ],
      agreeTnc: [],
      motherMaidenName: '',
      noOfDepedent: '',
      maritalStatus: '',
      isPrimaryContactCurrent: true,
      correspondentContact: {
        addressLines: {
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          addressLine4: '',
          addressLine5: '',
        },
        postalInfo: { postalCode: '', cityName: '', state: '', country: '' },
      },
    },
  })

  const [currentPage, setCurrentPage] = useState(
    ProfileInfoPageEnum.CONTACT_INFO
  )
  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const setUserNotIdle = useSetUserNotIdle()
  const navigate = useNavigate()

  const mutationCP = useMutationCustomerProfile(type)
  const { isLoading } = usePostSubmitSwitch({
    ...mutationCP,
    type,
    epfAction: decodedEpfToken?.action,
    onError: (error: unknown) => {
      const errMsg = getErrorResponseCode(error)
      if (errMsg === COMMON_PASSWORD_CHECK_FAILED) {
        navigate(RoutePathEnum.ERROR)
        return
      }
      if (errMsg === SUBMIT_FAILED) {
        navigate(RoutePathEnum.ERROR)
        return
      }
      setResponseErrorAttrs({ i18nKey: errMsg })
      scrollTop()
    },
    onSuccess: () => setResponseErrorAttrs(undefined),
  })

  return {
    lovsCurrLang,
    lovValidated,
    formMethods,
    currentPage,
    responseErrorAttrs,
    isLoading,
    onClickNext: () => {
      scrollTop()
      setCurrentPage(ProfileInfoPageEnum.EMPLOYMENT_INFO)
    },
    onClickBack: () => {
      scrollTop()
      setCurrentPage(ProfileInfoPageEnum.CONTACT_INFO)
    },
    onSubmit: (data: ProfileInfoFormProps) => {
      setResponseErrorAttrs(undefined)
      setUserNotIdle()
      mutationCP.mutate(handleConvertData(data))
    },
  }
}

const handleConvertData = (
  data: ProfileInfoFormProps
): UseMutationCustomerProfile => {
  const {
    fullName,
    idNo,
    epfNumber,
    epfAccountType,
    epfiStatusEffectiveDate,
    email,
    nationality,
    taxResidentOtherThanMalaysia,
    isPrimaryContactCurrent,
    agreeTnc,
    ...rest
  } = data

  const pepFlag = data.pepFlag === BooleanFormValues.TRUE ? true : false

  const crsCountries =
    !data.crsCountries ||
    !data.crsCountries[0]?.tin ||
    !data.crsCountries[0]?.country
      ? undefined
      : data.crsCountries

  const _primaryContact = getCustomerProfileAddress(data.primaryContact)

  const primaryContact = formatAddress({
    address: _primaryContact,
    addressLineNum: 5,
  }) as CustomerProfileAddressContact

  const _correspondentContact = getCustomerProfileAddress(
    data.correspondentContact
  )

  const correspondentContact = (
    isPrimaryContactCurrent
      ? primaryContact
      : formatAddress({
          address: _correspondentContact,
          addressLineNum: 5,
        })
  ) as CustomerProfileAddressContact

  return {
    ...rest,
    pepFlag,
    crsCountries,
    primaryContact,
    correspondentContact,
  }
}

export default useProfileForm
