import { TextField } from '@mui/material'
import { validateUsername } from '@pimy-b2cweb/common'
import {
  ButtonWithLoading,
  FormLabel,
  ResponseError,
  useResponseError,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  useMutationSignInUsername,
  UseMutationSignInUsername as UsernameFormProps,
} from '@/api/authApi'
import BottomAction from '@/components/BottomAction'
import UserIDPw from '@/components/UserIDPw'
import { INVALID_USERID } from '@/constants/errorCodes'
import {
  sessionSignInForceResetPassword,
  sessionSignInPendingPassword,
} from '@/stores/auth'
import { getErrorResponseCode } from '@/utils'

export const Username = ({
  pwExpireModalOpen,
}: {
  pwExpireModalOpen: () => void
}) => {
  const { t } = useTranslation(['signinPage', 'common'])
  const dispatch = useDispatch()

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()
  const { control, handleSubmit } = useForm<UsernameFormProps>({
    mode: 'onChange',
    defaultValues: {
      username: '',
    },
  })

  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationSignInUsername()
  const onSubmit = async (data: UsernameFormProps) => {
    console.log(data)
    setResponseErrorAttrs(undefined)
    mutate(data)
  }

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      if (!!mutatedData.forceResetPassword) {
        dispatch(
          sessionSignInForceResetPassword({ username: mutatedData.username })
        )
        setResponseErrorAttrs({
          i18nKey: 'require-reset-password',
        })
        pwExpireModalOpen()
        return
      }
      dispatch(sessionSignInPendingPassword(mutatedData))
    }
  }, [mutatedData, mutateStatus, mutatedError])

  return (
    <>
      <h1>{t('welcome-back')}</h1>
      <p>{t('please-enter-user-id')}</p>
      {!!responseErrorAttrs && (
        <ResponseError>
          {t(responseErrorAttrs.i18nKey, {
            ns: responseErrorAttrs.ns,
          })}
        </ResponseError>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='username'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
            validate: (val: string) => {
              const issues = validateUsername(val)
              return issues.length === 0 || t(INVALID_USERID)
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='username'
              label={t('User ID', { ns: 'common' })}
              isError={!!error}
              className='!mb-2'
            >
              <TextField
                type='text'
                fullWidth
                {...field}
                error={!!error}
                helperText={!!error ? (error?.message as string) : ''}
                disabled={mutateIsLoading}
                placeholder='johndoe123'
                autoComplete='off'
              />
            </FormLabel>
          )}
        />
        <UserIDPw disabled={mutateIsLoading} />
        <ButtonWithLoading
          type='submit'
          fullWidth
          variant='contained'
          size='large'
          className='mb-10 sm:mb-2'
          disabled={mutateIsLoading}
          isLoading={mutateIsLoading}
        >
          {t('Continue', { ns: 'common' })}
        </ButtonWithLoading>
      </form>
      <BottomAction type='signup' disabled={mutateIsLoading} />
    </>
  )
}

export default Username
