import { AddressLines } from '@/utils/CustomerProfileAddress'

export interface AddressInfoProps extends AddressLines {}

const AddressInfo = ({
  addressLine1,
  addressLine2,
  addressLine3,
  addressLine4,
  addressLine5,
}: AddressInfoProps) => {
  return (
    <div className='flex flex-col text-base'>
      <div>{addressLine1}</div>
      {!!addressLine2 && <div>{addressLine2}</div>}
      {!!addressLine3 && <div>{addressLine3}</div>}
      {!!addressLine4 && <div>{addressLine4}</div>}
      {!!addressLine5 && <div>{addressLine5}</div>}
    </div>
  )
}

export default AddressInfo
