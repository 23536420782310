import { forwardRef } from 'react'

type InviewportCheckerProps = unknown
export const InviewportChecker = forwardRef<
  HTMLDivElement,
  InviewportCheckerProps
>((_, ref) => {
  return (
    <div className='col-span-full relative'>
      <div className={`absolute w-full `} ref={ref} />
    </div>
  )
})

export default InviewportChecker
