import { PropsWithChildren } from 'react'
import EcddModal from './components/EcddModal'
import Header from './components/Header'
import ISAFModal from './components/ISAFModal'
import useUserStatusModals from './hooks/useUserStatusModals'

export const PostLogonPages = ({ children }: PropsWithChildren) => {
  const { isafStatus, isafModal, ecddModal } = useUserStatusModals()

  return (
    <>
      <Header />
      <div className='bg-pi-gray-6'>{children}</div>
      <ISAFModal isafStatus={isafStatus} {...isafModal} />
      <EcddModal {...ecddModal} />
    </>
  )
}

export default PostLogonPages
