import { PortfolioSummaryResDto } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { formattedAmount } from '@pimy-b2cweb/frontend-lib'
import { Trans, useTranslation } from 'react-i18next'

export interface AccountStatusProps
  extends Partial<Pick<PortfolioSummaryResDto, 'totalValue' | 'totalGain'>> {
  className?: string
}
export const AccountStatus = ({
  className = '',
  totalValue = 0,
  totalGain = 0,
}: AccountStatusProps) => {
  const { t } = useTranslation('dashboardPage')

  return (
    <div
      className={`bg-pi-navy-blue text-white px-4 py-6 -mx-4 md:mx-auto text-left md:text-center ${className}`}
    >
      <div className='text-sm font-normal leading-7 text-pi-gray-5'>
        {t('your-total-assets')}
      </div>
      <div className='text-3xl font-black'>
        {formattedAmount({ amount: totalValue })}
      </div>
      <div
        className={`text-sm font-normal ${
          totalGain >= 0 ? 'text-pi-chartreuse' : 'text-pi-gray-4'
        }`}
      >
        {!!totalGain ? (
          <Trans
            i18nKey={
              totalGain >= 0 ? 'yes-we-earned' : 'investment-decreased-by'
            }
            t={t}
            components={{ B: <span className='font-bold' /> }}
            values={{
              amount: formattedAmount({ amount: Math.abs(totalGain) }),
            }}
          />
        ) : (
          t('no-investment')
        )}
      </div>
    </div>
  )
}

export default AccountStatus
