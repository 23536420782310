import { useEffect } from 'react'
import { AuthedStatusEnum } from '@/config'
import { RoutePathEnum } from '@/constants/routePath'
import useClearEpfStatus from '@/hooks/useClearEpfStatus'

const RESET_AUTH_STATUS_PATHS = [
  RoutePathEnum.ROOT,
  RoutePathEnum.PROFILE_SETUP,
  RoutePathEnum.PROFILE_ECDD,
  RoutePathEnum.ORDER_PURCHASE_FORM,
  RoutePathEnum.ORDER_REDEMPTION_FORM,
  RoutePathEnum.ORDER_SWITCHING_FORM,
]

const EPF_JOURNEY_PATHS = [
  ...RESET_AUTH_STATUS_PATHS,
  RoutePathEnum.ORDER_SWITCHING,
]

const EPF_JOURNEY_AUTH_STATUS = [
  AuthedStatusEnum.EpfPurchasing,
  AuthedStatusEnum.EpfRedemption,
  AuthedStatusEnum.EpfSwitching,
]

export const useCheckEpfJourney = ({
  pathname,
  authStatus,
}: {
  pathname: string
  authStatus: AuthedStatusEnum
}) => {
  const { clearEpfToken, resetAuthStatus } = useClearEpfStatus()

  useEffect(() => {
    const hasEpfStatus = EPF_JOURNEY_AUTH_STATUS.includes(authStatus)
    /**
     * if user is on EPF purchasing/buying/switching journey but navigate to pages out of thee journey,
     * remove epf token
     */
    if (
      !RESET_AUTH_STATUS_PATHS.includes(pathname as unknown as RoutePathEnum) &&
      hasEpfStatus
    )
      clearEpfToken()

    /**
     * if user is on EPF purchasing/buying/switching journey but navigate to pages out of thee journey,
     * reset AuthStatus as per the customer indicator API response
     */
    if (
      !EPF_JOURNEY_PATHS.includes(pathname as unknown as RoutePathEnum) &&
      hasEpfStatus
    )
      resetAuthStatus()
  }, [pathname, authStatus])
}

export default useCheckEpfJourney
