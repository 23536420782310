import { TextField } from '@mui/material'
import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CustomerIdTypeEnum as idTypeEnum } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { ID_NUMBER_REGX } from '@pimy-b2cweb/common'
import {
  ButtonWithLoading,
  FormLabel,
  SelectBoxComp,
  ResponseError,
  useResponseError,
  CSLink,
  useModal,
} from '@pimy-b2cweb/frontend-lib'
import {
  useMutationSignUpId,
  UseMutationSignUpId as IdFormProps,
} from '@/api/onboardingApi'
import BottomAction from '@/components/BottomAction'
import { ID_TYPES } from '@/constants'
import {
  INVALID_ID_NUMBER,
  RECIPIENT_REQUIRED,
  SIGNUP_NOT_ALLOWED,
} from '@/constants/errorCodes'
import {
  sessionSignUpPendingOtp,
  sessionSignUpPendingEmail,
} from '@/stores/auth'
import { selectEpfDecoded } from '@/stores/epfToken.selectors'
import { getErrorResponseCode } from '@/utils'
import SignupBlockedModal from './SignupBlockedModal'

export const Id = () => {
  const { t } = useTranslation(['signUpPage', 'common'])

  const epfdecoded = useSelector(selectEpfDecoded)

  const {
    control,
    handleSubmit,
    trigger,
    watch,
    reset,
    formState: {
      dirtyFields: { idNo: isIdNoDirty },
    },
  } = useForm<IdFormProps>({
    mode: 'onChange',
    defaultValues: { idType: ID_TYPES[0] },
  })
  useEffect(() => {
    if (!!isIdNoDirty) trigger('idNo')
  }, [watch('idType'), isIdNoDirty])

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()
  const { modalOpen, open } = useModal()

  useEffect(() => {
    reset({
      idType: (epfdecoded?.idType as idTypeEnum) || ID_TYPES[0],
      idNo: epfdecoded?.idNo,
    })
  }, [epfdecoded])

  const dispatch = useDispatch()
  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationSignUpId()

  const onSubmit = async (data: IdFormProps) => {
    console.log(data)
    setResponseErrorAttrs(undefined)
    mutate({
      ...data,
    })
  }

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      if (errMsg === SIGNUP_NOT_ALLOWED) {
        modalOpen()
        return
      }
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      console.log('mutatedData: ', mutatedData)

      if (mutatedData.code === RECIPIENT_REQUIRED) {
        dispatch(sessionSignUpPendingEmail(mutatedData))
        return
      }
      dispatch(sessionSignUpPendingOtp(mutatedData))
    }
  }, [mutatedData, mutateStatus, mutatedError])

  return (
    <>
      <h1>{t('sign-up')}</h1>
      <p>{t('please-enter-your-id-type-and-id-number')}</p>
      {!!responseErrorAttrs && (
        <ResponseError>
          <Trans
            {...responseErrorAttrs}
            t={t}
            components={{ CS: <CSLink /> }}
          />
        </ResponseError>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='idType'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='idType'
              label={t('ID type', { ns: 'common' })}
              isError={!!error}
            >
              <SelectBoxComp
                id='idType'
                items={ID_TYPES.map((value) => ({
                  label: t(value, { ns: 'idTypes' }),
                  value,
                }))}
                {...field}
                error={error}
                disabled={mutateIsLoading || !!epfdecoded?.idType}
                fullWidth
              />{' '}
            </FormLabel>
          )}
        />
        <Controller
          name='idNo'
          control={control}
          defaultValue=''
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
            validate: {
              validateUserIdByType: (
                val: string,
                formValues: { idType: string },
              ) => {
                let passed = false
                switch (formValues.idType) {
                  case idTypeEnum.NRIC:
                    passed = ID_NUMBER_REGX.nric.test(val)
                    break
                  case idTypeEnum.ARID:
                    passed = ID_NUMBER_REGX.arid.test(val)
                    break
                  case idTypeEnum.OLDIC:
                    passed = ID_NUMBER_REGX.oldIc.test(val)
                    break
                  case idTypeEnum.PSPORT:
                    passed = ID_NUMBER_REGX.passport.test(val)
                    break
                  case idTypeEnum.POID:
                    passed = ID_NUMBER_REGX.arid.test(val)
                    break
                  default:
                    break
                }
                return passed || t(INVALID_ID_NUMBER)
              },
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='idNo'
              label={t('ID number', { ns: 'common' })}
              isError={!!error}
              className='!mb-10'
            >
              <TextField
                type='text'
                fullWidth
                {...field}
                error={!!error}
                helperText={!!error ? (error?.message as string) : ''}
                disabled={mutateIsLoading || !!epfdecoded?.idNo}
                autoComplete='off'
              />
            </FormLabel>
          )}
        />
        <ButtonWithLoading
          type='submit'
          fullWidth
          variant='contained'
          size='large'
          disabled={mutateIsLoading}
          isLoading={mutateIsLoading}
        >
          {t('Continue', { ns: 'common' })}
        </ButtonWithLoading>
      </form>
      <BottomAction type='signin' />
      <SignupBlockedModal open={open} />
    </>
  )
}

export default Id
