import { ActionModal, ActionModalProps } from '@pimy-b2cweb/frontend-lib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const HighRiskAlertModal = ({
  modalClose,
  handleHardProceed,
  ...rest
}: Omit<
  ActionModalProps,
  'title' | 'actions' | 'actionsStyle' | 'closeBtn' | 'onClose' | 'children'
> & {
  modalClose: () => void
  handleHardProceed: () => void
}) => {
  const { t } = useTranslation(['cashInPage', 'common'])
  const [triggerNavOnClose, setTriggerNavOnClose] = useState(false)
  useEffect(() => {
    if (!!triggerNavOnClose && !rest.open) handleHardProceed()
  }, [rest.open, triggerNavOnClose])

  return (
    <ActionModal
      {...rest}
      title={t('higher-risk-fund-alert-title')}
      actions={[
        {
          label: t('yes-continue'),
          onClick: () => {
            setTriggerNavOnClose(true)
            modalClose()
          },
          variant: 'contained',
        },
        {
          label: t('maybe-other-fund'),
          onClick: modalClose,
          variant: 'text',
        },
      ]}
      actionsStyle='full'
      closeBtn={false}
      onClose={modalClose}
    >
      <p className='mb-0'>{t('higher-risk-fund-alert-text')}</p>
    </ActionModal>
  )
}

export default HighRiskAlertModal
