import { getSignedCurrency, getSignedNumber } from '@/utils'
import { FundPortfolioDto } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { formattedAmount } from '@pimy-b2cweb/frontend-lib'

export interface FundPriceProps
  extends Pick<
    FundPortfolioDto,
    'currentValue' | 'totalAssetGain' | 'totalAssetGainPercentage'
  > {
  className?: string
  disabled?: boolean
}

const FundPrice = ({
  currentValue,
  totalAssetGain,
  totalAssetGainPercentage,
  className = '',
  disabled = false,
}: FundPriceProps) => {
  return (
    <div className={className}>
      <div
        className={`text-xl font-black ${
          !!disabled ? 'text-pi-gray-3' : 'text-pi-navy-blue'
        }`}
      >
        {formattedAmount({ amount: currentValue })}
      </div>
      <div
        className={`text-xs ${
          !!disabled
            ? 'text-pi-gray-3'
            : totalAssetGain > 0
            ? 'text-pi-utility-green'
            : 'text-pi-utility-red'
        }`}
      >
        {`${getSignedCurrency({
          num: totalAssetGain,
          currency: 'MYR',
          currencyDisplay: 'narrowSymbol',
        })} (${getSignedNumber({
          num:
            totalAssetGainPercentage === 0 ? 0 : totalAssetGainPercentage / 100,
          style: 'percent',
        })})`}
      </div>
    </div>
  )
}

export default FundPrice
