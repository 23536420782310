import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { InvestmentCategoryEnum } from '@/enums/InvestmentCategoryEnum'
import { useShowHeaderStatus } from '@/hooks/useShowHeaderStatus'
import useTabs from '@/hooks/useTabs'
import { resetLocationState } from '@/utils'

export const useInvestmentCategoryTabs = (
  investmentCategories?: InvestmentCategoryEnum[]
) => {
  const { state } = useLocation()
  const { handleSetValue, ...useTabRest } = useTabs()
  const showHeader = useShowHeaderStatus()

  const tabList = !!investmentCategories?.length
    ? investmentCategories
    : Object.values(InvestmentCategoryEnum)

  useEffect(() => {
    if (!!state?.category && tabList.includes(state.category)) {
      handleSetValue(tabList.indexOf(state.category))
      resetLocationState()
    }
  }, [])

  return {
    showHeader,
    investmentCategories: tabList,
    tabType: tabList[useTabRest.value] as InvestmentCategoryEnum,
    handleSetValue,
    ...useTabRest,
  }
}

export default useInvestmentCategoryTabs
