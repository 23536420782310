import { Button } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { openCSLink } from '@/utils'

export const AccountVerification = () => {
  const { t } = useTranslation(['accountVerification', 'common'])

  return (
    <>
      <h1>{t('account-verification')}</h1>
      <p className='mb-10'>
        <Trans
          i18nKey='account-verification-text'
          t={t}
          components={{
            P: <p />,
            B: <strong />,
          }}
        />
      </p>
      <Button fullWidth variant='contained' size='large' onClick={openCSLink}>
        {t('chat-with-us-on-wp', { ns: 'common' })}
      </Button>
    </>
  )
}

export default AccountVerification
