import { ActionModal, ActionModalProps } from '@pimy-b2cweb/frontend-lib'
import { useTranslation } from 'react-i18next'

export interface SwitchingFeeModalProps
  extends Omit<
    ActionModalProps,
    'title' | 'actions' | 'actionsStyle' | 'closeBtn' | 'onClose' | 'children'
  > {
  modalClose: () => void
}

const SwitchingFeeModal = ({ modalClose, ...rest }: SwitchingFeeModalProps) => {
  const { t } = useTranslation(['switchingPage'])

  const numItems = t(`switching-fee-modal-items.length`, { defaultValue: 0 })

  return (
    <ActionModal
      {...rest}
      title={t(`switching-fee`)}
      actions={[
        {
          label: t('OK', { ns: 'common' }),
          onClick: () => modalClose(),
          variant: 'text',
        },
      ]}
      actionsStyle='right'
      closeBtn={false}
      onClose={modalClose}
    >
      <>
        <ul className='mb-4 !ps-8'>
          {Array.from({ length: Number(numItems) }, (_, i) => (
            <li key={i}>{t(`switching-fee-modal-items.${i}`)}</li>
          ))}
        </ul>
        <p>{t('switching-fee-modal-more-details')}</p>
      </>
    </ActionModal>
  )
}

export default SwitchingFeeModal
