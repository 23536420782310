import { useTranslation } from 'react-i18next'
import { ContactInfoFormProps } from '../../../ContactInfo/subpages/ContactInfoForm'
import InfoContainer from '../InfoContainer'
import InfoField from '../InfoField'

export interface ContactInfoProps extends ContactInfoFormProps {}

const ContactInfo = ({ email, mobilePhoneNo }: ContactInfoProps) => {
  const { t } = useTranslation('profileInfoPage')
  return (
    <InfoContainer>
      <InfoField fieldName={t('contact-number')} value={mobilePhoneNo} />
      <InfoField fieldName={t('email')} value={email} />
    </InfoContainer>
  )
}

export default ContactInfo
