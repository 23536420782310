import useGetEkycToken from '@/hooks/useGetEkycToken'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'

const DirectToEKYC = () => {
  const navigate = useNavigate()
  const { handleMutate, mutatedError } = useGetEkycToken()

  useEffect(() => handleMutate(), [])
  useEffect(() => {
    if (!!mutatedError) navigate(RoutePathEnum.ERROR)
  }, [mutatedError])

  return null
}

export default DirectToEKYC
