import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'
import useIsSignedIn from '@/hooks/useIsSignedIn'

export const ErrorRedirectBtn = ({
  className = '',
}: {
  className?: string
}) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const isSignedIn = useIsSignedIn()

  return (
    <Button
      fullWidth
      variant='contained'
      size='large'
      className={`mt-6 ${className}`}
      onClick={() =>
        navigate(!isSignedIn ? RoutePathEnum.SIGN_IN : RoutePathEnum.DASHBOARD)
      }
    >
      {t(!isSignedIn ? 'back-to-login' : 'back-to-dashboard')}
    </Button>
  )
}

export default ErrorRedirectBtn
