import { useSelector } from 'react-redux'
import { useMutationCustomerIndicator } from '@/api/customerApi'
import { selectAuthStatus } from '@/stores/auth.selectors'
import { selectEpfTokenSessionState } from '@/stores/epfToken.selectors'
import customerIndicatorSwitchLogic, {
  CustomerIndicatorSwitchLogic,
} from '../../utils/customerIndicatorSwitchLogic'

export const useCustomerIndicator = () => {
  const useMCI = useMutationCustomerIndicator()
  const {
    token: epfToken,
    expAt: epfExpAt,
    decoded: decodedEpfToken,
  } = useSelector(selectEpfTokenSessionState)
  const authStatus = useSelector(selectAuthStatus)

  const handleGetCiStatus = () => {
    if (useMCI.status === 'error' || !useMCI.data) return undefined

    return customerIndicatorSwitchLogic({
      ...useMCI.data,
      currAuthStatus: authStatus,
      epfToken,
      epfExpAt,
      decodedEpfToken,
    } as CustomerIndicatorSwitchLogic)
  }

  return {
    handleGetCiStatus,
    ...useMCI,
  }
}

export default useCustomerIndicator
