import TwoSideText from '@/components/TwoSideText'
import PostLogonMainBody from '@/layout/PostLogonMainBody'
import { useTranslation } from 'react-i18next'
import LoginInfo from './components/LoginInfo'
import PersonalInfo from './components/PersonalInfo'
import ContactInfo from './components/ContactInfo'
import EmploymentInfo from './components/EmploymentInfo'
import { ProfileInfoProps, useInitData } from './hooks/useInitData'
import Loading from '@/components/Loading'
import useHandleUserStatus from '@/hooks/useHandleUserStatus'
import { EcddStatusEnum } from '@/enums/ecddEnums'
import { useNavigate } from 'react-router-dom'
import { ProfileSectionEnum } from '@/enums/profileSectionEnums'

const ProfileSecionNameMap = {
  [ProfileSectionEnum.PERSONAL_INFO]: 'personal-information',
  [ProfileSectionEnum.CONTACT_INFO]: 'contact-information',
  [ProfileSectionEnum.RESET_PASSWORD]: 'login',
  [ProfileSectionEnum.EMPLOYMENT_INFO]: 'employment-information',
}

const FormTypedComponent = {
  [ProfileSectionEnum.PERSONAL_INFO]: PersonalInfo,
  [ProfileSectionEnum.CONTACT_INFO]: ContactInfo,
  [ProfileSectionEnum.RESET_PASSWORD]: LoginInfo,
  [ProfileSectionEnum.EMPLOYMENT_INFO]: EmploymentInfo,
}

const ManageProfile = () => {
  const { t } = useTranslation('profileInfoPage')

  const { isLoading, profileInfo } = useInitData()

  return (
    <PostLogonMainBody>
      {isLoading ? (
        <Loading isLoadingPage />
      ) : (
        <>
          <h1 className='font-bold'>{t('manage-profile')}</h1>
          <div className='flex flex-col gap-4 md:gap-6'>
            <ProfileInfoSection
              category={ProfileSectionEnum.PERSONAL_INFO}
              profileInfo={profileInfo}
            />
            <ProfileInfoSection
              category={ProfileSectionEnum.CONTACT_INFO}
              profileInfo={profileInfo}
            />
            <ProfileInfoSection
              category={ProfileSectionEnum.RESET_PASSWORD}
              profileInfo={profileInfo}
            />
            <ProfileInfoSection
              category={ProfileSectionEnum.EMPLOYMENT_INFO}
              profileInfo={profileInfo}
            />
            <p className='text-pi-gray-2 text-sm mb-0'>
              {t('edit-profile-note')}
            </p>
          </div>
        </>
      )}
    </PostLogonMainBody>
  )
}

export interface ProfileInfoSectionProps {
  category: ProfileSectionEnum
  profileInfo: ProfileInfoProps
}

const ProfileInfoSection = ({
  category,
  profileInfo,
}: ProfileInfoSectionProps) => {
  const { t } = useTranslation('profileInfoPage')
  const navigate = useNavigate()

  const { ecddStatus, handleCheckUserStatus } = useHandleUserStatus()

  const onClickEdit = (category: ProfileSectionEnum) => {
    /* ECDD Check */
    if (
      category !== ProfileSectionEnum.RESET_PASSWORD &&
      ecddStatus !== EcddStatusEnum.Passed
    ) {
      handleCheckUserStatus({ forceOpenModal: 'ecdd' })
      return
    }

    navigate(category)
  }

  const MappedType = FormTypedComponent[category]

  return (
    <div className='flex flex-col gap-2'>
      <TwoSideText
        className='text-base font-bold'
        left={t(ProfileSecionNameMap[category])}
        right={
          <div
            className='text-base text-pi-principal-blue cursor-pointer'
            onClick={() => onClickEdit(category)}
          >
            {t('edit')}
          </div>
        }
      />
      {!!MappedType && <MappedType {...profileInfo} />}
    </div>
  )
}

export default ManageProfile
