import { ActionModal, ActionModalProps } from '@pimy-b2cweb/frontend-lib'
import { useTranslation, Trans } from 'react-i18next'
import ExternalLink from '@/components/ExternalLink'
import useSwitchLang from '@/hooks/useSwitchLang'
import { openCSLink } from '@/utils'

export const SignupBlockedModal = (
  props: Omit<
    ActionModalProps,
    'title' | 'actionsStyle' | 'actions' | 'children'
  >
) => {
  const { t } = useTranslation('signinPage')
  const { langCode } = useSwitchLang()

  return (
    <ActionModal
      {...props}
      title={t('sign-in-blocked-title')}
      actionsStyle='full'
      actions={[
        {
          label: t('chat-with-us-on-whatsapp'),
          onClick: openCSLink,
          variant: 'contained',
        },
      ]}
      closeBtn={false}
    >
      <Trans
        i18nKey='sign-in-blocked-text'
        t={t}
        components={{
          P: <p />,
          NB: (
            <ExternalLink
              href={`https://www.principal.com.my/${langCode}/others/branches-offices`}
            />
          ),
          B: <strong />,
        }}
      />
    </ActionModal>
  )
}

export default SignupBlockedModal
