import { CustomerProfileAddressContact } from '@pimy-b2cweb/apiclient-b2cweb-r2'

export type AddressLines = Pick<
  CustomerProfileAddressContact,
  | 'addressLine1'
  | 'addressLine2'
  | 'addressLine3'
  | 'addressLine4'
  | 'addressLine5'
>

export type PostalInfo = Pick<
  CustomerProfileAddressContact,
  'postalCode' | 'cityName' | 'state' | 'country'
>

export interface CustomerAddress {
  addressLines: AddressLines
  postalInfo: PostalInfo
}

export const getCustomerProfileAddress = (
  address: CustomerAddress
): CustomerProfileAddressContact => {
  return {
    ...address.addressLines,
    ...address.postalInfo,
  }
}
