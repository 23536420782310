import TwoSideText from '@/components/TwoSideText'
import { RoutePathEnum } from '@/constants/routePath'
import { Button, Divider } from '@mui/material'
import { formattedAmount } from '@pimy-b2cweb/frontend-lib'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TransactionDetails } from '../..'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { DD_MMM_YYYY, HH_MM_SS_A } from '@/constants'

dayjs.extend(utc)

export interface ResultPageProps {
  transactionDetails?: TransactionDetails
}

const ResultPage = ({ transactionDetails }: ResultPageProps) => {
  const { t } = useTranslation(['redemptionPage', 'common'])
  const navigate = useNavigate()

  if (!transactionDetails) {
    navigate(RoutePathEnum.ERROR)
  }

  const date = dayjs.utc(transactionDetails?.submittedDate).local()

  return (
    <>
      <h1 className='mb-4 font-bold'>{t('cash-out-submitted')}</h1>
      <p className='mb-0'>{t('cash-out-submitted-summary')}</p>
      <Divider className='my-6' />
      <TwoSideText
        className='mb-2 text-pi-gray-1'
        left={t('cash-out-amount')}
        right={formattedAmount({
          amount: transactionDetails?.amount || 0,
        })}
      />
      <TwoSideText
        className='mb-2 text-pi-gray-1'
        left={t('cash-out-via')}
        right={transactionDetails?.trxType}
      />
      <TwoSideText
        className='mb-2 text-pi-gray-1'
        left={t('date', { ns: 'common' })}
        right={date.format(DD_MMM_YYYY)}
      />
      <TwoSideText
        className='mb-2 text-pi-gray-1'
        left={t('time', { ns: 'common' })}
        right={date.format(HH_MM_SS_A)}
      />
      <TwoSideText
        className='mb-2 text-pi-gray-1'
        left={t('reference-no')}
        right={transactionDetails?.trxRefNo}
      />
      <TwoSideText
        className='mb-2 text-pi-gray-1'
        left={t('transaction-no')}
        right={transactionDetails?.transactionHostRefNo || ''}
      />
      <TwoSideText
        className='mb-2 text-pi-gray-1'
        left={t('transaction-id')}
        right={transactionDetails?.pgRefNo || ''}
      />
      <TwoSideText
        className='text-xl font-bold text-pi-navy-blue my-2'
        left={`${t('total', { ns: 'common' })}:`}
        right={formattedAmount({ amount: transactionDetails?.amount || 0 })}
      />
      <Button
        fullWidth
        variant='contained'
        size='large'
        className='mb-8 mt-8'
        onClick={() => navigate(RoutePathEnum.DASHBOARD)}
      >
        {t('back-to-dashboard', { ns: 'common' })}
      </Button>
    </>
  )
}

export default ResultPage
