import { TextField } from '@mui/material'
import {
  ButtonWithLoading,
  CSLink,
  FormLabel,
  ResponseError,
  SelectBoxComp,
  useResponseError,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EcddFormLovKeysEnum, ScreenTypeEnum } from '@/api/commonApi'
import {
  UseMutationSubmitEcddForm as ECDDFormProps,
  useMutationSubmitEcddForm,
} from '@/api/customerApi'
import { useQueryCustomerProfile } from '@/api/customerApi'
import AddressFieldComp from '@/components/AddressFieldComp'
import Loading from '@/components/Loading'
import { RoutePathEnum } from '@/constants/routePath'
import useSetUserNotIdle from '@/hooks/useSetUserNotIdle'
import useLov from '@/hooks/useLov'
import {
  checkIfCustomerIsIndividual,
  formatAddress,
  getErrorResponseCode,
} from '@/utils'

export const ECDDForm = () => {
  const { lovsCurrLang, validated: lovValidated } = useLov({
    screen: ScreenTypeEnum.ECDD_FORM,
  })

  const { t } = useTranslation(['profileInfoPage', 'common'])

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()
  const [isIndividualCustomer, setIsIndividualCustomer] = useState(false)

  const { control, handleSubmit, setValue } = useForm<ECDDFormProps>({
    mode: 'onChange',
    defaultValues: {
      address: {
        line1: '',
      },
      sourceOfWealth: '',
      sourceOfIncome: '',
      numberOfTransaction: '',
      volumeOfTransaction: '',
      estimatedNetWorth: '',
      supportingDocuments: '',
    },
  })
  const { status: queryStatus, data: queryData } = useQueryCustomerProfile()

  useEffect(() => {
    if (queryStatus === 'error') navigate(RoutePathEnum.ERROR)
  }, [queryStatus])
  useEffect(() => {
    if (!!queryData?.employerName) setValue('name', queryData?.employerName)
    if (!!queryData?.occupation) {
      setIsIndividualCustomer(checkIfCustomerIsIndividual(queryData.occupation))
    }
  }, [queryData])

  const navigate = useNavigate()
  const setUserNotIdle = useSetUserNotIdle()

  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationSubmitEcddForm()

  const onSubmit = async (data: ECDDFormProps) => {
    setResponseErrorAttrs(undefined)
    setUserNotIdle()
    const address = (
      !isIndividualCustomer
        ? formatAddress({
            address: data.address,
            addressLineNum: 3,
            idPrefix: 'line',
          })
        : data.address
    ) as ECDDFormProps['address']
    mutate({ ...data, address })
  }

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      console.log('mutatedData: ', mutatedData)
      navigate(RoutePathEnum.PROFILE_VERIFICATION)
    }
  }, [mutatedData, mutateStatus, mutatedError])

  return !lovValidated || queryStatus !== 'success' ? (
    <Loading isLoadingPage={true} />
  ) : (
    <>
      <h1>{t('profile-info')}</h1>
      <h2>{t('employment-information')}</h2>

      {!!responseErrorAttrs && (
        <ResponseError>
          {t(responseErrorAttrs.i18nKey, {
            ns: responseErrorAttrs.ns,
          })}
        </ResponseError>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='name'
          control={control}
          defaultValue=''
          rules={{
            required: {
              value: !isIndividualCustomer,
              message: 'Required',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='name'
              label={t('employer-name')}
              isError={!!error}
              className='!mb-4'
            >
              <TextField
                type='text'
                fullWidth
                {...field}
                error={!!error}
                helperText={!!error ? (error?.message as string) : ''}
                disabled={isIndividualCustomer || mutateIsLoading}
              />
            </FormLabel>
          )}
        />
        <Controller
          name='address'
          control={control}
          rules={{
            validate: (value) =>
              isIndividualCustomer ||
              value.line1.trim().length > 0 ||
              'Required',
          }}
          render={({ field, fieldState: { error } }) => (
            <AddressFieldComp
              {...field}
              addressLineNum={3}
              error={error}
              disabled={isIndividualCustomer || mutateIsLoading}
              label={t('employer-address')}
              rootId='address'
              idPrefix='line'
            />
          )}
        />

        <Controller
          name='sourceOfIncome'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='sourceOfIncome'
              label={t('income-source')}
              isError={!!error}
            >
              <SelectBoxComp
                id='idType'
                items={lovsCurrLang[EcddFormLovKeysEnum.SourceOfIncome] || []}
                {...field}
                error={error}
                disabled={mutateIsLoading}
                fullWidth
                className='!mb-10'
                placeholder={t('please-select-an-option', { ns: 'common' })}
              />
            </FormLabel>
          )}
        />

        <h2>{t('account-activities-per-month')}</h2>

        <Controller
          name='numberOfTransaction'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='numberOfTransaction'
              label={t('number-of-txn')}
              isError={!!error}
            >
              <SelectBoxComp
                id='numberOfTransaction'
                items={
                  lovsCurrLang[EcddFormLovKeysEnum.NumberOfTransaction] || []
                }
                {...field}
                error={error}
                disabled={mutateIsLoading}
                fullWidth
                className='!mb-4'
                placeholder={t('please-select-an-option', { ns: 'common' })}
              />
            </FormLabel>
          )}
        />

        <Controller
          name='volumeOfTransaction'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='volumeOfTransaction'
              label={t('volume-of-txn')}
              isError={!!error}
            >
              <SelectBoxComp
                id='volumeOfTransaction'
                items={
                  lovsCurrLang[EcddFormLovKeysEnum.VolumeOfTransaction] || []
                }
                {...field}
                error={error}
                disabled={mutateIsLoading}
                fullWidth
                className='!mb-10'
                placeholder={t('please-select-an-option', { ns: 'common' })}
              />
            </FormLabel>
          )}
        />

        <h2>{t('others-info')}</h2>

        <Controller
          name='sourceOfWealth'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='sourceOfWealth'
              label={t('wealth-source')}
              isError={!!error}
            >
              <SelectBoxComp
                id='sourceOfWealth'
                items={lovsCurrLang[EcddFormLovKeysEnum.SourceOfWealth] || []}
                {...field}
                error={error}
                disabled={mutateIsLoading}
                fullWidth
                className='!mb-4'
                placeholder={t('please-select-an-option', { ns: 'common' })}
              />
            </FormLabel>
          )}
        />

        <Controller
          name='estimatedNetWorth'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='estimatedNetWorth'
              label={t('est-net-worth')}
              isError={!!error}
            >
              <SelectBoxComp
                id='estimatedNetWorth'
                items={
                  lovsCurrLang[EcddFormLovKeysEnum.EstimatedNetWorth] || []
                }
                {...field}
                error={error}
                disabled={mutateIsLoading}
                fullWidth
                className='!mb-4'
                placeholder={t('please-select-an-option', { ns: 'common' })}
              />
            </FormLabel>
          )}
        />

        <Controller
          name='supportingDocuments'
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='supportingDocuments'
              label={t('supporting-docs')}
              isError={!!error}
            >
              <SelectBoxComp
                id='supportingDocuments'
                items={
                  lovsCurrLang[EcddFormLovKeysEnum.SupportingDocument] || []
                }
                {...field}
                error={error}
                disabled={mutateIsLoading}
                fullWidth
                className='!mb-4'
                placeholder={t('please-select-an-option', { ns: 'common' })}
              />
            </FormLabel>
          )}
        />

        <p className='mb-10 text-sm'>
          <Trans
            i18nKey='any-info-contact-cs'
            t={t}
            ns='common'
            components={{
              CSLink: <CSLink />,
            }}
          />
        </p>

        <ButtonWithLoading
          type='submit'
          fullWidth
          variant='contained'
          size='large'
          disabled={mutateIsLoading}
          isLoading={mutateIsLoading}
        >
          {t('Continue', { ns: 'common' })}
        </ButtonWithLoading>
      </form>
    </>
  )
}

export default ECDDForm
