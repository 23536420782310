import { useQueryFundList } from '@/api/commonApi'
import { FundInformation } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useMemo } from 'react'

export type fundNameMapType = {
  [key: FundInformation['code']]: FundInformation['name']
}

export const useQueryFundNameMapping = () => {
  const { data: fundList, ...restQueryProps } = useQueryFundList({
    enabled: true,
  })

  const fundNamesMapping = useMemo(() => {
    const fundNamesMap: fundNameMapType = {}
    fundList?.forEach(({ code, name }) => (fundNamesMap[code] = name))
    return fundNamesMap
  }, [fundList])

  return {
    ...restQueryProps,
    fundNamesMapping,
  }
}
