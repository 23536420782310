export const SITE_TITLE = 'B2C Portal | Principal Malaysia'

export enum UnauthedStatusEnum {
  Unknown = 'unknown',
  SignInPendingPassword = 'signInPendingPassword',

  SignUpPendingEmail = 'signUpPendingEmail',
  SignUpPendingOtp = 'signUpPendingOtp',
  SignUpPendingUsername = 'signUpPendingUsername',
  SignUpPendingPassword = 'SignUpPendingPassword',
  SignUpSucceed = 'signUpSucceed',

  RecoverUserIdSucceed = 'RecoverUserIdSucceed',

  ResetPwPendingOtp = 'resetPwPendingOtp',
  ResetPwPendingPassword = 'resetPwPendingPassword',
  ResetPwSucceed = 'resetPwSucceed',
}
export enum AuthedStatusEnum {
  SigningIn = 'SigningIn',

  PostSignUpCheck = 'PostSignUpCheck',

  DirectToEKYC = 'directToEKYC',

  // profile setup -
  RequireEKYC = 'requireEKYC',
  RequireCustomerProfile = 'requireCustomerProfile',

  LoggedIn = 'loggedIn',

  IsafExpired = 'IsafExpired',
  IsafNotPassed = 'IsafNotPassed',
  IsafUnknown = 'IsafUnknown',

  //ECDD --
  EcddSubmitted = 'ecddSubmitted',
  EcddPending = 'ecddPending',
  EcddRejected = 'ecddRejected',
  EcddExpired = 'ecddExpired',

  EpfPurchasing = 'EpfPurchasing',
  EpfRedemption = 'EpfRedemption',
  EpfSwitching = 'EpfSwitching',

  CustomerIndicatorError = 'customerIndicatorError',

  SigningOut = 'SigningOut',
}
export const AUTHED_STATUS: string[] = Object.values(AuthedStatusEnum)

// export type AuthStatus = UnauthedStatusEnum | AuthedStatusEnum

const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID || '{clientId}'
const ISSUER =
  process.env.REACT_APP_OKTA_ISSUER ||
  'https://{yourOktaDomain}.com/oauth2/default'
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false
const REDIRECT_URI = `${window.location.origin}/login/callback`
const USE_INTERACTION_CODE =
  process.env.USE_INTERACTION_CODE === 'true' || false
export const oidc = {
  clientId: CLIENT_ID,
  issuer: ISSUER,
  redirectUri: REDIRECT_URI,
  scopes: ['openid', 'profile', 'email','offline_access'],
  pkce: true,
  disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  useInteractionCode: USE_INTERACTION_CODE,
}

export * from './legalLinks'
