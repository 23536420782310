import { useTranslation } from 'react-i18next'
import ButtonTabGroup, {
  ButtonTabGroupProps,
} from '@/components/ButtonTabGroup'
import { InvestmentCategoryEnum } from '@/enums/InvestmentCategoryEnum'

export interface InvestmentCategoryTabsProps
  extends Pick<ButtonTabGroupProps, 'handleSetValue' | 'value'> {
  investmentCategories?: InvestmentCategoryEnum[]
  showHeader: boolean
}
export const InvestmentCategoryTabs = ({
  showHeader,
  investmentCategories,
  ...rest
}: InvestmentCategoryTabsProps) => {
  const { t } = useTranslation('investmentCategories')
  const tabList = !!investmentCategories?.length
    ? investmentCategories
    : Object.values(InvestmentCategoryEnum)

  return (
    <div
      className={`col-span-full -mx-4 lg:-mx-24 p-4 lg:!px-24 -my-4 bg-pi-gray-6 sticky z-40 transition-top duration-300 ${
        showHeader ? 'top-20 lg:top-16' : 'top-0'
      }`}
    >
      <ButtonTabGroup
        {...rest}
        variant='scrollable'
        scrollButtons={false}
        tabList={tabList.map((category) => ({
          key: category,
          label: t(category),
        }))}
        onItemClick={(key: string) => console.log(key)}
      />
    </div>
  )
}

export default InvestmentCategoryTabs
