export const AML_FAILED = 'aml_failed'
export const AML_EXPIRED = 'aml_expired' //TODO
export const COMMON_PASSWORD_CHECK_FAILED = 'common_password_check_failed'
export const ERR_NETWORK = 'ERR_NETWORK'

export const INPUT_INVALID = 'input_invalid'

export const INTERNAL_SERVER_ERROR = 'internal_server_error'

export const INVALID_ID_NUMBER = 'invalid_id_number'
export const INVALID_PASSWORD = 'invalid-password'
export const INVALID_REQUEST = 'invalid_request'
export const INVALID_SESSION = 'invalid_session'
export const INVALID_USERID = 'invalid-userid'

export const LOCKED_OUT = 'LOCKED_OUT'

export const MISSING_TRX_REF_NO = 'mising-trx-ref-no'

export const NO_DUPLICATE_INPUT_SELECTION = 'no-duplicate-input-selection'

export const OKTA_AUTH_ERROR = 'okta_auth_error'

export const PASSWORD_EXPIRED = 'PASSWORD_EXPIRED'
export const PASSWORD_NOT_MATCH = 'password-do-not-match'

export const RECIPIENT_REQUIRED = 'recipient_required'
export const REQUEST_INVALID = 'request_invalid'
export const REQUIRE_RESET_PASSWORD = 'require_reset_password'
export const RISK_PROFILE_NOT_EXPIRED = 'risk_profile_expired'
export const RISK_PROFILE_NOT_PASSED = 'risk_profile_not_passed'

export const SIGNUP_NOT_ALLOWED = 'signup_not_allowed'
export const SUBACCOUNT_INVALID = 'subaccount_invalid'
export const SUBMIT_FAILED = 'submit_failed'

export const UNKNOWN_ERROR = 'unknown_error'
export const USERID_NOT_AVAILABLE = 'userid_not_available'
