import { AuthedStatusEnum } from '@/config'
import { IsafStatusEnum } from '@/enums/isafEnums'
import { EcddStatusEnum } from '@/enums/ecddEnums'

export const convertUserStatus = (authStatus: AuthedStatusEnum) => {
  const isafStatus = [
    AuthedStatusEnum.IsafExpired,
    AuthedStatusEnum.IsafNotPassed,
    AuthedStatusEnum.IsafUnknown,
  ].includes(authStatus as AuthedStatusEnum)
    ? (authStatus as unknown as IsafStatusEnum)
    : IsafStatusEnum.Passed

  const ecddStatus = getEcddStatus(authStatus)

  const isValidUserStatus =
    ecddStatus === EcddStatusEnum.Passed && isafStatus === IsafStatusEnum.Passed

  return { isafStatus, ecddStatus, isValidUserStatus }
}

const getEcddStatus = (authStatus: AuthedStatusEnum) => {
  switch (authStatus) {
    case AuthedStatusEnum.EcddPending:
    case AuthedStatusEnum.EcddSubmitted:
      return EcddStatusEnum.Pending

    case AuthedStatusEnum.EcddRejected:
      return EcddStatusEnum.Rejected

    case AuthedStatusEnum.EcddExpired:
      return EcddStatusEnum.Expired

    default:
      return EcddStatusEnum.Passed
  }
}
