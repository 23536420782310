import { ReactComponent as ErrorIco } from '../../assets/error-ico.svg'

const ErrorMessageComp = ({
  className = '',
  type,
  message,
  displayIcon = true,
}: {
  className?: string
  type: string
  message?: string
  displayIcon?: boolean
}) => {
  return (
    <div className={`flex flex-col items-center gap-4 px-4 ${className}`}>
      {displayIcon && <ErrorIco className='w-16 h-16' />}
      <h1 className='text-pi-navy-blue text-center'>{type}</h1>
      {!!message && <p className='text-center'>{message}</p>}
    </div>
  )
}
export default ErrorMessageComp
