import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { sessionReset as sesionProfileEditReset } from '@/stores/profileEdit'
import { selectProfileEditSessionState } from '@/stores/profileEdit.selectors'
import ContactInfo from './subpages/ContactInfo'
import EmploymentInfo from './subpages/EmploymentInfo'
import ResetPassword from './subpages/ResetPassword'
import ErrorNotFound from '../ErrorNotFound'
import PersonalInfo from './subpages/PersonalInfo'
import ManageProfile from './subpages/ManageProfile'
import { ProfileSectionEnum } from '@/enums/profileSectionEnums'

const ProfileEdit = () => {
  const params = useParams()
  const category = !params.category
    ? 'root'
    : Object.values(ProfileSectionEnum).find(
        (item) => item === params?.category
      )

  return <ProfileSection category={category} />
}

const ProfileSection = ({
  category,
}: {
  category?: ProfileSectionEnum | 'root'
}) => {
  const { pendingUpdateCheck } = useSelector(selectProfileEditSessionState)
  const dispatch = useDispatch()
  useEffect(() => {
    if (pendingUpdateCheck) dispatch(sesionProfileEditReset())
  }, [])

  switch (category) {
    case 'root':
      return <ManageProfile />
    case ProfileSectionEnum.PERSONAL_INFO:
      return <PersonalInfo openModalOnStart={pendingUpdateCheck} />
    case ProfileSectionEnum.CONTACT_INFO:
      return <ContactInfo openModalOnStart={pendingUpdateCheck} />
    case ProfileSectionEnum.EMPLOYMENT_INFO:
      return <EmploymentInfo openModalOnStart={pendingUpdateCheck} />
    case ProfileSectionEnum.RESET_PASSWORD:
      return <ResetPassword />

    default:
      return <ErrorNotFound />
  }
}

export default ProfileEdit
