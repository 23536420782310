import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  useMutationFetchLov,
  UseMutationFetchLov,
  getLovKey,
} from '@/api/commonApi'
import { selectLovSessionState } from '@/stores/lov.selectors'
import { useDispatch } from 'react-redux'
import { sessionUpdateLovItem } from '@/stores/lov'
import useSwitchLang from '@/hooks/useSwitchLang'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'

export const useLov = ({
  useOnErrorAutoRedirect = true,
  screen,
}: UseMutationFetchLov & {
  useOnErrorAutoRedirect?: boolean
}) => {
  const navigate = useNavigate()
  const { mutate, isError, error, status, data } = useMutationFetchLov({
    screen,
  })
  const dispatch = useDispatch()
  const { langCode } = useSwitchLang()
  const lovs = useSelector(selectLovSessionState)
  const [validated, setValidated] = useState(false)

  // if required items is missing from store, fetch API
  useEffect(() => {
    const _validateKeys = getLovKey(screen)

    const _validatePassed = _validateKeys.every((key) => !!lovs[langCode][key])

    if (!_validatePassed) {
      setValidated(false)
      mutate()
      return
    }
    setValidated(true)
  }, [lovs, langCode])

  // if api fetch complete, save data to store
  useEffect(() => {
    if (status === 'success' && !!data) {
      const lovs = data.reduce((acc, { key, items }) => {
        // race>others sort to bottom --
        const _items = (
          key !== 'race'
            ? items
            : items.sort((a, b) =>
                a.code === 'O' ? 1 : b.code === 'O' ? -1 : 0,
              )
        ).map(({ code, name }) => ({
          label: name,
          value: code,
          key: `${code}-${name}`,
        }))
        return { ...acc, [key]: _items }
      }, {})
      dispatch(sessionUpdateLovItem({ langCode, lovs }))
    }
  }, [status, data])

  useEffect(() => {
    if (useOnErrorAutoRedirect && (isError || !!error))
      navigate(RoutePathEnum.ERROR)
  }, [isError, error, useOnErrorAutoRedirect])

  return { lovsCurrLang: lovs[langCode], validated }
}

export default useLov
