import { useTranslation } from 'react-i18next'
import ButtonTabGroup from '@/components/ButtonTabGroup'
import { IsafResultEnum } from '@/enums/isafEnums'
import { RiskLevelDto } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { UseRiskProfileTabs } from '@/hooks/useRiskProfileTabs'

export interface RiskProfileTabsProps extends UseRiskProfileTabs {
  riskLevels?: RiskLevelDto[]
}

export const RiskProfileTabs = ({
  showHeader,
  riskLevels = [],
  ...rest
}: RiskProfileTabsProps) => {
  const { t } = useTranslation(['investmentPage', 'common'])

  return (
    <>
      <h3 className='col-span-full text-xl font-bold leading-7 mb-0'>
        {t('select-your-preffered-fund')}
      </h3>
      <div
        className={`col-span-full -mx-4 lg:-mx-24 p-4 lg:!px-24 -my-4 bg-pi-gray-6 sticky z-40 ${
          showHeader ? 'top-20 lg:top-16' : 'top-0'
        }`}
      >
        <ButtonTabGroup
          {...rest}
          variant='scrollable'
          scrollButtons={false}
          tabList={riskLevels.map(({ level }) => ({
            key: IsafResultEnum[level],
            label: t(IsafResultEnum[level], { ns: 'riskProfiles' }),
          }))}
          onItemClick={(key: string) => console.log(key)}
        />
      </div>
    </>
  )
}

export default RiskProfileTabs
