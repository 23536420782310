import { useTranslation } from 'react-i18next'
import { ReactComponent as Banner } from './assets/banner.svg'
import { Button } from '@mui/material'

export const Landing = ({ goNext }: { goNext: () => void }) => {
  const { t } = useTranslation(['isafPage', 'common'])

  return (
    <>
      <Banner className='w-full h-auto mt-11 mb-4' />
      <h1 className='text-2xl text-white'>
        {t('do-you-know-what-type-investor')}
      </h1>
      <p className='text-white mb-10'>{t('isaf-page-text')}</p>
      <Button variant='outlined' fullWidth onClick={goNext}>
        {t('Continue', { ns: 'common' })}
      </Button>
    </>
  )
}

export default Landing
