import { PropsWithChildren } from 'react'

const PostLogonContainer = ({
  className = '',
  children,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <div
      className={`bg-white sm:rounded-xl sm:p-6 sm:shadow-[0_4px_4px_0_rgba(0,0,0,0.1)] ${className}`}
    >
      {children}
    </div>
  )
}

export default PostLogonContainer
