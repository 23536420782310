import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface PasswordSucceedProps {
  goBackToProfile: () => void
}

export const PasswordSucceed = ({ goBackToProfile }: PasswordSucceedProps) => {
  const { t } = useTranslation('profileInfoPage')

  return (
    <>
      <h1>{t('password-successfully-changed')}</h1>
      <p className='mb-10'>{t('can-log-in-new-password')}</p>
      <Button
        fullWidth
        variant='contained'
        size='large'
        onClick={goBackToProfile}
      >
        {t('back-to-profile')}
      </Button>
    </>
  )
}

export default PasswordSucceed
