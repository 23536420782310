export const LEGAL_URL_PREFIX = 'https://www.principal.com.my/'

export const LEGAL_LINKS = {
  TermsAndConditions: {
    href: `${LEGAL_URL_PREFIX}others/terms-and-conditions?_ga=2.132971278.1896652433.1690343445-1267269838.1616149641`,
    labelKey: 'terms-and-conditions',
  },
  PrivacyNotice: {
    href: `${LEGAL_URL_PREFIX}privacy-notice-my?_ga=2.95738780.1896652433.1690343445-1267269838.1616149641`,
    labelKey: 'privacy-notice',
  },
  InternetRisk: {
    href: `${LEGAL_URL_PREFIX}internet-risk-my?_ga=2.95738780.1896652433.1690343445-1267269838.1616149641`,
    labelKey: 'internet-risk',
  },
  TransactionNotice: {
    href: `${LEGAL_URL_PREFIX}others/transaction-notice?_ga=2.95738780.1896652433.1690343445-1267269838.1616149641`,
    labelKey: 'transaction-notice',
  },
  CrossTradeProducts: {
    href: `${LEGAL_URL_PREFIX}cross-trades-policy-my?_ga=2.95738780.1896652433.1690343445-1267269838.1616149641`,
    labelKey: 'cross-trade-products',
  },
  FAQ: {
    href: `${LEGAL_URL_PREFIX}en/epf-faq?_ga=2.95738780.1896652433.1690343445-1267269838.1616149641`,
    labelKey: 'faq',
  },
  ContactUs: {
    href: `${LEGAL_URL_PREFIX}en/contact-us?_ga=2.95738780.1896652433.1690343445-1267269838.1616149641`,
    labelKey: 'contact-us',
  },
}
