import { BooleanFormValues } from '@/enums/formValueEnum'
import { useInitProfileInfoData } from '@/Routes/pages/ProfileEdit/hooks/useInitProfileInfoData'
import { CustomerProfileEditEmploymentInfoReqDto } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useForm } from 'react-hook-form'

export interface EmploymentInfoFormProps
  extends Omit<CustomerProfileEditEmploymentInfoReqDto, 'pepFlag'> {
  pepFlag: BooleanFormValues
  taxResidentOtherThanMalaysia: BooleanFormValues
  agreeTnc: string[]
}

export const useInitData = () => {
  const {
    lovs,
    lovValidated,
    isLoading,
    profileQueryData,
    profileQueryError,
    profileQueryStatus,
  } = useInitProfileInfoData()

  const employmentInfoFormMethods = useForm<EmploymentInfoFormProps>({
    mode: 'onChange',
    defaultValues: {
      occupation: '',
      natureOfBusiness: '',
      yearlyIncomeLevel: '',
      employerName: '',
      sourceOfFund: '',
      investmentObjectives: '',
      pepFlag: BooleanFormValues.FALSE,
      taxResidentOtherThanMalaysia: BooleanFormValues.FALSE,
      crsCountries: [
        {
          tin: '',
          country: '',
        },
      ],
      agreeTnc: [],
    },
  })

  return {
    employmentInfoFormMethods,
    lovs,
    lovValidated,
    isLoading,
    profileQueryError,
    profileQueryData,
    profileQueryStatus,
  }
}
