import { CssBaseline } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Security } from '@okta/okta-react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { SnackbarProvider } from 'notistack'
import { Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import EpfCountDownTimer from '@/components/EpfCountDownTimer'
import Loading from '@/components/Loading'
import Footer from '@/layout/Footer'
import { queryClient } from '@/lib/react-query'
import { store } from '@/stores'
import {
  InitRecaptcha,
  InitSearchParams,
  useOkta,
  OktaAutoSetToken,
} from './AppInit'
import Routes from './Routes'

function App() {
  const useOktaReturns = useOkta()

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Security {...useOktaReturns}>
          <HelmetProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SnackbarProvider
                maxSnack={10}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <CssBaseline />
                <Suspense fallback={<Loading />}>
                  <InitRecaptcha />
                  <InitSearchParams />
                  <OktaAutoSetToken />
                  <EpfCountDownTimer />
                  <Routes />
                  <Footer />
                </Suspense>
              </SnackbarProvider>
            </LocalizationProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </HelmetProvider>
        </Security>
      </QueryClientProvider>
    </Provider>
  )
}

export default App
