import { Button } from '@mui/material'
import { useTranslation, Trans } from 'react-i18next'
import { CSLink } from '@pimy-b2cweb/frontend-lib'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'
import { useSelector } from 'react-redux'
import { selectNotificationRecipient } from '@/stores/auth.selectors'

export const Succeed = () => {
  const { t } = useTranslation(['recoverUserIdPage', 'common'])
  const navigate = useNavigate()
  const notificationRecipient = useSelector(selectNotificationRecipient)

  return (
    <>
      <h1>{t('user-id-recovery-successful')}</h1>
      <p className='mb-10'>
        <Trans
          i18nKey='succeed-page-text'
          t={t}
          values={{ notificationRecipient }}
          components={{ B: <strong /> }}
        />
      </p>
      <Button
        fullWidth
        variant='contained'
        size='large'
        className='mb-6'
        onClick={() => navigate(RoutePathEnum.SIGN_IN)}
      >
        {t('Login Screen', { ns: 'common' })}
      </Button>
      <p className='text-center mb-0'>
        <Trans
          i18nKey='didnt-receive-the-email'
          t={t}
          components={{ CS: <CSLink /> }}
        />
      </p>
    </>
  )
}

export default Succeed
