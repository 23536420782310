import { FunctionComponent, SVGProps } from 'react'

export interface FunctionCardProps {
  title: string
  Icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>
  onClick?: () => void
}

const FunctionCard = ({ title, Icon, onClick }: FunctionCardProps) => {
  return (
    <div
      className='flex flex-col p-2 justify-center items-center gap-1 flex-shrink-0 bg-white rounded border border-solid border-pi-gray-6 shadow-[2px_2px_8px_-10px_rgba(165,165,174,0.10)] cursor-pointer'
      onClick={onClick}
    >
      <Icon className='w-6 h-6' />
      <div className='text-[10px]/[18px] font-bold text-pi-gray-1'>{title}</div>
    </div>
  )
}

export default FunctionCard
