import { TextField } from '@mui/material'
import { validateUsername } from '@pimy-b2cweb/common'
import {
  ButtonWithLoading,
  FormLabel,
  ResponseError,
  useModal,
  useResponseError,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  useMutationResetPwUsername,
  UseMutationResetPwUsername as UsernameFormProps,
} from '@/api/authApi'
import { INVALID_USERID } from '@/constants/errorCodes'
import { sessionResetPwPendingOtp } from '@/stores/auth'
import { getErrorResponseCode } from '@/utils'
import ActivationEmailModal from '../../components/ActivationEmailModal'

export const Username = () => {
  const { t } = useTranslation(['resetPasswordPage', 'common'])

  const dispatch = useDispatch()

  const { control, handleSubmit } = useForm<UsernameFormProps>({
    mode: 'onChange',
    defaultValues: {},
  })

  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationResetPwUsername()

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const activationEmailModal = useModal()

  const onSubmit = async (data: UsernameFormProps) => {
    console.log(data)
    setResponseErrorAttrs(undefined)
    mutate(data)
  }
  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      if (!!mutatedData.activationPending) {
        activationEmailModal.modalOpen()
        return
      }
      dispatch(sessionResetPwPendingOtp(mutatedData))
    }
  }, [mutatedData, mutateStatus, mutatedError])

  return (
    <>
      <h1>{t('reset-rassword')}</h1>
      <p>
        <Trans
          i18nKey='username-screen-text'
          t={t}
          components={{
            B: <strong />,
          }}
        />
      </p>
      {!!responseErrorAttrs && (
        <ResponseError>
          {t(responseErrorAttrs.i18nKey, {
            ns: responseErrorAttrs.ns,
          })}
        </ResponseError>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='username'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
            validate: (val: string) => {
              const issues = validateUsername(val)
              return issues.length === 0 || t(INVALID_USERID)
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='userId'
              label={t('User ID', { ns: 'common' })}
              isError={!!error}
              className='!mb-10'
            >
              <TextField
                type='text'
                fullWidth
                {...field}
                error={!!error}
                helperText={!!error ? (error?.message as string) : ''}
                disabled={mutateIsLoading}
                placeholder='johndoe123'
                autoComplete='off'
              />
            </FormLabel>
          )}
        />
        <ButtonWithLoading
          type='submit'
          fullWidth
          variant='contained'
          size='large'
          disabled={mutateIsLoading}
          isLoading={mutateIsLoading}
        >
          {t('Continue', { ns: 'common' })}
        </ButtonWithLoading>
      </form>
      <ActivationEmailModal {...activationEmailModal} />
    </>
  )
}

export default Username
