import { LanguageCodeEnum as LangEnum } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import useSwitchLang from '@/hooks/useSwitchLang'

export const SwitchLang = ({
  className = '',
  flipColor = false,
}: {
  className?: string
  flipColor?: boolean
}) => {
  const { langCode, handleSwitchLang } = useSwitchLang()
  const _clickableClass = `clickable-text ${
    flipColor ? 'white-text' : ''
  } font-normal`

  return (
    <div
      className={`flex gap-2 select-none ${
        flipColor ? 'text-pi-gray-3' : ''
      } ${className}`}
    >
      <span
        className={`${
          langCode !== LangEnum.en ? _clickableClass : 'font-bold'
        }`}
        onClick={() => handleSwitchLang(LangEnum.en)}
      >
        EN
      </span>
      |
      <span
        className={`${
          langCode !== LangEnum.ms ? _clickableClass : 'font-bold'
        }`}
        onClick={() => handleSwitchLang(LangEnum.ms)}
      >
        BM
      </span>
    </div>
  )
}

export default SwitchLang
