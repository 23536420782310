import { formattedAmount } from '@pimy-b2cweb/frontend-lib'
import { useTranslation } from 'react-i18next'
import FundPrice, { FundPriceProps } from './FundPrice'
import { FundPortfolioDto } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { getTransactionType } from '@/utils/transactionHistory'

export interface FundCardProps
  extends Pick<FundPortfolioDto, 'fundCode' | 'transactions'>,
    FundPriceProps {
  name: string
  className?: string
  isSelected?: boolean
  onCardClick?: () => void
  disabled?: boolean
}
const FundCard = ({
  name,
  currentValue,
  totalAssetGain,
  totalAssetGainPercentage,
  transactions = [],
  className = '',
  isSelected = false,
  onCardClick,
  disabled = false,
}: FundCardProps) => {
  const { t } = useTranslation(['dashboardPage', 'transactionHistoryPage'])

  return (
    <div
      className={`flex flex-col justify-between bg-white rounded px-4 py-3 outline outline-2 outline-transparent transition-ouline duration-300 shadow-[2px_6px_16px_-10px_#EAEAEA] ${
        !!onCardClick && isSelected ? '!outline-pi-principal-blue' : ''
      } ${!!onCardClick ? 'cursor-pointer' : ''} ${
        !!disabled ? 'cursor-not-allowed' : ''
      } ${className}`}
      onClick={onCardClick}
    >
      <div className='mb-2'>
        <div
          className={`text-sm font-bold mb-1 ${
            !!disabled ? 'text-pi-gray-3' : 'text-pi-gray-1'
          }`}
        >
          {name}
        </div>
        <FundPrice
          currentValue={currentValue}
          totalAssetGain={totalAssetGain}
          totalAssetGainPercentage={totalAssetGainPercentage}
          disabled={disabled}
        />
      </div>
      {!!transactions.length && (
        <div className='flex flex-col'>
          {transactions.map(({ id, type, amount = 0 }) => {
            const transactionType = getTransactionType(type)
            return (
              <div
                className={`text-xs mt-auto ${
                  !!disabled ? 'text-pi-gray-3' : 'text-pi-true-blue'
                }`}
                key={id}
              >
                {t('trnx-is-being-processing', {
                  amount: formattedAmount({
                    amount,
                  }),
                  transaction_type: t(transactionType, {
                    ns: 'transactionHistoryPage',
                  }),
                })}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default FundCard
