import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum EpfActionEnum {
  PURCHASE = 'purchase',
  REDEEM = 'redeem',
  SWITCH = 'switch',
}

export interface EpfTokenDecoded {
  aud?: string
  cifid?: string
  externalCifid?: string
  exp?: number
  iat?: number
  iss?: string
  scp?: string
  sub?: string
  idType?: string
  idNo?: string
  email?: string
  action?: EpfActionEnum
}
export interface EpfTokenSessionState {
  token?: string
  decoded?: EpfTokenDecoded
  expAt?: number //TimeStamp in ms
}

const initialState: EpfTokenSessionState = {
  token: undefined,
  decoded: undefined,
  expAt: undefined,
}

export const epfTokenSessionSlice = createSlice({
  name: 'epfTokenSession',
  initialState,
  reducers: {
    sessionReset: (state) => {
      state.token = initialState.token
      state.decoded = initialState.decoded
      state.expAt = initialState.expAt
    },
    sessionSetToken: (state, action: PayloadAction<EpfTokenSessionState>) => {
      state.token = action.payload.token
      state.decoded = action.payload.decoded
      state.expAt = action.payload.expAt
    },
  },
})

// action export
export const { sessionReset, sessionSetToken } = epfTokenSessionSlice.actions

export default epfTokenSessionSlice.reducer
