import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import Loading from '@/components/Loading'
import {
  ButtonWithLoading,
  FormLabel,
  RadioGroupComp,
  ResponseError,
} from '@pimy-b2cweb/frontend-lib'
import useLov from '@/hooks/useLov'
import { ScreenTypeEnum, RedemptionSurveyKeysEnum } from '@/api/commonApi'
import {
  RedemptionOrderState,
  SetRedemptionReasonProps,
} from '../../hooks/useRedemptionOrderReducer'
import {
  ProductIdEnum,
  WrapperTypeEnum,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { UseSubmitRedemptionOrderMutationResponse } from '../../hooks/useSubmitRedemptionOrderMutation'

export interface SurveyFormProps {
  cashOutReason: string
}

export interface SurveyPageProps
  extends UseSubmitRedemptionOrderMutationResponse {
  setRedemptionReason: (data: SetRedemptionReasonProps) => void
  goNext: () => void
  redemptionOrder: RedemptionOrderState
}

const SurveyPage = ({
  setRedemptionReason,
  goNext,
  redemptionOrder,
  mutate,
  mutateIsLoading,
  responseErrorAttrs,
}: SurveyPageProps) => {
  const { t } = useTranslation(['redemptionPage', 'common'])
  const { lovsCurrLang, validated: lovValidated } = useLov({
    screen: ScreenTypeEnum.REDEMPTION_SURVEY,
  })

  const { control, handleSubmit } = useForm<SurveyFormProps>({
    mode: 'onChange',
    defaultValues: {
      cashOutReason: '',
    },
  })

  const onSubmit = async (data: SurveyFormProps) => {
    console.log(data)
    setRedemptionReason({ redemptionReason: data.cashOutReason })
    const { productId } = redemptionOrder
    console.log('productId: ', productId)
    if (
      !!productId &&
      [ProductIdEnum.UTCash, ProductIdEnum.EPFCash].includes(productId)
    ) {
      goNext()
    } else {
      mutate({
        ...redemptionOrder,
        redemptionReason: data.cashOutReason,
        wrapperType: redemptionOrder.wrapperType as WrapperTypeEnum,
      })
    }
  }

  return !lovValidated ? (
    <Loading isLoadingPage={true} />
  ) : (
    <>
      <h1 className='font-black'>{t('cash-out', { ns: 'common' })}</h1>

      {!!responseErrorAttrs && (
        <ResponseError>
          {t(responseErrorAttrs.i18nKey, {
            ns: responseErrorAttrs.ns,
          })}
        </ResponseError>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='cashOutReason'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
          }}
          defaultValue=''
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='cashOutReason'
              label={
                <span className='whitespace-normal font-bold text-xl'>
                  {t('why-cash-out')}
                </span>
              }
              isError={!!error}
              className='mb-6'
            >
              <RadioGroupComp
                {...field}
                items={
                  lovsCurrLang[RedemptionSurveyKeysEnum.redemptionReason] || []
                }
                error={error}
                disabled={mutateIsLoading}
              />
            </FormLabel>
          )}
        />

        <ButtonWithLoading
          type='submit'
          fullWidth
          variant='contained'
          size='large'
          className='!mt-12'
          disabled={mutateIsLoading}
          isLoading={mutateIsLoading}
        >
          {t('Continue', { ns: 'common' })}
        </ButtonWithLoading>
      </form>
    </>
  )
}

export default SurveyPage
