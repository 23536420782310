import { TextField, TextFieldProps } from '@mui/material'
import { ChangeEvent, forwardRef, memo } from 'react'

export interface NumericTextFieldProps
  extends Omit<TextFieldProps, 'type' | 'onChangemay'> {
  InputProps?: Omit<TextFieldProps['InputProps'], 'inputMode'>
}

const NumericTextField = memo(
  forwardRef<HTMLDivElement, NumericTextFieldProps>(
    ({ onChange, InputProps, ...rest }, ref) => {
      const handleChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => {
        if (/^[0-9]*$/.test(e.target.value)) !!onChange && onChange(e)
        return
      }

      return (
        <TextField
          ref={ref}
          type='tel'
          onChange={handleChange}
          {...rest}
          InputProps={{
            ...InputProps,
            inputMode: 'numeric',
          }}
        />
      )
    }
  )
)

export default NumericTextField
