import { Card } from '@mui/material'
import { PropsWithChildren } from 'react'
import { MainBody } from '@pimy-b2cweb/frontend-lib'
import Logo from '@pimy-b2cweb/frontend-lib/libSrc/assets/logo-cimb.svg'
import LogoFlip from '@pimy-b2cweb/frontend-lib/libSrc/assets/logo-cimb-flip.svg'
import SwitchLang from '@/components/SwitchLang'

export const PreLogonLayout = ({
  className = '',
  children,
  flipColor = false,
}: PropsWithChildren<{
  className?: string
  flipColor?: boolean
}>) => {
  return (
    <MainBody flipColor={flipColor}>
      <Card
        className={`blue-border form-layout response-container sm:px-10 sm:pt-10 sm:pb-20 grow ${
          !!flipColor ? 'bg-pi-prussian-blue' : ''
        } ${className}`}
      >
        <div className='flex'>
          <img
            src={!!flipColor ? LogoFlip : Logo}
            className='main-logo block w-[170px] h-auto mb-6'
            alt='Principal in alliance with CIMB'
          />
          <SwitchLang className='grow justify-end' flipColor={flipColor} />
        </div>
        {children}
      </Card>
    </MainBody>
  )
}

export default PreLogonLayout
