import ErrorMessageComp from '@/components/ErrorMessageComp'
import FundInfoCard from '@/components/FundInfoCard'
import InvestmentDisclaimer from '@/components/InvestmentDisclaimer/Index'
import Loading from '@/components/Loading'
import { FundInformation } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { MainBody } from '@pimy-b2cweb/frontend-lib'
import { useTranslation } from 'react-i18next'
import { FundCategory } from '../..'
import CheckOutInvestmentFooter from '../../components/CheckOutInvestmentFooter'
import { RiskProfileStatusProps } from '@/components/RiskProfileStatus'
import RiskProfileTabs, {
  RiskProfileTabsProps,
} from '@/components/RiskProfileTabs'
import { SelectedFund } from '@/hooks/useInvestmentReducer'
import { UseFundSelection } from '../../hooks/useFundSelection'
import TechnicalErrorComp from '@/components/TechnicalErrorComp'

export interface FundListByCatProps
  extends RiskProfileTabsProps,
    Omit<UseFundSelection, 'selectedFunds'>,
    RiskProfileStatusProps {
  category: FundCategory
  fundList: FundInformation[]
  isLoading: boolean
  isError: boolean
  openHighRiskModal: (
    fund: Omit<SelectedFund, 'amount'>,
    selectCallback: (state: boolean) => void
  ) => void
}

const FundListByCat = ({
  category,
  fundList,
  isLoading,
  isError,
  selectedFundCodes,
  selectedFundCount,
  onDeselectFund,
  goExternalEpf,
  onCheckOut,
  openHighRiskModal,
  ...propsRest
}: FundListByCatProps) => {
  const { t } = useTranslation(['investmentPage', 'common'])
  const isEpf = category === FundCategory.EPF

  const checkHighRiskFund = (
    fund: Omit<SelectedFund, 'amount'>,
    selectCallback: (state: boolean) => void
  ) => {
    openHighRiskModal(fund, selectCallback)
  }

  return (
    <>
      <MainBody>
        <RiskProfileTabs {...propsRest} />
        <h1 className='col-span-full font-bold'>{t(`category-${category}`)}</h1>
        {isLoading ? (
          <Loading className='min-h-[calc(100vh-510px)]' />
        ) : isError ? (
          <TechnicalErrorComp />
        ) : !!fundList.length ? (
          <>
            {fundList?.map(({ code, name, fundInfoUrl, ...rest }) => (
              <FundInfoCard
                className='col-span-full md:col-span-4 lg:col-span-3'
                key={code}
                name={name}
                fundInfoUrl={fundInfoUrl}
                {...rest}
                onSelectFund={(selectCallback: (state: boolean) => void) =>
                  isEpf
                    ? goExternalEpf()
                    : checkHighRiskFund({ name, code, ...rest }, selectCallback)
                }
                onDeselectFund={() =>
                  isEpf ? goExternalEpf() : onDeselectFund(code)
                }
                hasSelected={selectedFundCodes.includes(code)}
              />
            ))}
            <InvestmentDisclaimer className='col-span-full' />
          </>
        ) : (
          <ErrorMessageComp
            className='col-span-full min-h-[calc(100vh-450px)] sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 lg:col-start-5 lg:col-span-4 flex justify-center items-center gap-2 [&>h1]:!mb-0 [&>h1]:!text-xl [&>h1]:!font-normal [&>h1]:!text-pi-gray-1'
            type={t('oops-no-funds-available')}
            message={t('please-check-back-later')}
            displayIcon={false}
          />
        )}
      </MainBody>
      <CheckOutInvestmentFooter
        selectedFundCount={selectedFundCount}
        onCheckOut={onCheckOut}
      />
    </>
  )
}

export default FundListByCat
