import { useTranslation } from 'react-i18next'
import { ReactComponent as FilterIco } from '../../assets/filter.svg'
export const FilterButton = ({
  onClick,
  className = '',
}: {
  onClick: () => void
  className?: string
}) => {
  const { t } = useTranslation('transactionHistoryPage')

  return (
    <div
      onClick={onClick}
      className={`flex flex-row gap-1 cursor-pointer items-center ${className}`}
    >
      <FilterIco className='w-6 h-6' />
      <div className='text-base text-pi-principal-blue select-none'>
        {t('filter')}
      </div>
    </div>
  )
}

export default FilterButton
