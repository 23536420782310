import { useTranslation } from 'react-i18next'
import InfoContainer from '../InfoContainer'
import InfoField from '../InfoField'

const LoginInfo = () => {
  const { t } = useTranslation('common')
  return (
    <InfoContainer>
      <InfoField fieldName={t('Password')} value='*****************' />
    </InfoContainer>
  )
}

export default LoginInfo
