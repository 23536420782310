import { ProfileFormLovKeysEnum } from '@/api/commonApi'
import { NOT_APPLICABLE_BUSINESS_CODE } from '@/constants'
import { LovSessionStateLang } from '@/stores/lov'
import { checkForNonBusinessCustomer } from '@/utils'
import {
  ButtonWithLoading,
  FormLabel,
  RadioGroupComp,
  SelectBoxComp,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect, useMemo } from 'react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TermsAndConditionsComp, {
  TncNoticeVariantEnum,
} from '../TermsAndConditionsComp'
import { TextField } from '@mui/material'

export interface EditEmploymentInfoFormProps {
  onSubmitForm: (data: FieldValues) => void
  lovs: LovSessionStateLang
  submitButtonLabel: string
  isSubmitting?: boolean | undefined
}
const EditEmploymentInfoForm = ({
  onSubmitForm,
  lovs,
  submitButtonLabel,
  isSubmitting = false,
}: EditEmploymentInfoFormProps) => {
  const { t } = useTranslation(['profileInfoPage', 'common'])
  const { control, watch, handleSubmit, resetField, setValue } =
    useFormContext()
  const isTROM = watch('taxResidentOtherThanMalaysia') === 'true'
  const isTncChecked = !!watch('agreeTnc').length
  const watchOccupation = watch('occupation')
  const isNonBusinessCustomer = useMemo(
    () => checkForNonBusinessCustomer(watchOccupation),
    [watchOccupation]
  )

  const natureOfBusinesses = useMemo(() => {
    const natureOfBusinessList =
      lovs[ProfileFormLovKeysEnum.NatureOfBusiness] || []
    return !isNonBusinessCustomer
      ? natureOfBusinessList.filter(
          ({ value }) => value !== NOT_APPLICABLE_BUSINESS_CODE
        )
      : natureOfBusinessList
  }, [isNonBusinessCustomer])

  useEffect(() => {
    if (isNonBusinessCustomer) {
      setValue('natureOfBusiness', NOT_APPLICABLE_BUSINESS_CODE)
      resetField('employerName')
      return
    }
    resetField('natureOfBusiness')
  }, [isNonBusinessCustomer])

  useEffect(() => {
    if (!isTROM) {
      resetField('crsCountries[0].country')
      resetField('crsCountries[0].tin')
    }
  }, [isTROM])

  const NoYesRadioItems = [
    {
      value: false,
      label: t('No', { ns: 'common' }),
      key: 'no',
    },
    {
      value: true,
      label: t('Yes', { ns: 'common' }),
      key: 'yes',
    },
  ]

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Controller
        name='occupation'
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Required',
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <FormLabel id='occupation' label={t('occupation')} isError={!!error}>
            <SelectBoxComp
              id='occupation'
              items={lovs[ProfileFormLovKeysEnum.Occupation] || []}
              {...field}
              error={error}
              disabled={isSubmitting}
              fullWidth
              placeholder={t('please-select-an-option', { ns: 'common' })}
            />
          </FormLabel>
        )}
      />
      <Controller
        name='natureOfBusiness'
        control={control}
        rules={{
          required: {
            value: !isNonBusinessCustomer,
            message: 'Required',
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <FormLabel
            id='natureOfBusiness'
            label={t('nature-of-business')}
            isError={!!error}
          >
            <SelectBoxComp
              id='natureOfBusiness'
              items={natureOfBusinesses}
              {...field}
              error={error}
              disabled={isNonBusinessCustomer || isSubmitting}
              fullWidth
              placeholder={t('please-select-an-option', { ns: 'common' })}
            />
          </FormLabel>
        )}
      />
      <Controller
        name='yearlyIncomeLevel'
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Required',
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <FormLabel
            id='yearlyIncomeLevel'
            label={t('annual-income')}
            isError={!!error}
          >
            <SelectBoxComp
              id='yearlyIncomeLevel'
              items={lovs[ProfileFormLovKeysEnum.AnnualIncome] || []}
              {...field}
              error={error}
              disabled={isSubmitting}
              fullWidth
              placeholder={t('please-select-an-option', { ns: 'common' })}
            />
          </FormLabel>
        )}
      />
      <Controller
        name='employerName'
        control={control}
        defaultValue=''
        rules={{
          required: {
            value: !isNonBusinessCustomer,
            message: 'Required',
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <FormLabel
            id='employerName'
            label={t('employer-name')}
            isError={!!error}
            className='!mb-10'
          >
            <TextField
              type='text'
              fullWidth
              {...field}
              error={!!error}
              helperText={!!error ? (error?.message as string) : ''}
              disabled={isNonBusinessCustomer || isSubmitting}
            />
          </FormLabel>
        )}
      />
      <h2>{t('other-information')}</h2>
      <Controller
        name='sourceOfFund'
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Required',
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <FormLabel
            id='sourceOfFund'
            label={t('source-of-funds')}
            isError={!!error}
          >
            <SelectBoxComp
              id='sourceOfFund'
              items={lovs[ProfileFormLovKeysEnum.SourceOfFunds] || []} //TODo
              {...field}
              error={error}
              disabled={isSubmitting}
              fullWidth
              placeholder={t('please-select-an-option', { ns: 'common' })}
            />
          </FormLabel>
        )}
      />
      <Controller
        name='investmentObjectives'
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Required',
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <FormLabel
            id='investmentObjectives'
            label={t('what-purpose-of-investment')}
            isError={!!error}
            className='!my-5'
          >
            <RadioGroupComp
              {...field}
              items={lovs[ProfileFormLovKeysEnum.PurposeOfInvestment] || []}
              error={error}
              disabled={isSubmitting}
            />
          </FormLabel>
        )}
      />
      <Controller
        name='pepFlag'
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Required',
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <FormLabel
            id='pepFlag'
            label={
              <span className='whitespace-normal'>
                {t('hold-position-in-pubic-office')}
              </span>
            }
            isError={!!error}
            className='!my-5'
          >
            <RadioGroupComp
              {...field}
              items={NoYesRadioItems}
              error={error}
              disabled={isSubmitting}
            />
          </FormLabel>
        )}
      />
      <Controller
        name='taxResidentOtherThanMalaysia'
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Required',
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <FormLabel
            id='taxResidentOtherThanMalaysia'
            label={
              <span className='whitespace-normal'>
                {t('are-you-tax-resident-other-than-malaysia')}
              </span>
            }
            isError={!!error}
            className='!my-5'
          >
            <RadioGroupComp
              {...field}
              items={NoYesRadioItems}
              error={error}
              disabled={isSubmitting}
            />
          </FormLabel>
        )}
      />
      {isTROM && (
        <>
          <Controller
            name='crsCountries[0].country'
            control={control}
            rules={{
              required: {
                value: isTROM,
                message: 'Required',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormLabel
                id='crsCountries_country'
                label={t('tax-country')}
                isError={!!error}
              >
                <SelectBoxComp
                  id='crsCountries_country'
                  items={lovs[ProfileFormLovKeysEnum.Country] || []}
                  {...field}
                  error={error}
                  disabled={isSubmitting}
                  fullWidth
                  placeholder={t('please-select-an-option', { ns: 'common' })}
                />
              </FormLabel>
            )}
          />
          <Controller
            name='crsCountries[0].tin'
            control={control}
            defaultValue=''
            rules={{
              required: {
                value: isTROM,
                message: 'Required',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormLabel
                id='crsCountries_tin'
                label={t('tax-id-number')}
                isError={!!error}
                className='!mb-10'
              >
                <TextField
                  type='text'
                  fullWidth
                  {...field}
                  error={!!error}
                  helperText={!!error ? (error?.message as string) : ''}
                  disabled={isSubmitting}
                />
              </FormLabel>
            )}
          />
        </>
      )}

      <Controller
        name='agreeTnc'
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Required',
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TermsAndConditionsComp
            id='agreeTnc'
            variant={TncNoticeVariantEnum.Privacy}
            {...field}
            error={error}
            disabled={false}
          />
        )}
      />
      <ButtonWithLoading
        type='submit'
        fullWidth
        variant='contained'
        size='large'
        disabled={isSubmitting || !isTncChecked}
        isLoading={isSubmitting}
        className='!mb-5'
      >
        {submitButtonLabel}
      </ButtonWithLoading>
    </form>
  )
}

export default EditEmploymentInfoForm
