import { PropsWithChildren } from 'react'
import { MainBody } from '@pimy-b2cweb/frontend-lib'

export const PostLogonMainBody = ({
  className = '',
  children,
  useTransBg = true,
  layout = '10-8-6',
}: PropsWithChildren<{
  className?: string
  useTransBg?: boolean
  layout?: '10-8-6' | '8-6-4' | '12-10-8'
}>) => {
  const _bg = useTransBg ? 'bg-transparent' : 'bg-white sm:bg-transparent'
  const _responsive =
    layout === '10-8-6'
      ? 'sm:col-start-2 sm:col-span-10 md:col-start-3 md:col-span-8 lg:col-start-4 lg:col-span-6'
      : layout === '8-6-4'
      ? 'sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 lg:col-start-5 lg:col-span-4'
      : 'md:col-start-2 md:col-span-10 lg:col-start-3 lg:col-span-8'
  return (
    <MainBody
      className={`${_bg} [&>div]:sm:my-8 [&>div]:md:my-14 ${className}`}
    >
      <div className={`col-span-full ${_responsive}`}>{children}</div>
    </MainBody>
  )
}

export default PostLogonMainBody
