// export const APPLY_PROMO = 'APPLY_PROMO'

export const CASH_IN = 'CASH_IN'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CONFIRM_EDIT_PROFILE_INFO = 'CONFIRM_EDIT_PROFILE_INFO'
export const CUSTOMER_INDICATOR = 'CUSTOMER_INDICATOR'
export const CUSTOMER_PORTFOLIO_FETCH = 'CUSTOMER_PORTFOLIO_FETCH'
export const CUSTOMER_PROFILE_SETUP = 'CUSTOMER_PROFILE_SETUP'
export const CUSTOMER_PROFILE_FETCH = 'CUSTOMER_PROFILE_FETCH'
export const CUSTOMER_METADATA_FETCH = 'CUSTOMER_METADATA_FETCH'

export const EDIT_EMPLOYMENT_INFO = 'EDIT_EMPLOYMENT_INFO'
export const EDIT_CONTACT_INFO = 'EDIT_CONTACT_INFO'
export const EDIT_PERSONAL_INFO = 'EDIT_PERSONAL_INFO'

export const FUND_INFO = 'FUND_INFO'

export const GET_FUNDS = 'GET_FUNDS'
export const GET_PROMO_CODE = 'GET_PROMO_CODE'

export const GET_BANKS = 'GET_BANKS'
export const GET_POSTAL_LIST = 'GET_POSTAL_LIST'
export const GET_REWARD_LIST = 'GET_REWARD_LIST'

export const ISAF_QUEST = 'ISAF_QUEST'
export const INFINITE = 'INFINITE'
export const INIT_EKYC = 'INIT_EKYC'

export const OKTA_SIGN_IN = 'OKTA_SIGN_IN'
export const OKTA_SESSION_REFRESH = 'OKTA_SESSION_REFRESH'
export const ORDER_HISTORY_FILTER = 'ORDER_HISTORY_FILTER'

export const FETCH_LOV = 'FETCH_LOV'

export const GET_CURRENT_QUESTIONNAIRE = 'GET_CURRENT_QUESTIONNAIRE'
export const GET_PROFILE_DATA = 'GET_PROFILE_DATA'

export const PORTFOLIO_DETAILS = 'PORTFOLIO_DETAILS'
export const PURCHASING_INSTRUCTION_INIT = 'PURCHASING_INSTRUCTION_INIT'
export const PURCHASING_INSTRUCTION_SUBMIT = 'PURCHASING_INSTRUCTION_SUBMIT'
export const PURCHASING_INSTRUCTION_CONFIRM = 'PURCHASING_INSTRUCTION_CONFIRM'
export const PURCHASING_GET_ORDER_TRXREFNO = 'PURCHASING_GET_ORDER_TRXREFNO'

export const QUERY_REDEMPTION_ORDER_INSSTRUCTION =
  'QUERY_REDEMPTION_ORDER_INSSTRUCTION'
export const QUERY_RISK_LEVEL_LIST = 'QUERY_RISK_LEVEL_LIST'

export const RECOVER_USERNAME = 'RECOVER_USERNAME'

export const REDEMPTION_INSTRUCTION_CONFIRM = 'REDEMPTION_INSTRUCTION_CONFIRM'
export const REDEMPTION_INSTRUCTION_SUBMIT = 'REDEMPTION_INSTRUCTION_SUBMIT'
export const REDEMPTION_ORDER_TRX_DETAIL = 'REDEMPTION_ORDER_TRX_DETAIL'

export const RESEND_OTP = 'RESEND_OTP'
export const RESET_PW_USERNAME = 'RESET_PW_USERNAME'
export const RESET_PW_SETUP_PASSWORD = 'RESET_PW_SETUP_PASSWORD'

export const SEARCH_TRANSACTION_HISTORY = 'SEARCH_TRANSACTION_HISTORY'

export const SIGN_IN_USERNAME = 'SIGN_IN_USERNAME'

export const SIGN_UP_USERNAME = 'SIGN_UP_USERNAME'
export const SIGN_UP_EMAIL = 'SIGN_UP_EMAIL'
// export const SIGN_UP_VERIFY_OTP = 'SIGN_UP_VERIFY_OTP'
export const SIGN_UP_SETUP_USER = 'SIGN_UP_SETUP_USER'
export const SIGN_UP_SETUP_PASSWORD = 'SIGN_UP_SETUP_PASSWORD'

export const SUBMIT_ECDD_FORM = 'SUBMIT_ECDD_FORM'

export const SWITCHING_ORDER_TRX_DETAIL = 'SWITCHING_ORDER_TRX_DETAIL'
export const SWITCHING_ORDER_INSTRUCTION = 'SWITCHING_ORDER_INSTRUCTION'
export const SWITCHING_ORDER_INSTRUCTION_SUBMIT =
  'SWITCHING_ORDER_INSTRUCTION_SUBMIT'
export const SWITCHING_ORDER_INSTRUCTION_CONFIRM =
  'SWITCHING_ORDER_INSTRUCTION_CONFIRM'

export const VERIFY_AGENT_CODE = 'VERIFY_AGENT_CODE'
export const VERIFY_OTP = 'VERIFY_OTP'
export const VERIFY_PROMO_CODE = 'VERIFY_PROMO_CODE'
export const VERIFY_REFERRAL_CODE = 'VERIFY_REFERRAL_CODE'
