import { useMemo, useState } from 'react'
import { FundInformation } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'
import { SelectedFund } from '@/hooks/useInvestmentReducer'
import useGoExternalEpf from '@/hooks/useGoExternalEpf'

export interface UseFundSelection {
  onSelectFund: (fund: Omit<SelectedFund, 'amount'>) => void
  onDeselectFund: (code: string) => void
  goExternalEpf: () => Window | null
  selectedFunds: FundInformation[]
  selectedFundCount: number
  selectedFundCodes: string[]
  onCheckOut: () => void
}

const useFundSelection = (): UseFundSelection => {
  const [selectedFunds, setSelectedFunds] = useState<SelectedFund[]>([])
  const navigate = useNavigate()

  const onSelectFund = (fund: Omit<SelectedFund, 'amount'>) => {
    console.log(fund)
    setSelectedFunds((selectedFundList) => [
      ...selectedFundList,
      { ...fund, amount: 0 },
    ])
  }

  const onDeselectFund = (code: string) => {
    console.log(code)
    setSelectedFunds(selectedFunds.filter((fund) => fund.code !== code))
  }

  const goExternalEpf = useGoExternalEpf()

  const selectedFundCount = useMemo(() => selectedFunds.length, [selectedFunds])

  const selectedFundCodes = useMemo(
    () => selectedFunds.map((fund) => fund.code),
    [selectedFunds]
  )
  const onCheckOut = () =>
    navigate(RoutePathEnum.ORDER_PURCHASE_FORM, {
      state: { selectedFunds },
    })

  return {
    onSelectFund,
    onDeselectFund,
    goExternalEpf,
    selectedFunds,
    selectedFundCount,
    selectedFundCodes,
    onCheckOut,
  }
}

export default useFundSelection
