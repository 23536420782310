import HelmetSiteTitle from '@/components/HelmetSiteTitle'
import Loading from '@/components/Loading'
import TermsAndConditionsComp, {
  TncNoticeVariantEnum,
} from '@/components/TermsAndConditionsComp'
import TwoSideText from '@/components/TwoSideText'
import { InvestSessionState } from '@/hooks/useInvestmentReducer'
import useSetUserNotIdle from '@/hooks/useSetUserNotIdle'
import PostLogonContainer from '@/layout/PostLogonContainer'
import PostLogonMainBody from '@/layout/PostLogonMainBody'
import { getErrorResponseCode } from '@/utils'
import {
  ButtonWithLoading,
  formattedAmount,
  useModal,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useConfirmPurchasingInstruction from './hooks/useConfirmPurchasingInstruction'
import TechnicalErrorModal from '@/components/TechnicalErrorModal'

const ERROR_MESSAGES = ['epf_token_expired', 'order_request_expired']
interface InvestmentSummaryFormProps {
  agreeTnc: string[]
}

interface InvestmentSummaryProps extends InvestSessionState {
  requestToken: string
  handleErrorMessage: (error?: string) => void
  goToOrderForm: () => void
}

const InvestmentSummary = ({
  requestToken,
  handleErrorMessage,
  goToOrderForm,
  funds,
  campaignCode,
  agentCode,
  totalAmount,
}: InvestmentSummaryProps) => {
  const { t } = useTranslation(['cashInPage', 'common'])

  const setUserNotIdle = useSetUserNotIdle()

  const techinalErrorModal = useModal()

  const { control, handleSubmit, watch } = useForm<InvestmentSummaryFormProps>({
    mode: 'onChange',
    defaultValues: {
      agreeTnc: [],
    },
  })

  const {
    handleMutate,
    mutateStatus,
    mutatedError,
    isLoading,
    proceedToConfirm,
  } = useConfirmPurchasingInstruction()

  const onSubmit = (data: InvestmentSummaryFormProps) => {
    console.log(data)
    handleMutate({ requestToken })
    setUserNotIdle()
  }

  useEffect(() => {
    if (mutateStatus === 'error') {
      console.log('error:', mutatedError)
      const errMsg = getErrorResponseCode(mutatedError)
      if (ERROR_MESSAGES.includes(errMsg)) {
        handleErrorMessage(errMsg)
        return
      }
      techinalErrorModal.modalOpen()
      return
    }
  }, [mutateStatus, mutatedError])

  return proceedToConfirm ? (
    <Loading isLoadingPage={true} />
  ) : (
    <>
      <PostLogonMainBody useTransBg={false}>
        <HelmetSiteTitle pageTitle={t('investment-summary')} />
        <PostLogonContainer>
          <h1 className='font-bold'>{t('your-inv-summary')}</h1>

          <div className='flex flex-col gap-1 mb-4'>
            {funds.map(({ name, amount, salesFeePerc = 0 }) => (
              <div
                className='-mx-4 sm:-mx-6 p-4 sm:px-6 bg-pi-sky-blue-1 flex flex-col gap-4'
                key={name}
              >
                <div className='text-sm font-bold'>{name}</div>
                <div className='flex flex-col gap-1'>
                  <TwoSideText
                    className='text-sm items-center'
                    left={t('sales-charge-inclusive')}
                    right={`${salesFeePerc}%`}
                  />
                  <TwoSideText
                    className='text-sm items-center'
                    left={t('cash-in-amount')}
                    right={
                      <span className='font-bold'>
                        {formattedAmount({ amount })}
                      </span>
                    }
                  />
                </div>
              </div>
            ))}
          </div>

          <div className='flex flex-col gap-2 mb-6'>
            {!!campaignCode?.name && (
              <TwoSideText
                className='text-sm items-center'
                left={
                  <span className='font-bold'>{`${t('campaign', {
                    ns: 'common',
                  })}:`}</span>
                }
                right={campaignCode.name}
              />
            )}
            {!!agentCode?.agentName && (
              <TwoSideText
                className='text-sm items-center'
                left={
                  <span className='font-bold'>{`${t('agent', {
                    ns: 'common',
                  })}:`}</span>
                }
                right={agentCode.agentName}
              />
            )}
            <TwoSideText
              className='text-sm font-bold items-center'
              left={t('total-cash-in')}
              right={
                <span className='text-[28px] leading-[36px]'>
                  {formattedAmount({
                    amount: totalAmount,
                  })}
                </span>
              }
            />
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name='agreeTnc'
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Required',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TermsAndConditionsComp
                  id='agreeTnc'
                  variant={TncNoticeVariantEnum.Transition}
                  {...field}
                  error={error}
                  disabled={isLoading}
                />
              )}
            />

            <ButtonWithLoading
              type='submit'
              fullWidth
              variant='contained'
              size='large'
              className='mb-10 sm:mb-2'
              disabled={isLoading || !watch('agreeTnc').length}
              isLoading={isLoading}
            >
              {t('cash-in', { ns: 'common' })}
            </ButtonWithLoading>
          </form>
        </PostLogonContainer>
      </PostLogonMainBody>
      <TechnicalErrorModal {...techinalErrorModal} onContinue={goToOrderForm} />
    </>
  )
}

export default InvestmentSummary
