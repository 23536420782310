import { CustomerRiskProfileStatusEnum as RiskStatusEnum } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { UseMutationCustomerIndicatorRes } from '@/api/customerApi'
import { AuthedStatusEnum } from '@/config'
import { EpfActionEnum, EpfTokenSessionState } from '@/stores/epfToken'
import {
  checkEpfTokenExpiration,
  verifyAMLStatus,
  verifyAMLStatusEnum,
} from '@/utils'

export interface CustomerIndicatorSwitchLogic
  extends Pick<
    UseMutationCustomerIndicatorRes,
    | 'customerProfileCompleted'
    | 'amlStatus'
    | 'riskProfileStatus'
    | 'customerProfileExist'
  > {
  currAuthStatus: AuthedStatusEnum
  epfToken: EpfTokenSessionState['token']
  epfExpAt: EpfTokenSessionState['expAt']
  decodedEpfToken: EpfTokenSessionState['decoded']
}
export const customerIndicatorSwitchLogic = ({
  customerProfileCompleted,
  amlStatus,
  riskProfileStatus,
  customerProfileExist,
  currAuthStatus,
  epfToken,
  epfExpAt,
  decodedEpfToken,
}: CustomerIndicatorSwitchLogic) => {
  /**
   * reference:
   *    https://github.com/principalseasia-emu/my-b2cweb2.0-techdesign/blob/feature/r2-auth-design/R2/auth/login-step4-indicator.seq.mmd
   */

  const _hasEpfToken =
    !!epfToken && !!epfExpAt && checkEpfTokenExpiration(epfExpAt)

  // verify customerProfileCompleted --

  // if customerProfileCompleted = true --
  if (!!customerProfileCompleted) {
    //-// verify amlStatus --
    //-//-// if amlStatus not passed: goto ECDD flow --
    //-//-// else, continue origional journey --
    const _amlStatus = verifyAMLStatus(amlStatus)
    if (_amlStatus !== verifyAMLStatusEnum.AmlPassed)
      return _amlStatus as AuthedStatusEnum | verifyAMLStatusEnum.AmlUnknown

    // Check ecdd action and redirect to particular Form based on action type
    if (_hasEpfToken) {
      switch (decodedEpfToken?.action) {
        case EpfActionEnum.PURCHASE:
          return AuthedStatusEnum.EpfPurchasing
        case EpfActionEnum.REDEEM:
          return AuthedStatusEnum.EpfRedemption
        case EpfActionEnum.SWITCH:
          return AuthedStatusEnum.EpfSwitching
      }
    }

    switch (riskProfileStatus) {
      case RiskStatusEnum.passed:
        return AuthedStatusEnum.LoggedIn
      case RiskStatusEnum.expired:
        return AuthedStatusEnum.IsafExpired
      case RiskStatusEnum.not_passed:
        return AuthedStatusEnum.IsafNotPassed
      default:
        return AuthedStatusEnum.IsafUnknown
    }
  }

  // if customerProfileCompleted = false --

  //-// check has epfToken (and not expired) --
  //-// if _hasEpfToken true --
  //-//-// goto profile setup flow --
  if (_hasEpfToken) return AuthedStatusEnum.RequireCustomerProfile

  //-// if _hasEpfToken false --
  //-//-// verify customer profile exist --
  //-//-//-// if _customerProfileExist = true, goto profile setup flow
  if (!!customerProfileExist) return AuthedStatusEnum.RequireCustomerProfile

  //-//-//-// else, goto ekyc flow --
  return currAuthStatus === AuthedStatusEnum.PostSignUpCheck
    ? AuthedStatusEnum.DirectToEKYC
    : AuthedStatusEnum.RequireEKYC
}

export default customerIndicatorSwitchLogic
