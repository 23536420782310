import { useModal, formatMillisecToTime } from '@pimy-b2cweb/frontend-lib'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useExtToken from '@/hooks/useExtToken'
import useHandleSignOut from '@/hooks/useHandleSignOut'
import { selectAuthIsUserIdle } from '@/stores/auth.selectors'
import useSessionCountdown from './useSessionCountdown'

const checkPt = 5 * 60 * 1000 //5 min in sec

export const useManageSession = () => {
  const handleSignOut = useHandleSignOut()
  const isUserIdle = useSelector(selectAuthIsUserIdle)

  const { timeleft, formattedTimeleft, resetCounter } = useSessionCountdown()

  const useModalRestReturns = useModal()
  const { modalOpen, modalClose, open } = useModalRestReturns
  const { onExtSession, mutateStatus, mutateIsLoading } = useExtToken({
    onError: handleSignOut,
    onSuccess: modalClose,
  })

  useEffect(() => {
    console.log(
      'count down: ',
      formatMillisecToTime(timeleft || 0),
      formatMillisecToTime(checkPt)
    )
    if (!!timeleft && timeleft - checkPt <= 1000 && !mutateIsLoading) {
      //when time is almost up...
      if (!isUserIdle) {
        // if user is not idle, call extend session and reset counter
        resetCounter()
        onExtSession()
        return
      }
      if (!open) {
        // if user is idle, open extend session modal
        modalOpen()
        return
      }
    }

    if (!!timeleft && timeleft <= 1000) handleSignOut()
  }, [timeleft, mutateIsLoading])

  return {
    formattedTimeleft,
    mutateStatus,
    mutateIsLoading,
    handleSignOut,
    onExtSession,
    ...useModalRestReturns,
  }
}

export default useManageSession
