import { useQueryFundList } from '@/api/commonApi'
import {
  useQueryCustomerMetadata,
  useQueryCustomerPortfolio,
} from '@/api/customerApi'
import { useQueryRiskLevelList } from '@/api/riskProfileApi'
import { useMemo } from 'react'

export const useInitSwitchingData = () => {
  const {
    data: riskLevelList,
    isLoading: isLoadingRiskLevelListQuery,
    isError: isRiskLevelListQueryError,
  } = useQueryRiskLevelList()
  const {
    data: metaData,
    isLoading: isLoadingMetaDataQuery,
    isError: isMetadataQueryError,
  } = useQueryCustomerMetadata({})

  const {
    data: portfolioData,
    isLoading: isLoadingCP,
    isError: isErrorCP,
  } = useQueryCustomerPortfolio()

  const {
    data: fundList,
    isLoading: isLoadingFL,
    isError: isErrorFL,
  } = useQueryFundList({ enabled: true })

  const isLoading = useMemo(
    () =>
      isLoadingRiskLevelListQuery ||
      isLoadingMetaDataQuery ||
      isLoadingCP ||
      isLoadingFL,
    [
      isLoadingRiskLevelListQuery,
      isLoadingMetaDataQuery,
      isLoadingCP,
      isLoadingFL,
    ]
  )
  const isError = useMemo(
    () =>
      isRiskLevelListQueryError ||
      isMetadataQueryError ||
      isErrorCP ||
      isErrorFL,
    [isRiskLevelListQueryError, isMetadataQueryError, isErrorCP, isErrorFL]
  )

  return {
    riskLevelList,
    metaData,
    portfolioData,
    fundList,
    isLoading,
    isError,
  }
}
