import { useOktaAuth } from '@okta/okta-react'
import { selectOktaTokens } from '@/stores/auth.selectors'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

export const OktaAutoSetToken = () => {
  const { oktaAuth } = useOktaAuth()
  const oktaTokens = useSelector(selectOktaTokens)
  useEffect(() => {
    if (!!oktaTokens) {
      console.log('auto set token:', oktaTokens)
      oktaAuth.tokenManager.setTokens(oktaTokens)
    }
  }, [oktaTokens])

  return null
}

export default OktaAutoSetToken
