import {
  useResponseError,
  ResponseError,
  ButtonWithLoading,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { AuthedStatusEnum } from '@/config'
import useSignin from '@/hooks/useSignin'
import { getErrorResponseCode } from '@/utils'

export const Succeed = () => {
  const { t } = useTranslation(['signUpPage', 'common'])

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()
  const {
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
    handleSignIn,
    handleDispatchSignIn,
  } = useSignin()

  const handleGetStarted = () => {
    handleSignIn()
  }

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    if (mutateStatus === 'success' && !!mutatedData) {
      setResponseErrorAttrs(undefined)
      handleDispatchSignIn({
        status: AuthedStatusEnum.PostSignUpCheck,
      })
    }
  }, [mutatedData, mutateStatus, mutatedError])

  return (
    <>
      <h1>{t('congratulations')}</h1>
      {!!responseErrorAttrs && (
        <ResponseError>
          {t(responseErrorAttrs.i18nKey, {
            ns: responseErrorAttrs.ns,
          })}
        </ResponseError>
      )}
      <p className='mb-10'>
        <Trans
          i18nKey='succeed-page-text'
          t={t}
          components={{
            P: <p />,
            B: <strong />,
          }}
        />
      </p>
      <ButtonWithLoading
        fullWidth
        variant='contained'
        size='large'
        disabled={mutateIsLoading}
        isLoading={mutateIsLoading}
        onClick={handleGetStarted}
      >
        {t('get-started')}
      </ButtonWithLoading>
    </>
  )
}

export default Succeed
