import { useSelector } from 'react-redux'
import { selectAuthStatus } from '@/stores/auth.selectors'
import HelmetSiteTitle from '@/components/HelmetSiteTitle'
import { UnauthedStatusEnum } from '@/config'
import Username from './subpages/Username'
import VerifyOtp from './subpages/VerifyOtp'
import NewPassword from './subpages/NewPassword'
import PasswordSucceed from './subpages/PasswordSucceed'
import { useTranslation } from 'react-i18next'

export const ResetPassword = () => {
  const { t } = useTranslation('resetPasswordPage')
  return (
    <>
      <HelmetSiteTitle pageTitle={t('reset-rassword')} />
      <StatusSwitch />
    </>
  )
}

const StatusSwitch = () => {
  const authStatus = useSelector(selectAuthStatus)
  switch (authStatus) {
    case UnauthedStatusEnum.Unknown:
      return <Username />
    case UnauthedStatusEnum.ResetPwPendingOtp:
      return <VerifyOtp />
    case UnauthedStatusEnum.ResetPwPendingPassword:
      return <NewPassword />
    case UnauthedStatusEnum.ResetPwSucceed:
      return <PasswordSucceed />
    default:
      return <Username />
  }
}

export default ResetPassword
