import { ActionModal, ActionModalProps } from '@pimy-b2cweb/frontend-lib'
import { useTranslation } from 'react-i18next'

const HasUnappliedCodeModal = ({
  modalClose,
  handleHardProceed,
  ...rest
}: Omit<
  ActionModalProps,
  'title' | 'actions' | 'actionsStyle' | 'closeBtn' | 'onClose' | 'children'
> & {
  modalClose: () => void
  handleHardProceed: () => void
}) => {
  const { t } = useTranslation(['cashInPage', 'common'])

  return (
    <ActionModal
      {...rest}
      title={t('has-unapplied-code-title')}
      actions={[
        {
          label: t('Proceed', { ns: 'common' }),
          onClick: () => {
            handleHardProceed()
            modalClose()
          },
          variant: 'contained',
        },
        {
          label: t('Cancel', { ns: 'common' }),
          onClick: modalClose,
          variant: 'text',
        },
      ]}
      actionsStyle='full'
      closeBtn={true}
      onClose={modalClose}
    >
      <p className='mb-0'>{t('has-unapplied-code-text')}</p>
    </ActionModal>
  )
}

export default HasUnappliedCodeModal
