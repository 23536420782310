import { RootState } from './index'

export const selectAuthSessionState = (rootState: RootState) =>
  rootState.authSession

export const selectAuthStatus = (rootState: RootState) =>
  selectAuthSessionState(rootState).status

export const selectAuthUsername = (rootState: RootState) =>
  selectAuthSessionState(rootState).username

export const selectAuthToken = (rootState: RootState) =>
  selectAuthSessionState(rootState).authToken

export const selectChallengeId = (rootState: RootState) =>
  selectAuthSessionState(rootState).challengeId

export const selectSecureImageId = (rootState: RootState) =>
  selectAuthSessionState(rootState).secureImageId

export const selectSecureWord = (rootState: RootState) =>
  selectAuthSessionState(rootState).secureWord

export const selectCanResend = (rootState: RootState) =>
  selectAuthSessionState(rootState).canResend

export const selectAuthExpiresAt = (rootState: RootState) =>
  selectAuthSessionState(rootState).authExpiresAt

export const selectAuthIsUserIdle = (rootState: RootState) =>
  selectAuthSessionState(rootState).isUserIdle

export const selectOktaTokens = (rootState: RootState) =>
  selectAuthSessionState(rootState).oktaTokens

export const selectNotificationRecipient = (rootState: RootState) =>
  selectAuthSessionState(rootState).notificationRecipient

export const selectSessionToken = (rootState: RootState) =>
  selectAuthSessionState(rootState).sessionToken

export const selectReferralCode = (rootState: RootState) =>
  selectAuthSessionState(rootState).referralCode
