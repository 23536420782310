import { useTranslation } from 'react-i18next'
import ErrorRedirectBtn from '@/components/ErrorRedirectBtn'
import ErrorMessageComp from '@/components/ErrorMessageComp'
import PostLogonMainBody from '@/layout/PostLogonMainBody'

export const ErrorNotFound = () => {
  const { t } = useTranslation('common')

  return (
    <PostLogonMainBody layout='8-6-4'>
      <ErrorMessageComp
        className='[&>p]:text-center'
        type={t('page-not-exist')}
        message={t('technical-issue-revisit-later')}
      />
      <ErrorRedirectBtn />
    </PostLogonMainBody>
  )
}

export default ErrorNotFound
