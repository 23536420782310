import { useDispatch } from 'react-redux'
import { EPF_TOKEN } from '@/constants/sessionKeys'
import { sessionSetPendingReloadCI } from '@/stores/auth'
import { sessionReset as sessionResetEpfToken } from '@/stores/epfToken'
import { removeSessionStorage } from '@/utils'

export const useClearEpfStatus = () => {
  const dispatch = useDispatch()

  const clearEpfToken = () => {
    removeSessionStorage(EPF_TOKEN)
    dispatch(sessionResetEpfToken())
  }

  const clearEpfStatus = () => {
    removeSessionStorage(EPF_TOKEN)
    dispatch(sessionResetEpfToken())
    // trigger indicator API call to reset auth status
    dispatch(sessionSetPendingReloadCI({ pendingReloadCI: true }))
  }

  const resetAuthStatus = () => {
    // trigger indicator API call to reset auth status
    dispatch(sessionSetPendingReloadCI({ pendingReloadCI: true }))
  }

  return {
    clearEpfStatus,
    clearEpfToken,
    resetAuthStatus,
  }
}
export default useClearEpfStatus
