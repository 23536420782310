import {
  OrderFundResDto,
  SwitchingOrderInstructionQueryResDto,
  SwitchingOrderReqDto,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useReducer } from 'react'
import { AgentCode } from '../useInvestmentReducer'

export interface SwitchingFunds {
  from: OrderFundResDto
  to: OrderFundResDto
}

export interface SwitchingData
  extends Pick<SwitchingOrderReqDto, 'productId' | 'subaccountNo'>,
    Pick<SwitchingOrderInstructionQueryResDto, 'subaccountStatus'> {
  funds: SwitchingFunds[]
  changeIndex: number
  agentCode?: AgentCode
}

export interface SetSwitchingAgentCodeProps
  extends Required<Pick<SwitchingData, 'agentCode'>> {}

export interface SetSwitchingFundsProps
  extends Required<Pick<SwitchingData, 'funds'>> {}

export interface SetSwitchingProductIdProps
  extends Required<Pick<SwitchingData, 'productId'>> {}

export interface SetSwitchingSubAccountIdProps
  extends Required<Pick<SwitchingData, 'subaccountNo'>> {}

export interface SetSwitchingIndexProps
  extends Required<Pick<SwitchingData, 'changeIndex'>> {}
export interface SetSwitchingOrderProps
  extends Required<Pick<SwitchingData, 'funds'>>,
    Pick<SwitchingData, 'agentCode'> {}

enum SwitchingDataActionType {
  SET = 'set',
  SET_AGENT_CODE = 'set_agent_code',
  SET_FUNDS = 'set_funds',
  SET_PRODUCT_ID = 'set_product_id',
  SET_SUBACCOUNT_NO = 'set_subaccount_no',
  SET_CHANGE_INDEX = 'set_change_index',
  SET_SWITCHING_ORDER = 'set_switching_order',
}

export interface SwitchingDataAction {
  type: SwitchingDataActionType
  payload?: Partial<SwitchingData>
}

const initialState: SwitchingData = {
  productId: undefined,
  subaccountNo: '',
  funds: [],
  changeIndex: 0,
  agentCode: undefined,
  subaccountStatus: undefined,
}

export const useSwitchingDataReducer = ({
  initData = initialState,
}: {
  initData?: SwitchingData
}) => {
  const [switchingData, dispatch] = useReducer(SwitchingDataReducer, initData)

  const setSwitchingData = (data: Partial<SwitchingData>) => {
    dispatch({ type: SwitchingDataActionType.SET, payload: data })
  }

  const setSwitchingAgentCode = (data: SetSwitchingAgentCodeProps) => {
    dispatch({ type: SwitchingDataActionType.SET_AGENT_CODE, payload: data })
  }

  const setSwitchingFunds = (data: SetSwitchingFundsProps) => {
    dispatch({ type: SwitchingDataActionType.SET_FUNDS, payload: data })
  }

  const setSwitchingProductId = (data: SetSwitchingProductIdProps) => {
    dispatch({ type: SwitchingDataActionType.SET_PRODUCT_ID, payload: data })
  }

  const setSwitchingSubAccountId = (data: SetSwitchingSubAccountIdProps) => {
    dispatch({ type: SwitchingDataActionType.SET_SUBACCOUNT_NO, payload: data })
  }

  const setSwitchingIndex = (data: SetSwitchingIndexProps) => {
    dispatch({ type: SwitchingDataActionType.SET_CHANGE_INDEX, payload: data })
  }
  const setSwitchingOrder = (data: SetSwitchingOrderProps) => {
    dispatch({
      type: SwitchingDataActionType.SET_SWITCHING_ORDER,
      payload: data,
    })
  }

  return {
    switchingData,
    dispatch,
    setSwitchingData,
    setSwitchingAgentCode,
    setSwitchingFunds,
    setSwitchingProductId,
    setSwitchingSubAccountId,
    setSwitchingIndex,
    setSwitchingOrder,
  }
}

const SwitchingDataReducer = (
  state: SwitchingData,
  action: SwitchingDataAction
) => {
  switch (action.type) {
    case SwitchingDataActionType.SET:
      return {
        ...state,
        agentCode: action.payload?.agentCode,
        productId: action.payload?.productId,
        subaccountNo: action.payload?.subaccountNo || '',
        funds: action.payload?.funds || [],
        changeIndex: action.payload?.changeIndex || 0,
        subaccountStatus: action.payload?.subaccountStatus,
      }

    case SwitchingDataActionType.SET_AGENT_CODE:
      return {
        ...state,
        agentCode: action.payload?.agentCode,
      }

    case SwitchingDataActionType.SET_FUNDS:
      return {
        ...state,
        funds: action.payload?.funds || [],
      }
    case SwitchingDataActionType.SET_PRODUCT_ID:
      return {
        ...state,
        productId: action.payload?.productId,
      }

    case SwitchingDataActionType.SET_SUBACCOUNT_NO:
      return {
        ...state,
        subaccountNo: action.payload?.subaccountNo || '',
      }
    case SwitchingDataActionType.SET_CHANGE_INDEX:
      return {
        ...state,
        changeIndex: action.payload?.changeIndex || 0,
      }
    case SwitchingDataActionType.SET_SWITCHING_ORDER:
      return {
        ...state,
        agentCode: action.payload?.agentCode,
        funds: action.payload?.funds || [],
      }

    default:
      return state
  }
}
