import { PersonalInfoFormProps } from '../../../PersonalInfo/subpages/PersonalInfoForm/useInitData'
import InfoContainer from '../InfoContainer'
import { useTranslation } from 'react-i18next'
import InfoField from '../InfoField'
import { Link } from 'react-router-dom'
import AddressInfo from '../AddressInfo'

import { ReactComponent as PlusIcon } from './assets/plus-ico.svg'
import { ProfileSectionEnum } from '@/enums/profileSectionEnums'

export interface PersonalInfoProps
  extends Omit<PersonalInfoFormProps, 'idType' | 'correspondentContact'>,
    Required<Pick<PersonalInfoFormProps, 'correspondentContact'>> {
  idType?: PersonalInfoFormProps['idType']
}

const PersonalInfo = ({
  fullName,
  idType,
  idNo,
  dateOfBirth,
  country,
  nationality,
  gender,
  epfAccountType,
  epfNumber,
  epfiStatusEffectiveDate,
  race,
  primaryContact,
  isPrimaryContactCurrent,
  correspondentContact,
}: PersonalInfoProps) => {
  const { t } = useTranslation(['profileInfoPage', 'common'])
  const showEpfDetails = !!epfNumber
  const showEpfEffectiveDate = epfAccountType === 'Islamic'
  return (
    <InfoContainer>
      <InfoField fieldName={t('full-name')} value={fullName} />
      <InfoField
        fieldName={t('ID type', { ns: 'common' })}
        value={idType || ''}
      />
      <InfoField fieldName={t('id-number')} value={idNo} />
      <InfoField fieldName={t('dateOfBirth')} value={dateOfBirth} />
      <InfoField fieldName={t('country')} value={country} />
      <InfoField fieldName={t('nationality')} value={nationality || ''} />
      <InfoField fieldName={t('gender')} value={gender} />
      {showEpfDetails && (
        <>
          <InfoField fieldName={t('epf-account-type')} value={epfAccountType} />
          <InfoField fieldName={t('epf-number')} value={epfNumber} />
          {showEpfEffectiveDate && (
            <InfoField
              fieldName={t('epf-effective-date')}
              value={epfiStatusEffectiveDate || ''}
            />
          )}
        </>
      )}
      <InfoField fieldName={t('ethnicity-race')} value={race} />
      <InfoField
        fieldName={t('address')}
        value={<AddressInfo {...primaryContact.addressLines} />}
      />
      <InfoField
        fieldName={t('post-code')}
        value={primaryContact.postalInfo.postalCode}
      />
      <InfoField
        fieldName={t('city')}
        value={primaryContact.postalInfo.cityName}
      />
      <InfoField
        fieldName={t('state')}
        value={primaryContact.postalInfo.state}
      />

      {isPrimaryContactCurrent ? (
        <Link
          to={ProfileSectionEnum.PERSONAL_INFO}
          className='font-normal text-base hover:no-underline flex flex-row gap-1'
        >
          {t('add-corresponding-address')}
          <PlusIcon className='w-6 h-6 [&>*]:hover:stroke-pi-navy-blue' />
        </Link>
      ) : (
        <>
          <InfoField
            fieldName={t('corresponding-address')}
            value={<AddressInfo {...correspondentContact.addressLines} />}
          />
          <InfoField
            fieldName={t('post-code')}
            value={correspondentContact.postalInfo.postalCode}
          />
          <InfoField
            fieldName={t('city')}
            value={correspondentContact.postalInfo.cityName}
          />
          <InfoField
            fieldName={t('state')}
            value={correspondentContact.postalInfo.state}
          />
        </>
      )}
    </InfoContainer>
  )
}

export default PersonalInfo
