import { TextField } from '@mui/material'
import {
  ButtonWithLoading,
  FormLabel,
  SelectBoxComp,
  TelTextField,
} from '@pimy-b2cweb/frontend-lib'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ProfileFormLovKeysEnum } from '@/api/commonApi'
import AddressFieldComp from '@/components/AddressFieldComp'
import NavBackBtn from '@/components/NavBackBtn'
import { checkEpfTokenExist } from '@/stores/epfToken.selectors'
import { LovSessionStateLang } from '@/stores/lov'
import { isNumber } from '@/utils'
import PostalInfoForm from '@/components/PostalInfoForm'
import { PostalInformation } from '@pimy-b2cweb/apiclient-b2cweb-r2'

export const ContactInfoForm = ({
  onSubmitForm,
  onNavBack,
  isSubmitting = false,
  lovs,
  postalList,
}: {
  onSubmitForm: () => void
  onNavBack?: () => void
  isSubmitting?: boolean
  lovs: LovSessionStateLang
  postalList: PostalInformation[]
}) => {
  const { t } = useTranslation(['profileInfoPage', 'common'])
  const epfTokenExist = useSelector(checkEpfTokenExist)
  const { control, watch, handleSubmit, setValue } = useFormContext()
  const showEpfEffectiveDate = watch('epfAccountType') === 'Islamic'
  const isPrimaryContactCurrent = watch('isPrimaryContactCurrent')

  const onSubmit = (data: FieldValues) => {
    console.log(data)
    onSubmitForm()
  }

  return (
    <>
      <h1 className='flex items-center'>
        {!!onNavBack && <NavBackBtn onClick={onNavBack} />}
        {t('profile-info')}
      </h1>

      <p className='text-sm font-bold mb-1'>
        {t('step-currStep-of-totalSteps', { currStep: 1, totalSteps: 2 })}
      </p>
      <p className='font-bold'>{t('identification-contact-information')}</p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='fullName'
          control={control}
          defaultValue=''
          render={({ field, fieldState: { error } }) => (
            <FormLabel id='fullName' label={t('full-name')} isError={!!error}>
              <TextField
                type='text'
                fullWidth
                {...field}
                error={!!error}
                helperText={!!error ? (error?.message as string) : ''}
                disabled
              />
            </FormLabel>
          )}
        />
        <Controller
          name='idNo'
          control={control}
          defaultValue=''
          render={({ field, fieldState: { error } }) => (
            <FormLabel id='idNo' label={t('id-number')} isError={!!error}>
              <TextField
                type='text'
                fullWidth
                {...field}
                error={!!error}
                helperText={!!error ? (error?.message as string) : ''}
                disabled
              />
            </FormLabel>
          )}
        />
        <Controller
          name='email'
          control={control}
          defaultValue=''
          render={({ field, fieldState: { error } }) => (
            <FormLabel id='email' label={t('email')} isError={!!error}>
              <TextField
                type='text'
                fullWidth
                {...field}
                error={!!error}
                helperText={!!error ? (error?.message as string) : ''}
                disabled
              />
            </FormLabel>
          )}
        />
        <Controller
          name='nationality'
          control={control}
          defaultValue=''
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='nationality'
              label={t('nationality')}
              isError={!!error}
            >
              <TextField
                type='text'
                fullWidth
                {...field}
                error={!!error}
                helperText={!!error ? (error?.message as string) : ''}
                disabled
              />
            </FormLabel>
          )}
        />
        <Controller
          name='mobilePhoneNo'
          control={control}
          defaultValue=''
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
            validate: (val: string) => {
              if (!isNumber(val)) {
                return t('invalid-input', { ns: 'errorResponse' })
              }
              return true
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='mobilePhoneNo'
              label={t('contact-number')}
              isError={!!error}
            >
              <TelTextField
                fullWidth
                {...field}
                error={!!error}
                helperText={!!error ? (error?.message as string) : ''}
                disabled={isSubmitting}
              />
            </FormLabel>
          )}
        />
        {!!epfTokenExist && (
          <>
            <Controller
              name='epfAccountType'
              control={control}
              defaultValue=''
              render={({ field, fieldState: { error } }) => (
                <FormLabel
                  id='epfAccountType'
                  label={t('epf-account-type')}
                  isError={!!error}
                >
                  <TextField
                    fullWidth
                    {...field}
                    error={!!error}
                    helperText={!!error ? (error?.message as string) : ''}
                    disabled
                  />
                </FormLabel>
              )}
            />
            <Controller
              name='epfNumber'
              control={control}
              defaultValue=''
              render={({ field, fieldState: { error } }) => (
                <FormLabel
                  id='epfNumber'
                  label={t('epf-number')}
                  isError={!!error}
                >
                  <TextField
                    fullWidth
                    {...field}
                    error={!!error}
                    helperText={!!error ? (error?.message as string) : ''}
                    disabled
                  />
                </FormLabel>
              )}
            />
            {!!showEpfEffectiveDate && (
              <Controller
                name='epfiStatusEffectiveDate'
                control={control}
                defaultValue=''
                render={({ field, fieldState: { error } }) => (
                  <FormLabel
                    id='epfiStatusEffectiveDate'
                    label={t('epf-effective-date')}
                    isError={!!error}
                  >
                    <TextField
                      fullWidth
                      {...field}
                      error={!!error}
                      helperText={!!error ? (error?.message as string) : ''}
                      disabled
                    />
                  </FormLabel>
                )}
              />
            )}
          </>
        )}
        <Controller
          name='race'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel id='race' label={t('race')} isError={!!error}>
              <SelectBoxComp
                id='race'
                items={lovs[ProfileFormLovKeysEnum.Race] || []}
                {...field}
                error={error}
                disabled={isSubmitting}
                fullWidth
                placeholder={t('please-select-an-option', { ns: 'common' })}
              />
            </FormLabel>
          )}
        />
        <Controller
          name='gender'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel id='gender' label={t('gender')} isError={!!error}>
              <SelectBoxComp
                id='gender'
                items={lovs[ProfileFormLovKeysEnum.Gender] || []}
                {...field}
                error={error}
                disabled={isSubmitting}
                fullWidth
                placeholder={t('please-select-an-option', { ns: 'common' })}
              />
            </FormLabel>
          )}
        />

        <Controller
          name='primaryContact.addressLines'
          control={control}
          rules={{
            validate: (value) =>
              value.addressLine1.trim().length > 0 || 'Required',
          }}
          render={({ field, fieldState: { error } }) => (
            <AddressFieldComp
              {...field}
              addressLineNum={5}
              error={error}
              disabled={isSubmitting}
              label={t('address')}
              rootId='primaryContact.addressLines'
            />
          )}
        />

        <Controller
          name='primaryContact.postalInfo'
          control={control}
          rules={{
            validate: (value) => !!value.postalCode || 'Required',
          }}
          render={({ field, fieldState: { error } }) => (
            <PostalInfoForm
              {...field}
              error={error}
              disabled={isSubmitting}
              id='primaryContact.postalInfo'
              stateInfo={lovs[ProfileFormLovKeysEnum.State] || []}
              postalInfo={postalList || []}
            />
          )}
        />

        {isPrimaryContactCurrent ? (
          <p className='!mb-10'>
            <Trans
              i18nKey='is-this-current-address'
              t={t}
              components={{
                A: (
                  <span
                    className={`clickable-text ${
                      isSubmitting ? 'disabled' : ''
                    }`}
                    onClick={() => setValue('isPrimaryContactCurrent', false)}
                  />
                ),
              }}
            />
          </p>
        ) : (
          <>
            <Controller
              name='correspondentContact.addressLines'
              control={control}
              rules={{
                validate: (value) =>
                  value.addressLine1.trim().length > 0 || 'Required',
              }}
              render={({ field, fieldState: { error } }) => (
                <AddressFieldComp
                  {...field}
                  addressLineNum={5}
                  error={error}
                  disabled={isSubmitting}
                  label={t('corresponding-address')}
                  rootId='correspondentContact.addressLines'
                />
              )}
            />

            <Controller
              name='correspondentContact.postalInfo'
              control={control}
              rules={{
                validate: (value) => !!value.postalCode || 'Required',
              }}
              render={({ field, fieldState: { error } }) => (
                <PostalInfoForm
                  {...field}
                  error={error}
                  disabled={isSubmitting}
                  id='correspondentContact.postalInfo'
                  stateInfo={lovs[ProfileFormLovKeysEnum.State] || []}
                  postalInfo={postalList}
                />
              )}
            />
          </>
        )}
        <ButtonWithLoading
          type='submit'
          fullWidth
          variant='contained'
          size='large'
          disabled={isSubmitting}
          isLoading={isSubmitting}
          // onClick={onFormSubmit}
        >
          {t('Continue', { ns: 'common' })}
        </ButtonWithLoading>
      </form>
    </>
  )
}

export default ContactInfoForm
