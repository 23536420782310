import {
  ButtonWithLoading,
  ResponseError,
  useResponseError,
} from '@pimy-b2cweb/frontend-lib'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import useGetEkycToken from '@/hooks/useGetEkycToken'
import { getErrorResponseCode } from '@/utils'

const EKYCRequired = () => {
  const { t } = useTranslation(['EKYCRequiredPage', 'common'])
  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()
  const { handleMutate, mutateStatus, mutatedError, isLoading } =
    useGetEkycToken()

  useEffect(() => {
    if (mutateStatus === 'error') {
      console.log('error:', mutatedError)
      const errMsg = getErrorResponseCode(mutatedError)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)
  }, [mutateStatus, mutatedError])

  return (
    <>
      <h1>{t('please-complete-your-account-setup')}</h1>
      <p>{t('you-havnt-complete-your-ekyc')}</p>
      {!!responseErrorAttrs && (
        <ResponseError>
          {t(responseErrorAttrs.i18nKey, {
            ns: responseErrorAttrs.ns,
          })}
        </ResponseError>
      )}
      <ButtonWithLoading
        fullWidth
        variant='contained'
        size='large'
        onClick={handleMutate}
        disabled={isLoading}
        isLoading={isLoading}
      >
        {t('Continue', { ns: 'common' })}
      </ButtonWithLoading>
    </>
  )
}

export default EKYCRequired
