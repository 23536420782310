import { ProfileFormLovKeysEnum } from '@/api/commonApi'
import AddressFieldComp from '@/components/AddressFieldComp'
import Loading from '@/components/Loading'
import { RoutePathEnum } from '@/constants/routePath'
import PostLogonContainer from '@/layout/PostLogonContainer'
import PostLogonMainBody from '@/layout/PostLogonMainBody'
import { filterByLov, formatAddress, getErrorResponseCode } from '@/utils'
import { TextField } from '@mui/material'
import {
  CustomerIdTypeEnum,
  CustomerProfileAddressContact,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import {
  ButtonWithLoading,
  FormLabel,
  ResponseError,
  SelectBoxComp,
  useResponseError,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useMutationEditCustomerProfilePersonalInfo } from '@/api/customerApi'
import { useDispatch } from 'react-redux'
import { sessionVerifyOtp } from '@/stores/auth'
import { PersonalInfoFormProps, useInitData } from './useInitData'
import dayjs from 'dayjs'
import { DD_MM_YYYY } from '@/constants'
import PostalInfoForm from '@/components/PostalInfoForm'
import { getCustomerProfileAddress } from '@/utils/CustomerProfileAddress'

export interface PersonalInfoFormCompProps {
  goToVerifyOtp: () => void
}

const PersonalInfoForm = ({ goToVerifyOtp }: PersonalInfoFormCompProps) => {
  const { t } = useTranslation(['profileInfoPage', 'common'])

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    personalInfoFormMethods,
    lovs,
    postalList,
    lovValidated,
    isLoading,
    queryStatus,
    queryData,
    queryError,
  } = useInitData()

  const { control, handleSubmit, watch, setValue } = personalInfoFormMethods

  useEffect(() => {
    if (queryStatus === 'error') {
      console.log('error:', queryError)
      navigate(RoutePathEnum.ERROR)
      return
    }
    if (queryStatus === 'success' && !!queryData && lovValidated) {
      const { identities, epfDetails, primaryContact, correspondentContact } =
        queryData

      setValue('fullName', queryData.fullName || '')
      setValue('idType', identities?.at(0)?.idType as CustomerIdTypeEnum)
      setValue('idNo', identities?.at(0)?.idNo || '')

      if (!!queryData.dateOfBirth) {
        const dob = dayjs(queryData.dateOfBirth).format(DD_MM_YYYY)
        setValue('dateOfBirth', dob)
      }

      if (identities?.at(0)?.countryCode && !!lovs?.country) {
        const _country = filterByLov(identities[0]?.countryCode, lovs.country)
        setValue('country', _country?.label || '')
      }

      if (!!queryData?.nationality && !!lovs?.country) {
        const _country = filterByLov(queryData.nationality, lovs.country)
        setValue('nationality', _country?.label || '')
      }
      if (!!queryData?.gender && !!lovs?.gender) {
        const _gender = lovs.gender.find(
          ({ value }) => value === queryData.gender
        )
        setValue('gender', _gender?.label || '')
      }
      if (!!queryData?.gender && !!lovs?.race) {
        const _race = lovs.race.find(({ value }) => value === queryData.race)
        setValue('race', _race?.value || '')
      }

      // EPF Fields Mapping
      if (!!epfDetails?.epfNumber) {
        setValue(
          'epfAccountType',
          epfDetails?.epfiStatus === undefined
            ? ''
            : !!epfDetails.epfiStatus
            ? 'Islamic'
            : 'Conventional'
        )
        setValue('epfNumber', epfDetails?.epfNumber || '')
        setValue(
          'epfiStatusEffectiveDate',
          epfDetails?.epfiStatusEffectiveDate || ''
        )
      }

      // Primary Address Mapping
      if (!!primaryContact) {
        const _pc = formatAddress({
          address: primaryContact,
          addressLineNum: 5,
        }) as CustomerProfileAddressContact

        setValue(
          'primaryContact.addressLines.addressLine1',
          _pc.addressLine1 || ''
        )
        setValue(
          'primaryContact.addressLines.addressLine2',
          _pc?.addressLine2 || ''
        )
        setValue(
          'primaryContact.addressLines.addressLine3',
          _pc?.addressLine3 || ''
        )
        setValue(
          'primaryContact.addressLines.addressLine4',
          _pc?.addressLine4 || ''
        )
        setValue(
          'primaryContact.addressLines.addressLine5',
          _pc?.addressLine5 || ''
        )
        setValue('primaryContact.postalInfo.cityName', _pc?.cityName || '')
        if (!!_pc?.country && !!lovs?.country) {
          const _country = filterByLov(_pc.country, lovs.country)
          setValue('primaryContact.postalInfo.country', _country?.value || '')
        }
        setValue('primaryContact.postalInfo.postalCode', _pc?.postalCode || '')
        if (!!_pc?.state && !!lovs?.state) {
          const _state = filterByLov(_pc.state, lovs.state)
          setValue('primaryContact.postalInfo.state', _state?.value || '')
        }
      }

      // Correspondence Address Mapping
      if (!!correspondentContact) {
        setValue('isPrimaryContactCurrent', false)

        const _cc = formatAddress({
          address: correspondentContact,
          addressLineNum: 5,
        }) as CustomerProfileAddressContact

        setValue(
          'correspondentContact.addressLines.addressLine1',
          _cc.addressLine1 || ''
        )
        setValue(
          'correspondentContact.addressLines.addressLine2',
          _cc?.addressLine2 || ''
        )
        setValue(
          'correspondentContact.addressLines.addressLine3',
          _cc?.addressLine3 || ''
        )
        setValue(
          'correspondentContact.addressLines.addressLine4',
          _cc?.addressLine4 || ''
        )
        setValue(
          'correspondentContact.addressLines.addressLine5',
          _cc?.addressLine5 || ''
        )
        setValue(
          'correspondentContact.postalInfo.cityName',
          _cc?.cityName || ''
        )
        if (!!_cc?.country && !!lovs?.country) {
          const _country = filterByLov(_cc.country, lovs.country)
          setValue(
            'correspondentContact.postalInfo.country',
            _country?.value || ''
          )
        }
        setValue(
          'correspondentContact.postalInfo.postalCode',
          _cc?.postalCode || ''
        )
        if (!!_cc?.state && !!lovs?.state) {
          const _state = filterByLov(_cc.state, lovs.state)
          setValue('correspondentContact.postalInfo.state', _state?.value || '')
        }
      }
    }
  }, [queryStatus, queryData, queryError, lovValidated])

  const {
    mutate,
    isLoading: isSubmitting,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationEditCustomerProfilePersonalInfo()

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      console.log('mutatedData: ', mutatedData)
      dispatch(sessionVerifyOtp({ ...mutatedData }))
      goToVerifyOtp()
    }
  }, [mutatedData, mutateStatus, mutatedError])

  const isPrimaryContactCurrent = watch('isPrimaryContactCurrent')
  const showEpfDetails = !!watch('epfNumber')
  const showEpfEffectiveDate = watch('epfAccountType') === 'Islamic'

  const onSubmit = (data: PersonalInfoFormProps) => {
    console.log('data: ', data)
    mutate({
      race: data.race,
      primaryContact: getCustomerProfileAddress(data.primaryContact),
      correspondentContact: getCustomerProfileAddress(
        data.correspondentContact
      ),
    })
  }

  return isLoading ? (
    <Loading isLoadingPage={true} />
  ) : (
    <PostLogonMainBody useTransBg={false}>
      <PostLogonContainer>
        <h1 className='font-bold'>{t('profile')}</h1>

        {!!responseErrorAttrs && (
          <ResponseError>
            {t(responseErrorAttrs.i18nKey, {
              ns: responseErrorAttrs.ns,
            })}
          </ResponseError>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name='fullName'
            control={control}
            defaultValue=''
            render={({ field, fieldState: { error } }) => (
              <FormLabel id='fullName' label={t('full-name')} isError={!!error}>
                <TextField
                  type='text'
                  fullWidth
                  {...field}
                  error={!!error}
                  helperText={!!error ? (error?.message as string) : ''}
                  disabled
                />
              </FormLabel>
            )}
          />

          <Controller
            name='idType'
            control={control}
            defaultValue={undefined}
            render={({ field, fieldState: { error } }) => (
              <FormLabel
                id='idType'
                label={t('ID type', { ns: 'common' })}
                isError={!!error}
              >
                <TextField
                  type='text'
                  fullWidth
                  {...field}
                  error={!!error}
                  helperText={!!error ? (error?.message as string) : ''}
                  disabled
                />
              </FormLabel>
            )}
          />

          <Controller
            name='idNo'
            control={control}
            defaultValue=''
            render={({ field, fieldState: { error } }) => (
              <FormLabel id='idNo' label={t('id-number')} isError={!!error}>
                <TextField
                  type='text'
                  fullWidth
                  {...field}
                  error={!!error}
                  helperText={!!error ? (error?.message as string) : ''}
                  disabled
                />
              </FormLabel>
            )}
          />

          <Controller
            name='dateOfBirth'
            control={control}
            defaultValue=''
            render={({ field, fieldState: { error } }) => (
              <FormLabel
                id='dateOfBirth'
                label={t('dateOfBirth')}
                isError={!!error}
              >
                <TextField
                  type='text'
                  fullWidth
                  {...field}
                  error={!!error}
                  helperText={!!error ? (error?.message as string) : ''}
                  disabled
                />
              </FormLabel>
            )}
          />

          <Controller
            name='country'
            control={control}
            defaultValue=''
            render={({ field, fieldState: { error } }) => (
              <FormLabel id='country' label={t('country')} isError={!!error}>
                <TextField
                  type='text'
                  fullWidth
                  {...field}
                  error={!!error}
                  helperText={!!error ? (error?.message as string) : ''}
                  disabled
                />
              </FormLabel>
            )}
          />

          <Controller
            name='nationality'
            control={control}
            defaultValue=''
            render={({ field, fieldState: { error } }) => (
              <FormLabel
                id='nationality'
                label={t('nationality')}
                isError={!!error}
              >
                <TextField
                  type='text'
                  fullWidth
                  {...field}
                  error={!!error}
                  helperText={!!error ? (error?.message as string) : ''}
                  disabled
                />
              </FormLabel>
            )}
          />

          <Controller
            name='gender'
            control={control}
            defaultValue=''
            render={({ field, fieldState: { error } }) => (
              <FormLabel id='gender' label={t('gender')} isError={!!error}>
                <TextField
                  type='text'
                  fullWidth
                  {...field}
                  error={!!error}
                  helperText={!!error ? (error?.message as string) : ''}
                  disabled
                />
              </FormLabel>
            )}
          />

          {showEpfDetails && (
            <>
              <Controller
                name='epfAccountType'
                control={control}
                defaultValue=''
                render={({ field, fieldState: { error } }) => (
                  <FormLabel
                    id='epfAccountType'
                    label={t('epf-account-type')}
                    isError={!!error}
                  >
                    <TextField
                      fullWidth
                      {...field}
                      error={!!error}
                      helperText={!!error ? (error?.message as string) : ''}
                      disabled
                    />
                  </FormLabel>
                )}
              />
              <Controller
                name='epfNumber'
                control={control}
                defaultValue=''
                render={({ field, fieldState: { error } }) => (
                  <FormLabel
                    id='epfNumber'
                    label={t('epf-number')}
                    isError={!!error}
                  >
                    <TextField
                      fullWidth
                      {...field}
                      error={!!error}
                      helperText={!!error ? (error?.message as string) : ''}
                      disabled
                    />
                  </FormLabel>
                )}
              />
              {showEpfEffectiveDate && (
                <Controller
                  name='epfiStatusEffectiveDate'
                  control={control}
                  defaultValue=''
                  render={({ field, fieldState: { error } }) => (
                    <FormLabel
                      id='epfiStatusEffectiveDate'
                      label={t('epf-effective-date')}
                      isError={!!error}
                    >
                      <TextField
                        fullWidth
                        {...field}
                        error={!!error}
                        helperText={!!error ? (error?.message as string) : ''}
                        disabled
                      />
                    </FormLabel>
                  )}
                />
              )}
            </>
          )}

          <Controller
            name='race'
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Required',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FormLabel id='race' label={t('race')} isError={!!error}>
                <SelectBoxComp
                  id='race'
                  items={lovs[ProfileFormLovKeysEnum.Race] || []}
                  {...field}
                  error={error}
                  disabled={isSubmitting}
                  fullWidth
                  placeholder={t('please-select-an-option', { ns: 'common' })}
                />
              </FormLabel>
            )}
          />

          <Controller
            name='primaryContact.addressLines'
            control={control}
            rules={{
              validate: (value) =>
                value.addressLine1.trim().length > 0 || 'Required',
            }}
            render={({ field, fieldState: { error } }) => (
              <AddressFieldComp
                {...field}
                addressLineNum={5}
                error={error}
                disabled={isSubmitting}
                label={t('address')}
                rootId='primaryContact.addressLines'
              />
            )}
          />
          <Controller
            name='primaryContact.postalInfo'
            control={control}
            rules={{
              validate: (value) => !!value.postalCode || 'Required',
            }}
            render={({ field, fieldState: { error } }) => (
              <PostalInfoForm
                {...field}
                error={error}
                disabled={isSubmitting}
                id='primaryContact.postalInfo'
                stateInfo={lovs[ProfileFormLovKeysEnum.State] || []}
                postalInfo={postalList || []}
              />
            )}
          />

          {isPrimaryContactCurrent ? (
            <p className='!mb-10'>
              <Trans
                i18nKey='is-this-current-address'
                t={t}
                components={{
                  A: (
                    <span
                      className={`clickable-text ${
                        isSubmitting ? 'disabled' : ''
                      }`}
                      onClick={() => setValue('isPrimaryContactCurrent', false)}
                    />
                  ),
                }}
              />
            </p>
          ) : (
            <>
              <Controller
                name='correspondentContact.addressLines'
                control={control}
                rules={{
                  validate: (value) =>
                    (!!value && value.addressLine1.trim().length > 0) ||
                    'Required',
                }}
                render={({ field, fieldState: { error } }) => (
                  <AddressFieldComp
                    {...field}
                    addressLineNum={5}
                    error={error}
                    disabled={isSubmitting}
                    label={t('corresponding-address')}
                    rootId='correspondentContact.addressLines'
                  />
                )}
              />

              <Controller
                name='correspondentContact.postalInfo'
                control={control}
                rules={{
                  validate: (value) =>
                    (!!value && !!value.postalCode) || 'Required',
                }}
                render={({ field, fieldState: { error } }) => (
                  <PostalInfoForm
                    {...field}
                    error={error}
                    disabled={isSubmitting}
                    id='correspondentContact.postalInfo'
                    stateInfo={lovs[ProfileFormLovKeysEnum.State] || []}
                    postalInfo={postalList || []}
                  />
                )}
              />
            </>
          )}
          <ButtonWithLoading
            type='submit'
            fullWidth
            variant='contained'
            size='large'
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            {t('update-profile')}
          </ButtonWithLoading>
        </form>
      </PostLogonContainer>
    </PostLogonMainBody>
  )
}

export default PersonalInfoForm
