import { useTranslation, Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'

export const BottomAction = ({
  type,
  className = '',
  disabled = false,
}: {
  type: 'signup' | 'signin'
  className?: string
  disabled?: boolean
}) => {
  const { t } = useTranslation('bottomAction')
  const navigate = useNavigate()
  return (
    <p className={`text-center mt-6 mb-0 ${className}`}>
      <Trans
        i18nKey={
          type === 'signup' ? 'no-user-id-signup-now' : 'has-user-id-signin-now'
        }
        t={t}
        components={{
          A: (
            <span
              className={`clickable-text ${disabled ? 'disabled' : ''}`}
              onClick={() =>
                !disabled &&
                navigate(
                  type === 'signup'
                    ? RoutePathEnum.SIGN_UP
                    : RoutePathEnum.SIGN_IN
                )
              }
            />
          ),
        }}
      />
    </p>
  )
}
export default BottomAction
