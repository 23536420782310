import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material'

export interface LoadingCompProps {
  className?: string
}

export const LoadingComp = ({ className = '' }: LoadingCompProps) => {
  const { t } = useTranslation('common')
  return (
    <div className={`flex flex-col items-center ${className}`}>
      <div className='text-pi-gray-2 text-base leading-7'>{t('loading')}</div>
      <CircularProgress size={14} thickness={6} className='!text-pi-gray-2' />
    </div>
  )
}

export default LoadingComp
