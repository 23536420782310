import { formatMillisecToTime } from '@pimy-b2cweb/frontend-lib'
import { useEffect, useState, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { ReactComponent as InfoIco } from '@/assets/info.svg'
import useCountDownTimer from '@/hooks/useCountDownTimer'
import { selectEpfExpAt } from '@/stores/epfToken.selectors'
import EpfTimeoutModal from './EpfTimeoutModal'

export const EpfCountDownTimer = memo(() => {
  const { t } = useTranslation('epfCountdownTimer')
  const { timeLeft, start } = useCountDownTimer(0)
  const [initialized, setInitialized] = useState(false)

  const epfExpAt = useSelector(selectEpfExpAt)
  useEffect(() => {
    if (!!epfExpAt) {
      start(new Date(epfExpAt).getTime() - new Date().getTime())
      setInitialized(true)
    }
  }, [epfExpAt])

  return !initialized || !epfExpAt ? null : (
    <>
      <div className='count-down-timer w-full h-16 xs:h-12 [&~.main-header.header-show]:!top-16 [&~.main-header.header-show]:xs:!top-12'>
        <div className='w-full h-16 xs:h-12 fixed z-[60] top-0 px-4 bg-pi-true-blue'>
          <div className='max-w-screen-xl h-16 xs:h-12 py-2 mx-auto flex gap-2 items-center text-sm text-white font-medium'>
            <div className='w-5 h-5'>
              <InfoIco className='[&>*>*]:stroke-white' />
            </div>
            <div className='grow'>{t('complete-your-sign-in')}</div>
            <div>{formatMillisecToTime(timeLeft)}</div>
          </div>
        </div>
      </div>
      <EpfTimeoutModal epfTimeLeft={timeLeft} />
    </>
  )
})

export default EpfCountDownTimer
