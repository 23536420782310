import { useModal } from '@pimy-b2cweb/frontend-lib'
import { useEffect, useState } from 'react'
import ProfileUpdatedModal from '../../components/ProfileUpdatedModal'
import VerifyOtp from '../../components/VerifyOtp'
import PersonalInfoForm from './subpages/PersonalInfoForm'
import { useQueryClient } from '@tanstack/react-query'
import { CUSTOMER_PROFILE_FETCH } from '@/constants/apiKeys'

enum PersonalInfoPageEnum {
  PERSONAL_INFO = 'personal_info',
  VERIFY_OTP = 'verify_otp',
}

const PersonalInfo = ({
  openModalOnStart = false,
}: {
  openModalOnStart: boolean
}) => {
  const profileUpdatedModal = useModal()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (openModalOnStart) {
      profileUpdatedModal.modalOpen()
      queryClient.invalidateQueries({
        queryKey: [CUSTOMER_PROFILE_FETCH],
        exact: true,
      })
    }
  }, [])

  const [currentPage, setCurrentPage] = useState(
    PersonalInfoPageEnum.PERSONAL_INFO
  )

  const goToVerifyOtp = () => setCurrentPage(PersonalInfoPageEnum.VERIFY_OTP)

  useEffect(() => console.log('currentPage: ', currentPage), [currentPage])

  return currentPage === PersonalInfoPageEnum.PERSONAL_INFO ? (
    <>
      <PersonalInfoForm goToVerifyOtp={goToVerifyOtp} />
      <ProfileUpdatedModal {...profileUpdatedModal} />
    </>
  ) : (
    <VerifyOtp />
  )
}

export default PersonalInfo
