import {
  FundInformation,
  RewardInformation,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useInitRewardListData } from '../../hooks/useInitRewardListData'
import { useEffect, useState } from 'react'
export interface RewardItemProps {
  reward: RewardInformation
  className?: string
}

const RewardItem = ({ reward, className = '' }: RewardItemProps) => {
  const { fundList } = useInitRewardListData()
  const [fundName, setFundName] = useState<string | null>(null)

  useEffect(() => {
    const matchingFundCode = fundList?.find(
      (fund: FundInformation) => fund.code === reward.fundCode
    )
    if (matchingFundCode) {
      setFundName(matchingFundCode.name)
    }
  }, [fundList])

  const formatDescription = (description: string) => {
    const regex = /\$\{rewardValue\}/g
    const splitText = description.split(regex)
    return (
      <>
        {splitText.map((text, index) => (
          <span key={index}>
            {text}
            {index < splitText.length - 1 && (
              <strong>RM{reward.rewardValue}</strong>
            )}
          </span>
        ))}
      </>
    )
  }

  return (
    <div
      className={`flex flex-col justify-between bg-white rounded px-4 py-3 shadow-[2px_6px_16px_-10px_#EAEAEA] cursor-pointer ${className}`}
    >
      <div className='flex flex-col gap-1'>
        <div className='text-base font-bold text-pi-utility-green'>
          {reward.campaignName}
        </div>
        <div className='text-xs font-normal'>
          <div>{fundName}</div>

          {reward?.rewardDescription?.map(
            (rewardDesc: string, index: number) => {
              return (
                <>
                  <div key={index}>{formatDescription(rewardDesc)}</div>
                </>
              )
            }
          )}
        </div>
      </div>
    </div>
  )
}

export default RewardItem
