import { Route, Link } from 'react-router-dom'
import PostLogonLayout from '@/layout/PostLogonLayout'
import PreLogonLayout from '@/layout/PreLogonLayout'
import { Pages } from '@/Routes'

import AccountDeactivated from '@/Routes/pages/AccountDeactivated'
import AccountVerification from '@/Routes/pages/AccountVerification'

import CashIn from '@/Routes/pages/CashIn'

import Dashboard from '@/Routes/pages/Dashboard'

import ECDDForm from '@/Routes/pages/ECDDForm'
import ErrorNotFound from '@/Routes/pages/ErrorNotFound'
import ErrorGenerlic from '@/Routes/pages/ErrorGenerlic'
import EKYCRequired from '@/Routes/pages/EKYCRequired'

import FundList from '@/Routes/pages/FundList'

import ISAFQuestionnaire from '@/Routes/pages/ISAF/subpages/Questionnaire'
import ISAFResult from '@/Routes/pages/ISAF/subpages/Result'

import ProfileInfo from '@/Routes/pages/ProfileInfo'

import ResetPassword from '@/Routes/pages/ResetPassword'

import SignInUsername from '@/Routes/pages/SignIn/subpages/Username'
import SignInPassword from '@/Routes/pages/SignIn/subpages/Password'

import SignUp from '@/Routes/pages/SignUp'
import SignUpPassword from '@/Routes/pages/SignUp/subpages/Password'
import SignUpSucceed from '@/Routes/pages/SignUp/subpages/Succeed'
import SignUpUsername from '@/Routes/pages/SignUp/subpages/Username'
import SignUpVerifyOtp from '@/Routes/pages/SignUp/subpages/VerifyOtp'

import RecoverUserId from '@/Routes/pages/RecoverUserId'
import ResetPasswordNewPassword from '@/Routes/pages/ResetPassword/subpages/NewPassword'
import ResetPasswordSucceed from '@/Routes/pages/ResetPassword/subpages/PasswordSucceed'
import ResetPasswordVerifyOtp from '@/Routes/pages/ResetPassword/subpages/VerifyOtp'

import TransactionHistory from '@/Routes/pages/TransactionHistory'

import ResultPage from '@/Routes/pages/RedemptionOrder/subpages/ResultPage'

import { MOCK_QUESTIONNAIRE } from '@/api/riskProfileApi'
import {
  OrderStatusEnum,
  OrderTrxTypeEnum,
  OrderTypeEnum,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import SwitchResult from '@/Routes/pages/SwitchingForm/subpages/SwitchResult'

const demoPreLogonPages: Pages[] = [
  {
    title: 'Sign-in - User Name',
    path: 'signin-username',
    element: <SignInUsername pwExpireModalOpen={() => {}} />,
  },
  {
    title: 'Sign-in - Password',
    path: 'signin-password',
    element: <SignInPassword pwExpireModalOpen={() => {}} />,
  },
  {
    title: 'Sign up',
    path: 'signup',
    element: <SignUp />,
  },
  {
    title: 'Sign up - Otp',
    path: 'signup-otp',
    element: <SignUpVerifyOtp />,
  },
  {
    title: 'Sign up - User Name',
    path: 'signup-username',
    element: <SignUpUsername />,
  },
  {
    title: 'Sign up - Password',
    path: 'signup-password',
    element: <SignUpPassword />,
  },
  {
    title: 'Sign up - Succeed',
    path: 'signup-succeed',
    element: <SignUpSucceed />,
  },
  {
    title: 'Reset Password',
    path: 'reset-password',
    element: <ResetPassword />,
  },
  {
    title: 'Reset Password - Otp',
    path: 'reset-password-otp',
    element: <ResetPasswordVerifyOtp />,
  },
  {
    title: 'Reset Password - New password',
    path: 'reset-password-new',
    element: <ResetPasswordNewPassword />,
  },
  {
    title: 'Reset Password - Succeed',
    path: 'reset-password-succeed',
    element: <ResetPasswordSucceed />,
  },
  {
    title: 'Recover User ID',
    path: 'recover-user-id',
    element: <RecoverUserId />,
  },
]
const demoInBetweenPages: Pages[] = [
  {
    title: 'E-KYC Alert',
    path: 'ekyc-alert',
    element: <EKYCRequired />,
  },
  {
    title: 'Profile Info Setup',
    path: 'profile-info',
    element: <ProfileInfo />,
  },
  {
    title: 'Profile Info Setup - ECDD Form',
    path: 'profile-info-ecdd-form',
    element: <ECDDForm />,
  },
  // {
  //   title: 'ISAF',
  //   path: 'isaf',
  //   element: <ISAF />,
  // },
  {
    title: 'ISAF - Questionnaire',
    path: 'isaf-questionnaire',
    element: (
      <ISAFQuestionnaire goNext={() => {}} questionnaire={MOCK_QUESTIONNAIRE} />
    ),
  },
  {
    title: 'ISAF - Result',
    path: 'isaf-result',
    element: <ISAFResult />,
  },
  {
    title: 'Account Verification',
    path: 'account-verification',
    element: <AccountVerification />,
  },
  {
    title: 'Account Deactivated',
    path: 'account-deactivated',
    element: <AccountDeactivated />,
  },
  {
    title: 'Redemption Result Page',
    path: 'redemption-result',
    element: (
      <ResultPage
        transactionDetails={{
          code: 'ok',
          message: 'string',
          trxRefNo: 'string',
          type: OrderTypeEnum.CoolOff,
          trxType: OrderTrxTypeEnum.EPF,
          status: OrderStatusEnum.SETTLED,
          submittedDate: '2024-04-18T10:50:44.668Z',
          transactionHostRefNo: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          ppgRefNo: 'string',
          pgRefNo: 'string',
          amount: 0,
          amountCcy: 'MYR',
        }}
      />
    ),
  },
  {
    title: 'Switch Result',
    path: 'switch-result',
    element: (
      <SwitchResult
        transactionDetails={{
          code: 'ok',
          message: 'string',
          trxRefNo: '5678AXUI900000',
          type: OrderTypeEnum.CoolOff,
          trxType: OrderTrxTypeEnum.EPF,
          status: OrderStatusEnum.SETTLED,
          submittedDate: '2024-04-23T07:26:19.027Z',
          transactionHostRefNo: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          ppgRefNo: 'string',
          pgRefNo: 'string',
          amount: 0,
          amountCcy: 'MYR',
        }}
      />
    ),
  },
]
const demoPostLogonPages: Pages[] = [
  {
    title: 'dashboard',
    path: 'dashboard',
    element: <Dashboard />,
  },
  {
    title: 'Fund List',
    path: 'fund-list',
    element: <FundList />,
  },
  {
    title: 'Fund List By Category',
    path: 'fund-list/:category',
    element: <FundList />,
  },
  {
    title: 'Cash-In',
    path: 'cash-in',
    element: <CashIn />,
  },
  {
    title: 'Transaction History',
    path: 'transaction-history',
    element: <TransactionHistory />,
  },
]
const demoErrorPages: Pages[] = [
  {
    title: 'Generlic error page',
    path: 'generlic-error',
    element: <PostLogonLayout children={<ErrorGenerlic />} />,
  },
  {
    title: 'Not Found Error page',
    path: 'not-found-error',
    element: <PostLogonLayout children={<ErrorNotFound />} />,
  },
]
const PageList = () => (
  <div className='flex flex-col p-4 gap-y-2'>
    {demoPreLogonPages.map(({ path, title }) => (
      <Link key={path} to={path}>
        {title}
      </Link>
    ))}
    {demoInBetweenPages.map(({ path, title }) => (
      <Link key={path} to={path}>
        {title}
      </Link>
    ))}
    {demoPostLogonPages.map(({ path, title }) => (
      <Link
        key={path}
        to={path !== 'fund-list/:category' ? path : 'fund-list/Cash'}
      >
        {title}
      </Link>
    ))}
    {demoErrorPages.map(({ path, title }) => (
      <Link key={path} to={path}>
        {title}
      </Link>
    ))}
  </div>
)
const demoPreLogonRoute = demoPreLogonPages.map(({ path, element }) => (
  <Route
    key={path}
    path={`/page-list/${path}`}
    element={<PreLogonLayout children={element} />}
  />
))
const demoInBetweenRoute = demoInBetweenPages.map(({ path, element }) => (
  <Route
    key={path}
    path={`/page-list/${path}`}
    element={<PreLogonLayout children={element} />}
  />
))
const demoPostLogonRoute = demoPostLogonPages.map(({ path, element }) => (
  <Route
    key={path}
    path={`/page-list/${path}`}
    element={<PostLogonLayout children={element} />}
  />
))
const demoErrorPageRoutes = demoErrorPages.map(({ path, element }) => (
  <Route
    key={path}
    path={`/page-list/${path}`}
    element={<PostLogonLayout children={element} />}
  />
))

export const devDemoRoutes =
  process.env.NODE_ENV !== 'development'
    ? []
    : [
        <Route path='/page-list' element={<PageList />} />,
        ...demoPreLogonRoute,
        ...demoInBetweenRoute,
        ...demoPostLogonRoute,
        ...demoErrorPageRoutes,
      ]

export default devDemoRoutes
