import {
  AgentInformation as AgentCode,
  WrapperTypeEnum,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQueryFundList } from '@/api/commonApi'
import { useQueryCustomerMetadata } from '@/api/customerApi'
import { useQueryPurchasingInstruction } from '@/api/orderApi'
import { RoutePathEnum } from '@/constants/routePath'
import { SelectedFund } from '@/hooks/useInvestmentReducer'
import {
  getErrorResponseCode,
  getFundCodesString,
  resetLocationState,
} from '@/utils'
import { useSelector } from 'react-redux'
import { selectEpfToken } from '@/stores/epfToken.selectors'
import useHandleUserStatus from '@/hooks/useHandleUserStatus'
import {
  AML_EXPIRED,
  AML_FAILED,
  RISK_PROFILE_NOT_EXPIRED,
  RISK_PROFILE_NOT_PASSED,
} from '@/constants/errorCodes'

const AML_ERROR_STATUS = [AML_FAILED, AML_EXPIRED]

const ISAF_FAILED_STATUS = [RISK_PROFILE_NOT_PASSED, RISK_PROFILE_NOT_EXPIRED]

export const useInitData = ({
  handelSetFunds,
  handelSetAgentCode,
  resetInvestments,
}: {
  handelSetFunds: (funds: SelectedFund[]) => void
  handelSetAgentCode: (agentCode: AgentCode) => void
  resetInvestments: () => void
}) => {
  const navigate = useNavigate()

  const { state } = useLocation()
  const { selectedFunds = [] } = state || {}
  const xEpfToken = useSelector(selectEpfToken) || undefined
  const { isValidUserStatus, handleCheckUserStatus } = useHandleUserStatus()

  /* Redirect to fund selection Page, if no fund selected or not directed from Epf side */
  if (!selectedFunds?.length && !xEpfToken) {
    navigate(RoutePathEnum.ORDER_PURCHASE)
  }
  const [setFundsCompleted, setSetFundsCompleted] = useState<boolean>(false)

  const queryPI = useQueryPurchasingInstruction({
    fundCodes: !!selectedFunds?.length
      ? getFundCodesString(selectedFunds as SelectedFund[])
      : undefined,
  })

  const queryCM = useQueryCustomerMetadata({ enabled: queryPI.isSuccess })
  useEffect(() => {
    if (queryCM.status === 'error') {
      console.log('error:', queryCM.error)
      navigate(RoutePathEnum.ERROR)
      return
    }
  }, [queryCM.status, queryCM.error])

  const isEpf = useMemo(() => {
    if (!queryPI.data) return undefined
    return [
      WrapperTypeEnum.PersonalPortfolioUTCashEPF,
      WrapperTypeEnum.EPFUTEPFeMIS,
    ].includes(queryPI.data.wrapperType)
  }, [queryPI.data])

  useEffect(() => {
    if (queryPI.status === 'error') {
      const errMsg = getErrorResponseCode(queryPI.error)
      console.log('errMsg: ', errMsg)

      if (AML_ERROR_STATUS.includes(errMsg)) {
        console.log('triggered')
        handleCheckUserStatus({
          forceOpenModal: 'ecdd',
          onEcddModalClose: () => navigate(RoutePathEnum.DASHBOARD),
        })
        return
      }

      if (ISAF_FAILED_STATUS.includes(errMsg)) {
        handleCheckUserStatus({
          forceOpenModal: 'isaf',
          onIsafModalClose: () => navigate(RoutePathEnum.DASHBOARD),
        })
        return
      }
      navigate(RoutePathEnum.ERROR)
      return
    }
    if (queryPI.status === 'success' && !!queryPI.data) {
      if (queryPI.data.wrapperType === WrapperTypeEnum.EPFUTEPFePPA) {
        console.error('unexpected wrapperType')
        navigate(RoutePathEnum.ERROR)
        return
      }
      if (!queryPI.data.agentCodeDisabled && !!queryPI.data.lastAgent) {
        handelSetAgentCode(queryPI.data.lastAgent)
      }
      if (
        queryPI.data.wrapperType === WrapperTypeEnum.PersonalPortfolioUTCash
      ) {
        //normal purchaising --
        if (!selectedFunds?.length) {
          navigate(RoutePathEnum.ORDER_PURCHASE)
          return
        }
        handelSetFunds(selectedFunds)
        resetLocationState()
        setSetFundsCompleted(true)
      }
    }
  }, [queryPI.status, queryPI.data, queryPI.error])

  //EPF --
  const queryFL = useQueryFundList({ enabled: isEpf })
  useEffect(() => {
    if (!isEpf) return
    if (queryFL.status === 'error') {
      console.log('query fund list error:', queryFL.error)
      navigate(RoutePathEnum.ERROR)
      return
    }
    if (
      queryPI.status === 'success' &&
      !!queryPI.data &&
      queryFL.status === 'success' &&
      !!queryFL.data
    ) {
      const epfFunds = queryPI.data.funds.reduce(
        (acc, { fundCode, amount, feePercentage, ccy }) => {
          const _fund = queryFL.data.find(({ code }) => code === fundCode)
          return !!_fund
            ? [
                ...acc,
                {
                  ..._fund,
                  amount,
                  salesFeePerc: feePercentage,
                  currency: ccy,
                },
              ]
            : acc
        },
        [] as SelectedFund[]
      )
      if (!epfFunds.length) {
        console.error('epf fund list empty')
        navigate(RoutePathEnum.ERROR)
        return
      }
      resetInvestments()
      handelSetFunds(epfFunds)
      !!selectedFunds?.length && resetLocationState()
      setSetFundsCompleted(true)
    }
  }, [
    isEpf,
    queryPI.status,
    queryPI.data,
    queryFL.status,
    queryFL.data,
    queryFL.error,
  ])
  //[end] EPF --

  const isLoading = useMemo(
    () =>
      queryCM.isFetching ||
      queryPI.isLoading ||
      (isEpf && !!queryFL && queryFL.isLoading) ||
      !setFundsCompleted,
    [queryCM.isFetching, queryPI.isLoading, isEpf, queryFL, setFundsCompleted]
  )

  const isError = useMemo(
    () => queryCM.isError || queryPI.isError || (isEpf && queryFL.isError),
    [queryCM.isError, queryPI.isError, isEpf, queryFL.isError]
  )

  return {
    isEpf,
    cmData: queryCM.data,
    piData: queryPI.data,
    isValidUserStatus,
    handleCheckUserStatus,
    isLoading,
    isError,
  }
}

export default useInitData
