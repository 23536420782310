import StickyBottomContainer from '@/components/StickyBottomContainer'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

export interface CheckOutInvestmentFooterProps {
  selectedFundCount: number
  className?: string
  onCheckOut: () => void
}

const CheckOutInvestmentFooter = ({
  selectedFundCount,
  className = '',
  onCheckOut,
}: CheckOutInvestmentFooterProps) => {
  const { t } = useTranslation('investmentPage')

  return (
    <StickyBottomContainer className={`bg-pi-navy-blue py-3 ${className}`}>
      <div className='max-w-screen-xl mx-auto flex flex-row justify-between items-center'>
        <div className='text-white'>
          {t('fund-selected', {
            count: selectedFundCount,
          })}
        </div>
        <Button
          variant='contained'
          className='!bg-white !bg-none text-pi-principal-blue tracking-wide disabled:!bg-gradient-to-r !from-pi-gray-4 !to-pi-gray-3 disabled:!shadow-none disabled:!text-white'
          onClick={onCheckOut}
          disabled={selectedFundCount === 0}
        >
          {t('make-deposit')}
        </Button>
      </div>
    </StickyBottomContainer>
  )
}

export default CheckOutInvestmentFooter
