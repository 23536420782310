import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { DEF_CURRENCY, DEF_DECIMAL } from '@pimy-b2cweb/frontend-lib'
import { forwardRef, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { NumericFormat, NumericFormatProps } from 'react-number-format'

export interface FundAmountInputProps
  extends Omit<TextFieldProps, 'type' | 'onChangemay'> {
  InputProps?: Omit<TextFieldProps['InputProps'], 'endAdornment'>
  decimal?: number
  currency?: string
  isLoading?: boolean
  setMaxAmount?: () => void
}

interface NumericFormatInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  decimal?: number
}

const NumericFormatInput = memo(
  forwardRef<NumericFormatProps, NumericFormatInputProps>((props, ref) => {
    const { onChange, decimal = DEF_DECIMAL, ...other } = props

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        allowLeadingZeros={false}
        thousandSeparator
        valueIsNumericString
        decimalScale={decimal}
      />
    )
  })
)

export const FundAmountInput = memo(
  forwardRef<HTMLDivElement, FundAmountInputProps>(
    (
      {
        InputProps,
        currency = DEF_CURRENCY,
        value,
        isLoading = false,
        disabled,
        decimal,
        setMaxAmount,
        ...rest
      },
      ref
    ) => {
      const { t } = useTranslation('common')
      return (
        <TextField
          ref={ref}
          type='tel'
          name='fundAmount'
          InputProps={{
            ...InputProps,
            inputComponent: NumericFormatInput as never,
            inputProps: {
              decimal,
              allowNegative: false,
            },
            inputMode: 'decimal',
            startAdornment: (
              <InputAdornment position='start' className='text-SIA'>
                <span className='select-none text-pi-gray-1'>{currency}</span>
              </InputAdornment>
            ),
            endAdornment: !!setMaxAmount && (
              <InputAdornment position='end'>
                <Button
                  onClick={setMaxAmount}
                  className='-mx-2 !px-2 !py-1 !rounded !border !border-pi-true-blue !font-normal !text-pi-true-blue'
                  disabled={disabled || isLoading}
                  variant='outlined'
                >
                  {!isLoading ? (
                    t('max-amount')
                  ) : (
                    <CircularProgress
                      size={14}
                      thickness={6}
                      className='text-pi-gray-4'
                    />
                  )}
                </Button>
              </InputAdornment>
            ),
          }}
          {...rest}
          value={value === 0 ? '' : value}
        />
      )
    }
  )
)

export default FundAmountInput
