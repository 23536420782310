import { DD_MM_YYYY } from '@/constants'
import { RoutePathEnum } from '@/constants/routePath'
import { useInitProfileInfoData } from '@/Routes/pages/ProfileEdit/hooks/useInitProfileInfoData'
import { filterByLov, formatAddress } from '@/utils'
import {
  CustomerIdTypeEnum,
  CustomerProfileAddressContact,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ContactInfoProps } from '../../components/ContactInfo'
import { EmploymentInfoProps } from '../../components/EmploymentInfo'
import { PersonalInfoProps } from '../../components/PersonalInfo'

export interface ProfileInfoProps
  extends PersonalInfoProps,
    ContactInfoProps,
    EmploymentInfoProps {}

const initProfileInfo: ProfileInfoProps = {
  fullName: '',
  idType: undefined,
  idNo: '',
  dateOfBirth: '',
  country: '',
  nationality: '',
  gender: '',
  race: '',
  epfAccountType: '',
  epfNumber: '',
  epfiStatusEffectiveDate: '',
  primaryContact: {
    addressLines: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      addressLine4: '',
      addressLine5: '',
    },
    postalInfo: { postalCode: '', cityName: '', state: '', country: '' },
  },
  isPrimaryContactCurrent: true,
  correspondentContact: {
    addressLines: {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      addressLine4: '',
      addressLine5: '',
    },
    postalInfo: { postalCode: '', cityName: '', state: '', country: '' },
  },
  mobilePhoneNo: '',
  email: '',
  occupation: '',
  natureOfBusiness: '',
  yearlyIncomeLevel: '',
  employerName: '',
  sourceOfFund: '',
  investmentObjectives: '',
  pepFlag: false,
  taxResidentOtherThanMalaysia: false,
  crsCountries: [
    {
      tin: '',
      country: '',
    },
  ],
}

export const useInitData = () => {
  const {
    lovs,
    lovValidated,
    isLoading,
    profileQueryData: queryData,
    profileQueryError: queryError,
    profileQueryStatus: queryStatus,
  } = useInitProfileInfoData()

  const navigate = useNavigate()

  const [profileInfo, setProfileInfo] = useState(initProfileInfo)

  useEffect(() => {
    if (queryStatus === 'error') {
      console.log('error:', queryError)
      navigate(RoutePathEnum.ERROR)
      return
    }
    if (queryStatus === 'success' && !!queryData && lovValidated) {
      const _profileInfo = { ...initProfileInfo }
      const {
        identities,
        epfDetails,
        primaryContact,
        correspondentContact,
        occupation,
        natureOfBusiness,
        yearlyIncomeLevel,
        employerName,
        sourceOfFund,
        investmentObjectives,
        pepFlag,
        crsCountries,
      } = queryData

      /* Personal Info */
      _profileInfo.fullName = queryData.fullName || ''
      _profileInfo.idType = identities?.at(0)?.idType as CustomerIdTypeEnum
      _profileInfo.idNo = identities?.at(0)?.idNo || ''

      if (!!queryData.dateOfBirth) {
        const dob = dayjs(queryData.dateOfBirth).format(DD_MM_YYYY)
        _profileInfo.dateOfBirth = dob
      }

      if (identities?.at(0)?.countryCode && !!lovs?.country) {
        const _country = filterByLov(identities[0]?.countryCode, lovs.country)
        _profileInfo.country = _country?.label || ''
      }

      if (!!queryData?.nationality && !!lovs?.country) {
        const _country = filterByLov(queryData.nationality, lovs.country)
        _profileInfo.nationality = _country?.label || ''
      }
      if (!!queryData?.gender && !!lovs?.gender) {
        const _gender = filterByLov(queryData.gender, lovs.gender)
        _profileInfo.gender = _gender?.label || ''
      }
      if (!!queryData?.gender && !!lovs?.race) {
        const _race = filterByLov(queryData.race, lovs.race)
        _profileInfo.race = _race?.label || ''
      }

      // EPF Fields Mapping
      if (!!epfDetails?.epfNumber) {
        _profileInfo.epfAccountType =
          epfDetails?.epfiStatus === undefined
            ? ''
            : !!epfDetails.epfiStatus
            ? 'Islamic'
            : 'Conventional'

        _profileInfo.epfNumber = epfDetails?.epfNumber || ''
        _profileInfo.epfiStatusEffectiveDate =
          epfDetails?.epfiStatusEffectiveDate || ''
      }

      // Primary Address Mapping
      if (!!primaryContact) {
        const _pc = formatAddress({
          address: primaryContact,
          addressLineNum: 5,
        }) as CustomerProfileAddressContact

        _profileInfo.primaryContact.addressLines.addressLine1 =
          _pc.addressLine1 || ''
        _profileInfo.primaryContact.addressLines.addressLine2 =
          _pc?.addressLine2 || ''
        _profileInfo.primaryContact.addressLines.addressLine3 =
          _pc?.addressLine3 || ''
        _profileInfo.primaryContact.addressLines.addressLine4 =
          _pc?.addressLine4 || ''
        _profileInfo.primaryContact.addressLines.addressLine5 =
          _pc?.addressLine5 || ''
        _profileInfo.primaryContact.postalInfo.cityName = _pc?.cityName || ''
        if (!!_pc?.country && !!lovs?.country) {
          const _country = filterByLov(_pc.country, lovs.country)
          _profileInfo.primaryContact.postalInfo.country = _country?.label || ''
        }
        _profileInfo.primaryContact.postalInfo.postalCode =
          _pc?.postalCode || ''
        if (!!_pc?.state && !!lovs?.state) {
          const _state = filterByLov(_pc.state, lovs.state)
          _profileInfo.primaryContact.postalInfo.state = _state?.label || ''
        }
      }

      // Correspondence Address Mapping
      if (!!correspondentContact) {
        _profileInfo.isPrimaryContactCurrent = false

        const _cc = formatAddress({
          address: correspondentContact,
          addressLineNum: 5,
        }) as CustomerProfileAddressContact

        _profileInfo.correspondentContact.addressLines.addressLine1 =
          _cc.addressLine1 || ''
        _profileInfo.correspondentContact.addressLines.addressLine2 =
          _cc?.addressLine2 || ''
        _profileInfo.correspondentContact.addressLines.addressLine3 =
          _cc?.addressLine3 || ''
        _profileInfo.correspondentContact.addressLines.addressLine4 =
          _cc?.addressLine4 || ''
        _profileInfo.correspondentContact.addressLines.addressLine5 =
          _cc?.addressLine5 || ''
        _profileInfo.correspondentContact.postalInfo.cityName =
          _cc?.cityName || ''
        if (!!_cc?.country && !!lovs?.country) {
          const _country = filterByLov(_cc.country, lovs.country)
          _profileInfo.correspondentContact.postalInfo.country =
            _country?.label || ''
        }
        _profileInfo.correspondentContact.postalInfo.postalCode =
          _cc?.postalCode || ''
        if (!!_cc?.state && !!lovs?.state) {
          const _state = filterByLov(_cc.state, lovs.state)
          _profileInfo.correspondentContact.postalInfo.state =
            _state?.label || ''
        }
      }

      /* Contact Info Mapping */
      _profileInfo.mobilePhoneNo = !!queryData.mobilePhoneNo
        ? `+${queryData.mobilePhoneNo}`
        : ''
      _profileInfo.email = queryData.email || ''

      /* Employment Info Mapping */
      if (!!occupation && !!lovs?.occupation) {
        const _occupation = filterByLov(occupation, lovs.occupation)
        _profileInfo.occupation = _occupation?.label || ''
      }
      if (!!natureOfBusiness && !!lovs.nature_of_business) {
        const _natureOfBusiness = filterByLov(
          natureOfBusiness,
          lovs.nature_of_business
        )
        _profileInfo.natureOfBusiness = _natureOfBusiness?.label || ''
      }
      if (!!yearlyIncomeLevel && !!lovs.annual_income) {
        const _yearlyIncomeLevel = filterByLov(
          yearlyIncomeLevel,
          lovs.annual_income
        )
        _profileInfo.yearlyIncomeLevel = _yearlyIncomeLevel?.label || ''
      }
      _profileInfo.employerName = employerName || ''
      if (!!sourceOfFund && !!lovs.source_of_funds) {
        const _sourceOfFund = filterByLov(sourceOfFund, lovs.source_of_funds)
        _profileInfo.sourceOfFund = _sourceOfFund?.label || ''
      }
      if (!!investmentObjectives && !!lovs.purpose_of_investment) {
        const _investmentObjectives = filterByLov(
          investmentObjectives,
          lovs.purpose_of_investment
        )
        _profileInfo.investmentObjectives = _investmentObjectives?.label || ''
      }
      _profileInfo.pepFlag = !!pepFlag

      const isTROM = !!crsCountries && !!crsCountries.at(0)
      _profileInfo.taxResidentOtherThanMalaysia = isTROM

      if (isTROM && lovs.country) {
        const _crsCountry = filterByLov(crsCountries[0].country, lovs.country)
        _profileInfo.crsCountries = [
          {
            country: _crsCountry?.label || '',
            tin: crsCountries[0].tin,
          },
        ]
      }

      setProfileInfo({ ...profileInfo, ..._profileInfo })
    }
  }, [queryStatus, queryData, queryError, lovValidated])

  return {
    isLoading,
    profileInfo,
  }
}
