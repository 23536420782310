import { LanguageCodeEnum as LangEnum } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useTranslation } from 'react-i18next'
import { apiLangCode } from '@/utils'
import { useMemo } from 'react'

export const useSwitchLang = () => {
  const {
    i18n: { language, changeLanguage },
  } = useTranslation()
  const langCode = useMemo(() => apiLangCode(language), [language])

  const handleSwitchLang = (langCode: LangEnum) => {
    if (language !== langCode) changeLanguage(langCode)
  }

  return { langCode, handleSwitchLang }
}

export default useSwitchLang
