import { validatePassword } from '@pimy-b2cweb/common'
import {
  ButtonWithLoading,
  FormLabel,
  ResponseError,
  useResponseError,
  PasswordTextField,
  usePasswordText,
  CSLink,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useMutationSetUpPassword } from '@/api/onboardingApi'
import { PASSWORD_NOT_MATCH } from '@/constants/errorCodes'
import { sessionSignUpSucceed } from '@/stores/auth'
import { selectAuthSessionState } from '@/stores/auth.selectors'
import { getErrorResponseCode } from '@/utils'

interface PasswordFormProps {
  password: string
  rePassword: string
}

export const Password = () => {
  const { t } = useTranslation(['signUpPage', 'common'])
  const usePasswordTextReturns = usePasswordText()

  const { control, handleSubmit } = useForm<PasswordFormProps>({
    mode: 'onChange',
  })

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const dispatch = useDispatch()
  const { username } = useSelector(selectAuthSessionState)

  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationSetUpPassword()

  const onSubmit = async (data: PasswordFormProps) => {
    console.log(data)
    if (!!username && !!data.password) {
      mutate({
        username,
        newPassword: data.password,
      })
    }
  }

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      dispatch(sessionSignUpSucceed())
    }
  }, [mutatedData, mutateStatus, mutatedError])

  return (
    <>
      <h1>{t('sign-up')}</h1>
      <p>{t('password-page-text', { ns: 'common' })}</p>
      {!!responseErrorAttrs && (
        <ResponseError>
          <Trans
            {...responseErrorAttrs}
            t={t}
            components={{ CS: <CSLink /> }}
          />
        </ResponseError>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='password'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
            validate: (val: string) => {
              const issues = validatePassword(val)
              return issues.length === 0
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='password'
              label={t('Password', { ns: 'common' })}
              isError={!!error}
              className='!mb-4'
            >
              <PasswordTextField
                fullWidth
                {...field}
                error={!!error}
                // helperText={!!error ? (error?.message as string) : ''}
                helperText={t('password-helper-text', { ns: 'common' })}
                className='[&>.MuiFormHelperText-root]:!text-xs'
                disabled={mutateIsLoading}
                placeholder='112233'
                {...usePasswordTextReturns}
              />
            </FormLabel>
          )}
        />
        <Controller
          name='rePassword'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
            validate: (value: string, formValues: { password: string }) =>
              value === formValues.password || t(PASSWORD_NOT_MATCH),
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='rePassword'
              label={t('Confirm password', { ns: 'common' })}
              isError={!!error}
              className='!mb-10'
            >
              <PasswordTextField
                fullWidth
                {...field}
                error={!!error}
                helperText={!!error ? (error?.message as string) : ''}
                disabled={mutateIsLoading}
                placeholder='112233'
                {...usePasswordTextReturns}
              />
            </FormLabel>
          )}
        />
        <ButtonWithLoading
          type='submit'
          fullWidth
          variant='contained'
          size='large'
          disabled={mutateIsLoading}
          isLoading={mutateIsLoading}
        >
          {t('Continue', { ns: 'common' })}
        </ButtonWithLoading>
      </form>
    </>
  )
}

export default Password
