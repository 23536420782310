import { UseQuerySearchTransactionHistory } from '@/api/orderApi'
import { DD_MM_YYYY, TRANSACTION_DT_FORMAT } from '@/constants'
import { AssetGroupEnum, OrderTypeEnum } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { Dayjs } from 'dayjs'
import { TFunction } from 'i18next'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ASSET_TYPES, TRANSACTION_TYPES } from '../../TransactionFilterModal'
import { useTranslation } from 'react-i18next'

export interface TransactionFilterFormProps {
  assetTypes: AssetGroupEnum[]
  transactionTypes: OrderTypeEnum[]
  fromDt: Dayjs | null
  toDt: Dayjs | null
}

export const useTransactionFilterForm = () => {
  const { t } = useTranslation('transactionHistoryPage')
  const formMethods = useForm<TransactionFilterFormProps>({
    mode: 'onChange',
    defaultValues: {
      assetTypes: [],
      transactionTypes: [],
      fromDt: null,
      toDt: null,
    },
  })

  const [filterData, setFilterData] =
    useState<UseQuerySearchTransactionHistory>({})

  const [filters, setFilters] = useState<string[]>([])

  const onSubmitForm = (data: TransactionFilterFormProps) => {
    const { assetTypes, transactionTypes, fromDt, toDt } = data
    const _fromDt = fromDt?.format(TRANSACTION_DT_FORMAT)
    const _toDt = toDt?.endOf('day')?.format(TRANSACTION_DT_FORMAT)

    setFilterData({
      ...filterData,
      assetGroup: assetTypes.length === 1 ? assetTypes[0] : undefined,
      types: transactionTypes,
      dateStart: _fromDt,
      dateEnd: _toDt,
    })
    setFilters(getFilters(data, t))
  }

  const onClearFilter = () => {
    formMethods.reset()
    setFilterData({})
    setFilters([])
  }

  return {
    formMethods,
    filterData,
    filters,
    onSubmitForm,
    onClearFilter,
  }
}

const getFilters = (
  data: TransactionFilterFormProps,
  t: TFunction<[string], undefined>
) => {
  const filters: string[] = []

  const { fromDt, toDt, assetTypes, transactionTypes } = data

  if (!!fromDt && !!toDt) {
    filters.push(
      t('date', {
        date_range: `${fromDt.format(DD_MM_YYYY)} - ${toDt.format(DD_MM_YYYY)}`,
      })
    )
  }

  if (assetTypes.length > 0) {
    ASSET_TYPES.forEach(({ value, label }) => {
      if (assetTypes.includes(value)) {
        filters.push(t(label))
      }
    })
  }
  if (transactionTypes.length > 0) {
    TRANSACTION_TYPES.forEach(({ value, label }) => {
      if (transactionTypes.includes(value)) {
        filters.push(t(label))
      }
    })
  }

  return filters
}
