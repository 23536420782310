import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import TwoSideText from '@/components/TwoSideText'
import { RoutePathEnum } from '@/constants/routePath'
import { formattedAmount, useModal } from '@pimy-b2cweb/frontend-lib'
import { Button, Divider } from '@mui/material'
import { ReactComponent as InfoIco } from '@/assets/info.svg'
import SwitchingFeeModal from '../../components/SwitchingFeeModal'
import { SwitchingTransactionDetails } from '../..'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { DD_MMM_YYYY, HH_MM_SS_A } from '@/constants'

dayjs.extend(utc)

export interface SwitchResultProps {
  transactionDetails?: SwitchingTransactionDetails
}

export const SwitchResult = ({ transactionDetails }: SwitchResultProps) => {
  const { t } = useTranslation(['switchingPage', 'common'])
  const navigate = useNavigate()
  const switchingFeeModal = useModal()

  if (!transactionDetails) {
    navigate(RoutePathEnum.ERROR)
  }

  const date = dayjs.utc(transactionDetails?.submittedDate).local()

  return (
    <>
      <h1 className='mb-4 font-bold'>{t('switch-fund-submitted')}</h1>
      <p className='mb-0'>{t('switch-fund-submitted-summary')}</p>
      <Divider className='my-6' />
      <TwoSideText
        className='mb-2 text-pi-gray-1'
        left={t('date', { ns: 'common' })}
        right={date.format(DD_MMM_YYYY)}
      />
      <TwoSideText
        className='mb-2 text-pi-gray-1'
        left={t('time', { ns: 'common' })}
        right={date.format(HH_MM_SS_A)}
      />
      <TwoSideText
        className='mb-2 text-pi-gray-1'
        left={t('transaction-id', { ns: 'common' })}
        right={transactionDetails?.transactionHostRefNo || ''}
      />
      <TwoSideText
        className='mb-2 text-pi-gray-1'
        left={t('reference-no', { ns: 'common' })}
        right={transactionDetails?.trxRefNo || ''}
      />
      <TwoSideText
        className='mb-2 text-pi-gray-1'
        left={t('transaction-no', { ns: 'common' })}
        right={transactionDetails?.pgRefNo || ''}
      />
      <TwoSideText
        className='text-xl font-bold text-pi-navy-blue my-2 mt-4'
        left={`${t('total', { ns: 'common' })}:`}
        right={formattedAmount({ amount: transactionDetails?.amount || 0 })}
      />

      <div className='flex flex-row gap-2 mt-4'>
        <span className='-mt-[1px]'>
          <InfoIco className='w-6 h-6' />
        </span>
        <div className='flex flex-col gap-2'>
          {t('switch-fund-submitted-info')}
          <span>
            {t('switching-fee-info')}
            <span
              className='clickable-text font-normal pl-1'
              onClick={() => switchingFeeModal.modalOpen()}
            >
              {t('more-info', { ns: 'common' })}
            </span>
          </span>
        </div>
      </div>

      <Button
        fullWidth
        variant='contained'
        size='large'
        className='mb-8 mt-8'
        onClick={() => navigate(RoutePathEnum.DASHBOARD)}
      >
        {t('done', { ns: 'common' })}
      </Button>
      <SwitchingFeeModal {...switchingFeeModal} />
    </>
  )
}

export default SwitchResult
