import { TextField } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import {
  ButtonWithLoading,
  FormLabel,
  ResponseError,
  ResponseErrorProps,
  CSLink,
} from '@pimy-b2cweb/frontend-lib'
import { isNumber } from '@/utils'
import { UseMutationVerifyOtp } from '@/api/commonApi'
import { OtpNotificationTypeEnum } from '@pimy-b2cweb/apiclient-b2cweb-r2'

export interface VerifyOtpFormProps extends UseMutationVerifyOtp {}

export interface VerifyOtpUiProps {
  onSubmit: (data: VerifyOtpFormProps) => Promise<void>
  isLoading?: boolean
  resending?: boolean
  notificationType?: OtpNotificationTypeEnum
  canResend: boolean | null
  timeLeft: number
  onResendOtp: () => void

  notificationRecipient: string | null
  responseErrorAttrs?: ResponseErrorProps
  resendErrorAttrs?: ResponseErrorProps
  operation: 'reset' | 'continue' | 'signup'
}
export const VerifyOtpUi = ({
  h1Text,
  notificationRecipient,
  responseErrorAttrs,
  resendErrorAttrs,
  onSubmit,
  isLoading,
  resending,
  notificationType,
  canResend,
  timeLeft,
  onResendOtp,
  operation,
}: {
  h1Text?: string
  notificationRecipient?: string
  responseErrorAttrs?: ResponseErrorProps
  resendErrorAttrs?: ResponseErrorProps
  onSubmit: (data: VerifyOtpFormProps) => Promise<void>
  isLoading?: boolean
  resending?: boolean
  notificationType?: OtpNotificationTypeEnum
  canResend?: boolean
  timeLeft: number
  onResendOtp: () => void
  operation: 'reset' | 'continue' | 'signup'
}) => {
  const { t } = useTranslation(['otpPage', 'errorResponse', 'common'])
  const { control, handleSubmit, setValue, resetField } =
    useForm<VerifyOtpFormProps>({
      mode: 'onChange',
      defaultValues: {},
    })

  return (
    <>
      <h1>{h1Text || t('enter-verification-code')}</h1>
      <p>
        <Trans
          i18nKey='pin-has-been-sent'
          t={t}
          values={{ notification: notificationRecipient }}
          components={{ B: <strong /> }}
        />
        <span>{t(`pin-has-been-sent-${operation}`)}</span>
      </p>
      {!!responseErrorAttrs && (
        <ResponseError>
          {t(responseErrorAttrs.i18nKey, { ns: responseErrorAttrs.ns })}
        </ResponseError>
      )}
      {!!resendErrorAttrs && (
        <ResponseError>
          {t(resendErrorAttrs.i18nKey, { ns: resendErrorAttrs.ns })}
        </ResponseError>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='code'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
            validate: (val: string) => {
              if (!isNumber(val) || val.length !== 6) {
                return t('invalid-input', { ns: 'errorResponse' })
              }
              return true
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='code'
              label={t('verification-pin')}
              isError={!!error}
              className={canResend ? '!mb-1' : '!mb-10'}
            >
              <TextField
                type='tel'
                fullWidth
                {...field}
                error={!!error}
                helperText={!!error ? (error?.message as string) : ''}
                disabled={isLoading}
                placeholder='112233'
                inputProps={{ maxLength: 6 }}
                autoComplete='off'
              />
            </FormLabel>
          )}
        />
        {canResend && (
          <p className='!mb-10 text-pi-gray-1'>
            {t('did-not-receive-pin', { ns: 'common' })}
            {timeLeft > 0 ? (
              <span className='text-pi-gray-3'>
                {t('Resend', { ns: 'common' })}({timeLeft / 1000}s)
              </span>
            ) : !!isLoading || !!resending ? (
              <span className='text-pi-gray-3'>
                {t('Resend', { ns: 'common' })}
              </span>
            ) : (
              <span
                className='text-pi-principal-blue hover:text-pi-prussian-blue underline cursor-pointer'
                onClick={() => {
                  resetField('code')
                  setValue('code', '')
                  onResendOtp()
                }}
              >
                {t('Resend', { ns: 'common' })}
              </span>
            )}
          </p>
        )}
        <ButtonWithLoading
          type='submit'
          fullWidth
          variant='contained'
          size='large'
          className='mb-10 sm:mb-2'
          disabled={isLoading || resending}
          isLoading={isLoading}
        >
          {t('Continue', { ns: 'common' })}
        </ButtonWithLoading>
        {notificationType === OtpNotificationTypeEnum.sms && (
          <p className='text-center mt-6 mb-0'>
            <Trans
              i18nKey='not-your-mobile-number-p'
              t={t}
              components={{ CS: <CSLink /> }}
            />
          </p>
        )}
      </form>
    </>
  )
}

export default VerifyOtpUi
