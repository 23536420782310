import { createSlice } from '@reduxjs/toolkit'

export interface ProfileEditSessionState {
  pendingUpdateCheck: boolean
}

const initialState: ProfileEditSessionState = {
  pendingUpdateCheck: false,
}

export const profileEditSessionSlice = createSlice({
  name: 'profileEditSession',
  initialState,
  reducers: {
    sessionReset: (state) => {
      state.pendingUpdateCheck = initialState.pendingUpdateCheck
    },

    sessionSetPendingCheck: (state) => {
      state.pendingUpdateCheck = true
    },
  },
})

//action export
export const { sessionReset, sessionSetPendingCheck } =
  profileEditSessionSlice.actions
export default profileEditSessionSlice.reducer
