import { useEffect } from 'react'

export const useBeforeUnload = ({ enabled }: { enabled: boolean }) => {
  useEffect(() => {
    if (enabled) window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      if (enabled)
        window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [enabled])
}

const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  event.preventDefault()
  event.returnValue = ''
}

export default useBeforeUnload
