import { ReactComponent as FrameIco } from '../../assets/frame-ico.svg'

export interface SectionHeadingProps {
  className?: string
  title: string
}
const SectionHeading = ({ className = '', title }: SectionHeadingProps) => {
  return (
    <h3
      className={`flex flex-row gap-2 text-xl font-black text-pi-prussian-blue my-0 ${className}`}
    >
      <FrameIco className='w-7 h-7' />
      {title}
    </h3>
  )
}

export default SectionHeading
