import { TokenParams, AuthApiError, IdxStatus } from '@okta/okta-auth-js'
import { useOktaAuth } from '@okta/okta-react'
import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { OKTA_SIGN_IN, OKTA_SESSION_REFRESH } from '@/constants/apiKeys'
import {
  INTERNAL_SERVER_ERROR,
  LOCKED_OUT,
  OKTA_AUTH_ERROR,
  PASSWORD_EXPIRED,
  REQUIRE_RESET_PASSWORD,
} from '@/constants/errorCodes'
import { selectOktaTokens } from '@/stores/auth.selectors'

export interface UseMutationOktaAuth {
  username: string
  password: string
}

const TOKEN_PARAMS: TokenParams = {
  responseType: ['code', 'token', 'id_token', 'refresh_token'],
  scopes: ['offline_access', 'openid', 'profile'],
  pkce: true,
}

export const useMutationOktaAuth = () => {
  const { oktaAuth } = useOktaAuth()

  return useMutation({
    mutationFn: async ({ username, password }: UseMutationOktaAuth) => {
      const authResult = await oktaAuth
        .signInWithCredentials({
          username,
          password,
        })
        .catch((err: AuthApiError) => {
          throw new Error(
            err.errorCode === 'E0000004'
              ? OKTA_AUTH_ERROR
              : INTERNAL_SERVER_ERROR
          )
        })

      if (authResult.status !== IdxStatus.SUCCESS) {
        throw new Error(
          [LOCKED_OUT, PASSWORD_EXPIRED].includes(authResult.status)
            ? REQUIRE_RESET_PASSWORD
            : authResult.status
        )
      }

      return await oktaAuth.token.getWithoutPrompt({
        sessionToken: authResult.sessionToken,
        ...TOKEN_PARAMS,
      })
    },
    mutationKey: [OKTA_SIGN_IN],
  })
}

export const useMutationSessionRefresh = () => {
  const { oktaAuth } = useOktaAuth()
  const oktaTokens = useSelector(selectOktaTokens)

  const mutationReturns = useMutation({
    mutationFn: async () => {
      if (!!oktaTokens?.refreshToken) {
        return await oktaAuth.token.renewTokensWithRefresh(
          TOKEN_PARAMS,
          oktaTokens.refreshToken
        )
      }
    },
    mutationKey: [OKTA_SESSION_REFRESH],
  })

  return mutationReturns
}
