import { useEffect } from 'react'
import useInvestmentCategoryTabs from '@/hooks/useInvestmentCategoryTabs'
import useFeeds from '@/hooks/useFeeds'
import { InvestmentCategoryEnum } from '@/enums/InvestmentCategoryEnum'
import useFundSelection from '@/Routes/pages/FundList/hooks/useFundSelection'
import useInitInvestmentData from '../useInitInvestmentData'

export const useInvestmentPageContent = () => {
  const { portfolioData, fundList, isLoading, isError } =
    useInitInvestmentData()

  const fundSelectionUtils = useFundSelection()

  const investmentCategories = [
    InvestmentCategoryEnum.KWSP_I_INVEST,
    InvestmentCategoryEnum.CASH,
  ]

  const useTabsReturns = useInvestmentCategoryTabs(investmentCategories)
  useEffect(() => {
    useTabsReturns.handleSetValue(
      investmentCategories.indexOf(InvestmentCategoryEnum.CASH)
    )
  }, [])

  const useFeedsReturns = useFeeds({
    portfolioData,
    fundList,
    tabType: useTabsReturns.tabType,
  })

  return {
    isLoading,
    isError,
    feeds: useFeedsReturns,
    tabs: useTabsReturns,
    portfolioData,
    fundSelectionUtils,
  }
}

export default useInvestmentPageContent
