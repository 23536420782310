import { createSlice } from '@reduxjs/toolkit'
import { PayloadAction } from '@reduxjs/toolkit'

export interface userStatusModalSessionState {
  isIsafModalOpen: boolean
  isEcddModalOpen: boolean
  onEcddModalClose?: () => void
  onIsafModalClose?: () => void
}

const initialState: userStatusModalSessionState = {
  isIsafModalOpen: false,
  isEcddModalOpen: false,
  onEcddModalClose: undefined,
  onIsafModalClose: undefined
}

export const userStatusModalSessionSlice = createSlice({
  name: 'userStatusModal',
  initialState,
  reducers: {
    sessionResetUserStatusModal: (state) => {
      state.isIsafModalOpen = initialState.isIsafModalOpen
      state.isEcddModalOpen = initialState.isEcddModalOpen
      state.onEcddModalClose = initialState.onEcddModalClose
      state.onIsafModalClose = initialState.onIsafModalClose
    },

    sessionOpenIsafModal: (
      state,
      action: PayloadAction<
        Pick<userStatusModalSessionState, 'onIsafModalClose'>
      >
    ) => {
      state.isIsafModalOpen = true
      state.isEcddModalOpen = false
      state.onIsafModalClose = action.payload.onIsafModalClose
    },

    sessionOpenEcddModal: (
      state,
      action: PayloadAction<
      Pick<userStatusModalSessionState, 'onEcddModalClose'>
    >
    ) => {
      state.isIsafModalOpen = false
      state.isEcddModalOpen = true
      state.onEcddModalClose = action.payload.onEcddModalClose
    },
  },
})

//action export
export const {
  sessionResetUserStatusModal,
  sessionOpenIsafModal,
  sessionOpenEcddModal,
} = userStatusModalSessionSlice.actions

export default userStatusModalSessionSlice.reducer
