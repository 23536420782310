import {
  OnboardingApi,
  SignupVerifyIdentityReqDto,
  // SignupVerifyIdentityResDto,
  SignupSelectRecipientReqDto,
  SignupCheckUsernameAvailabilityReqDto,
  SignupCreateAccountReqDto,
  OnboardingProfileQueryResDto,
  // OtpNotificationTypeEnum,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import {
  SIGN_UP_USERNAME,
  SIGN_UP_EMAIL,
  SIGN_UP_SETUP_USER,
  SIGN_UP_SETUP_PASSWORD,
  GET_PROFILE_DATA,
  INIT_EKYC,
} from '@/constants/apiKeys'
import { SIGN_UP as RECAPTCHA_SIGN_UP } from '@/constants/RecaptchaActions'
import i18n from '@/i18n'
import useRecaptcha from '@/hooks/useRecaptcha'
import { selectSessionToken } from '@/stores/auth.selectors'
import { selectEpfToken } from '@/stores/epfToken.selectors'
import { apiLangCode } from '@/utils'
import { BaseApiConfig, apiClient } from './api-config'
import { CustomerProfileTypeEnum } from './customerApi'

const onboardingApiClient = new OnboardingApi(BaseApiConfig, apiClient)

export interface UseMutationSignUpId
  extends Omit<SignupVerifyIdentityReqDto, 'langCode'> {}
export const useMutationSignUpId = () => {
  const { handleExecute } = useRecaptcha()
  const epfToken = useSelector(selectEpfToken)
  const langCode = apiLangCode(i18n.language)

  return useMutation({
    mutationFn: async ({ ...data }: UseMutationSignUpId) => {
      const recaptchaToken = await handleExecute(RECAPTCHA_SIGN_UP)
      console.log(recaptchaToken)

      const res = await onboardingApiClient.verifyIdentity({
        xEpfToken: epfToken || undefined,
        xRecaptchaAction: RECAPTCHA_SIGN_UP,
        xRecaptchaToken: recaptchaToken,
        signupVerifyIdentityReqDto: {
          ...data,
          langCode,
        },
      })

      return res.data
    },
    mutationKey: [SIGN_UP_USERNAME, langCode],
  })
}

export interface UseMutationSignUpEmail
  extends Pick<SignupSelectRecipientReqDto, 'email'> {}
export const useMutationSignUpEmail = () => {
  const sessionToken = useSelector(selectSessionToken)
  const langCode = apiLangCode(i18n.language)

  return useMutation({
    mutationFn: async ({ ...data }: UseMutationSignUpEmail) => {
      const res = await onboardingApiClient.selectRecipient({
        xSessionToken: sessionToken as string,
        signupSelectRecipientReqDto: {
          ...data,
          langCode,
        },
      })
      return res.data
    },
    mutationKey: [SIGN_UP_EMAIL, langCode],
  })
}

export interface UseMutationSetUpUsername
  extends SignupCheckUsernameAvailabilityReqDto {}
export const useMutationSetUpUsername = () => {
  const sessionToken = useSelector(selectSessionToken)

  return useMutation({
    mutationFn: async ({ ...data }: UseMutationSetUpUsername) => {
      const res = await onboardingApiClient.checkUsernameAvailability({
        xSessionToken: sessionToken as string,
        signupCheckUsernameAvailabilityReqDto: data,
      })
      return res.data
    },
    mutationKey: [SIGN_UP_SETUP_USER],
  })
}

export interface UseMutationSetUpPassword extends SignupCreateAccountReqDto {}
export const useMutationSetUpPassword = () => {
  const sessionToken = useSelector(selectSessionToken)

  return useMutation({
    mutationFn: async ({ ...data }: UseMutationSetUpPassword) => {
      const res = await onboardingApiClient.createAccount({
        xSessionToken: sessionToken as string,
        signupCreateAccountReqDto: { ...data },
      })
      return res.data
    },
    mutationKey: [SIGN_UP_SETUP_PASSWORD],
  })
}

export interface UseQueryGetPofileData {
  type: CustomerProfileTypeEnum
  key?: string
}
export interface UseQueryGetPofileDataRes
  extends Partial<OnboardingProfileQueryResDto> {}
export const useQueryGetPofileData = (props?: UseQueryGetPofileData) => {
  return useQuery({
    queryFn: async () => {
      if (!props) return {} as UseQueryGetPofileDataRes

      const { type, key } = props
      switch (type) {
        case CustomerProfileTypeEnum.EKYC: {
          const res = await onboardingApiClient.getDataFromEkyc({
            xEkycRefno: key as string,
          })

          return {
            fullName: res.data.fullName,
            email: res.data.email,
            identities: res.data.identities,
            mobilePhoneNo: res.data.mobilePhoneNo,
            primaryContact: res.data.primaryContact,
            gender: res.data.gender,
            nationality: res.data.nationality,
          } as UseQueryGetPofileDataRes
        }
        case CustomerProfileTypeEnum.EPF: {
          const res = await onboardingApiClient.getDataFromEPF({
            xEpfToken: key as string,
          })

          return {
            fullName: res.data.fullName,
            email: res.data.email,
            identities: res.data.identities,
            mobilePhoneNo: res.data.mobilePhoneNo,
            primaryContact: res.data.primaryContact,
            gender: res.data.gender,
            nationality: res.data.nationality,
            epfDetails: res.data.epfDetails,
          } as UseQueryGetPofileDataRes
        }
        case CustomerProfileTypeEnum.EXISTING: {
          const res = await onboardingApiClient.getDataFromExistingCustomer()
          return res.data as UseQueryGetPofileDataRes
        }
        default:
          break
      }
    },
    queryKey: [GET_PROFILE_DATA, props],
    enabled: !!props,
  })
}

export const useMutationGetEkycToken = () => {
  return useMutation({
    mutationFn: async () => {
      const res = await onboardingApiClient.initEkycRequest()
      return res.data
    },
    mutationKey: [INIT_EKYC],
  })
}
