import useSwitchLang from '@/hooks/useSwitchLang'
import { NO_REFERRER } from '@/constants'
import { validateExternalURLs } from '@/utils'

export const useGoExternalEpf = () => {
  const { langCode } = useSwitchLang()

  return () =>
    window.open(
      validateExternalURLs(
        process.env.REACT_APP_EPF_PORTAL?.replace('[LANG]', langCode) as string
      ),
      '_blank',
      NO_REFERRER
    )
}
export default useGoExternalEpf
