import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.scss'
import App from './App'
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles'
import { muiTheme } from '@pimy-b2cweb/frontend-lib'
import './i18n'

/*** this code snippet is to enable service

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
serviceWorkerRegistration.register()

 ***/

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const container = document.getElementById('app')
const theme = createTheme({ ...muiTheme(container) })

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </BrowserRouter>
  </React.StrictMode>
)
