import {
  ActionModal,
  ActionModalProps,
  CSLink,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

export interface TechnicalErrorModalProps
  extends Omit<
    ActionModalProps,
    'title' | 'actions' | 'actionsStyle' | 'closeBtn' | 'onClose' | 'children'
  > {
  modalClose: () => void
  onContinue: () => void
}

const TechnicalErrorModal = ({
  modalClose,
  onContinue,
  ...rest
}: TechnicalErrorModalProps) => {
  const { t } = useTranslation('common')

  const [triggerNavOnClose, setTriggerNavOnClose] = useState(false)
  useEffect(() => {
    if (!!triggerNavOnClose && !rest.open) onContinue()
  }, [rest.open, triggerNavOnClose])

  return (
    <ActionModal
      {...rest}
      title={t('something-went-wrong')}
      actions={[
        {
          label: t('Continue'),
          onClick: () => {
            setTriggerNavOnClose(true)
            modalClose()
          },
          variant: 'contained',
        },
      ]}
      actionsStyle='full'
      closeBtn={true}
      onClose={modalClose}
    >
      <Trans
        i18nKey={'facing-technical-issue-contact-customer-care'}
        t={t}
        components={{ P: <p />, CSLink: <CSLink /> }}
      />
    </ActionModal>
  )
}

export default TechnicalErrorModal
