import { DD_MM_YYYY } from '@/constants'
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers'
import { FormErrorMessage } from '@pimy-b2cweb/frontend-lib'
import { Dayjs } from 'dayjs'
import { forwardRef, memo } from 'react'
import { FieldError } from 'react-hook-form'

export interface DatePickerCompProps extends DatePickerProps<Dayjs> {
  error?: FieldError
}

export const DatePickerComp = memo(
  forwardRef<HTMLDivElement, DatePickerCompProps>(
    ({ className = '', format = DD_MM_YYYY, error, ...rest }, ref) => {
      return (
        <div className='flex flex-col'>
          <DatePicker
            ref={ref}
            format={format}
            className={`pt-1 [&>label]:!font-normal [&>label]:!text-pi-gray-3 [&>label]:translate-x-[14px] [&>label]:translate-y-[16px] [&>label.Mui-focused]:!-translate-y-[7px] [&>label.Mui-focused]:!scale-75 [&>label.MuiFormLabel-filled]:!-translate-y-[7px] [&>label.MuiFormLabel-filled]:!scale-75 [&>div>div>button>svg]:text-pi-principal-blue ${className}`}
            timezone='UTC'
            {...rest}
          />
          <FormErrorMessage error={error} />
        </div>
      )
    }
  )
)

export default DatePickerComp
