import { PropsWithChildren } from 'react'

const InfoContainer = ({
  className = '',
  children,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <div
      className={`bg-white rounded p-4 border border-solid border-pi-gray-4 ${className}`}
    >
      <div className='flex flex-col gap-4'>{children}</div>
    </div>
  )
}

export default InfoContainer
