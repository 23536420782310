import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  FormGroupProps,
} from '@mui/material'
import { FormErrorMessage } from '@pimy-b2cweb/frontend-lib'
import { forwardRef, memo } from 'react'
import { FieldError } from 'react-hook-form'

type Value = string | number
export interface CheckboxGroupProps extends FormGroupProps {
  error?: FieldError
  className?: string
  value?: Value[]
  items: {
    label: string | JSX.Element
    value: Value
    key: string
    disabled?: boolean
  }[]
  onChange: (e: unknown) => void
}
export const CheckboxGroup = memo(
  forwardRef<HTMLDivElement, CheckboxGroupProps>(
    (
      { items, className = '', error, value: fieldVal = [], onChange, ...rest },
      ref
    ) => {
      const onChangeItem = (itemVal: Value) => {
        if (fieldVal.includes(itemVal)) {
          onChange(fieldVal.filter((item) => item !== itemVal))
          return
        }
        onChange([...fieldVal, itemVal])
      }

      return (
        <div className={`checkbox-group ${className}`}>
          <FormGroup {...rest} ref={ref} className='gap-2'>
            {items.map(({ label, value, key, disabled = false }) => {
              return (
                <FormControlLabel
                  key={key}
                  control={<Checkbox className='self-start -mt-2 mr-[7px]' />}
                  label={label}
                  value={value}
                  checked={fieldVal.includes(value)}
                  disabled={disabled}
                  onChange={() => onChangeItem(value)}
                  labelPlacement='start'
                  className='justify-between items-center m-0 px-4 py-3 rounded border border-solid border-pi-gray-4 [&>span]:p-0 [&>span]:m-0'
                />
              )
            })}
          </FormGroup>
          <FormErrorMessage error={error} />
        </div>
      )
    }
  )
)

export default CheckboxGroup
