import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useMutationSessionRefresh } from '@/api/oktaAuth'
import useNotifications from '@/hooks/useNotifications/'
import { sessionRenewAuthToken } from '@/stores/auth'
import { getErrorResponseCode } from '@/utils'

const useExtToken = ({
  onError,
  onSuccess,
  useNetworkErrorNotice = true,
}: {
  onError?: () => void
  onSuccess?: () => void
  useNetworkErrorNotice?: boolean
}) => {
  const { addNetworkErrorNotice } = useNotifications()
  const dispatch = useDispatch()

  const {
    mutate: onExtSession,
    status: mutateStatus,
    isLoading: mutateIsLoading,
    data: mutatedData,
    error: mutatedError,
  } = useMutationSessionRefresh()

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      console.error(errMsg)
      useNetworkErrorNotice && addNetworkErrorNotice()
      !!onError && onError()
      return
    }

    if (mutateStatus === 'success' && !!mutatedData) {
      dispatch(sessionRenewAuthToken({ oktaTokens: mutatedData }))
      !!onSuccess && onSuccess()
    }
  }, [mutateStatus, mutatedData, mutatedError])

  return {
    onExtSession,
    mutateStatus,
    mutateIsLoading,
    mutatedData,
    mutatedError,
  }
}

export default useExtToken
