import { LanguageCodeEnum as LangEnum } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ProfileFormLovKeysEnum,
  EcddFormLovKeysEnum,
  RedemptionSurveyKeysEnum,
  BankAccountFormKeysEnum,
} from '@/api/commonApi'
import { SelectBoxItem } from '@pimy-b2cweb/frontend-lib'

export interface LovItem extends SelectBoxItem {
  value: Extract<SelectBoxItem['value'], 'string'>
  key: string
}
type LovStateItem =
  | ProfileFormLovKeysEnum
  | EcddFormLovKeysEnum
  | RedemptionSurveyKeysEnum
  | BankAccountFormKeysEnum
export type LovSessionStateLang = {
  [K in LovStateItem]?: LovItem[]
}
export interface LovSessionState {
  [LangEnum.en]: LovSessionStateLang
  [LangEnum.ms]: LovSessionStateLang
}

const initialStateLang = Object.values({
  ...ProfileFormLovKeysEnum,
  ...EcddFormLovKeysEnum,
}).reduce(
  (accu, key) => ({ ...accu, [key]: undefined }),
  {} as LovSessionStateLang
)

const initialState: LovSessionState = {
  [LangEnum.en]: initialStateLang,
  [LangEnum.ms]: initialStateLang,
}

export const lovSessionSlice = createSlice({
  name: 'lovSession',
  initialState,
  reducers: {
    sessionReset: (state) => {
      state[LangEnum.en] = initialStateLang
      state[LangEnum.ms] = initialStateLang
    },

    sessionUpdateLovItem: (
      state,
      action: PayloadAction<{
        langCode: LangEnum
        lovs: Partial<LovSessionStateLang>
      }>
    ) => {
      const { langCode, lovs } = action.payload
      Object.keys(lovs).forEach((key) => {
        const _item = lovs[key as LovStateItem]
        if (!!_item) state[langCode][key as LovStateItem] = _item
      })
    },
  },
})

// action export
export const { sessionReset, sessionUpdateLovItem } = lovSessionSlice.actions

export default lovSessionSlice.reducer
