import HelmetSiteTitle from '@/components/HelmetSiteTitle'
import { MainBody } from '@pimy-b2cweb/frontend-lib'
import { useTranslation } from 'react-i18next'
import RewardItem from './components/RewardItem'
import { useInitRewardListData } from './hooks/useInitRewardListData'
import Loading from '@/components/Loading'
import { RewardInformation } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'

const Inbox = () => {
  const { t } = useTranslation('inboxPage')
  const navigate = useNavigate()
  const { rewardList, isLoading, isError } = useInitRewardListData()

  useEffect(() => {
    if (isError) {
      navigate(RoutePathEnum.ERROR)
    }
  }, [isError])

  return isLoading ? (
    <Loading isLoadingPage={true} />
  ) : (
    <>
      <HelmetSiteTitle pageTitle={t('inbox')} />
      <MainBody>
        <h1 className='col-span-full text-xl font-black mb-0'>{t('inbox')}</h1>

        <h2 className='col-span-full text-base font-bold mb-0'>
          {t('entitled-rewards')}
        </h2>

        <div className='col-span-full grid grid-cols-3 gap-4'>
          <div className='col-span-full flex flex-col gap-6 md:col-span-2'>
            {rewardList?.map((reward: RewardInformation, index: number) => {
              return <RewardItem key={index} reward={reward} />
            })}
          </div>
        </div>
      </MainBody>
    </>
  )
}

export default Inbox
