import { RootState } from './index'

export const selectEpfTokenSessionState = (rootState: RootState) =>
  rootState.epfTokenSession

export const selectEpfToken = (rootState: RootState) =>
  selectEpfTokenSessionState(rootState).token

export const checkEpfTokenExist = (rootState: RootState) =>
  !!selectEpfTokenSessionState(rootState).token

export const selectEpfDecoded = (rootState: RootState) =>
  selectEpfTokenSessionState(rootState).decoded

export const selectEpfExpAt = (rootState: RootState) =>
  selectEpfTokenSessionState(rootState).expAt
