import { IconButton } from '@mui/material'
import { useRef, PropsWithChildren, useState, useEffect } from 'react'
import { useSwipeable } from 'react-swipeable'
import { ReactComponent as Arrow } from './assets/arrow.svg'
import useBreakpoint from '@/hooks/useBreakPoint'

const BTN_CLASS = 'hidden xl:!block absolute top-[calc(50%-32px)]'

export const Slider = ({
  children,
  className = '',
  useDesktopSlide = true,
  useScrollAutoAdjust = true,
}: PropsWithChildren<{
  className?: string
  useDesktopSlide?: boolean
  useScrollAutoAdjust?: boolean
}>) => {
  const { isMdAbove } = useBreakpoint()

  const sliderRef = useRef<HTMLElement>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const refPassthrough = (el: any) => {
    handlers.ref(el)
    sliderRef.current = el
  }

  const [scrollStatus, setScrollStatus] = useState<
    'scrollTop' | 'scrollMiddle' | 'scrollBottom'
  >('scrollTop')

  /**
   * if not using useDesktopSlide mode and screen sized to mdScreenAbove: reset scroll left to 'scrollTop'
   */
  useEffect(() => {
    if (!useDesktopSlide && !!isMdAbove) {
      !!sliderRef.current?.scrollTo && sliderRef.current.scrollTo({ top: 0 })
      setScrollStatus('scrollTop')
    }
  }, [isMdAbove, useDesktopSlide])

  const handleSlide = (dir: 'next' | 'prev') => {
    if (isMdAbove && !useDesktopSlide) return

    if (
      !sliderRef.current ||
      (!sliderRef.current?.scrollLeft && sliderRef.current?.scrollLeft !== 0)
    )
      return

    const {
      scrollLeft: _currLeft,
      scrollWidth,
      clientWidth,
    } = sliderRef.current

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const _cardW = sliderRef.current.firstChild.offsetWidth || 0
    const _gap = parseInt(
      window.getComputedStyle(sliderRef.current).getPropertyValue('row-gap'),
      10
    )
    const _step = _cardW + _gap
    /**
     * _autoAdjust:
     *    if only 1/2 of the card left to scroll to scrollTop , auto go to scrollTop;
     *    if only 1/2 of the card left to scroll to scrollBottom , auto go to scrollBottom
     */
    const _autoAdjust = !!useScrollAutoAdjust ? _step / 2 : 0

    let left = _currLeft + (dir === 'prev' ? 0 - _step : _step)
    if (left <= _autoAdjust) {
      left = 0
      setScrollStatus('scrollTop')
    } else if (left + clientWidth >= scrollWidth - _autoAdjust) {
      left = scrollWidth - clientWidth
      setScrollStatus('scrollBottom')
    } else {
      setScrollStatus('scrollMiddle')
    }

    sliderRef.current.scrollTo({ left, behavior: 'smooth' })
  }

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSlide('next'),
    onSwipedRight: () => handleSlide('prev'),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  })

  return (
    <div
      className={`slider col-span-full flex flex-row flex-nowrap items-stretch relative ${className}`}
    >
      <div
        {...handlers}
        ref={refPassthrough}
        className={`col-span-full overflow-x-hidden w-full flex flex-row flex-nowrap items-stretch gap-2 md:!gap-4 ${
          !useDesktopSlide ? 'md:!overflow-auto md:!grid md:!grid-cols-2' : ''
        }`}
      >
        {children}
      </div>
      {!!useDesktopSlide && (
        <>
          <IconButton
            className={`${BTN_CLASS} -left-10 w-20 h-20 ${
              scrollStatus === 'scrollTop' ? 'opacity-30' : ''
            }`}
            disabled={scrollStatus === 'scrollTop'}
            onClick={() => handleSlide('prev')}
          >
            <Arrow className='w-[64px] h-[64px] transform rotate-180' />
          </IconButton>
          <IconButton
            className={`${BTN_CLASS} -right-10 w-20 h-20 ${
              scrollStatus === 'scrollBottom' ? 'opacity-30' : ''
            }`}
            disabled={scrollStatus === 'scrollBottom'}
            onClick={() => handleSlide('next')}
          >
            <Arrow className='w-[64px] h-[64px]' />
          </IconButton>
        </>
      )}
    </div>
  )
}
export default Slider
