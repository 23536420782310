import { useSelector } from 'react-redux'
import HelmetSiteTitle from '@/components/HelmetSiteTitle'
import { UnauthedStatusEnum } from '@/config'
import { selectAuthStatus } from '@/stores/auth.selectors'
import UserId from './subpages/UserId'
import Succeed from './subpages/Succeed'
import { useTranslation } from 'react-i18next'

export const SignUp = () => {
  const { t } = useTranslation('recoverUserIdPage')
  return (
    <>
      <HelmetSiteTitle pageTitle={t('recover-user-id')} />
      <StatusSwitch />
    </>
  )
}

const StatusSwitch = () => {
  const authStatus = useSelector(selectAuthStatus)

  switch (authStatus) {
    case UnauthedStatusEnum.Unknown:
      return <UserId />

    case UnauthedStatusEnum.RecoverUserIdSucceed:
      return <Succeed />

    default:
      return <UserId />
  }
}

export default SignUp
