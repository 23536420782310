import { useState } from 'react'
import CashInAmount from './subpages/CashInAmount'
import InvestmentSummary from './subpages/InvestmentSummary'
import useInvestmentReducer from '@/hooks/useInvestmentReducer'

export const CashIn = () => {
  const [requestToken, setRequestToken] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()

  const { investments, setInvestments, resetInvestments } =
    useInvestmentReducer()

  return !requestToken ? (
    <CashInAmount
      handleSetRequestToken={(requestToken: string) => {
        setRequestToken(requestToken)
      }}
      errorMessage={errorMessage}
      handleErrorMessage={(error?: string) => {
        setErrorMessage(error)
      }}
      setInvestments={setInvestments}
      resetInvestments={resetInvestments}
    />
  ) : (
    <InvestmentSummary
      requestToken={requestToken}
      handleErrorMessage={(error?: string) => {
        setErrorMessage(error)
        setRequestToken(undefined)
      }}
      goToOrderForm={() => setRequestToken(undefined)}
      {...investments}
    />
  )
}

export default CashIn
