import { ReactNode } from 'react'

export interface InfoFieldProps {
  fieldName: ReactNode
  value: ReactNode
}

const InfoField = ({ fieldName, value }: InfoFieldProps) => {
  return (
    <div className='flex flex-col gap-0.5'>
      <div className='text-xs font-bold'>{fieldName}</div>
      <div className='text-base font-normal'>{value}</div>
    </div>
  )
}

export default InfoField
