import { Tabs, TabsProps, Tab } from '@mui/material'

export interface ButtonTabGroupProps extends TabsProps {
  tabList: {
    key: string
    label: string
  }[]
  onItemClick: (key: string) => void
  handleSetValue: (newValue: number) => void
  disabled?: boolean
}
export const ButtonTabGroup = ({
  tabList,
  onItemClick,
  handleSetValue,
  disabled = false,
  ...rest
}: ButtonTabGroupProps) => (
  <ButtonTabs onChange={(_, newValue) => handleSetValue(newValue)} {...rest}>
    {tabList.map(({ key, label }) => (
      <ButtonTab
        label={label}
        key={key}
        onClick={() => onItemClick(key)}
        disabled={disabled}
      />
    ))}
  </ButtonTabs>
)

const ButtonTabs = ({ className, ...rest }: TabsProps) => (
  <Tabs
    className={`min-h-min [&>.MuiTabs-scroller>.MuiTabs-indicator]:hidden ${className}`}
    {...rest}
  />
)

const ButtonTab = (props: {
  label: string
  onClick: () => void
  disabled?: boolean
}) => (
  <Tab
    disableRipple
    {...props}
    className={
      '!min-w-0 mr-4 py-2 px-4 min-h-min rounded-full border border-solid border-pi-gray-3 ' +
      'normal-case font-bold text-sm text-pi-gray-1 ' +
      'hover:bg-pi-gray-5 ' +
      '[&.Mui-selected]:bg-pi-sky-blue-1 [&.Mui-selected]:text-pi-principal-blue [&.Mui-selected]:border-pi-principal-blue ' +
      '[&.Mui-focusVisible]:bg-pi-sky-blue-4'
    }
  />
)

export default ButtonTabGroup
