import { useTheme, useMediaQuery } from '@mui/material'

export const useBreakpoint = () => {
  const { breakpoints } = useTheme()
  const isMdAbove = useMediaQuery(breakpoints.up('md'))

  return { breakpoints, isMdAbove }
}

export default useBreakpoint
