import { ReactComponent as CloseIco } from '@/assets/close-ico.svg'
import FundAmountInput from '@/components/FundAmountInput'
import TwoSideText from '@/components/TwoSideText'
import { Divider, IconButton } from '@mui/material'
import { OrderFundResDto } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import {
  DEF_DECIMAL,
  FormErrorMessage,
  FormLabel,
  formattedAmount,
} from '@pimy-b2cweb/frontend-lib'
import { forwardRef, memo, useEffect, useMemo, useState } from 'react'
import { ControllerRenderProps, FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FundInput from '../FundInput'

export interface SwitchingOrdersProps {
  error?: FieldError
  value: SwitchingOrder[]
  disabled?: boolean
  disableAdjustAmount?: boolean
  onChange: ControllerRenderProps['onChange']
  className?: string
  onClickSwitchOut: (changeIndex: number) => void
  onClickSwitchIn: (changeIndex: number) => void
}

export enum SwitchingOrdersErrTypeEnum {
  FROM = 'from',
  TO = 'to',
  AMOUNT = 'amount',
}
export interface SwitchingOrdersItemErr {
  code: string
  errType: SwitchingOrdersErrTypeEnum
  message: string
}

export interface SwitchingOrderFund extends OrderFundResDto {
  name: string
}
export interface SwitchingOrder {
  to: SwitchingOrderFund
  from: SwitchingOrderFund
}
export const SwitchingOrders = memo(
  forwardRef<HTMLDivElement, SwitchingOrdersProps>(
    (
      {
        value: orders,
        error,
        disabled,
        disableAdjustAmount = false,
        onChange,
        onClickSwitchOut,
        onClickSwitchIn,
        className = '',
      },
      ref
    ) => {
      const { t } = useTranslation(['switchingPage'])

      const [localOrders, setLocalOrders] = useState<SwitchingOrder[]>([])
      useEffect(() => {
        if (JSON.stringify(orders) !== JSON.stringify(localOrders))
          setLocalOrders(orders)
      }, [orders])
      useEffect(() => {
        const timer = setTimeout(() => {
          if (JSON.stringify(orders) !== JSON.stringify(localOrders))
            onChange(localOrders)
        }, 100)
        return () => clearTimeout(timer)
      }, [localOrders])

      const handleChange = ({ id, value }: { id: string; value: number }) => {
        const _localVal = localOrders.map((item) =>
          item.from.fundCode === id
            ? {
                ...item,
                from: {
                  ...item.from,
                  amount: value || 0,
                },
                to: {
                  ...item.to,
                  amount: value || 0,
                },
              }
            : item
        )
        setLocalOrders(_localVal)
      }

      const handleRemove = (code: string) => {
        if (localOrders.length <= 1 || !!disabled) return
        const _localVal = localOrders.filter(
          (item) => item.from.fundCode !== code
        )
        setLocalOrders(_localVal)
      }

      const errMessages = useMemo(
        () =>
          (!!error &&
            !!error.message &&
            (JSON.parse(error.message) as SwitchingOrdersItemErr[])) ||
          undefined,
        [error]
      )

      return (
        <div ref={ref} className={`flex flex-col gap-4 ${className}`}>
          {localOrders.map(
            (
              {
                from: {
                  name: switchOutFundName,
                  fundCode: switchOutFundCode,
                  availableAmount,
                },
                to: { name: switchInFundName, amount },
              },
              index
            ) => {
              const _disabled = localOrders.length <= 1 || !!disabled
              const amountErrMsg =
                (!!errMessages &&
                  errMessages.find(
                    (item) =>
                      item.code === switchOutFundCode &&
                      item.errType === SwitchingOrdersErrTypeEnum.AMOUNT
                  )) ||
                undefined
              const toErrMsg =
                (!!errMessages &&
                  errMessages.find(
                    (item) =>
                      item.code === switchOutFundCode &&
                      item.errType === SwitchingOrdersErrTypeEnum.TO
                  )) ||
                undefined
              const showSeparator = index !== localOrders.length - 1

              return (
                <div key={switchOutFundCode}>
                  <FormLabel
                    id='from'
                    className='[&>label]:overflow-visible'
                    label={
                      <TwoSideText
                        className='!gap-4 [&>:first-child]:truncate'
                        left={t('from')}
                        right={
                          <IconButton
                            onClick={() => handleRemove(switchOutFundCode)}
                            disabled={_disabled}
                            className={`-m-2 ${
                              _disabled ? '' : 'cusor-pointer'
                            }`}
                          >
                            <CloseIco
                              className={
                                _disabled ? '[&>path]:stroke-pi-gray-3' : ''
                              }
                            />
                          </IconButton>
                        }
                      />
                    }
                  >
                    <FundInput
                      value={switchOutFundName}
                      fullWidth
                      onClick={() => {
                        onClickSwitchOut(index)
                      }}
                      disabled={disabled}
                    />
                  </FormLabel>

                  <FormLabel
                    id='to'
                    label={t('to')}
                    className='mb-0'
                    isError={!!toErrMsg}
                  >
                    <FundInput
                      value={switchInFundName}
                      fullWidth
                      onClick={() => {
                        onClickSwitchIn(index)
                      }}
                      disabled={disabled}
                      error={!!toErrMsg}
                    />
                    <FormErrorMessage
                      error={
                        !!error && !!toErrMsg
                          ? { type: error.type, message: toErrMsg.message }
                          : undefined
                      }
                    />
                  </FormLabel>

                  <Divider className='my-6' />

                  <FormLabel
                    id={switchOutFundCode}
                    className='[&>label]:overflow-visible !mb-2'
                    label={t('enter-amount')}
                    isError={!!amountErrMsg}
                  >
                    <FundAmountInput
                      id={switchOutFundCode}
                      fullWidth
                      disabled={disabled || disableAdjustAmount}
                      value={amount}
                      onChange={(event) =>
                        handleChange({
                          id: switchOutFundCode,
                          value: Number(event.target.value),
                        })
                      }
                      className='mb-2'
                      error={!!amountErrMsg}
                      inputProps={{ maxLength: 15 }}
                      setMaxAmount={
                        !!availableAmount
                          ? () =>
                              handleChange({
                                id: switchOutFundCode,
                                value: Number(
                                  availableAmount.toFixed(DEF_DECIMAL)
                                ),
                              })
                          : undefined
                      }
                    />
                    <FormErrorMessage
                      error={
                        !!error && !!amountErrMsg
                          ? { type: error.type, message: amountErrMsg.message }
                          : undefined
                      }
                    />
                  </FormLabel>

                  <p className='mb-0 font-bold'>
                    {t('switchable-amount')}
                    <span className='text-pi-principal-blue'>
                      {formattedAmount({ amount: availableAmount })}
                    </span>
                  </p>

                  {showSeparator && <Divider className='my-6 -mx-4 border' />}
                </div>
              )
            }
          )}
        </div>
      )
    }
  )
)
export default SwitchingOrders
