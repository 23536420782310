import CheckboxGroup from '@/components/CheckboxGroup'
import DatePickerComp from '@/components/DatePickerComp'
import ResponseContainer from '@/components/ResponseContainer'
import {
  ButtonWithLoading,
  FormLabel,
  FullScreenModal,
  FullScreenModalProps,
  ResponseError,
  ResponseErrorProps,
  ScrollArea,
} from '@pimy-b2cweb/frontend-lib'
import dayjs from 'dayjs'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TransactionFilterFormProps } from '../hooks/useTransactionFilterForm'
import { AssetGroupEnum, OrderTypeEnum } from '@pimy-b2cweb/apiclient-b2cweb-r2'

export interface TransactionFilterModalProps
  extends Omit<FullScreenModalProps, 'children'> {
  onSubmitForm: (data: TransactionFilterFormProps) => void
  isSubmitting?: boolean
  responseErrorAttrs?: ResponseErrorProps
}

export const ASSET_TYPES = [
  {
    label: 'kwsp-i-invest',
    value: AssetGroupEnum.EPF,
  },
  {
    label: 'cash',
    value: AssetGroupEnum.CASH,
  },
]

export const TRANSACTION_TYPES = [
  {
    label: 'cash-in',
    value: OrderTypeEnum.Buy,
  },
  {
    label: 'cash-out',
    value: OrderTypeEnum.Sell,
  },
  {
    label: 'switching',
    value: OrderTypeEnum.SwitchOut,
  },
  {
    label: 'income-bonus-distribution',
    value: OrderTypeEnum.Dividend,
  },
]

export const TransactionFilterModal = ({
  onSubmitForm,
  isSubmitting = false,
  responseErrorAttrs,
  ...rest
}: TransactionFilterModalProps) => {
  const { t } = useTranslation('transactionHistoryPage')

  const { control, handleSubmit, watch, reset } =
    useFormContext<TransactionFilterFormProps>()

  const watchFromDt = watch('fromDt')
  const watchToDt = watch('toDt')

  return (
    <FullScreenModal {...rest} className='filter-modal'>
      <div className='flex flex-col grow max-h-full'>
        <ResponseContainer className='[&>div]:pb-4'>
          <h3 className='font-bold text-xl mb-0'>
            {t('transaction-history-filter')}
          </h3>
          {!!responseErrorAttrs && (
            <ResponseError className='mb-0 mt-4'>
              {t(responseErrorAttrs.i18nKey, {
                ns: responseErrorAttrs.ns,
              })}
            </ResponseError>
          )}
        </ResponseContainer>
        <ScrollArea className='grow'>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <ResponseContainer>
              <FormLabel id='timePeriod' label={t('date-period')}>
                <div className='flex flex-row items-start justify-between gap-4'>
                  <Controller
                    name='fromDt'
                    control={control}
                    rules={{
                      required: {
                        value: !!watchToDt,
                        message: 'Required',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <DatePickerComp
                        label='From'
                        {...field}
                        maxDate={!!watchToDt ? watchToDt : dayjs()}
                        error={error}
                      />
                    )}
                  />

                  <Controller
                    name='toDt'
                    control={control}
                    rules={{
                      required: {
                        value: !!watchFromDt,
                        message: 'Required',
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <DatePickerComp
                        label='To'
                        {...field}
                        minDate={!!watchFromDt ? watchFromDt : undefined}
                        maxDate={dayjs()}
                        error={error}
                      />
                    )}
                  />
                </div>
              </FormLabel>

              <Controller
                name='assetTypes'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormLabel
                    id='assetType'
                    label={t('asset-type')}
                    isError={!!error}
                  >
                    <CheckboxGroup
                      {...field}
                      error={error}
                      items={ASSET_TYPES.map(({ label, value }) => {
                        return {
                          key: label,
                          label: t(label),
                          value,
                          disabled: false,
                        }
                      })}
                    />
                  </FormLabel>
                )}
              />

              <Controller
                name='transactionTypes'
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormLabel
                    id='transactionType'
                    label={t('transaction-type')}
                    isError={!!error}
                  >
                    <CheckboxGroup
                      {...field}
                      error={error}
                      items={TRANSACTION_TYPES.map(({ label, value }) => {
                        return {
                          key: label,
                          label: t(label),
                          value,
                          disabled: false,
                        }
                      })}
                    />
                  </FormLabel>
                )}
              />
            </ResponseContainer>
            <ResponseContainer className='sticky bottom-0 z-40 pt-10 pb-1 bg-pi-gray-6 [&>div]:pb-0'>
              <ButtonWithLoading
                type='submit'
                fullWidth
                variant='contained'
                size='large'
                disabled={isSubmitting}
                isLoading={isSubmitting}
                className='mb-6'
              >
                {t('apply-filter')}
              </ButtonWithLoading>
              <div
                className='text-base text-pi-principal-blue text-center cursor-pointer'
                onClick={() => reset()}
              >
                {t('reset-filter')}
              </div>
            </ResponseContainer>
          </form>
        </ScrollArea>
      </div>
    </FullScreenModal>
  )
}

export default TransactionFilterModal
