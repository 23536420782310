import { useTranslation } from 'react-i18next'

const NavItem = ({
  label,
  isNew,
  className = '',
}: {
  label: string
  isNew: boolean
  className?: string
}) => {
  const { t } = useTranslation('common')
  return (
    <var className={`not-italic ${className}`}>
      {label}
      {isNew && (
        <sup className='bg-pi-chartreuse text-xs leading-4 text-pi-navy-blue py-0.5 px-1 rounded ml-1'>
          {t('New')}
        </sup>
      )}
    </var>
  )
}

export default NavItem
