import useFeeds from '@/hooks/useFeeds'
import useInitInvestmentData from '@/hooks/useInitInvestmentData'
import useInvestmentCategoryTabs from '@/hooks/useInvestmentCategoryTabs'
import { useRiskProfileStatus } from '@/hooks/useRiskProfileStatus'

export const useInitData = () => {
  const {
    isLoading: isLoadingPage,
    isError: isErrorPage,
    riskProfileName,
    profitLoss,
    totalAssetsAmt,
  } = useRiskProfileStatus()
  const { portfolioData, fundList, isLoading, isError } =
    useInitInvestmentData()

  const useTabsReturns = useInvestmentCategoryTabs()
  const useFeedsReturns = useFeeds({
    portfolioData,
    fundList,
    tabType: useTabsReturns.tabType,
  })

  return {
    isLoading,
    isLoadingPage,
    isError,
    isErrorPage,
    feeds: useFeedsReturns,
    tabs: useTabsReturns,
    riskProfileName,
    profitLoss,
    totalAssetsAmt,
  }
}

export default useInitData
