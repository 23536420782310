import { useMutationEditCustomerProfileEmploymentInfo } from '@/api/customerApi'
import EditEmploymentInfoForm from '@/components/EditEmploymentInfoForm'
import Loading from '@/components/Loading'
import PostLogonContainer from '@/layout/PostLogonContainer'
import PostLogonMainBody from '@/layout/PostLogonMainBody'
import { sessionVerifyOtp } from '@/stores/auth'
import { filterByLov, getErrorResponseCode } from '@/utils'
import { CustomerProfileEditEmploymentInfoReqDto } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { ResponseError, useResponseError } from '@pimy-b2cweb/frontend-lib'
import { useEffect } from 'react'
import { FieldValues, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { EmploymentInfoFormProps, useInitData } from './useInitData'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'
import { BooleanFormValues } from '@/enums/formValueEnum'

const EmploymentInfoForm = ({
  goToVerifyOtp,
}: {
  goToVerifyOtp: () => void
}) => {
  const { t } = useTranslation(['profileInfoPage', 'common'])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const {
    employmentInfoFormMethods,
    lovs,
    lovValidated,
    isLoading,
    profileQueryData,
    profileQueryError,
    profileQueryStatus,
  } = useInitData()

  const { setValue } = employmentInfoFormMethods

  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationEditCustomerProfileEmploymentInfo()

  useEffect(() => {
    if (profileQueryStatus === 'error') {
      console.log('error:', profileQueryError)
      navigate(RoutePathEnum.ERROR)
      return
    }
    if (
      profileQueryStatus === 'success' &&
      !!profileQueryData &&
      lovValidated
    ) {
      const {
        occupation,
        natureOfBusiness,
        yearlyIncomeLevel,
        employerName,
        sourceOfFund,
        investmentObjectives,
        pepFlag,
        crsCountries,
      } = profileQueryData

      if (!!occupation && !!lovs?.occupation) {
        const _occupation = filterByLov(occupation, lovs.occupation)
        setValue('occupation', _occupation?.value || '')
      }
      setValue('natureOfBusiness', natureOfBusiness || '')
      setValue('yearlyIncomeLevel', yearlyIncomeLevel || '')
      setValue('employerName', employerName || '')
      setValue('sourceOfFund', sourceOfFund || '')
      setValue('investmentObjectives', investmentObjectives || '')
      setValue(
        'pepFlag',
        !!pepFlag ? BooleanFormValues.TRUE : BooleanFormValues.FALSE
      )
      const isTROM = !!crsCountries && !!crsCountries[0]
      setValue(
        'taxResidentOtherThanMalaysia',
        isTROM ? BooleanFormValues.TRUE : BooleanFormValues.FALSE
      )
      const _crsCountries = isTROM
        ? [
            {
              country: crsCountries[0].country,
              tin: crsCountries[0].tin,
            },
          ]
        : []
      setValue('crsCountries', _crsCountries)
    }
  }, [profileQueryStatus, profileQueryData, profileQueryError, lovValidated])

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      console.log('mutatedData: ', mutatedData)
      dispatch(sessionVerifyOtp({ ...mutatedData }))
      goToVerifyOtp()
    }
  }, [mutatedData, mutateStatus, mutatedError])

  const onSubmit = (data: FieldValues) => {
    console.log('data: ', data)
    const formData = data as EmploymentInfoFormProps
    const pepFlag = formData.pepFlag === BooleanFormValues.TRUE ? true : false
    const crsCountries =
      !formData.crsCountries ||
      !formData.crsCountries[0]?.tin ||
      !formData.crsCountries[0]?.country
        ? undefined
        : formData.crsCountries
    const mutatingData: CustomerProfileEditEmploymentInfoReqDto = {
      ...formData,
      pepFlag,
      crsCountries,
    }
    console.log('mutatingData: ', mutatingData)
    mutate(mutatingData)
  }

  return isLoading ? (
    <Loading isLoadingPage={true} />
  ) : (
    <PostLogonMainBody useTransBg={false}>
      <PostLogonContainer>
        <h1 className='font-bold'>{t('employment-info')}</h1>

        {!!responseErrorAttrs && (
          <ResponseError>
            {t(responseErrorAttrs.i18nKey, {
              ns: responseErrorAttrs.ns,
            })}
          </ResponseError>
        )}

        <FormProvider {...employmentInfoFormMethods}>
          <EditEmploymentInfoForm
            onSubmitForm={onSubmit}
            isSubmitting={mutateIsLoading}
            lovs={lovs}
            submitButtonLabel={t('update-info')}
          />
        </FormProvider>
      </PostLogonContainer>
    </PostLogonMainBody>
  )
}

export default EmploymentInfoForm
