import { useQueryFundList, useQueryGetRewardList } from '@/api/commonApi'
import { useMemo } from 'react'

export const useInitRewardListData = () => {
  const {
    isLoading: isLoadingRewardListQuery,
    data: rewardList,
    isError: isRewardListQueryError,
  } = useQueryGetRewardList()

  const {
    data: fundList,
    isLoading: isLoadingFundListQuery,
    isError: isFundListQueryError,
  } = useQueryFundList({ enabled: true })

  const isLoading = useMemo(
    () => isLoadingFundListQuery || isLoadingRewardListQuery,
    [isLoadingFundListQuery, isLoadingRewardListQuery]
  )
  const isError = useMemo(
    () => isFundListQueryError || isRewardListQueryError,
    [isFundListQueryError, isRewardListQueryError]
  )

  return {
    isLoading,
    isError,
    rewardList,
    fundList,
  }
}
