import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export interface CopyToClipboardProps {
  text: string
  className?: string
}

const CopyToClipboard = ({ text, className = '' }: CopyToClipboardProps) => {
  const { t } = useTranslation('copyToClipboard')
  const [isCopied, setIsCopied] = useState(false)

  const writeClipboardText = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text)
      setIsCopied(true)
    } catch (error) {
      setIsCopied(false)
      if (error instanceof Error) {
        console.error(error.message)
      }
      console.error(error)
    }
  }

  useEffect(() => {
    let timer: NodeJS.Timeout
    if (isCopied) {
      timer = setTimeout(() => {
        setIsCopied(false)
      }, 1000)
    }

    return () => {
      if (!!timer) {
        clearTimeout(timer)
      }
    }
  }, [isCopied])

  return (
    <div
      onClick={() => writeClipboardText(text)}
      className={`!cursor-pointer select-none text-pi-bright-cyan font-normal text-base ${className}`}
    >
      {t(isCopied ? 'copied' : 'copy')}
    </div>
  )
}

export default CopyToClipboard
