import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { ReactComponent as ArrowUpIco } from './assets/arrow-up.svg'

const ScrollToTop = ({ className = '' }: { className?: string }) => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)
    return () => window.removeEventListener('scroll', toggleVisibility)
  }, [])

  return (
    <div
      className={`sticky bottom-10 text-right z-50 transition-bottom duration-300 ${className}`}
    >
      {isVisible && (
        <Button
          className='rounded-full !bg-pi-principal-blue !bg-none !p-1 !min-w-0 ml-auto'
          variant='contained'
          onClick={scrollToTop}
        >
          <ArrowUpIco className='w-6 h-6' />
        </Button>
      )}
    </div>
  )
}

export default ScrollToTop
