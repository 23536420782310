import { useShowHeaderStatus } from '@/hooks/useShowHeaderStatus'
import useTabs, { UseTabs } from '@/hooks/useTabs'

export interface UseRiskProfileTabs extends UseTabs {
  showHeader: boolean
}
export const useRiskProfileTabs = (): UseRiskProfileTabs => {
  const useTabReturns = useTabs()
  const showHeader = useShowHeaderStatus()

  return {
    showHeader,
    ...useTabReturns,
  }
}

export default useRiskProfileTabs
