import { useResponseError } from '@pimy-b2cweb/frontend-lib'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useMutationVerifyOtp } from '@/api/commonApi'
import { useMutationConfirmCustomerProfileEditRequest } from '@/api/customerApi'
import VerifyOtpUi, { VerifyOtpFormProps } from '@/components/VerifyOtpUi'
import { useResendOtp } from '@/hooks/useResendOtp'
import PostLogonContainer from '@/layout/PostLogonContainer'
import PostLogonMainBody from '@/layout/PostLogonMainBody'
import { sessionSetPendingReloadCI, sessionResetVerifyOtp } from '@/stores/auth'
import { selectAuthSessionState } from '@/stores/auth.selectors'
import { sessionSetPendingCheck } from '@/stores/profileEdit'
import { getErrorResponseCode } from '@/utils'

export const VerifyOtp = () => {
  const { t } = useTranslation(['otpPage', 'profileInfoPage'])
  const dispatch = useDispatch()
  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()
  const { canResend, notificationRecipient, notificationType } = useSelector(
    selectAuthSessionState
  )

  const {
    mutate: mutateVO,
    isLoading: mutateIsLoadingVO,
    status: mutateStatusVO,
    data: mutatedDataVO,
    error: mutatedErrorVO,
  } = useMutationVerifyOtp()

  const {
    mutate: mutatePE,
    isLoading: mutateIsLoadingPE,
    status: mutateStatusPE,
    data: mutatedDataPE,
    error: mutatedErrorPE,
  } = useMutationConfirmCustomerProfileEditRequest()

  const { onResetResendError, ...useResendOtpRest } = useResendOtp({
    initCanResend: canResend !== false,
    handleResetPageError: () => setResponseErrorAttrs(undefined),
  })

  useEffect(() => {
    if (mutateStatusVO === 'error') {
      const errMsg = getErrorResponseCode(mutatedErrorVO)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)

    if (mutateStatusVO === 'success' && !!mutatedDataVO) {
      console.log('success')
      mutatePE({ sessionToken: mutatedDataVO.sessionToken })
    }
  }, [mutateStatusVO, mutatedDataVO, mutatedErrorVO])

  useEffect(() => {
    if (mutateStatusPE === 'error') {
      const errMsg = getErrorResponseCode(mutatedErrorPE)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)

    if (mutateStatusPE === 'success' && !!mutatedDataPE) {
      console.log('success')
      dispatch(sessionResetVerifyOtp())

      /**
       * Post OTP verification success:
       *  1) Set 'sessionSetPendingCheck' to true to enable subsequent customer indicator checks.
       *  2) Set 'sessionSetPendingReloadCI' to true, which prompts a customer indicator re-check via the RequireAuth HOC. This action will unmount the ProfileEdit page.
       *
       * During the customer indicator re-check by the RequireAuth HOC:
       *   If the ECDD status check fails:
       *     Redirect the user to the ECDD page through the RequireAuth HOC.
       *
       *   If the ECDD status check passes:
       *     Remount the ProfileEdit page and return to the Form page for the current category.
       *        Upon mounting the ProfileEdit page, if 'sessionSetPendingReloadCI' is true:
       *          Initiate post-action, such as displaying the ProfileUpdatedModal.
       */
      dispatch(sessionSetPendingCheck())
      dispatch(sessionSetPendingReloadCI({ pendingReloadCI: true }))
    }
  }, [mutateStatusPE, mutatedDataPE, mutatedErrorPE])

  const onSubmit = async (data: VerifyOtpFormProps) => {
    console.log(data)
    setResponseErrorAttrs(undefined)
    onResetResendError()
    mutateVO({
      ...data,
    })
  }

  const isLoading = useMemo(
    () => mutateIsLoadingVO || mutateIsLoadingPE,
    [mutateIsLoadingVO, mutateIsLoadingPE]
  )

  return (
    <PostLogonMainBody useTransBg={false}>
      <PostLogonContainer>
        <VerifyOtpUi
          h1Text={t('update-profile-verification', { ns: 'profileInfoPage' })}
          notificationRecipient={notificationRecipient}
          notificationType={notificationType ?? undefined}
          responseErrorAttrs={responseErrorAttrs}
          onSubmit={onSubmit}
          isLoading={isLoading}
          canResend={canResend}
          {...useResendOtpRest}
          operation='continue'
        />
      </PostLogonContainer>
    </PostLogonMainBody>
  )
}

export default VerifyOtp
