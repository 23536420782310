import { PropsWithChildren } from 'react'

const StickyBottomContainer = ({
  className = '',
  children,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <div className={`bg-pi-gray-6 sticky bottom-0 px-4 py-10 ${className}`}>
      {children}
    </div>
  )
}

export default StickyBottomContainer
