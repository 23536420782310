import {
  AgentInformation,
  CampaignInformation,
  FundInformation,
  ReferralInformation,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useReducer } from 'react'

export interface SelectedFund extends FundInformation {
  amount: number
  maxAmount?: number
}

export interface CampaignCode extends CampaignInformation {}
export interface AgentCode extends AgentInformation {}
export interface ReferralCode extends ReferralInformation {}

export interface InvestSessionState {
  funds: SelectedFund[]
  campaignCode?: CampaignCode
  agentCode?: AgentCode
  referralCode?: ReferralCode
  totalAmount: number
}

enum InvestmentSessionActionType {
  SET = 'set',
  RESET = 'reset',
}
export interface InvestmentSessionAction {
  type: InvestmentSessionActionType
  payload?: InvestSessionState
}

const initialInvestState: InvestSessionState = {
  funds: [],
  campaignCode: undefined,
  agentCode: undefined,
  referralCode: undefined,
  totalAmount: 0,
}

export const useInvestmentReducer = () => {
  const [investments, dispatch] = useReducer(
    investmentReducer,
    initialInvestState
  )

  const setInvestments = (data: InvestSessionState) => {
    dispatch({ type: InvestmentSessionActionType.SET, payload: data })
  }

  const resetInvestments = () => {
    dispatch({ type: InvestmentSessionActionType.RESET })
  }

  return {
    investments,
    dispatch,
    setInvestments,
    resetInvestments,
  }
}

const investmentReducer = (
  state: InvestSessionState,
  action: InvestmentSessionAction
) => {
  switch (action.type) {
    case InvestmentSessionActionType.SET:
      return {
        ...state,
        funds: action.payload?.funds || [],
        campaignCode: action.payload?.campaignCode,
        agentCode: action.payload?.agentCode,
        referralCode: action.payload?.referralCode,
        totalAmount: action.payload?.totalAmount || 0,
      }
    case InvestmentSessionActionType.RESET:
      return {
        ...state,
        ...initialInvestState,
      }
  }
}

export default useInvestmentReducer
