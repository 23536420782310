import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RiskProfileResultResDto } from '@pimy-b2cweb/apiclient-b2cweb-r2'

export interface IsafSessionState {
  result?: RiskProfileResultResDto['riskLevel']
}

const initialState: IsafSessionState = {
  result: undefined,
}

export const isafSessionSlice = createSlice({
  name: 'isafSession',
  initialState,
  reducers: {
    sessionReset: (state) => {
      state.result = initialState.result
    },
    sessionResult: (
      state,
      action: PayloadAction<Pick<IsafSessionState, 'result'>>
    ) => {
      state.result = action.payload.result
    },
  },
})

// action export
export const { sessionReset, sessionResult } = isafSessionSlice.actions

export default isafSessionSlice.reducer
