import { ScreenTypeEnum, useQueryPostalList } from '@/api/commonApi'
import { useQueryCustomerProfile } from '@/api/customerApi'
import useLov from '@/hooks/useLov'
import { useMemo } from 'react'

export const useInitProfileInfoData = () => {
  const { lovsCurrLang: lovs, validated: lovValidated } = useLov({
    screen: ScreenTypeEnum.PROFILE_FORM,
  })

  const {
    isLoading: queryIsLoading,
    status: profileQueryStatus,
    data: profileQueryData,
    error: profileQueryError,
  } = useQueryCustomerProfile()

  const {
    isLoading: isLoadingPostalQuery,
    status: postalQueryStatus,
    data: postalList,
    error: postalQueryError,
  } = useQueryPostalList()

  const isLoading = useMemo(
    () => queryIsLoading || !lovValidated || isLoadingPostalQuery,
    [queryIsLoading, lovValidated, isLoadingPostalQuery]
  )

  return {
    lovs,
    postalList,
    lovValidated,
    isLoading,
    profileQueryError,
    profileQueryData,
    profileQueryStatus,
    postalQueryStatus,
    postalQueryError,
  }
}
