import Slider from '@/components/Slider'
import FundCard from '@/components/FundCard'
import { useTranslation } from 'react-i18next'
import { PortfolioFund } from '@/utils/portfolioFund'

export interface InvestmentsByCategoryProps {
  className?: string
  category: string
  funds: PortfolioFund[]
  onClickFund?: (fund: PortfolioFund) => void
}

const InvestmentsByCategory = ({
  className = '',
  category,
  funds,
  onClickFund,
}: InvestmentsByCategoryProps) => {
  const { t } = useTranslation('dashboardPage')

  return (
    <div className={`mb-6 ${className}`}>
      <div className='text-base font-bold text-pi-gray-1 mb-3 md:mb-4'>
        {t(category)}
      </div>
      <Slider
        useDesktopSlide={false}
        useScrollAutoAdjust={false}
        className='mb-6 md:mb-10'
      >
        {funds.map((fund) => (
          <FundCard
            key={fund.code}
            {...fund}
            className='h-full min-w-[300px] w-[95vw] md:min-w-0 md:w-full'
            onCardClick={() => !!onClickFund && onClickFund(fund)}
          />
        ))}
      </Slider>
    </div>
  )
}

export default InvestmentsByCategory
