import { ActionModal, ActionModalProps } from '@pimy-b2cweb/frontend-lib'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { RoutePathEnum } from '@/constants/routePath'
import { EcddStatusEnum } from '@/enums/ecddEnums'
import { openCSLink } from '@/utils'

export enum EcddIssueTypeEnum {
  Expired = EcddStatusEnum.Expired,
  CashInFailed = 'cash-in-failed',
  SwitchFailed = 'switch-failed',
}
export const EcddModal = ({
  modalClose,
  ecddStatus,
  ...rest
}: Omit<
  ActionModalProps,
  'title' | 'actions' | 'actionsStyle' | 'closeBtn' | 'onClose' | 'children'
> & {
  modalClose: () => void
  ecddStatus?: EcddStatusEnum
}) => {
  const { t } = useTranslation(['ecddModal', 'common'])
  const navigate = useNavigate()
  const [triggerNavOnClose, setTriggerNavOnClose] = useState(false)
  useEffect(() => {
    if (!!triggerNavOnClose && !rest.open) navigate(RoutePathEnum.PROFILE_ECDD)
  }, [rest.open, triggerNavOnClose])

  return !!ecddStatus ? (
    <ActionModal
      {...rest}
      title={t(
        ecddStatus === EcddStatusEnum.Expired
          ? 'ecdd-expired-title'
          : ecddStatus === EcddStatusEnum.Rejected
          ? 'ecdd-rejected-title'
          : 'ecdd-pending-title'
      )}
      actions={[
        ecddStatus === EcddStatusEnum.Expired
          ? {
              label: t('link-to-ecdd'),
              onClick: () => {
                setTriggerNavOnClose(true)
                modalClose()
              },
              variant: 'contained',
            }
          : {
              label: t('chat-with-us-on-wp', { ns: 'common' }),
              onClick: openCSLink,
              variant: 'contained',
            },
      ]}
      actionsStyle='full'
      closeBtn={true}
      onClose={modalClose}
    >
      <Trans
        i18nKey={
          ecddStatus === EcddStatusEnum.Expired
            ? 'ecdd-expired-text'
            : ecddStatus === EcddStatusEnum.Rejected
            ? 'ecdd-rejected-text'
            : 'ecdd-pending-text'
        }
        t={t}
        components={{ P: <p /> }}
      />
    </ActionModal>
  ) : null
}

export default EcddModal
