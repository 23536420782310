import { IconButton, IconButtonProps } from '@mui/material'
import { ReactComponent as IcoArrowLeft } from './assets/arrow-left.svg'

export interface NavBackBtnProps extends Omit<IconButtonProps, 'children'> {
  className?: string
}

export const NavBackBtn = ({ className, ...rest }: NavBackBtnProps) => (
  <IconButton
    {...rest}
    className={`nav-back-btn -ml-3 mr-2 transform translate-y-[-1.5px] ${className}`}
  >
    <IcoArrowLeft />
  </IconButton>
)

export default NavBackBtn
