import { TextField } from '@mui/material'
import { validateUsername } from '@pimy-b2cweb/common'
import {
  ButtonWithLoading,
  FormLabel,
  ResponseError,
  useResponseError,
  CSLink,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {
  useMutationSetUpUsername,
  UseMutationSetUpUsername,
} from '@/api/onboardingApi'
import { INVALID_USERID, USERID_NOT_AVAILABLE } from '@/constants/errorCodes'
import { sessionSignUpPendingPassword } from '@/stores/auth'
import { getErrorResponseCode } from '@/utils'

interface UsernameFormProps extends UseMutationSetUpUsername {}
export const Username = () => {
  const { t } = useTranslation(['signUpPage', 'common', 'errorResponse'])

  const { control, handleSubmit, getValues } = useForm<UsernameFormProps>({
    mode: 'onChange',
  })

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const dispatch = useDispatch()

  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationSetUpUsername()

  const onSubmit = async (data: UsernameFormProps) => {
    console.log(data)
    mutate({
      ...data,
    })
  }

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      console.log('mutatedData: ', mutatedData)
      if (!mutatedData.available) {
        setResponseErrorAttrs({
          i18nKey: USERID_NOT_AVAILABLE,
          ns: 'errorResponse',
        })
        return
      }
      dispatch(sessionSignUpPendingPassword(getValues()))
    }
  }, [mutatedData, mutateStatus, mutatedError])

  return (
    <>
      <h1>{t('sign-up')}</h1>
      <p>{t('username-page-text')}</p>
      {!!responseErrorAttrs && (
        <ResponseError>
          <Trans
            {...responseErrorAttrs}
            t={t}
            components={{ CS: <CSLink /> }}
          />
        </ResponseError>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='username'
          control={control}
          defaultValue=''
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
            validate: (val: string) => {
              const issues = validateUsername(val)
              return issues.length === 0 || t(INVALID_USERID)
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='username'
              label={t('User ID', { ns: 'common' })}
              isError={!!error}
              className='!mb-10'
            >
              <TextField
                type='text'
                fullWidth
                {...field}
                error={!!error}
                // helperText={!!error ? (error?.message as string) : ''}
                helperText={t('username-helper-text')}
                className='[&>.MuiFormHelperText-root]:!text-xs'
                disabled={mutateIsLoading}
                autoComplete='off'
              />
            </FormLabel>
          )}
        />
        <ButtonWithLoading
          type='submit'
          fullWidth
          variant='contained'
          size='large'
          disabled={mutateIsLoading}
          isLoading={mutateIsLoading}
        >
          {t('Continue', { ns: 'common' })}
        </ButtonWithLoading>
      </form>
    </>
  )
}

export default Username
