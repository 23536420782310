import {
  CustomerOnboardSubmitResDto,
  CustomerAMLStatusEnum,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { UseMutationResult } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  CustomerProfileTypeEnum,
  UseMutationCustomerProfile,
  useMutationCustomerIndicator,
} from '@/api/customerApi'
import { AuthedStatusEnum } from '@/config'
import { UNKNOWN_ERROR } from '@/constants/errorCodes'
import { RoutePathEnum } from '@/constants/routePath'
import useExtToken from '@/hooks/useExtToken'
import { sessionAuthedStatus, sessionReferralCode } from '@/stores/auth'
import {
  ProfileSetupSwitchEnum,
  profileSetupSwitchLogic,
} from '../../../utils/profileSetupSwitchLogic'
import { EpfActionEnum } from '@/stores/epfToken'

export const usePostSubmitSwitch = (
  props: UseMutationResult<
    CustomerOnboardSubmitResDto,
    unknown,
    UseMutationCustomerProfile,
    unknown
  > & {
    type?: CustomerProfileTypeEnum
    epfAction?: EpfActionEnum
    onError: (error: unknown) => void
    onSuccess: () => void
  }
) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  //keeping loading state in between the 3 Api call --
  const [isApiLoading, setIsApiLoading] = useState(false)
  useEffect(() => {
    if (props.isLoading) setIsApiLoading(true)
  }, [props.isLoading])

  const handleError = (error: unknown) => {
    props.onError(error)
    setIsApiLoading(false)
  }

  const mutationExtToken = useExtToken({
    onError: () => handleError({ i18nKey: UNKNOWN_ERROR }),
    onSuccess: () => handleSwitch({ ...props.data }),
    useNetworkErrorNotice: false,
  })
  const mutationCI = useMutationCustomerIndicator()

  useEffect(() => {
    if (props.status === 'error') {
      console.log('error:', props.error)
      handleError(props.error)
      return
    }

    props.onSuccess()
    if (props.status === 'success' && !!props.data) {
      console.log('mutationCP.data: ', props.data)

      if (!!props.data.cifid) {
        mutationExtToken.onExtSession()
        return
      }
      handleSwitch({ ...props.data })
    }
  }, [props.data, props.status, props.error])

  useEffect(() => {
    if (mutationCI.status === 'error') {
      console.log('error:', mutationCI.error)
      handleError(mutationCI.error)
      return
    }

    if (mutationCI.status === 'success' && !!mutationCI.data) {
      console.log('indicator data: ', mutationCI.data)

      // Store referral code coming from indicator API
      const referralCode = mutationCI.data.referralCode
      dispatch(sessionReferralCode({ referralCode }))

      handleSwitch({
        ...props.data,
        amlStatus: mutationCI.data?.amlStatus,
        ciReloaded: true,
      })
    }
  }, [mutationCI.data, mutationCI.status, mutationCI.error])

  const handleSwitch = ({
    cifid,
    amlStatus,
    ciReloaded = false,
  }: {
    cifid?: string
    amlStatus?: CustomerAMLStatusEnum
    ciReloaded?: boolean
  }) => {
    const nextAction = profileSetupSwitchLogic({
      cifid,
      amlStatus,
      type: props.type,
      epfAction: props.epfAction,
    })

    switch (nextAction) {
      case ProfileSetupSwitchEnum.AmlUnknown:
        if (!ciReloaded) {
          /**
           * when aml status is unknown, and done with reflash okta token,
           * reload indicator
           */
          mutationCI.mutate({})
          return
        }

        /**
         * if already reloaded customerIndicator and aml status is unknown, return error
         */
        handleError({ i18nKey: UNKNOWN_ERROR })
        return
      case ProfileSetupSwitchEnum.EcddPending:
        dispatch(sessionAuthedStatus({ status: AuthedStatusEnum.EcddPending }))
        navigate(RoutePathEnum.PROFILE_ECDD)
        return
      case ProfileSetupSwitchEnum.EcddSubmitted:
        dispatch(
          sessionAuthedStatus({ status: AuthedStatusEnum.EcddSubmitted })
        )
        navigate(RoutePathEnum.PROFILE_VERIFICATION)
        return
      case ProfileSetupSwitchEnum.EcddRejected:
        dispatch(sessionAuthedStatus({ status: AuthedStatusEnum.EcddRejected }))
        navigate(RoutePathEnum.ACCOUNT_DEACTIVATED)
        return
      case ProfileSetupSwitchEnum.EpfPurchaseFlow:
        dispatch(
          sessionAuthedStatus({ status: AuthedStatusEnum.EpfPurchasing })
        )
        navigate(RoutePathEnum.ORDER_PURCHASE_FORM)
        return
      case ProfileSetupSwitchEnum.EpfRedemptionFlow:
        dispatch(
          sessionAuthedStatus({ status: AuthedStatusEnum.EpfRedemption })
        )
        navigate(RoutePathEnum.ORDER_REDEMPTION_FORM)
        return
      case ProfileSetupSwitchEnum.EpfSwitchingFlow:
        dispatch(sessionAuthedStatus({ status: AuthedStatusEnum.EpfSwitching }))
        navigate(RoutePathEnum.ORDER_SWITCHING_FORM)
        return
      case ProfileSetupSwitchEnum.IsafNotPassed:
        dispatch(
          sessionAuthedStatus({
            status: AuthedStatusEnum.IsafNotPassed,
          })
        )
        navigate(RoutePathEnum.ISAF)
        return
      default:
        handleError({ i18nKey: UNKNOWN_ERROR })
        return
    }
  }

  return {
    isLoading: useMemo(
      () =>
        props.isLoading ||
        mutationExtToken.mutateIsLoading ||
        mutationCI.isLoading ||
        isApiLoading,
      [
        props.isLoading,
        mutationExtToken.mutateIsLoading,
        mutationCI.isLoading,
        isApiLoading,
      ]
    ),
  }
}

export default usePostSubmitSwitch
