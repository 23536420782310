import theme from '@pimy-b2cweb/frontend-lib/libSrc/config/theme.json'
import ExternalLink from '@/components/ExternalLink'
import { validateExternalURLs } from '@/utils'

const DESKTOP_IMG_SRC = `${process.env.PUBLIC_URL}/assets/images/marketing-insight/desktop-banner.png`
const MOBILE_IMG_SRC = `${process.env.PUBLIC_URL}/assets/images/marketing-insight/mobile-banner.png`

const MarketInsigntBanner = ({
  alt = '',
  className = '',
  url = '#',
}: {
  alt?: string
  className?: string
  url?: string
}) => {
  return (
    <ExternalLink href={url} className={className}>
      <picture>
        <source
          media={`(min-width: ${theme.screens.md})`}
          srcSet={validateExternalURLs(DESKTOP_IMG_SRC)}
        />
        <img
          src={validateExternalURLs(MOBILE_IMG_SRC)}
          className='w-full block mb-8'
          alt={alt}
        />
      </picture>
    </ExternalLink>
  )
}

export default MarketInsigntBanner
