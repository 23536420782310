import { useSelector } from 'react-redux'
import Loading from '@/components/Loading'
import HelmetSiteTitle from '@/components/HelmetSiteTitle'
import { UnauthedStatusEnum, AUTHED_STATUS } from '@/config'
import { selectAuthStatus } from '@/stores/auth.selectors'
import Email from './subpages/Email'
import Id from './subpages/Id'
import Password from './subpages/Password'
import Succeed from './subpages/Succeed'
import Username from './subpages/Username'
import VerifyOtp from './subpages/VerifyOtp'
import { useTranslation } from 'react-i18next'

export const SignUp = () => {
  const { t } = useTranslation('signUpPage')
  return (
    <>
      <HelmetSiteTitle pageTitle={t('sign-up')} />
      <StatusSwitch />
    </>
  )
}

const StatusSwitch = () => {
  const authStatus = useSelector(selectAuthStatus)

  if ((AUTHED_STATUS as string[]).includes(authStatus)) {
    return <Loading />
  }

  switch (authStatus) {
    case UnauthedStatusEnum.Unknown:
      return <Id />
    case UnauthedStatusEnum.SignUpPendingEmail:
      return <Email />
    case UnauthedStatusEnum.SignUpPendingOtp:
      return <VerifyOtp />
    case UnauthedStatusEnum.SignUpPendingUsername:
      return <Username />
    case UnauthedStatusEnum.SignUpPendingPassword:
      return <Password />
    case UnauthedStatusEnum.SignUpSucceed:
      return <Succeed />
    default:
      return <Id />
  }
}

export default SignUp
