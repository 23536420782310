import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useResponseError } from '@pimy-b2cweb/frontend-lib'
import { useMutationVerifyOtp } from '@/api/commonApi'
import VerifyOtpUi, { VerifyOtpFormProps } from '@/components/VerifyOtpUi'
import { selectAuthSessionState } from '@/stores/auth.selectors'
import { sessionResetPwPendingPassword } from '@/stores/auth'
import { useResendOtp } from '@/hooks/useResendOtp'
import { getErrorResponseCode } from '@/utils'

export const VerifyOtp = () => {
  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const { notificationRecipient, notificationType, canResend } = useSelector(
    selectAuthSessionState
  )

  const dispatch = useDispatch()

  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationVerifyOtp()

  const { onResetResendError, ...useResendOtpRest } = useResendOtp({
    initCanResend: canResend !== false,
    handleResetPageError: () => setResponseErrorAttrs(undefined),
  })

  const onSubmit = async (data: VerifyOtpFormProps) => {
    console.log(data)
    setResponseErrorAttrs(undefined)
    onResetResendError()
    mutate(data)
  }

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)

    if (mutateStatus === 'success' && !!mutatedData) {
      dispatch(sessionResetPwPendingPassword(mutatedData))
    }
  }, [mutateStatus, mutatedData, mutatedError])

  return (
    <VerifyOtpUi
      notificationRecipient={notificationRecipient}
      notificationType={notificationType ?? undefined}
      responseErrorAttrs={responseErrorAttrs}
      onSubmit={onSubmit}
      isLoading={mutateIsLoading}
      canResend={canResend}
      operation='continue'
      {...useResendOtpRest}
    />
  )
}

export default VerifyOtp
