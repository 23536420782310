import { useTranslation } from 'react-i18next'

const InvestmentDisclaimer = ({ className }: { className?: string }) => {
  const { t } = useTranslation('investmentPage')

  const notes = t('disclaimer-notes', {
    returnObjects: true,
  }) as Array<string>

  return (
    <div className={`${className}`}>
      <p className='text-sm font-bold mb-1'>{`${t('disclaimer')}:`}</p>
      {notes?.map((note, idx) => (
        <p key={idx} className='whitespace-normal text-sm'>
          {note}
        </p>
      ))}
    </div>
  )
}

export default InvestmentDisclaimer
