import { useEffect } from 'react'
const SITE_KEY = process.env.REACT_APP_SITE_KEY || ''

export const InitRecaptcha = () => {
  useEffect(() => {
    if (!!document.getElementById('recaptcha')) return
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`
    script.id = 'recaptcha'
    script.addEventListener('load', () => console.log('recaptcha initialized'))
    document.body.appendChild(script)
  }, [])

  return (
    <div
      className='g-recaptcha'
      data-sitekey={SITE_KEY}
      data-size='invisible'
    />
  )
}

export default InitRecaptcha
