import StickyBottomContainer from '@/components/StickyBottomContainer'
import { InvestmentCategoryEnum } from '@/enums/InvestmentCategoryEnum'
import { UseFundSelection } from '@/Routes/pages/FundList/hooks/useFundSelection'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

export interface CashInInvestmentFooterProps
  extends Pick<UseFundSelection, 'selectedFundCount' | 'goExternalEpf'> {
  tabType: InvestmentCategoryEnum
  onExploreNewFunds: () => void
  onCashIn: () => void
}

const CashInInvestmentFooter = ({
  tabType,
  selectedFundCount,
  goExternalEpf,
  onExploreNewFunds,
  onCashIn,
}: CashInInvestmentFooterProps) => {
  const { t } = useTranslation(['investedFundsPage', 'common'])

  return (
    <StickyBottomContainer className='max-w-screen-xl mx-auto xl:!px-0'>
      {tabType === InvestmentCategoryEnum.KWSP_I_INVEST ? (
        <Button
          variant='contained'
          fullWidth
          className='go-epf-button'
          onClick={() => goExternalEpf()}
        >
          {t('cash-in-via-i-invest', { ns: 'common' })}
        </Button>
      ) : (
        <>
          <Button
            variant='contained'
            fullWidth
            disabled={!selectedFundCount}
            onClick={() => onCashIn()}
            className='mb-6 disabled:!bg-gradient-to-r disabled:!from-pi-gray-4 disabled:!to-pi-gray-3 disabled:!shadow-none disabled:!text-white'
          >
            {t('cash-in-fund', { count: selectedFundCount })}
          </Button>
          <div
            className='text-pi-principal-blue cursor-pointer text-center'
            onClick={() => onExploreNewFunds()}
          >
            {t('explore-new-fund', { ns: 'common' })}
          </div>
        </>
      )}
    </StickyBottomContainer>
  )
}

export default CashInInvestmentFooter
