import { Route, Routes as ReactRoutes } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'
import SessionModal from '@/components/SessionModal'
import PostLogonLayout from '@/layout/PostLogonLayout'
import PreLogonLayout from '@/layout/PreLogonLayout'

import RequireAuth from './hoc/RequireAuth'
import NotRequireAuth from './hoc/NotRequireAuth'

import AccountVerification from './pages/AccountVerification'
import CashIn from './pages/CashIn'

import Dashboard from './pages/Dashboard'

import ECDDForm from './pages/ECDDForm'
import ErrorGenerlic from './pages/ErrorGenerlic'
import ErrorNotFound from './pages/ErrorNotFound'
import EKYCRequired from './pages/EKYCRequired'

import FundList from './pages/FundList'

import ISAF from './pages/ISAF'

import ProfileInfo from './pages/ProfileInfo'

import RecoverUserId from './pages/RecoverUserId'
import ResetPassword from './pages/ResetPassword'

import SignUp from './pages/SignUp'

import devDemoRoutes from './devDemoRoutes'
import AccountDeactivated from './pages/AccountDeactivated'
import Portfolio from './pages/Portfolio'
import InvestedFunds from './pages/InvestedFunds'
import Redemption from './pages/Redemption'

import TransactionHistory from './pages/TransactionHistory'
import ProfileEdit from './pages/ProfileEdit'
import Switching from './pages/Switching'
import RedemptionOrder from './pages/RedemptionOrder'
import SwitchingForm from './pages/SwitchingForm'
import OktaSignInWidget from './pages/SignIn/subpages/OktaSignInWidget'
import { LoginCallback } from '@okta/okta-react'
import Loading from '@/components/Loading'
import Inbox from './pages/Inbox'

export interface Pages {
  title?: string
  path: string
  element: JSX.Element
}

const preLogonPages: Pages[] = [
  {
    path: RoutePathEnum.SIGN_UP,
    element: <SignUp />,
  },
  {
    path: RoutePathEnum.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    path: RoutePathEnum.RECOVER_USER_ID,
    element: <RecoverUserId />,
  },
]
const inBetweenPages: Pages[] = [
  {
    path: RoutePathEnum.EKYC_REQUIRED,
    element: <EKYCRequired />,
  },
  {
    path: RoutePathEnum.PROFILE_SETUP,
    element: <ProfileInfo />,
  },
  {
    path: RoutePathEnum.PROFILE_ECDD,
    element: <ECDDForm />,
  },
  {
    path: RoutePathEnum.PROFILE_VERIFICATION,
    element: <AccountVerification />,
  },
  {
    path: RoutePathEnum.ACCOUNT_DEACTIVATED,
    element: <AccountDeactivated />,
  },
]
const postLogonPages: Pages[] = [
  {
    path: RoutePathEnum.DASHBOARD,
    element: <Dashboard />,
  },
  {
    path: RoutePathEnum.PORTFOLIO,
    element: <Portfolio />,
  },
  {
    path: RoutePathEnum.PROFILE_EDIT,
    element: <ProfileEdit />,
  },
  {
    path: `${RoutePathEnum.PROFILE_EDIT}/:category`,
    element: <ProfileEdit />,
  },
  {
    path: RoutePathEnum.ORDER_HISTORY,
    element: <TransactionHistory />,
  },
  {
    path: RoutePathEnum.ORDER_REDEMPTION,
    element: <Redemption />,
  },
  {
    path: RoutePathEnum.ORDER_REDEMPTION_FORM,
    element: <RedemptionOrder />,
  },
  {
    path: RoutePathEnum.ORDER_REDEMPTION_RECEIPT,
    element: <>Order REDEMPTION Result (EPF)</>,
  },
  {
    path: RoutePathEnum.ORDER_SWITCHING,
    element: <Switching />,
  },
  {
    path: RoutePathEnum.ORDER_SWITCHING_FORM,
    element: <SwitchingForm />,
  },
  {
    path: RoutePathEnum.ORDER_SWITCHING_RECEIPT,
    element: <>Order Switching Result (EPF)</>,
  },
  {
    path: RoutePathEnum.ORDER_PURCHASE,
    element: <FundList />,
  },
  {
    path: `${RoutePathEnum.ORDER_PURCHASE}/:category`,
    element: <FundList />,
  },
  {
    path: RoutePathEnum.ORDER_PURCHASE_FORM,
    element: <CashIn />,
  },
  {
    path: RoutePathEnum.ORDER_PURCHASE_INVESTED_FUNDS,
    element: <InvestedFunds />,
  },
  {
    path: RoutePathEnum.INBOX,
    element: <Inbox />,
  },
]
const preLogonRoute = preLogonPages.map(({ path, element }) => (
  <Route
    key={path}
    path={path.slice(1)}
    element={
      <NotRequireAuth children={<PreLogonLayout children={element} />} />
    }
  />
))
const inBetweenRoute = inBetweenPages.map(({ path, element }) => (
  <Route
    key={path}
    path={path.slice(1)}
    element={
      <RequireAuth
        children={
          <PreLogonLayout
            children={
              <>
                {element}
                <SessionModal />
              </>
            }
          />
        }
      />
    }
  />
))
const postLogonRoute = postLogonPages.map(({ path, element }) => (
  <Route
    key={path}
    path={path.slice(1)}
    element={
      <RequireAuth
        children={
          <PostLogonLayout
            children={
              <>
                {element}
                <SessionModal />
              </>
            }
          />
        }
      />
    }
  />
))

export const Routes = () => (
  <ReactRoutes>
    <Route
      path={RoutePathEnum.ROOT}
      element={<RequireAuth children={null} />}
    />
    <Route
      path={RoutePathEnum.SIGN_IN}
      element={<NotRequireAuth children={<OktaSignInWidget />} />}
    />
    <Route
      path={RoutePathEnum.LOGIN_CALLBACK}
      element={
        <>
          <LoginCallback />
          <Loading isLoadingPage={true} />
        </>
      }
    />
    {preLogonRoute}
    {inBetweenRoute}
    {/* special handeling for special layout */}
    <Route
      path={RoutePathEnum.ISAF}
      element={
        <RequireAuth
          children={
            <>
              <ISAF />
              <SessionModal />
            </>
          }
        />
      }
    />
    {postLogonRoute}
    {devDemoRoutes}

    <Route
      path={RoutePathEnum.ERROR}
      element={<PostLogonLayout children={<ErrorGenerlic />} />}
    />
    <Route
      path='*'
      element={<PostLogonLayout children={<ErrorNotFound />} />}
    />
  </ReactRoutes>
)

export default Routes
