import { RoutePathEnum } from '@/constants/routePath'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import CashOutAmount from './subpages/CashOutAmount'

import HelmetSiteTitle from '@/components/HelmetSiteTitle'
import Loading from '@/components/Loading'
import { selectEpfToken } from '@/stores/epfToken.selectors'
import { getErrorResponseCode } from '@/utils'
import {
  ResponseErrorProps,
  useModal,
  useResponseError,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import PostLogonMainBody from '@/layout/PostLogonMainBody'
import ErrorNotFound from '../ErrorNotFound'
import { useInitData } from './hooks/useInitData/indx'
import { useRedemptionOrderReducer } from './hooks/useRedemptionOrderReducer'
import BankAccountInfo from './subpages/BankAccountInfo'
import CashOutSummary from './subpages/CashOutSummary'
import ErrorPage from './subpages/ErrorPage'
import ResultPage from './subpages/ResultPage'
import SurveyPage from './subpages/SurveyPage'
import VerifyOtp from './subpages/VerifyOTP'
import { OrderCreateResultResDto } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import PostLogonContainer from '@/layout/PostLogonContainer'
import { useSubmitRedemptionOrderMutation } from './hooks/useSubmitRedemptionOrderMutation'
import TechnicalErrorModal from '@/components/TechnicalErrorModal'

enum RedemptionOrderPageState {
  ORDER_FORM = 'order_form',
  ORDER_SUMMARY = 'order_summary',
  REDEMPTION_REASON = 'redemption_reason',
  BANK_DETAILS = 'bank_details',
  VERIFY_OTP = 'verify_otp',
  REDEMPTION_RESULT = 'redemption_result',
}

export interface TransactionDetails extends OrderCreateResultResDto {}

const RedemptionOrder = () => {
  const { t } = useTranslation('redemptionPage')
  const { state } = useLocation()
  const navigate = useNavigate()
  const xEpfToken = useSelector(selectEpfToken) || undefined
  console.log('state: ', state)
  const { subaccountNo, productId, funds = [] } = state || {}

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()
  const [pageState, setPageState] = useState(
    RedemptionOrderPageState.ORDER_FORM
  )
  const [transactionDetails, setTransactionDetails] =
    useState<TransactionDetails>()

  const technicalErrorModal = useModal()

  const {
    redemptionOrder,
    setRedemptionOrder,
    setRedemptionFunds,
    setRedemptionReason,
    setBankDetails,
  } = useRedemptionOrderReducer()

  const [orderFormError, setOrderFormError] = useResponseError()

  const submitRedemptionOrderMutation = useSubmitRedemptionOrderMutation({
    goVerifyOtp: () => setPageState(RedemptionOrderPageState.VERIFY_OTP),
    openTechnicalErrorModal: () => technicalErrorModal.modalOpen(),
    goToOrderForm: () => setPageState(RedemptionOrderPageState.ORDER_FORM),
    setOrderFormErrMsg: (err: ResponseErrorProps) => setOrderFormError(err),
  })

  if (!xEpfToken && (!subaccountNo || !funds?.length || !productId)) {
    navigate(RoutePathEnum.ORDER_REDEMPTION)
  }

  const {
    mutate,
    mutateStatus,
    mutatedData,
    mutatedError,
    isLoading,
    isError,
    cmData,
  } = useInitData()

  useEffect(() => {
    if (isError) {
      navigate(RoutePathEnum.ERROR)
    }
  }, [isError])

  useEffect(() => {
    mutate({ productId, subaccountNo, funds })
  }, [])

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      console.error(errMsg)
      if (errMsg === 'epf_token_invalid') {
        navigate(RoutePathEnum.ERROR)
        return
      }
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      const headaccountStatus = cmData?.headAccount?.sts
      console.log('mutatedData: ', mutatedData)
      setRedemptionOrder({ ...mutatedData, headaccountStatus })
    }
  }, [mutateStatus, mutatedData, mutatedError, cmData])

  const cashOutFunds = useMemo(() => mutatedData?.funds || [], [mutatedData])

  return (
    <>
      <HelmetSiteTitle pageTitle={t('redemption-order')} />
      {isLoading ? (
        <Loading isLoadingPage />
      ) : !!responseErrorAttrs ? (
        <ErrorPage responseErrorAttrs={responseErrorAttrs} />
      ) : Object.values(RedemptionOrderPageState).includes(pageState) ? (
        <PostLogonMainBody
          useTransBg={
            ![
              RedemptionOrderPageState.ORDER_SUMMARY,
              RedemptionOrderPageState.REDEMPTION_RESULT,
              RedemptionOrderPageState.BANK_DETAILS,
            ].includes(pageState)
          }
        >
          <PostLogonContainer className='!bg-transparent sm:!bg-white'>
            {pageState === RedemptionOrderPageState.ORDER_FORM ? (
              <CashOutAmount
                cashOutFunds={cashOutFunds}
                {...redemptionOrder}
                orderFormError={orderFormError}
                onSubmitAmount={setRedemptionFunds}
                goNext={() =>
                  setPageState(RedemptionOrderPageState.ORDER_SUMMARY)
                }
              />
            ) : pageState === RedemptionOrderPageState.ORDER_SUMMARY ? (
              <CashOutSummary
                {...redemptionOrder}
                goNext={() =>
                  setPageState(RedemptionOrderPageState.REDEMPTION_REASON)
                }
              />
            ) : pageState === RedemptionOrderPageState.REDEMPTION_REASON ? (
              <SurveyPage
                redemptionOrder={redemptionOrder}
                setRedemptionReason={setRedemptionReason}
                {...submitRedemptionOrderMutation}
                goNext={() =>
                  setPageState(RedemptionOrderPageState.BANK_DETAILS)
                }
              />
            ) : pageState === RedemptionOrderPageState.BANK_DETAILS ? (
              <BankAccountInfo
                redemptionOrder={redemptionOrder}
                onSubmitBankDetails={setBankDetails}
                {...submitRedemptionOrderMutation}
              />
            ) : pageState === RedemptionOrderPageState.VERIFY_OTP ? (
              <VerifyOtp
                goNext={() =>
                  setPageState(RedemptionOrderPageState.REDEMPTION_RESULT)
                }
                setTrxDetails={(trxDetails: OrderCreateResultResDto) =>
                  setTransactionDetails(trxDetails)
                }
                goToOrderForm={() =>
                  setPageState(RedemptionOrderPageState.ORDER_FORM)
                }
              />
            ) : (
              <ResultPage transactionDetails={transactionDetails} />
            )}
          </PostLogonContainer>
        </PostLogonMainBody>
      ) : (
        <ErrorNotFound />
      )}
      <TechnicalErrorModal
        {...technicalErrorModal}
        onContinue={() => setPageState(RedemptionOrderPageState.ORDER_FORM)}
      />
    </>
  )
}

export default RedemptionOrder
