import { useQueryBankList } from '@/api/commonApi'
import Loading from '@/components/Loading'
import { isNumber } from '@/utils'
import { TextField } from '@mui/material'
import {
  ButtonWithLoading,
  FormLabel,
  ResponseError,
  SelectBoxComp,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'
import { UseSubmitRedemptionOrderMutationResponse } from '../../hooks/useSubmitRedemptionOrderMutation'
import {
  RedemptionOrderState,
  SetBankDetailsProps,
} from '../../hooks/useRedemptionOrderReducer'
import { WrapperTypeEnum } from '@pimy-b2cweb/apiclient-b2cweb-r2'

export interface BankAccountFormProps {
  bankCode: string
  bankAccountNo: string
}

export interface BankAccountInfoProps
  extends UseSubmitRedemptionOrderMutationResponse {
  onSubmitBankDetails: (data: SetBankDetailsProps) => void
  redemptionOrder: RedemptionOrderState
}
const BankAccountInfo = ({
  onSubmitBankDetails,
  redemptionOrder,
  mutate,
  mutateIsLoading,
  responseErrorAttrs,
}: BankAccountInfoProps) => {
  const { t } = useTranslation(['redemptionPage', 'errorResponse', 'common'])

  const navigate = useNavigate()

  const {
    data: bankList,
    isLoading: isLoadingBL,
    isError: isErrorBL,
  } = useQueryBankList({ enabled: true })

  const { control, handleSubmit, setValue } = useForm<BankAccountFormProps>({
    mode: 'onChange',
    defaultValues: {
      bankCode: '',
      bankAccountNo: '',
    },
  })

  const onSubmit = (data: BankAccountFormProps) => {
    console.log('bank Details: ', data)
    onSubmitBankDetails({ ...data })
    mutate({
      ...redemptionOrder,
      ...data,
      wrapperType: redemptionOrder.wrapperType as WrapperTypeEnum,
    })
  }

  useEffect(() => {
    if (!isLoadingBL && !isErrorBL) {
      setValue(
        'bankCode',
        !!redemptionOrder?.bankCode
          ? redemptionOrder.bankCode
          : bankList?.at(0)?.bankCd || ''
      )
      setValue('bankAccountNo', redemptionOrder?.bankAccountNo || '')
    }
  }, [isLoadingBL, isErrorBL])

  useEffect(() => {
    if (isErrorBL) navigate(RoutePathEnum.ERROR)
  }, [isErrorBL])

  return isLoadingBL ? (
    <Loading isLoadingPage={true} />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h1 className='font-bold mb-6'>{t('cash-out', { ns: 'common' })}</h1>

      {!!responseErrorAttrs && (
        <ResponseError>
          {t(responseErrorAttrs.i18nKey, {
            ns: responseErrorAttrs.ns,
          })}
        </ResponseError>
      )}

      <p className='text-base'>
        {t('please-proceed-with-registered-bank-account')}
      </p>

      <Controller
        name='bankCode'
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Required',
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <FormLabel
            id='bankCode'
            label={t('bank-name')}
            isError={!!error}
            className='!mb-4'
          >
            <SelectBoxComp
              id='bankCode'
              items={
                bankList?.map((bank) => {
                  return {
                    label: bank.name,
                    value: bank.bankCd,
                    key: bank.code,
                  }
                }) || []
              }
              {...field}
              error={error}
              fullWidth
              disabled={mutateIsLoading}
            />
          </FormLabel>
        )}
      />

      <Controller
        name='bankAccountNo'
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Required',
          },
          validate: (val: string) => {
            if (!isNumber(val)) {
              return t('invalid-input', { ns: 'errorResponse' })
            }
            return true
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <FormLabel
            id='bankAccountNo'
            label={t('bank-account-number')}
            isError={!!error}
            className='!mb-6'
          >
            <TextField
              type='tel'
              fullWidth
              {...field}
              error={!!error}
              helperText={!!error ? (error?.message as string) : ''}
              autoComplete='off'
              disabled={mutateIsLoading}
            />
          </FormLabel>
        )}
      />

      <ButtonWithLoading
        type='submit'
        fullWidth
        variant='contained'
        size='large'
        className='mb-10'
        disabled={mutateIsLoading}
        isLoading={mutateIsLoading}
      >
        {t('Continue', { ns: 'common' })}
      </ButtonWithLoading>
    </form>
  )
}

export default BankAccountInfo
