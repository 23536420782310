import { AuthedStatusEnum } from '@/config'

export enum IsafResultEnum {
  Conservative = 1,
  ModeratelyConservative,
  Moderate,
  ModeratelyAggressive,
  Aggressive,
}

export type IsafResultStrings = keyof typeof IsafResultEnum

export enum IsafStatusEnum {
  Expired = AuthedStatusEnum.IsafExpired,
  NotPassed = AuthedStatusEnum.IsafNotPassed,
  Unknown = AuthedStatusEnum.IsafUnknown,
  Passed = 'passed',
}
