import { formatMillisecToTime } from '@pimy-b2cweb/frontend-lib'
import { useSelector } from 'react-redux'
import { selectAuthExpiresAt } from '@/stores/auth.selectors'
import { useEffect, useState } from 'react'
import useCountDown from 'react-countdown-hook'
import { INTERVAL } from '@/constants'

export const useSessionCountdown = () => {
  const authExpiresAt = useSelector(selectAuthExpiresAt)
  const [initialized, setInitialized] = useState(false)
  const [timeleft, { start, reset }] = useCountDown(0, INTERVAL)

  const resetCounter = () => {
    reset()
    setInitialized(false)
  }

  useEffect(() => {
    if (!!authExpiresAt) {
      start(new Date(authExpiresAt).getTime() - new Date().getTime())
      setInitialized(true)
      return
    }

    resetCounter()
  }, [authExpiresAt])

  return {
    resetCounter,
    timeleft: !initialized ? undefined : timeleft,
    formattedTimeleft: !initialized
      ? undefined
      : formatMillisecToTime(timeleft),
  }
}

export default useSessionCountdown
