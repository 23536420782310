import { selectAuthSessionState } from '@/stores/auth.selectors'
import { useSelector, useDispatch } from 'react-redux'
import { userStatusModalSessionSlice } from '@/stores/userStatusModal'
import { EcddStatusEnum } from '@/enums/ecddEnums'
import { IsafStatusEnum } from '@/enums/isafEnums'

export const useHandleUserStatus = () => {
  const dispatch = useDispatch()
  const { isafStatus, ecddStatus, isValidUserStatus } = useSelector(
    selectAuthSessionState
  )
  const {
    sessionResetUserStatusModal,
    sessionOpenIsafModal,
    sessionOpenEcddModal,
  } = userStatusModalSessionSlice.actions

  const handleCheckUserStatus = ({
    forceOpenModal,
    onEcddModalClose,
    onIsafModalClose,
  }: {
    forceOpenModal?: 'isaf' | 'ecdd'
    onEcddModalClose?: () => void
    onIsafModalClose?: () => void
  }) => {
    if (forceOpenModal === 'isaf' || isafStatus !== IsafStatusEnum.Passed) {
      dispatch(sessionOpenIsafModal({ onIsafModalClose }))
      return
    }

    if (forceOpenModal === 'ecdd' || ecddStatus !== EcddStatusEnum.Passed) {
      dispatch(sessionOpenEcddModal({ onEcddModalClose }))
      return
    }

    dispatch(sessionResetUserStatusModal())
  }

  return {
    isafStatus,
    ecddStatus,
    isValidUserStatus,
    handleCheckUserStatus,
  }
}

export default useHandleUserStatus
