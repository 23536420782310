import FundPrice from '@/components/FundCard/FundPrice'
import { getLocalFormattedNumber, getSignedCurrency } from '@/utils'
import { Button } from '@mui/material'
import { formattedAmount } from '@pimy-b2cweb/frontend-lib'
import { useTranslation } from 'react-i18next'
import { PortfolioFund } from '@/utils/portfolioFund'
import FundInfoLink from '@/components/FundInfoLink'
import { NAV_DECIMAL } from '@/constants'

export interface FundDetailsCompProps {
  fundDetails: PortfolioFund
  onCashIn: () => void
}

const FundDetailsComp = ({
  onCashIn,
  fundDetails: {
    name,
    unitHeld,
    navPrice,
    isEPFFund,
    totalCost,
    currentValue,
    totalGain,
    fundInfoUrl = '',
    ...fundPrice
  },
}: FundDetailsCompProps) => {
  const { t } = useTranslation(['portfolioPage', 'common'])

  return (
    <>
      <div className='text-xl font-black'>{name}</div>
      <FundPrice {...fundPrice} currentValue={currentValue} />
      <table className='table-auto text-base border-spacing-y-2'>
        <tbody>
          <tr>
            <td>{t('total-net-investment')}</td>
            <td className='font-bold text-right'>
              {formattedAmount({ amount: totalCost })}
            </td>
          </tr>
          <tr>
            <td>{t('total-units')}</td>
            <td className='font-bold text-right'>
              {getLocalFormattedNumber({ num: unitHeld })}
            </td>
          </tr>
          <tr>
            <td>{t('latest-nav')}</td>
            <td className='font-bold text-right'>
              {formattedAmount({ amount: navPrice, decimal: NAV_DECIMAL })}
            </td>
          </tr>
          <tr>
            <td>{t('total-value')}</td>
            <td className='font-bold text-right'>
              {formattedAmount({
                amount: currentValue,
              })}
            </td>
          </tr>
          <tr>
            <td>{t('total-profit-loss')}</td>
            <td
              className={`font-bold text-right ${
                totalGain > 0 ? 'text-pi-utility-green' : 'text-pi-utility-red'
              }`}
            >
              {getSignedCurrency({
                num: totalGain,
                currency: 'MYR',
                currencyDisplay: 'narrowSymbol',
              })}
            </td>
          </tr>
        </tbody>
      </table>
      <FundInfoLink url={fundInfoUrl} />
      <Button
        fullWidth
        variant='contained'
        size='large'
        className={`mt-10 ${isEPFFund ? 'go-epf-button' : ''}`}
        onClick={() => onCashIn()}
      >
        {t(isEPFFund ? 'cash-in-via-i-invest' : 'cash-in', { ns: 'common' })}
      </Button>
    </>
  )
}

export default FundDetailsComp
