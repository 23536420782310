import StickyBottomContainer from '@/components/StickyBottomContainer'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

export interface SwitchFundFooterProps {
  onClickNext: () => void
  disabled?: boolean
  className?: string
}

const SwitchFundFooter = ({
  onClickNext,
  disabled = false,
  className = '',
}: SwitchFundFooterProps) => {
  const { t } = useTranslation('switchingPage')

  return (
    <StickyBottomContainer
      className={`max-w-screen-xl mx-auto !px-0 ${className}`}
    >
      <Button
        variant='contained'
        fullWidth
        className='disabled:!bg-gradient-to-r disabled:!from-pi-gray-4 disabled:!to-pi-gray-3 disabled:!shadow-none disabled:!text-white'
        onClick={onClickNext}
        disabled={disabled}
      >
        {t('switch-fund')}
      </Button>
    </StickyBottomContainer>
  )
}

export default SwitchFundFooter
