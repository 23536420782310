import { ReactComponent as CloseIco } from '@/assets/close-ico.svg'
import FundAmountInput from '@/components/FundAmountInput'
import TwoSideText from '@/components/TwoSideText'
import { SelectedFund } from '@/hooks/useInvestmentReducer'
import { IconButton } from '@mui/material'
import {
  DEF_DECIMAL,
  FormErrorMessage,
  FormLabel,
  formattedAmount,
} from '@pimy-b2cweb/frontend-lib'
import { forwardRef, memo, useEffect, useMemo, useState } from 'react'
import { ControllerRenderProps, FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export interface FundsCompProps {
  error?: FieldError
  value: SelectedFund[]
  disabled?: boolean
  disableAdjustAmount?: boolean
  onChange: ControllerRenderProps['onChange']
  className?: string
}
export interface FundsCompItemErr {
  code: string
  message: string
}
export const FundsComp = memo(
  forwardRef<HTMLDivElement, FundsCompProps>(
    (
      {
        value: funds,
        error,
        disabled,
        disableAdjustAmount = false,
        onChange,
        className = '',
      },
      ref
    ) => {
      const { t } = useTranslation('redemptionPage')

      const [localFunds, setLocalFunds] = useState<SelectedFund[]>([])
      useEffect(() => {
        if (JSON.stringify(funds) !== JSON.stringify(localFunds))
          setLocalFunds(funds)
      }, [funds])
      useEffect(() => {
        const timer = setTimeout(() => {
          if (JSON.stringify(funds) !== JSON.stringify(localFunds))
            onChange(localFunds)
        }, 100)
        return () => clearTimeout(timer)
      }, [localFunds])

      const handleChange = ({ id, value }: { id: string; value: number }) => {
        const _localVal = localFunds.map((item) =>
          item.code === id
            ? {
                ...item,
                amount: value || 0,
              }
            : item
        )
        setLocalFunds(_localVal)
      }

      const handleRemove = (code: string) => {
        if (localFunds.length <= 1 || !!disabled) return
        const _localVal = localFunds.filter((item) => item.code !== code)
        setLocalFunds(_localVal)
      }

      const errMessages = useMemo(
        () =>
          (!!error &&
            !!error.message &&
            (JSON.parse(error.message) as FundsCompItemErr[])) ||
          undefined,
        [error]
      )

      return (
        <div ref={ref} className={`flex flex-col gap-4 ${className}`}>
          {localFunds.map(({ name, code, amount, maxAmount = 0 }) => {
            const _disabled = localFunds.length <= 1 || !!disabled
            const errMsg =
              (!!errMessages &&
                errMessages.find((item) => item.code === code)) ||
              undefined

            return (
              <FormLabel
                id={code}
                key={code}
                className='[&>label]:overflow-visible'
                label={
                  <TwoSideText
                    className='!gap-4 [&>:first-child]:truncate'
                    left={name}
                    right={
                      <IconButton
                        onClick={() => handleRemove(code)}
                        disabled={_disabled}
                        className={`-m-2 ${_disabled ? '' : 'cusor-pointer'}`}
                      >
                        <CloseIco
                          className={
                            _disabled ? '[&>path]:stroke-pi-gray-3' : ''
                          }
                        />
                      </IconButton>
                    }
                  />
                }
                isError={!!errMsg}
              >
                <FundAmountInput
                  id={code}
                  fullWidth
                  disabled={disabled || disableAdjustAmount}
                  value={amount}
                  onChange={(event) => {
                    handleChange({
                      id: code,
                      value: Number(event.target.value),
                    })
                  }}
                  setMaxAmount={() => {
                    handleChange({
                      id: code,
                      value: Number(Number(maxAmount).toFixed(DEF_DECIMAL)),
                    })
                  }}
                  className='mb-2'
                  error={!!errMsg}
                  inputProps={{ maxLength: 15 }}
                />
                <p className='mb-0 font-bold'>
                  {t('available-cash-out-amt')}
                  <span className='text-pi-principal-blue'>
                    {formattedAmount({ amount: Number(maxAmount) })}
                  </span>
                </p>
                <FormErrorMessage
                  error={
                    !!error && !!errMsg
                      ? { type: error.type, message: errMsg.message }
                      : undefined
                  }
                />
              </FormLabel>
            )
          })}
        </div>
      )
    }
  )
)
export default FundsComp
