import { useOktaAuth } from '@okta/okta-react'
import { useSelector } from 'react-redux'
import { AuthedStatusEnum } from '@/config'
import { selectAuthStatus } from '@/stores/auth.selectors'

export const useIsSignedIn = () => {
  const { authState } = useOktaAuth()
  const authStatus = useSelector(selectAuthStatus)

  return (
    !!authState?.isAuthenticated &&
    (
      [
        AuthedStatusEnum.LoggedIn,
        AuthedStatusEnum.IsafExpired,
        AuthedStatusEnum.IsafNotPassed,
        AuthedStatusEnum.IsafUnknown,
        AuthedStatusEnum.EcddExpired,
        AuthedStatusEnum.EcddRejected,
        AuthedStatusEnum.EcddSubmitted,
        AuthedStatusEnum.EpfPurchasing,
        AuthedStatusEnum.EpfRedemption,
        AuthedStatusEnum.EpfSwitching,
      ] as string[]
    ).includes(authStatus)
  )
}

export default useIsSignedIn
