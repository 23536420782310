import { useDispatch } from 'react-redux'
import { sessionSetIsUserNotIdle } from '@/stores/auth'

export const useSetUserNotIdle = () => {
  const dispatch = useDispatch()

  const setUserNotIdle = () => {
    dispatch(sessionSetIsUserNotIdle())
  }

  return setUserNotIdle
}

export default useSetUserNotIdle
