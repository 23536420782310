import { useCallback, useEffect, useState } from 'react'

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.5,
}

export const useIsInViewport = <T extends HTMLElement>() => {
  const [isInViewport, setIsInViewport] = useState(false)
  const [element, setElement] = useState<T>()

  const setRef = useCallback((node: T) => setElement(node), [])

  const callbackFunction = useCallback(
    ([entry]: IntersectionObserverEntry[]) => {
      setIsInViewport(entry.isIntersecting)
    },
    []
  )

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, options)
    if (!!element) {
      observer.observe(element)
    }
    return () => {
      if (!!element) {
        observer.unobserve(element)
      }
    }
  }, [element])

  return { setRef, isInViewport }
}

export default useIsInViewport
