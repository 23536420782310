import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface EkycSessionState {
  refNo?: string
}

const initialState: EkycSessionState = {
  refNo: undefined,
}

export const ekycSessionSlice = createSlice({
  name: 'ekycSession',
  initialState,
  reducers: {
    sessionReset: (state) => {
      state.refNo = initialState.refNo
    },
    sessionSetEkyc: (state, action: PayloadAction<EkycSessionState>) => {
      state.refNo = action.payload.refNo
    },
  },
})

// action export
export const { sessionReset, sessionSetEkyc } = ekycSessionSlice.actions

export default ekycSessionSlice.reducer
