import { useInitProfileInfoData } from '@/Routes/pages/ProfileEdit/hooks/useInitProfileInfoData'
import { CustomerAddress } from '@/utils/CustomerProfileAddress'
import {
  CustomerEPF,
  CustomerProfileEditPersonalInfoReqDto,
  CustomerProfileIdentiy,
  CustomerProfileResDto,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useForm } from 'react-hook-form'

export interface PersonalInfoFormProps
  extends Omit<
      CustomerProfileEditPersonalInfoReqDto,
      'correspondentContact' | 'primaryContact'
    >,
    Pick<
      CustomerProfileResDto,
      'fullName' | 'dateOfBirth' | 'nationality' | 'gender'
    > {
  idType: CustomerProfileIdentiy['idType']
  idNo: CustomerProfileIdentiy['idNo']
  country: string
  //epfiStatus: true = Islamic, require effective date; otherwise false = Conventional
  epfAccountType: 'Islamic' | 'Conventional' | ''
  epfNumber: CustomerEPF['epfNumber']
  epfiStatusEffectiveDate: CustomerEPF['epfiStatusEffectiveDate']
  isPrimaryContactCurrent: boolean
  primaryContact: CustomerAddress
  correspondentContact: CustomerAddress
}

export const useInitData = () => {
  const {
    lovs,
    postalList,
    lovValidated,
    isLoading,
    profileQueryData,
    profileQueryError,
    profileQueryStatus,
  } = useInitProfileInfoData()

  const personalInfoFormMethods = useForm<PersonalInfoFormProps>({
    mode: 'onChange',
    defaultValues: {
      fullName: '',
      idType: undefined,
      idNo: '',
      dateOfBirth: '',
      country: '',
      nationality: '',
      gender: '',
      race: '',
      epfAccountType: '',
      epfNumber: '',
      epfiStatusEffectiveDate: '',
      primaryContact: {
        addressLines: {
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          addressLine4: '',
          addressLine5: '',
        },
        postalInfo: { postalCode: '', cityName: '', state: '', country: '' },
      },
      isPrimaryContactCurrent: true,
      correspondentContact: {
        addressLines: {
          addressLine1: '',
          addressLine2: '',
          addressLine3: '',
          addressLine4: '',
          addressLine5: '',
        },
        postalInfo: { postalCode: '', cityName: '', state: '', country: '' },
      },
    },
  })

  return {
    personalInfoFormMethods,
    lovs,
    postalList,
    lovValidated,
    isLoading,
    queryError: profileQueryError,
    queryData: profileQueryData,
    queryStatus: profileQueryStatus,
  }
}
