import useCountDown from 'react-countdown-hook'
import { INTERVAL } from '@/constants'

export const useCountDownTimer = (initialTime: number) => {
  const [timeLeft, actions] = useCountDown(initialTime, INTERVAL)

  return { timeLeft, ...actions }
}

export default useCountDownTimer
