import { CustomerIdTypeEnum } from '@pimy-b2cweb/apiclient-b2cweb-r2'

export const ID_TYPES = Object.values(CustomerIdTypeEnum)

export const INTERVAL = 1000 // interval to change

export const NO_REFERRER = 'noopener noreferrer nofollow'

export const NOT_APPLICABLE_BUSINESS_CODE = '99'

export const DD_MM_YYYY = 'DD/MM/YYYY'

export const MMMM_YYYY = 'MMMM YYYY'

export const DD_MMM_YYYY = 'DD MMM YYYY'

export const HH_MM_SS_A = 'HH:mm:ss A'

export const DEFAULT_RISK_PROFILE = 0

export const EMPTY_VALUE = '-'

export const NAV_DECIMAL = 4

export const TRANSACTION_DT_FORMAT = 'YYYY-MM-DD HH:mm:ss'
