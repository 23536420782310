import { RoutePathEnum } from '@/constants/routePath'
import { ActionModal, ActionModalProps } from '@pimy-b2cweb/frontend-lib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export interface LeaveCashInModalProps
  extends Omit<
    ActionModalProps,
    'title' | 'actions' | 'actionsStyle' | 'closeBtn' | 'onClose' | 'children'
  > {
  modalClose: () => void
}

const LeaveCashInModal = ({ modalClose, ...rest }: LeaveCashInModalProps) => {
  const { t } = useTranslation('investedFundsPage')
  const navigate = useNavigate()
  const [triggerNavOnClose, setTriggerNavOnClose] = useState(false)
  useEffect(() => {
    if (!!triggerNavOnClose && !rest.open)
      navigate(RoutePathEnum.ORDER_PURCHASE)
  }, [rest.open, triggerNavOnClose])

  return (
    <ActionModal
      {...rest}
      // TODO: pending for actual text --
      title={t(`confirm-leaving-this-page`)}
      actions={[
        {
          label: t('yes-i-want-to-leave'),
          onClick: () => {
            setTriggerNavOnClose(true)
            modalClose()
          },
          variant: 'contained',
        },
        {
          label: t('continue-select-fund'),
          onClick: () => modalClose(),
          variant: 'text',
        },
      ]}
      actionsStyle='full'
      closeBtn={true}
      onClose={modalClose}
    >
      {/* TODO: pending for actual text -- */}
      <p className='mb-0'>{t(`confirm-leaving-this-page-description`)}</p>
    </ActionModal>
  )
}

export default LeaveCashInModal
