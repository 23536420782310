import { ActionModal, ActionModalProps } from '@pimy-b2cweb/frontend-lib'
import { useTranslation, Trans } from 'react-i18next'
import { openCSLink } from '@/utils'
import { useEffect, useState } from 'react'

export enum ActionTypeEnum {
  CASH_IN = 'cash-in',
  SWITCH = 'switch',
  REDEMPTION = 'redemption',
}
export enum TransactionActionEnum {
  CASH_IN = 'cash-in',
  CASH_OUT = 'cash-out',
  SWITCH_IN = 'switch-in',
  SWITCH_OUT = 'switch-out',
}
export const SubaccountInvalidModal = ({
  modalClose,
  onClose,
  action,
  ...rest
}: Omit<
  ActionModalProps,
  'title' | 'actions' | 'actionsStyle' | 'closeBtn' | 'onClose' | 'children'
> & {
  modalClose: () => void
  onClose?: () => void
  action: ActionTypeEnum
}) => {
  const { t } = useTranslation(['subaccountInvalidModal', 'common'])

  const [triggerNavOnClose, setTriggerNavOnClose] = useState(false)
  useEffect(() => {
    if (!!triggerNavOnClose && !rest.open) !!onClose && onClose()
  }, [rest.open, triggerNavOnClose])

  return (
    <ActionModal
      {...rest}
      title={t(
        action === ActionTypeEnum.CASH_IN
          ? 'cash-in-unavailable-title'
          : action === ActionTypeEnum.SWITCH
          ? 'switch-unavailable-title'
          : 'redemption-unavailable-title'
      )}
      actions={[
        {
          label: t('chat-with-us-on-wp', { ns: 'common' }),
          onClick: openCSLink,
          variant: 'contained',
        },
      ]}
      actionsStyle='full'
      closeBtn={true}
      onClose={() => {
        setTriggerNavOnClose(true)
        modalClose()
      }}
    >
      <Trans
        i18nKey={'unavilable-to-process-text'}
        t={t}
        components={{ P: <p /> }}
      />
    </ActionModal>
  )
}

export default SubaccountInvalidModal
