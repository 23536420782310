import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import TwoSideLinks from '@/components/TwoSideLinks'
import { RoutePathEnum } from '@/constants/routePath'

export const UserIDPw = ({ disabled = false }: { disabled?: boolean }) => {
  const { t } = useTranslation(['recoverUserIdPage', 'common'])
  const navigate = useNavigate()

  return (
    <TwoSideLinks
      left={{
        label: t('recover-user-id', { ns: 'recoverUserIdPage' }),
        onClick: () => navigate(RoutePathEnum.RECOVER_USER_ID),
      }}
      right={{
        label: t('forgot-password', { ns: 'common' }),
        onClick: () => navigate(RoutePathEnum.RESET_PASSWORD),
      }}
      disabled={disabled}
      className='mb-10'
    />
  )
}

export default UserIDPw
