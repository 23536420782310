import Loading from '@/components/Loading'
import { RoutePathEnum } from '@/constants/routePath'
import {
  FundInformation,
  ProductIdEnum,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formattedAmount, MainBody, useModal } from '@pimy-b2cweb/frontend-lib'
import ErrorMessageComp from '@/components/ErrorMessageComp'
import FundInfoCard from '@/components/FundInfoCard'
import { useTranslation } from 'react-i18next'
import useInitFundListData from '@/hooks/useInitFundListData'
import RiskProfileTabs from '@/components/RiskProfileTabs'
import TechnicalErrorComp from '@/components/TechnicalErrorComp'
import SwitchFundFooter from '../../components/SwitchFundFooter'
import HighRiskAlertModal from '@/components/HighRiskAlertModal'
import { SwitchingData } from '@/hooks/useSwitchingDataReducer'

export enum FundCategory {
  EPF = 'epf',
  Cash = 'cash',
}

export type FundListByCategory = {
  [key in FundCategory]: FundInformation[]
}
export interface SwitchInProps extends SwitchingData {
  onSubmitSwitching: (selectedFund: FundInformation) => void
}
const SwitchIn = ({ onSubmitSwitching, ...switchingData }: SwitchInProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['investmentPage', 'common', 'switchingPage'])

  const [selectedFund, setSelectedFund] = useState<FundInformation | undefined>(
    undefined
  )

  const highRiskAlertModal = useModal()

  const {
    isLoadingFundList,
    isLoadingPage,
    isFundListQueryError,
    isErrorPage,
    fundListByCategory,
    riskLevelList,
    riskProfileTabs,
    metaData,
    riskProfile,
  } = useInitFundListData()

  const { productId, funds, changeIndex } = switchingData

  const requireEPFFund =
    !!productId &&
    [ProductIdEnum.EPF, ProductIdEnum.EPFCash].includes(productId)

  const requireSyariahFund =
    !!metaData?.epfDetails && !!metaData.epfDetails.epfiStatus

  const category = requireEPFFund ? FundCategory.EPF : FundCategory.Cash

  const switchOutAvailableAmt = funds[changeIndex].from.availableAmount

  const _fundList =
    category === FundCategory.Cash
      ? fundListByCategory[FundCategory.Cash]
      : fundListByCategory[FundCategory.EPF]
  const fundList = _fundList.filter(
    (fund) =>
      (!requireSyariahFund || fund.isSyariahFund) &&
      fund.code !== funds[changeIndex].from.fundCode &&
      fund.isActive
  )

  useEffect(() => {
    if (isErrorPage) {
      navigate(RoutePathEnum.ERROR)
    }
  }, [isErrorPage])

  useEffect(() => {
    riskProfileTabs.handleSetValue(riskProfile)
  }, [riskProfile])

  const onFundCardClick = (fund: FundInformation) => {
    if (selectedFund?.code === fund.code) {
      setSelectedFund(undefined)
      return
    }
    setSelectedFund({ ...fund })
  }

  const onSubmitSwitchIn = () => {
    const fundRisk = selectedFund?.riskLevel || 0
    const userRisk = metaData?.riskProfile?.level || 0
    if (productId === ProductIdEnum.UTCash && fundRisk > userRisk) {
      highRiskAlertModal.modalOpen()
    } else {
      submitSwitchInData()
    }
  }
  const submitSwitchInData = () => {
    console.log('Selected Fund:', selectedFund)
    onSubmitSwitching(selectedFund as FundInformation)
    navigate(RoutePathEnum.ORDER_SWITCHING_FORM, {
      state: { ...switchingData },
    })
  }

  return (
    <>
      <MainBody>
        {isLoadingPage ? (
          <Loading isLoadingPage={true} />
        ) : (
          <>
            <RiskProfileTabs
              {...riskProfileTabs}
              riskLevels={riskLevelList?.levels}
            />
            <h1 className='col-span-full font-bold'>
              {t(`category-${category}`)}
            </h1>
            {isLoadingFundList ? (
              <Loading className='min-h-[calc(100vh-510px)]' />
            ) : isFundListQueryError ? (
              <TechnicalErrorComp />
            ) : !!fundList.length ? (
              <>
                {fundList?.map(
                  ({
                    code,
                    name,
                    fundInfoUrl,
                    details = [],
                    minSubsAmt = 0,
                    ...rest
                  }) => {
                    const fundDetails = [...details]
                    fundDetails.push({
                      index: fundDetails.length + 1,
                      detail: t('min-cash-in-amount', {
                        amount: formattedAmount({
                          amount: minSubsAmt,
                          decimal: 0,
                        }),
                        ns: 'switchingPage',
                      }),
                    })
                    return (
                      <FundInfoCard
                        className={`col-span-full md:col-span-4 lg:col-span-3 outline outline-2 outline-transparent transition-outline duration-300 ${
                          selectedFund?.code === code
                            ? '!outline-pi-principal-blue'
                            : ''
                        }`}
                        key={code}
                        name={name}
                        fundInfoUrl={fundInfoUrl}
                        showSelectButton={false}
                        onCardClick={() =>
                          onFundCardClick({
                            code,
                            name,
                            fundInfoUrl,
                            minSubsAmt,
                            ...rest,
                          })
                        }
                        details={fundDetails}
                        disabled={switchOutAvailableAmt < minSubsAmt}
                        {...rest}
                      />
                    )
                  }
                )}
              </>
            ) : (
              <ErrorMessageComp
                className='col-span-full min-h-[calc(100vh-450px)] sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 lg:col-start-5 lg:col-span-4 flex justify-center items-center gap-2 [&>h1]:!mb-0 [&>h1]:!text-xl [&>h1]:!font-normal [&>h1]:!text-pi-gray-1'
                type={t('oops-no-funds-available')}
                message={t('please-check-back-later')}
                displayIcon={false}
              />
            )}
            <SwitchFundFooter
              className='col-span-full w-full md:relative'
              onClickNext={onSubmitSwitchIn}
              disabled={!selectedFund}
            />
          </>
        )}
        <HighRiskAlertModal
          {...highRiskAlertModal}
          handleHardProceed={submitSwitchInData}
        />
      </MainBody>
    </>
  )
}

export default SwitchIn
