import { useTranslation } from 'react-i18next'
import ErrorMessageComp from '../ErrorMessageComp'

const TechnicalErrorComp = ({ className = '' }: { className?: string }) => {
  const { t } = useTranslation('common')

  return (
    <ErrorMessageComp
      className={`col-span-full min-h-[calc(100vh-510px)] sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 lg:col-start-5 lg:col-span-4 flex justify-center items-center ${className}`}
      type={t('ops-something-went-wrong')}
      message={t('technical-issue-try-again')}
    />
  )
}

export default TechnicalErrorComp
