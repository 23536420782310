import { useTranslation } from 'react-i18next'
import { EmploymentInfoFormProps } from '../../../EmploymentInfo/subpages/EmploymentInfoForm/useInitData'
import InfoContainer from '../InfoContainer'
import InfoField from '../InfoField'

export interface EmploymentInfoProps
  extends Omit<
    EmploymentInfoFormProps,
    'agreeTnc' | 'pepFlag' | 'taxResidentOtherThanMalaysia'
  > {
  pepFlag: boolean
  taxResidentOtherThanMalaysia: boolean
}

const EmploymentInfo = ({
  occupation,
  natureOfBusiness,
  yearlyIncomeLevel,
  employerName,
  sourceOfFund,
  investmentObjectives,
  taxResidentOtherThanMalaysia,
  crsCountries,
}: EmploymentInfoProps) => {
  const { t } = useTranslation(['profileInfoPage', 'common'])
  return (
    <InfoContainer>
      <InfoField fieldName={t('occupation')} value={occupation} />
      <InfoField
        fieldName={t('nature-of-business')}
        value={natureOfBusiness || ''}
      />
      <InfoField fieldName={t('annual-income')} value={yearlyIncomeLevel} />
      <InfoField fieldName={t('employer-name')} value={employerName || ''} />
      <InfoField fieldName={t('source-of-funds')} value={sourceOfFund} />
      <InfoField
        fieldName={t('purpose-of-investment')}
        value={investmentObjectives}
      />
      <InfoField
        fieldName={t('are-you-tax-resident-other-than-malaysia')}
        value={t(taxResidentOtherThanMalaysia ? 'Yes' : 'No', { ns: 'common' })}
      />
      {taxResidentOtherThanMalaysia &&
        !!crsCountries?.at(0) &&
        !!crsCountries?.at(0)?.country &&
        !!crsCountries?.at(0)?.tin && (
          <>
            <InfoField
              fieldName={t('tax-country')}
              value={crsCountries?.at(0)?.country || ''}
            />
            <InfoField
              fieldName={t('tax-id-number')}
              value={crsCountries?.at(0)?.tin || ''}
            />
          </>
        )}
    </InfoContainer>
  )
}

export default EmploymentInfo
