import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  ButtonWithLoading,
  FormLabel,
  ResponseError,
  useResponseError,
  PasswordTextField,
  usePasswordText,
} from '@pimy-b2cweb/frontend-lib'
import { useMutationChangePw } from '@/api/authApi'
import { INVALID_PASSWORD, PASSWORD_NOT_MATCH } from '@/constants/errorCodes'
import { getErrorResponseCode } from '@/utils'
import { validatePassword } from '@pimy-b2cweb/common'

interface NewPasswordProps {
  goToSuccess: () => void
}

interface NewPasswordFormProps {
  currentPassword: string
  newPassword: string
  rePassword: string
}

export const NewPassword = ({ goToSuccess }: NewPasswordProps) => {
  const { t } = useTranslation(['profileInfoPage', 'common'])

  const { control, handleSubmit } = useForm<NewPasswordFormProps>({
    mode: 'onChange',
    defaultValues: {},
  })

  const {
    mutate,
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
  } = useMutationChangePw()

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const onSubmit = async (data: NewPasswordFormProps) => {
    console.log(data)
    mutate({
      recaptchaToken: '',
      ...data,
    })
  }
  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)
    if (mutateStatus === 'success' && !!mutatedData) {
      console.log('mutatedData: ', mutatedData)
      goToSuccess()
    }
  }, [mutatedData, mutateStatus, mutatedError])

  const usePasswordTextReturns = usePasswordText()

  return (
    <>
      <h1>{t('set-up-new-password')}</h1>
      <p>{t('password-page-text', { ns: 'common' })}</p>
      {!!responseErrorAttrs && (
        <ResponseError>
          {t(responseErrorAttrs.i18nKey, {
            ns: responseErrorAttrs.ns,
          })}
        </ResponseError>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='currentPassword'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='currentPassword'
              label={t('existing-password')}
              isError={!!error}
              className='!mb-4'
            >
              <PasswordTextField
                fullWidth
                {...field}
                error={!!error}
                helperText={!!error ? (error?.message as string) : ''}
                disabled={mutateIsLoading}
                placeholder='112233'
                {...usePasswordTextReturns}
              />
            </FormLabel>
          )}
        />
        <Controller
          name='newPassword'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
            validate: (val: string) => {
              const issues = validatePassword(val)
              return issues.length === 0 || t(INVALID_PASSWORD)
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='newPassword'
              label={t('new-password')}
              isError={!!error}
              className='!mb-4'
            >
              <PasswordTextField
                fullWidth
                {...field}
                error={!!error}
                helperText={
                  !!error
                    ? (error?.message as string)
                    : t('password-helper-text', { ns: 'common' })
                }
                disabled={mutateIsLoading}
                placeholder='112233'
                {...usePasswordTextReturns}
              />
            </FormLabel>
          )}
        />
        <Controller
          name='rePassword'
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
            validate: (value: string, formValues: { newPassword: string }) =>
              value === formValues.newPassword || t(PASSWORD_NOT_MATCH),
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='rePassword'
              label={t('confirm-new-password')}
              isError={!!error}
              className='!mb-10'
            >
              <PasswordTextField
                fullWidth
                {...field}
                error={!!error}
                helperText={!!error ? (error?.message as string) : ''}
                disabled={mutateIsLoading}
                placeholder='112233'
                {...usePasswordTextReturns}
              />
            </FormLabel>
          )}
        />
        <ButtonWithLoading
          type='submit'
          fullWidth
          variant='contained'
          size='large'
          disabled={mutateIsLoading}
          isLoading={mutateIsLoading}
        >
          {t('Continue', { ns: 'common' })}
        </ButtonWithLoading>
      </form>
    </>
  )
}

export default NewPassword
