import {
  ButtonWithLoading,
  FormLabel,
  PasswordTextField,
  usePasswordText,
  ResponseError,
  useResponseError,
} from '@pimy-b2cweb/frontend-lib'
import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { UseMutationOktaAuth } from '@/api/oktaAuth'
import BottomAction from '@/components/BottomAction'
import UserIDPw from '@/components/UserIDPw'
import useSignin from '@/hooks/useSignin'
import { selectAuthUsername } from '@/stores/auth.selectors'
import { REQUIRE_RESET_PASSWORD } from '@/constants/errorCodes'
import { getErrorResponseCode } from '@/utils'

export interface PasswordFormProps
  extends Pick<UseMutationOktaAuth, 'password'> {}

export const Password = ({
  pwExpireModalOpen,
}: {
  pwExpireModalOpen: () => void
}) => {
  const username = useSelector(selectAuthUsername)
  const {
    isLoading: mutateIsLoading,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
    handleSignIn,
    handleDispatchSignIn,
  } = useSignin()

  const { t } = useTranslation(['signinPage', 'common'])
  const usePasswordTextReturns = usePasswordText()

  const { control, handleSubmit } = useForm<PasswordFormProps>({
    mode: 'onChange',
    defaultValues: {},
  })

  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const onSubmit = async (data: PasswordFormProps) => {
    console.log(data)
    setResponseErrorAttrs(undefined)
    handleSignIn()
  }

  useEffect(() => {
    if (mutateStatus === 'error') {
      const errMsg = getErrorResponseCode(mutatedError)
      setResponseErrorAttrs({ i18nKey: errMsg })

      if (errMsg === REQUIRE_RESET_PASSWORD) pwExpireModalOpen()
      return
    }

    if (mutateStatus === 'success' && !!mutatedData) {
      setResponseErrorAttrs(undefined)
      handleDispatchSignIn({})
    }
  }, [mutatedData, mutateStatus, mutatedError])

  return (
    <>
      <h1>{t('hi-username', { username })}</h1>
      <p className='mb-6'>{t('is-this-your-security-image')}</p>
      {!!responseErrorAttrs && (
        <ResponseError>
          {t(responseErrorAttrs.i18nKey, {
            ns: responseErrorAttrs.ns,
          })}
        </ResponseError>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='password'
          control={control}
          defaultValue=''
          rules={{
            required: {
              value: true,
              message: 'Required',
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <FormLabel
              id='idNo'
              label={t('Password', { ns: 'common' })}
              isError={!!error}
            >
              <PasswordTextField
                fullWidth
                {...field}
                error={!!error}
                helperText={!!error ? (error?.message as string) : ''}
                disabled={mutateIsLoading}
                {...usePasswordTextReturns}
                autoComplete='off'
              />
            </FormLabel>
          )}
        />
        <UserIDPw disabled={mutateIsLoading} />
        <ButtonWithLoading
          type='submit'
          fullWidth
          variant='contained'
          size='large'
          className='mb-10 sm:mb-2'
          disabled={mutateIsLoading}
          isLoading={mutateIsLoading}
        >
          {t('Login', { ns: 'common' })}
        </ButtonWithLoading>
      </form>
      <BottomAction type='signup' disabled={mutateIsLoading} />
    </>
  )
}

export default Password
