import { ReactComponent as InfoIco } from '@/assets/info.svg'
import { CSLink } from '@pimy-b2cweb/frontend-lib'
import { useTranslation } from 'react-i18next'

export interface FundInfoLinkProps {
  url: string
  disabled?: boolean
}

const FundInfoLink = ({ url, disabled = false }: FundInfoLinkProps) => {
  const { t } = useTranslation('investmentPage')

  return (
    <CSLink url={url} disabled={disabled}>
      <div className='flex flex-row items-center gap-2'>
        <InfoIco className={!!disabled ? '[&>*>*]:stroke-pi-gray-3' : ''} />
        {t('fund-info')}
      </div>
    </CSLink>
  )
}

export default FundInfoLink
