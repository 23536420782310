import { TextField } from '@mui/material'
import { FormErrorMessage, FormLabel } from '@pimy-b2cweb/frontend-lib'
import { forwardRef, memo } from 'react'
import { ControllerRenderProps, ControllerFieldState } from 'react-hook-form'

export const AddressFieldComp = memo(
  forwardRef<
    HTMLDivElement,
    {
      addressLineNum: number
      label: string
      rootId: string
      idPrefix?: string
      error: ControllerFieldState['error']
      onChange: ControllerRenderProps['onChange']
      value: ControllerRenderProps['value']
      disabled: boolean
      className?: string
    }
  >(
    (
      {
        addressLineNum,
        onChange,
        error,
        value,
        disabled,
        label,
        rootId,
        idPrefix = 'addressLine',
        className = '',
      },
      ref
    ) => {
      const lineNum = addressLineNum <= 1 ? 1 : addressLineNum
      const fieldNames = Array.from({ length: lineNum }, (_, i) => i + 1).map(
        (keyNum) => `${idPrefix}${keyNum}`
      )

      return (
        <FormLabel
          id={rootId}
          label={label}
          isError={!!error?.message}
          className={className}
          ref={ref}
        >
          {fieldNames.map((fieldName, idx) => (
            <TextField
              key={`${rootId}.${fieldName}`}
              type='text'
              id={`${rootId}.${fieldName}`}
              name={fieldName}
              onChange={(e) => {
                onChange({
                  ...value,
                  [fieldName]: e.target.value,
                })
              }}
              value={value[fieldName]}
              fullWidth
              error={fieldName === fieldNames[0] && !!error?.message}
              disabled={disabled}
              className={idx < fieldNames.length - 1 ? '!mb-1' : ''}
            />
          ))}
          {!!error?.message && (
            <FormErrorMessage
              error={{
                type: 'validation-error',
                message: error?.message,
              }}
            />
          )}
        </FormLabel>
      )
    }
  )
)

export default AddressFieldComp
