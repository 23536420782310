import { useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import Loading from '@/components/Loading'

const OktaSignInWidget = () => {
  const { authState, oktaAuth } = useOktaAuth()

  useEffect(() => {
    console.log('authState: ', authState)
    if (!authState?.isAuthenticated) {
      oktaAuth.signInWithRedirect()
    }
  }, [authState, oktaAuth])

  return <Loading isLoadingPage={true} />
}

export default OktaSignInWidget
