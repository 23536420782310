import { Tokens as OktaTokens } from '@okta/okta-auth-js'
import { OtpNotificationTypeEnum } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UnauthedStatusEnum, AuthedStatusEnum } from '@/config'
import { EcddStatusEnum } from '@/enums/ecddEnums'
import { IsafStatusEnum } from '@/enums/isafEnums'
import { convertUserStatus } from '@/utils/userStatus/'

type ChallengeId = string
interface NewChallengeId {
  newChallengeId: ChallengeId
}
export interface AuthSessionState {
  status: UnauthedStatusEnum | AuthedStatusEnum
  isafStatus?: IsafStatusEnum
  ecddStatus?: EcddStatusEnum
  isValidUserStatus?: boolean
  pendingReloadCI: boolean
  username?: string
  secureImageId?: string
  secureWord?: string
  authToken?: string
  authExpiresAt?: number //TimeStamp in ms
  recaptchaToken?: string
  oktaTokens?: OktaTokens
  isUserIdle?: boolean
  challengeId?: ChallengeId
  canResend?: boolean
  notificationType?: OtpNotificationTypeEnum
  notificationRecipient?: string
  sessionToken?: string
  expiriesIn?: number
  referralCode?: string
}

const initialState: AuthSessionState = {
  status: UnauthedStatusEnum.Unknown,
  isafStatus: undefined,
  ecddStatus: undefined,
  isValidUserStatus: undefined,
  username: undefined,
  pendingReloadCI: false,
  secureImageId: undefined,
  secureWord: undefined,
  authToken: undefined,
  authExpiresAt: undefined,
  recaptchaToken: undefined,
  oktaTokens: undefined,
  isUserIdle: undefined,
  challengeId: undefined,
  canResend: undefined,
  notificationType: undefined,
  notificationRecipient: undefined,
  sessionToken: undefined,
  expiriesIn: undefined,
  referralCode: undefined,
}

export const authSessionSlice = createSlice({
  name: 'authSession',
  initialState,
  reducers: {
    sessionReset: (state) => {
      state.status = initialState.status
      state.isafStatus = initialState.isafStatus
      state.ecddStatus = initialState.ecddStatus
      state.isValidUserStatus = initialState.isValidUserStatus
      state.pendingReloadCI = initialState.pendingReloadCI
      state.username = initialState.username
      state.secureImageId = initialState.secureImageId
      state.secureWord = initialState.secureWord
      state.authToken = initialState.authToken
      state.recaptchaToken = initialState.recaptchaToken
      state.oktaTokens = initialState.oktaTokens
      state.authExpiresAt = initialState.authExpiresAt
      state.isUserIdle = initialState.isUserIdle
      state.challengeId = initialState.challengeId
      state.canResend = initialState.canResend
      state.notificationType = initialState.notificationType
      state.notificationRecipient = initialState.notificationRecipient
      state.sessionToken = initialState.sessionToken
      state.expiriesIn = initialState.expiriesIn
      state.referralCode = initialState.referralCode
    },

    // sign-in --
    sessionSignInForceResetPassword: (
      state,
      action: PayloadAction<Pick<AuthSessionState, 'username'>>
    ) => {
      state.username = action.payload.username
    },
    sessionSignInPendingPassword: (
      state,
      action: PayloadAction<
        Pick<
          AuthSessionState,
          'username' | 'secureImageId' | 'secureWord' | 'recaptchaToken'
        >
      >
    ) => {
      state.status = UnauthedStatusEnum.SignInPendingPassword
      state.username = action.payload.username
      state.secureImageId = action.payload.secureImageId
      state.secureWord = action.payload.secureWord
      state.authToken = action.payload.recaptchaToken
      state.recaptchaToken = action.payload.recaptchaToken
    },
    // [end] sign-in --

    // sign-up --
    sessionSignUpPendingEmail: (
      state,
      action: PayloadAction<
        Pick<AuthSessionState, 'sessionToken' | 'expiriesIn'>
      >
    ) => {
      state.status = UnauthedStatusEnum.SignUpPendingEmail
      state.sessionToken = action.payload.sessionToken
      state.expiriesIn = action.payload.expiriesIn
      state.authExpiresAt = convertAuthExpiresAt(action.payload.expiriesIn)
    },
    sessionSignUpPendingPassword: (
      state,
      action: PayloadAction<
        Pick<AuthSessionState, 'username' | 'secureImageId' | 'secureWord'>
      >
    ) => {
      state.status = UnauthedStatusEnum.SignUpPendingPassword
      state.username = action.payload.username
      state.secureImageId = action.payload.secureImageId
      state.secureWord = action.payload.secureWord
    },
    sessionSignUpPendingOtp: (
      state,
      action: PayloadAction<
        Partial<
          Pick<
            AuthSessionState,
            'sessionToken' | 'notificationRecipient' | 'canResend'
          > &
            NewChallengeId
        >
      >
    ) => {
      state.status = UnauthedStatusEnum.SignUpPendingOtp
      state.sessionToken = action.payload.sessionToken
      state.notificationRecipient = action.payload.notificationRecipient
      state.challengeId = action.payload.newChallengeId
      state.canResend = action.payload.canResend
    },
    sessionSignUpPendingUsername: (
      state,
      action: PayloadAction<
        Pick<AuthSessionState, 'sessionToken' | 'expiriesIn'>
      >
    ) => {
      state.status = UnauthedStatusEnum.SignUpPendingUsername
      state.sessionToken = action.payload.sessionToken
      state.expiriesIn = action.payload.expiriesIn
      state.authExpiresAt = convertAuthExpiresAt(action.payload.expiriesIn)
    },
    sessionSignUpSucceed: (state) => {
      state.status = UnauthedStatusEnum.SignUpSucceed
      state.isafStatus = initialState.isafStatus
      state.ecddStatus = initialState.ecddStatus
      state.isValidUserStatus = initialState.isValidUserStatus
    },
    // [end] sign-up --

    // reset-pw --
    sessionResetPwPendingOtp: (
      state,
      action: PayloadAction<
        Pick<
          AuthSessionState,
          | 'notificationType'
          | 'notificationRecipient'
          | 'canResend'
          | 'sessionToken'
          | 'expiriesIn'
        > &
          NewChallengeId
      >
    ) => {
      state.status = UnauthedStatusEnum.ResetPwPendingOtp
      state.notificationType = action.payload.notificationType
      state.notificationRecipient = action.payload.notificationRecipient
      state.challengeId = action.payload.newChallengeId
      state.canResend = action.payload.canResend
      state.sessionToken = action.payload.sessionToken
      state.expiriesIn = action.payload.expiriesIn
    },
    sessionResetPwPendingPassword: (
      state,
      action: PayloadAction<Pick<AuthSessionState, 'sessionToken'>>
    ) => {
      state.status = UnauthedStatusEnum.ResetPwPendingPassword
      state.sessionToken = action.payload.sessionToken
    },
    sessionResetPwSucceed: (state) => {
      state.status = UnauthedStatusEnum.ResetPwSucceed
      state.isafStatus = initialState.isafStatus
      state.ecddStatus = initialState.ecddStatus
      state.isValidUserStatus = initialState.isValidUserStatus
    },
    // [end] reset-pw --

    sessionRenewChallengeId: (
      state,
      action: PayloadAction<
        Pick<AuthSessionState, 'canResend'> & NewChallengeId
      >
    ) => {
      state.challengeId = action.payload.newChallengeId
      state.canResend = action.payload.canResend
    },

    // verify otp
    sessionVerifyOtp: (
      state,
      action: PayloadAction<
        Pick<
          AuthSessionState,
          | 'notificationType'
          | 'notificationRecipient'
          | 'canResend'
          | 'sessionToken'
          | 'expiriesIn'
        > &
          NewChallengeId
      >
    ) => {
      state.notificationType = action.payload.notificationType
      state.notificationRecipient = action.payload.notificationRecipient
      state.challengeId = action.payload.newChallengeId
      state.canResend = action.payload.canResend
      state.sessionToken = action.payload.sessionToken
      state.expiriesIn = action.payload.expiriesIn
    },
    sessionResetVerifyOtp: (state) => {
      state.notificationType = initialState.notificationType
      state.notificationRecipient = initialState.notificationRecipient
      state.challengeId = initialState.challengeId
      state.canResend = initialState.canResend
      state.sessionToken = initialState.sessionToken
      state.expiriesIn = initialState.expiriesIn
    },
    // [end] verify otp

    sessionSignIn: (
      state,
      action: PayloadAction<{
        status?: AuthedStatusEnum
        oktaTokens: OktaTokens
      }>
    ) => {
      const status = action.payload.status || AuthedStatusEnum.SigningIn
      const { isafStatus, ecddStatus, isValidUserStatus } =
        convertUserStatus(status)
      const { authToken, authExpiresAt } = convertedAuthToken(
        action.payload.oktaTokens
      )

      state.status = status
      state.isafStatus = isafStatus
      state.ecddStatus = ecddStatus
      state.isValidUserStatus = isValidUserStatus
      state.oktaTokens = action.payload.oktaTokens
      state.authToken = authToken
      state.authExpiresAt = authExpiresAt
      state.isUserIdle = true

      state.recaptchaToken = initialState.recaptchaToken
      state.username = initialState.username
      state.secureImageId = initialState.secureImageId
      state.secureWord = initialState.secureWord
      state.notificationRecipient = initialState.notificationRecipient
      state.challengeId = initialState.challengeId
      state.canResend = initialState.canResend
    },

    sessionAuthedStatus: (
      state,
      action: PayloadAction<{
        status: AuthedStatusEnum
        authStatus?: AuthedStatusEnum
      }>
    ) => {
      const { isafStatus, ecddStatus, isValidUserStatus } = convertUserStatus(
        action.payload.status
      )
      state.status = action.payload.authStatus || action.payload.status
      state.isafStatus = isafStatus
      state.ecddStatus = ecddStatus
      state.isValidUserStatus = isValidUserStatus
    },

    sessionReferralCode: (
      state,
      action: PayloadAction<Pick<AuthSessionState, 'referralCode'>>
    ) => {
      state.referralCode = action.payload.referralCode
    },

    //session timeout handleing --
    sessionSetIsUserNotIdle: (state) => {
      state.isUserIdle = false
    },
    sessionRenewAuthToken: (
      state,
      action: PayloadAction<{
        oktaTokens: OktaTokens
      }>
    ) => {
      const { authToken, authExpiresAt } = convertedAuthToken(
        action.payload.oktaTokens
      )

      state.oktaTokens = action.payload.oktaTokens
      state.authToken = authToken
      state.authExpiresAt = authExpiresAt
      state.isUserIdle = true
    },
    //[end] session timeout handleing --

    // session recover ID handling
    sessionRecoverUserIdSucceed: (
      state,
      action: PayloadAction<
        Pick<AuthSessionState, 'notificationRecipient' | 'notificationType'>
      >
    ) => {
      state.status = UnauthedStatusEnum.RecoverUserIdSucceed
      state.isafStatus = initialState.isafStatus
      state.ecddStatus = initialState.ecddStatus
      state.isValidUserStatus = initialState.isValidUserStatus
      state.notificationRecipient = action.payload.notificationRecipient
      state.notificationType = action.payload.notificationType
    },
    // [end] session recover ID handling

    sessionSigningOut: (state) => {
      state.status = AuthedStatusEnum.SigningOut
      state.isafStatus = initialState.isafStatus
      state.ecddStatus = initialState.ecddStatus
      state.isValidUserStatus = initialState.isValidUserStatus
    },

    sessionSetPendingReloadCI: (
      state,
      action: PayloadAction<Pick<AuthSessionState, 'pendingReloadCI'>>
    ) => {
      state.pendingReloadCI = action.payload.pendingReloadCI
    },
  },
})

const convertedAuthToken = (oktaTokens: AuthSessionState['oktaTokens']) => {
  const authToken = oktaTokens?.accessToken?.accessToken
  const _expiresAt = !!authToken && oktaTokens?.accessToken?.expiresAt

  return {
    authToken,
    authExpiresAt: !!_expiresAt ? _expiresAt * 1000 : undefined,
  }
}

const convertAuthExpiresAt = (expiriesIn?: number) => {
  return !!expiriesIn ? expiriesIn * 1000 : undefined
}

// action export
export const {
  sessionReset,

  sessionSignInForceResetPassword,
  sessionSignInPendingPassword,

  sessionSignUpPendingEmail,
  sessionSignUpPendingPassword,
  sessionSignUpPendingOtp,
  sessionSignUpPendingUsername,
  sessionSignUpSucceed,

  sessionRecoverUserIdSucceed,

  sessionResetPwPendingOtp,
  sessionResetPwPendingPassword,
  sessionResetPwSucceed,

  sessionRenewChallengeId,

  sessionVerifyOtp,
  sessionResetVerifyOtp,

  sessionSignIn,
  sessionAuthedStatus,
  sessionReferralCode,

  sessionSetIsUserNotIdle,
  sessionRenewAuthToken,

  sessionSigningOut,

  sessionSetPendingReloadCI,
} = authSessionSlice.actions

export default authSessionSlice.reducer
