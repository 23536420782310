import { LEGAL_LINKS } from '@/config'
import { Trans, useTranslation } from 'react-i18next'
import ExternalLink from '@/components/ExternalLink'
import {
  CSLink,
  CheckboxGroupComp,
  CheckboxGroupCompProps,
} from '@pimy-b2cweb/frontend-lib'

const { TermsAndConditions, TransactionNotice, PrivacyNotice } = LEGAL_LINKS

export enum TncNoticeVariantEnum {
  Transition = 'transaction',
  Privacy = 'privacy',
}

export interface TermsAndConditionsCompProps
  extends Omit<CheckboxGroupCompProps, 'ref' | 'items'> {
  variant: TncNoticeVariantEnum
  disabled?: boolean
}

export const TermsAndConditionsComp = ({
  className = '',
  variant,
  disabled = false,
  ...rest
}: TermsAndConditionsCompProps) => {
  const { t } = useTranslation('common')

  return (
    <CheckboxGroupComp
      className={`mb-10 ${className}`}
      items={[
        {
          label: (
            <>
              <p className='mb-2 text-xs'>
                <Trans
                  i18nKey={
                    variant === TncNoticeVariantEnum.Transition
                      ? 'i-have-read-tnc-loan-financing'
                      : 'i-have-read-tnc'
                  }
                  t={t}
                  components={{
                    A1: <ExternalLink href={TermsAndConditions.href} />,
                    A2: (
                      <ExternalLink
                        href={
                          variant === TncNoticeVariantEnum.Transition
                            ? TransactionNotice.href
                            : PrivacyNotice.href
                        }
                      />
                    ),
                  }}
                />
              </p>
              <p className='mb-0 text-xs text-pi-gray-2'>
                {variant === TncNoticeVariantEnum.Transition ? (
                  t('i-acknowledge-inv-risk')
                ) : (
                  <Trans
                    i18nKey='any-info-contact-cs'
                    t={t}
                    components={{
                      CSLink: <CSLink />,
                    }}
                  />
                )}
              </p>
            </>
          ),
          value: 'tncAgreeded',
          key: 'agreeded',
          disabled,
        },
      ]}
      {...rest}
    />
  )
}

export default TermsAndConditionsComp
