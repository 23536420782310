import ErrorMessageComp from '@/components/ErrorMessageComp'
import FundCard from '@/components/FundCard'
import InvestmentCategoryTabs from '@/components/InvestmentCategoryTabs'
import Loading from '@/components/Loading'
import { RoutePathEnum } from '@/constants/routePath'
import useHandleUserStatus from '@/hooks/useHandleUserStatus'
import { PortfolioFund } from '@/utils/portfolioFund'
import { MainBody, useModal } from '@pimy-b2cweb/frontend-lib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import SwitchFundFooter from '../../components/SwitchFundFooter'
import { useMutationQuerySwitchingOrderInstructionData } from '@/api/orderApi'
import { SwitchingOrderInstructionQueryResDto } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useInitData } from './hooks/useInitData'
import { SwitchingData } from '@/hooks/useSwitchingDataReducer'
import SubaccountInvalidModal, {
  ActionTypeEnum,
} from '@/components/SubaccountInvalidModal'

import { useIsInViewport } from '@/hooks/InfintiFeedLoad/useIsInViewport'
import InviewportChecker from '@/components/InfintiFeedLoad/InviewportChecker'
import { scrollTop } from '@/utils'

export interface SwitchOutProps extends SwitchingData {
  goToSwitchIn: (instructionData: SwitchingOrderInstructionQueryResDto) => void
}
export const SwitchOut = ({
  goToSwitchIn,
  ...switchingData
}: SwitchOutProps) => {
  const { isInViewport, setRef } = useIsInViewport<HTMLDivElement>()
  const { t } = useTranslation(['switchingPage', 'investmentPage'])

  const { funds, productId, changeIndex } = switchingData

  const { isLoading, feeds, tabs, isError } = useInitData({
    productId,
  })
  const [selectedFund, setSelectedFund] = useState<PortfolioFund | undefined>(
    undefined
  )
  const navigate = useNavigate()
  const { isValidUserStatus, handleCheckUserStatus } = useHandleUserStatus()

  const subaccountInvalidModal = useModal()

  const {
    mutate,
    status: mutateStatus,
    isLoading: mutateIsLoading,
    isError: mutateIsError,
    data: mutatedData,
  } = useMutationQuerySwitchingOrderInstructionData()

  useEffect(() => {
    if (isError || mutateIsError) navigate(RoutePathEnum.ERROR)
  }, [isError, mutateIsError])

  useEffect(() => {
    scrollTop()
  }, [tabs.tabType])

  useEffect(() => {
    console.log('isInViewport: ', isInViewport)
    if (isInViewport) feeds.handleShowMore()
  }, [isInViewport])

  const onSwitchOut = () => {
    /* ISAF/ECDD Check */
    handleCheckUserStatus({})
    if (!isValidUserStatus || !selectedFund) return

    const fromFunds = funds.map((fund) => fund.from)
    const selectedFundCode = selectedFund.fundCode
    const mutatingData = { ...switchingData, funds: fromFunds }
    if (!!productId && fromFunds.length > 0) {
      fromFunds[changeIndex].fundCode = selectedFundCode
      mutatingData.funds = [...fromFunds]
    } else {
      mutatingData.subaccountNo = selectedFund.accountNo
      mutatingData.productId = selectedFund.productId
      mutatingData.funds = [
        {
          fundCode: selectedFund.fundCode,
          amount: 0,
          availableAmount: 0,
          maxAmount: 0,
          minAmount: 0,
        },
      ]
    }

    mutate({
      ...mutatingData,
    })
  }

  useEffect(() => {
    if (mutateStatus === 'success' && !!mutatedData) {
      console.log(mutatedData)
      goToSwitchIn({ ...mutatedData })
    }
  }, [mutateStatus, mutatedData])

  const onFundCardClick = (fund: PortfolioFund) => {
    if (selectedFund?.code === fund.code) {
      setSelectedFund(undefined)
      return
    }
    setSelectedFund({ ...fund })
  }

  return (
    <>
      <MainBody>
        <h3 className='col-span-full text-xl font-bold leading-7 mb-0'>
          {t('please-select-fund-to-switch-from')}
        </h3>

        <InvestmentCategoryTabs {...tabs} />

        {isLoading ? (
          <Loading isLoadingPage />
        ) : (
          <>
            <div className='col-span-full'>
              <div className='flex flex-col gap-4'>
                {!!feeds.displayFeeds.length ? (
                  feeds.displayFeeds.map((fund) => {
                    return (
                      <FundCard
                        key={fund.code}
                        {...fund}
                        className='h-full'
                        onCardClick={() => onFundCardClick(fund)}
                        isSelected={selectedFund?.code === fund.code}
                      />
                    )
                  })
                ) : (
                  <ErrorMessageComp
                    className='col-span-full min-h-[calc(100vh-450px)] sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 lg:col-start-5 lg:col-span-4 flex justify-center items-center gap-2 [&>h1]:!mb-0 [&>h1]:!text-xl [&>h1]:!font-normal [&>h1]:!text-pi-gray-1'
                    type={t('oops-no-funds-available', {
                      ns: 'investmentPage',
                    })}
                    message={t('please-check-back-later', {
                      ns: 'investmentPage',
                    })}
                    displayIcon={false}
                  />
                )}
              </div>
            </div>
            <InviewportChecker ref={setRef} />
            <SwitchFundFooter
              className='col-span-full w-full'
              onClickNext={onSwitchOut}
              disabled={!selectedFund || mutateIsLoading}
            />
          </>
        )}
      </MainBody>
      <SubaccountInvalidModal
        {...subaccountInvalidModal}
        action={ActionTypeEnum.SWITCH}
      />
    </>
  )
}

export default SwitchOut
