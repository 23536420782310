import { useState } from 'react'

export interface UseTabs {
  value: number
  handleSetValue: (newValue: number) => void
}
const useTabs = (defaultValue = 0): UseTabs => {
  const [value, setValue] = useState(defaultValue)

  const handleSetValue = (newValue: number) => {
    setValue(newValue)
  }

  return { value, handleSetValue }
}

export default useTabs
