import { configureStore } from '@reduxjs/toolkit'
import authSessionReducer from './auth'
import epfTokenSessionReducer from './epfToken'
import ekycSessionReducer from './ekyc'
import isafSessionReducer from './isaf'
import lovSessionReducer from './lov'
import profileEditSessionReducer from './profileEdit'
import userStatusModalSessionReducer from './userStatusModal'

export const store = configureStore({
  reducer: {
    authSession: authSessionReducer,
    epfTokenSession: epfTokenSessionReducer,
    ekycSession: ekycSessionReducer,
    isafSession: isafSessionReducer,
    lovSession: lovSessionReducer,
    profileEditSession: profileEditSessionReducer,
    userStatusModalSession: userStatusModalSessionReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
