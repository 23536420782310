import { useDispatch } from 'react-redux'
import { sessionSigningOut } from '@/stores/auth'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'

/**
 * 1) set authStatus to SigningOut
 * 2) route Root page, then:
 *   - '@/Routes/hoc/RequireAuth' will sign-out the user by watching authStatus
 */
export const useHandleSignOut = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return () => {
    dispatch(sessionSigningOut())
    navigate(RoutePathEnum.ROOT)
  }
}

export default useHandleSignOut
