import { useNavigate } from 'react-router-dom'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { oidc } from '@/config'
import { RoutePathEnum } from '@/constants/routePath'

const oktaAuth = new OktaAuth(oidc)

export const useOkta = () => {
  const navigate = useNavigate()
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), {
      replace: true,
    })
  }
  const onAuthRequired = async () => navigate(RoutePathEnum.SIGN_IN)

  return {
    oktaAuth,
    restoreOriginalUri,
    onAuthRequired,
  }
}

export default useOkta
