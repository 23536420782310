import { SwitchingData, SwitchingFunds } from '@/hooks/useSwitchingDataReducer'
import {
  ButtonWithLoading,
  DEF_DECIMAL,
  ResponseError,
  ResponseErrorProps,
  useModal,
} from '@pimy-b2cweb/frontend-lib'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SwitchingOrders, {
  SwitchingOrder,
  SwitchingOrdersErrTypeEnum,
  SwitchingOrdersItemErr,
  SwitchingOrdersProps,
} from './components/SwitchingOrders'
import Loading from '@/components/Loading'
import { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'
import { useQueryFundNameMapping } from '@/hooks/useQueryFundNameMapping'
import { Divider } from '@mui/material'
import { AgentCode } from '@/hooks/useInvestmentReducer'
import AgentCodeComp, {
  AgentCodeRef,
} from '@/Routes/pages/CashIn/subpages/CashInAmount/components/AgentCodeComp'
import InsufficientBalanceModal, {
  TrxTypeEnum,
} from '@/components/InsufficientBalanceModal'
import { insufficientBalanceCheck } from '@/utils'

export interface SwitchingFormProps {
  orders: SwitchingOrder[]
  agentCode?: AgentCode
}

export interface SwitchAmountProps
  extends SwitchingData,
    Pick<SwitchingOrdersProps, 'onClickSwitchOut'> {
  showAgentCode?: boolean
  onSubmitOrder: (switchingOrder: SwitchingFormProps) => void
  orderFormError?: ResponseErrorProps
  onSwitchIn: (changeIndex: number, orderState: SwitchingFunds[]) => void
}

const checkInsufficientBalance = (orderFunds: SwitchingOrder[]) => {
  return orderFunds.some(
    ({ from: { availableAmount, minAmount }, to: { amount } }) =>
      insufficientBalanceCheck({ amount, availableAmount, minAmount })
  )
}

export const SwitchAmount = ({
  funds,
  agentCode,
  orderFormError,
  showAgentCode = true,
  onSubmitOrder,
  onClickSwitchOut,
  onSwitchIn,
}: SwitchAmountProps) => {
  const { t } = useTranslation(['switchingPage'])

  const agentCodeRef = useRef<AgentCodeRef>(null)

  const insufficientBalanceModal = useModal()

  const navigate = useNavigate()

  const {
    fundNamesMapping,
    status: queryStatus,
    isLoading: isLoadingFL,
    isError: isErrorFL,
  } = useQueryFundNameMapping()

  const { control, handleSubmit, watch, setValue, getValues } =
    useForm<SwitchingFormProps>({
      defaultValues: {
        orders: [],
        agentCode: undefined,
      },
    })

  useEffect(() => setValue('agentCode', agentCode), [agentCode])

  useEffect(() => {
    if (queryStatus === 'success' && !!fundNamesMapping) {
      const _orders = funds.map(({ from, to }) => {
        return {
          from: {
            ...from,
            name: Object.prototype.hasOwnProperty.call(
              fundNamesMapping,
              from.fundCode
            )
              ? fundNamesMapping[from.fundCode]
              : '',
          },
          to: {
            ...to,
            name: Object.prototype.hasOwnProperty.call(
              fundNamesMapping,
              to.fundCode
            )
              ? fundNamesMapping[to.fundCode]
              : '',
          },
        }
      })
      setValue('orders', _orders)
    }
  }, [queryStatus, fundNamesMapping, funds])

  useEffect(() => {
    if (isErrorFL) {
      navigate(RoutePathEnum.ERROR)
    }
  }, [isErrorFL])

  const onContinue = useCallback(() => {
    const _orders = watch('orders').map((order) =>
      insufficientBalanceCheck({
        amount: order.from.amount,
        availableAmount: order.from.availableAmount,
        minAmount: order.to.minAmount,
      })
        ? {
            ...order,
            from: {
              ...order.from,
              amount: order.from.availableAmount,
            },
            to: {
              ...order.to,
              amount: order.from.availableAmount,
            },
          }
        : order
    )
    setValue('orders', _orders)
  }, [watch('orders')])

  const onSubmit = (data: SwitchingFormProps) => {
    const _hasInsufficientBalance = checkInsufficientBalance(data.orders)
    if (_hasInsufficientBalance) {
      insufficientBalanceModal.modalOpen()
      return
    }
    onSubmitOrder(data)
  }

  const onClickSwitchIn = (changeIndex: number) => {
    const orderState = getValues('orders')
    onSwitchIn(changeIndex, orderState)
  }

  return isLoadingFL ? (
    <Loading isLoadingPage />
  ) : (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1 className='font-bold mb-6'>{t('switching')}</h1>
        {!!orderFormError && (
          <ResponseError>
            {t(orderFormError.i18nKey, {
              ns: orderFormError.ns,
            })}
          </ResponseError>
        )}

        <Controller
          name='orders'
          control={control}
          rules={{
            validate: (value) => {
              const _err = value.reduce(
                (
                  acc,
                  {
                    from: {
                      fundCode: fromFundCode,
                      availableAmount,
                      maxAmount: fromMaxAmount,
                    },
                    to: {
                      fundCode: toFundCode,
                      amount,
                      minAmount: toMinAmt,
                      maxAmount: toMaxAmount,
                    },
                  }
                ) => {
                  const errors: SwitchingOrdersItemErr[] = []
                  const toFundErrMsg = !toFundCode
                    ? 'Please select to fund'
                    : undefined
                  !!toFundErrMsg &&
                    errors.push({
                      code: fromFundCode,
                      message: toFundErrMsg,
                      errType: SwitchingOrdersErrTypeEnum.TO,
                    })
                  const _toMinAmt = Number(toMinAmt.toFixed(DEF_DECIMAL))
                  const _toMaxAmount = Number(toMaxAmount.toFixed(DEF_DECIMAL))
                  const amountErrMsg =
                    amount <= 0
                      ? 'Required'
                      : amount < _toMinAmt
                      ? 'lower-than-min-switch-amount'
                      : amount >
                        Number(
                          Math.min(fromMaxAmount, availableAmount).toFixed(
                            DEF_DECIMAL
                          )
                        )
                      ? 'larger-than-max-switch-out-amount'
                      : _toMaxAmount > 0 && amount > _toMaxAmount
                      ? 'larger-than-max-switch-in-amount'
                      : undefined
                  !!amountErrMsg &&
                    errors.push({
                      code: fromFundCode,
                      message: amountErrMsg,
                      errType: SwitchingOrdersErrTypeEnum.AMOUNT,
                    })
                  return !!errors.length ? [...acc, ...errors] : acc
                },
                [] as SwitchingOrdersItemErr[]
              )
              return !_err.length || JSON.stringify(_err)
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <SwitchingOrders
              {...field}
              error={error}
              className='mb-4'
              onClickSwitchIn={onClickSwitchIn}
              onClickSwitchOut={onClickSwitchOut}
            />
          )}
        />

        <p className='text-xs text-pi-gray-2'>{t('switching-note')}</p>

        {showAgentCode && (
          <>
            <Divider className='my-6 -mx-4 sm:-mx-6 border' />
            <Controller
              name='agentCode'
              control={control}
              render={({ field, fieldState: { error } }) => (
                <AgentCodeComp
                  {...field}
                  error={error}
                  disabled={false}
                  ref={agentCodeRef}
                />
              )}
            />
          </>
        )}

        <ButtonWithLoading
          type='submit'
          fullWidth
          variant='contained'
          size='large'
          className='mb-10 mt-6 sm:mb-2'
        >
          {t('Continue', { ns: 'common' })}
        </ButtonWithLoading>
      </form>
      <InsufficientBalanceModal
        {...insufficientBalanceModal}
        trxType={TrxTypeEnum.SWITCHING}
        onContinue={onContinue}
      />
    </>
  )
}

export default SwitchAmount
