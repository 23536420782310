import LogoCimb from '@pimy-b2cweb/frontend-lib/libSrc/assets/logo-cimb.svg'
import { IconButton } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import SwitchLang from '@/components/SwitchLang'
import { RoutePathEnum } from '@/constants/routePath'
import useIsSignedIn from '@/hooks/useIsSignedIn'
import { useShowHeaderStatus } from '@/hooks/useShowHeaderStatus'
import { ReactComponent as Airplay } from './assets/airplay.svg'
import { ReactComponent as MenuIco } from './assets/align-right.svg'
import { ReactComponent as CloseIco } from './assets/close-ico.svg'
import { ReactComponent as FileText } from './assets/file-text.svg'
import { ReactComponent as PieChart } from './assets/pie-chart.svg'
import { ReactComponent as UserWhite } from './assets/user-white.svg'
import { ReactComponent as ZoomIn } from './assets/zoom-in.svg'
import { ReactComponent as MailIco } from './assets/mail.svg'
import { ReactComponent as HelpSupportIco } from './assets/help-support-ico.svg'
import { ReactComponent as FeedbackIco } from './assets/feedback-ico.svg'
import NavItem from './NavItem'
import Navbar, { NavbarNavItem } from './Navbar'
import ProfileMenu from './ProfileMenu'
import useHandleUserStatus from '@/hooks/useHandleUserStatus'
import { CS_URL } from '@pimy-b2cweb/frontend-lib'
import { useSelector } from 'react-redux'
import { selectReferralCode } from '@/stores/auth.selectors'

export const Header = () => {
  const { t } = useTranslation('header')
  const [navOpen, setNavOpen] = useState(false)
  const showHeader = useShowHeaderStatus()
  const isSignedIn = useIsSignedIn()
  const referralCode = useSelector(selectReferralCode)
  const { isValidUserStatus, handleCheckUserStatus } = useHandleUserStatus()

  const onClickInvest = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    handleCheckUserStatus({})
    if (!isValidUserStatus) {
      event.preventDefault()
    }
  }

  const desktopNavItems: NavbarNavItem[] = [
    { label: 'dashboard', path: RoutePathEnum.DASHBOARD, Icon: PieChart },
    {
      label: 'invest',
      path: RoutePathEnum.ORDER_PURCHASE,
      Icon: Airplay,
      isNew: true,
      onClick: onClickInvest,
    },
    {
      label: 'transaction-history',
      path: RoutePathEnum.ORDER_HISTORY,
      Icon: FileText,
    },
    {
      label: 'market-insight',
      path: RoutePathEnum.ERROR, // TODO: to be replaced with actual Path
      Icon: ZoomIn,
    },
    {
      label: 'inbox',
      path: RoutePathEnum.INBOX,
      Icon: MailIco,
    },
  ]

  const mobileNavItems: NavbarNavItem[] = [
    ...desktopNavItems,
    {
      label: 'manage-profile',
      path: RoutePathEnum.PROFILE_EDIT,
      Icon: UserWhite,
    },
    {
      label: 'help-support',
      path: CS_URL,
      Icon: HelpSupportIco,
    },
    {
      label: 'feedback',
      path: RoutePathEnum.ERROR, // TODO: to be replaced with actual Path
      Icon: FeedbackIco,
    },
  ]

  return (
    <>
      <header
        className={`main-header sticky ${
          !showHeader ? 'header-hide -top-20' : 'header-show top-0'
        } ${
          navOpen ? 'max-[767.98px]:top-0' : ''
        } z-50 bg-white p-4 lg:py-2 shadow-[0_2px_6px_0px_rgba(0,0,0,0.2)] transition-top duration-300`}
      >
        <div className='max-w-screen-xl mx-auto flex flex-row justify-between items-center gap-6 md:gap-4'>
          <NavLink
            to={isSignedIn ? RoutePathEnum.DASHBOARD : RoutePathEnum.SIGN_IN}
          >
            <img
              src={LogoCimb}
              className='main-logo block w-[128px] h-auto'
              alt='Principal in alliance with CIMB'
            />
          </NavLink>
          <div className='flex justify-end items-center gap-4 lg:gap-10'>
            {!!isSignedIn && (
              <div className='hidden md:!flex justify-end items-center gap-4 lg:gap-10 font-bold leading-6 '>
                {desktopNavItems.map(
                  ({ path, label, isNew = false, onClick }) => (
                    <NavLink
                      key={label}
                      to={path}
                      onClick={onClick}
                      className={({ isActive }) =>
                        `text-pi-principal-blue hover:text-pi-prussian-blue hover:no-underline ${
                          isActive ? 'text-pi-prussian-blue font-black' : ''
                        }`
                      }
                    >
                      <NavItem label={t(label)} isNew={isNew} />
                    </NavLink>
                  )
                )}

                <ProfileMenu referralCode={referralCode} />
              </div>
            )}

            <div className='flex justify-end items-center gap-4 lg:gap-10'>
              <SwitchLang />
              {!!isSignedIn && (
                <IconButton
                  className='cursor-pointer w-6 h-6 p-0 md:hidden'
                  onClick={() => setNavOpen(!navOpen)}
                >
                  {navOpen ? <CloseIco /> : <MenuIco />}
                </IconButton>
              )}
            </div>
          </div>
        </div>
      </header>
      {!!isSignedIn && (
        <Navbar
          navOpen={navOpen}
          setNavOpen={setNavOpen}
          NavItems={mobileNavItems}
          referralCode={referralCode}
        />
      )}
    </>
  )
}

export default Header
